export default async function fetchAPI(path: string, options?: RequestInit) {
  const parsedOptions = {
    ...options,
    headers: {
      ...options?.headers,
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
  };

  const response = await fetch(path, parsedOptions);

  let json = null;
  try {
    // Try to parse JSON response
    if (response.status !== 204) {
      json = await response.json();
    } else {
      console.log('No content to process (204).');
      return { statusCode: response.status };
    }
  } catch (error) {
    // If JSON parsing fails, and it's a 404 error, return status 404
    if (response.status === 404) {
      return { statusCode: response.status, message: 'Resource not found' };
    }

    if (response.status === 500) {
      return { statusCode: response.status, message: 'Server Error' };
    }

    // If it's another error, rethrow the error
    throw error;
  }

  // Return the parsed JSON with the response status code
  if (json) {
    return { ...json, statusCode: response.status };
  }
  return { statusCode: response.status };
}
