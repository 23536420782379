import { TrainingFacility } from '../../types/TrainingFacility';

const endpoint = `/api/trainingfacilities`;

const getTrainingFacilities = async () => {
  const trainingFacilitiesFetchResponse = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
  });

  const facilities =
    (await trainingFacilitiesFetchResponse.json()) as TrainingFacility[];

  return { facilities, statusCode: trainingFacilitiesFetchResponse.status };
};

export default getTrainingFacilities;
