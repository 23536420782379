import { useState } from 'react';
import { Quote } from '../../types/Quote';
import Heading from '../../components/typography/Heading';
import QuoteCard from '../../components/organisms/QuoteCard/QuoteCard';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../components/atoms/Button/Button';

interface ConfirmQuoteModalProps {
  quote: Quote;
  onConfirm: () => unknown;
  onContinueEditing: () => unknown;
}

export default function ConfirmQuoteModal({
  quote,
  onConfirm,
  onContinueEditing,
}: ConfirmQuoteModalProps) {
  const [isConfirming, setIsConfirming] = useState(false);

  const handleConfirm = async () => {
    try {
      setIsConfirming(true);
      await onConfirm();
      setIsConfirming(false);
    } catch (error) {
      setIsConfirming(false);
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col gap-4 mb-24">
      <Heading level={3}>Confirm Quote</Heading>
      <div className="flex flex-col gap-4">
        <Heading level={3}>Please confirm the details of your quote.</Heading>
        <QuoteCard quote={quote} />
        <div className="grid grid-cols-2 gap-4 w-full">
          <SecondaryButton onClick={onContinueEditing}>
            Continue Editing
          </SecondaryButton>
          <PrimaryButton onClick={handleConfirm} isLoading={isConfirming}>
            Confirm
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
}
