/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Field, FieldConfig, useField } from 'formik';
import classNames from 'classnames';
import ErrorMessage from '../../molecules/ErrorMessage';

// I was trying to replicate this: https://github.com/jaredpalmer/formik/blob/main/examples/CustomInputs.js#L8

// not totally type safe, but prevents TS from complaining about unknown props for now
type CustomComponentProps = React.ComponentProps<
  Exclude<FieldConfig['component'], undefined | string>
>;

type FormFieldProps = CustomComponentProps & {
  inline?: boolean;
  noMaxWidth?: boolean;
  name: string;
  label: string | React.ReactNode;
  component?: FieldConfig['component'];
  optional: boolean;
};

/**
 * a wrapper component to use Formik for most of our inputs
 ```
 <FormikField
  name="email"
  label="Email"
  type="email"
  placeholder="Enter your email"
  component={FormikInput}
 />
```
 */
export default function FormikField({
  inline = false,
  name,
  noMaxWidth = false,
  label,
  optional = false,
  ...rest
}: FormFieldProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(name);
  const { error, touched } = meta;

  return (
    <div
      className={classNames('w-full py-5', {
        'flex flex-row-reverse justify-end items-center gap-4': inline,
        'max-w-sm': !noMaxWidth,
      })}
    >
      <label
        className="text-base text-left font-semibold block mb-1 max-w-fit"
        htmlFor={name}
      >
        {optional ? (
          <>
            {label} <span className="italic font-normal">optional</span>
          </>
        ) : (
          <>
            {label} <span className="text-error">&nbsp;*</span>
          </>
        )}
      </label>
      <div className="relative">
        <Field name={name} {...rest} />
        {error && touched && (
          <div className="absolute w-[65ch]">
            <ErrorMessage>{error}</ErrorMessage>
          </div>
        )}
      </div>
    </div>
  );
}
