/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { ChangeEvent, useEffect, useState } from 'react';

type ParsedFormValue =
  | string
  | number
  | boolean
  | File
  | Record<string, unknown>
  | unknown[]
  | Date
  | null;

interface UseFormHook<T> {
  inputs: T;
  updateFields: (fields: Record<string, ParsedFormValue>) => void;
  resetForm: () => void;
  /**
   * This function gets passed the native event from a given HTML input and gracefully extracts the value to update the form
   * */
  handleChange: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}

/**
 * Custom hook to manage form state and input handling.
 * Maintains form values in state, provides handlers to update state and reset form.
 * Handles change events from inputs to update state. Parses values from inputs.
 * Initial form values can be passed in and reset to later.
 * @deprecated Use MultiStepForm or SettingsForm instead, or create one using `Formik`, `src/atoms/Form` fields and `Yup` for validation
 */
export default function useForm<
  T extends Partial<Record<string & keyof T, ParsedFormValue & T[keyof T]>>
>(initial: T): UseFormHook<T> {
  // TODO: handle Date
  const [inputs, setInputs] = useState<T>(initial);
  const initialValues = Object.values(initial).join('');

  useEffect(() => {
    setInputs(initial);
    // if you add initial to the dependencies, it bugs out when you start calling handleChange
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  type Fields = Record<string, ParsedFormValue>;

  function updateFields(fields: Fields) {
    setInputs({
      ...inputs,
      ...fields,
    });
  }

  function handleChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const element = event?.target;

    let value: ParsedFormValue = element?.value;
    const { id, type } = element;

    if (type === 'checkbox') value = (element as HTMLInputElement)?.checked;
    else if (type === 'number') value = parseInt(value, 10);
    else if (
      type === 'file' &&
      element instanceof HTMLInputElement &&
      element.files &&
      element.files[0]
    ) {
      value = element.files[0] as File;
    }

    updateFields({
      [id]: value,
    });
  }

  function resetForm() {
    setInputs(initial);
  }

  return {
    inputs,
    handleChange,
    updateFields,
    resetForm,
  };
}
