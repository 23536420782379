/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext } from 'react';
import { useField } from 'formik';
import classNames from 'classnames';
import Direction from '../../../types/Direction';
import ErrorMessage from '../../molecules/ErrorMessage';
import { DisabledContext } from '../../organisms/Form/Form';
import { SettingsFormDisabledContext } from '../../organisms/SettingsForm/SettingsForm';

export interface Option {
  label: string;
  value: string | number | null;
}
export interface FormikDropdownProps<T> {
  placeholder?: string;
  options: readonly Option[];
  direction?: Direction;
  disabled?: boolean;
  className?: string;
  name: string;
  label: string;
  optional?: boolean;
}

function FormikDropdown({
  name,
  label,
  placeholder,
  options,
  direction = 'bottom',
  disabled = false,
  className,
  optional = true,
}: FormikDropdownProps<any>) {
  // Get the disabled state from the context of either SettingsForm or Form
  const settingsDisabled = useContext(SettingsFormDisabledContext);
  const formDisabled = useContext(DisabledContext);
  const [field, meta, helpers] = useField(name);
  const { onBlur, onChange } = field;
  const { value, touched, error } = meta;
  const { setValue, setError, setTouched } = helpers;
  const localDisabled = settingsDisabled ?? formDisabled ?? disabled;

  return (
    <div className="w-full py-5 relative">
      <label
        className="text-base text-left font-semibold block mb-1 max-w-fit"
        htmlFor={name}
      >
        {optional ? (
          <>
            {label} <span className="italic font-normal">optional</span>
          </>
        ) : (
          <>
            {label} <span className="text-error">&nbsp;*</span>
          </>
        )}
      </label>
      <div className={`dropdown dropdown-${direction}`}>
        <div
          role="button"
          aria-disabled={localDisabled}
          tabIndex={0}
          className={classNames(
            'btn btn-sm font-normal hover:bg-base-200 bg-base-100 text-neutral group-disabled/field:bg-base-200 group-disabled/field:text-neutral group-disabled/field:',
            localDisabled && 'cursor-not-allowed'
          )}
          onClick={() => setTouched(true)}
        >
          {options.find((o) => o.value === value)?.label ||
            value ||
            placeholder}
          <span>
            <svg
              className="w-2.5 h-2.5 ms-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </span>
        </div>
        {!localDisabled && (
          <ul
            tabIndex={0}
            className={`dropdown-content text-sm z-[1] p-2 shadow bg-base-100 rounded-box w-52 ${className}`}
            id={`${placeholder}FormikDropdown`}
          >
            {options.map((o) => (
              <li
                key={o.value}
                className="cursor-pointer hover:bg-info/20"
                value={o.value ?? ''}
                onClick={() => {
                  setValue(o.value);
                  (document?.activeElement as HTMLElement)?.blur();
                }}
              >
                {o.label}
              </li>
            ))}
          </ul>
        )}
      </div>
      {error && touched && (
        <div className="absolute w-[65ch]">
          <ErrorMessage>{error}</ErrorMessage>
        </div>
      )}
    </div>
  );
}

export default FormikDropdown;
