/* eslint-disable jsx-a11y/control-has-associated-label */
import { FieldProps } from 'formik';
import React, { InputHTMLAttributes, forwardRef, ForwardedRef } from 'react';

export type FormikCheckboxProps = InputHTMLAttributes<HTMLInputElement> &
  FieldProps;

const FormikCheckbox = forwardRef(
  (
    {
      className,
      field, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      ...standardInputProps
    }: FormikCheckboxProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <input
        className="checkbox p-3 group-disabled/field:checked:!opacity-100 group-disabled/field:[--chkbg:oklch(var(--b2))] group-disabled/field:checked:[--chkfg:oklch(var(--n))]"
        type="checkbox"
        ref={ref}
        checked={field.value}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...standardInputProps}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
      />
    );
  }
);

export default FormikCheckbox;
