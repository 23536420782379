import { useState } from 'react';
import {
  StateButton,
  PrimaryButton,
} from '../../components/atoms/Button/Button';
import Heading from '../../components/typography/Heading';
import { useModalContext } from '../../hooks/ModalProvider/ModalProvider';
import PlaneType from '../../types/PlaneType';
import Select from '../../components/atoms/Select/Select';

interface AddTypeRatingModalProps {
  typeRatingOptions: PlaneType[];
  handleNewTypeRating: (typeRating: PlaneType) => void;
}

export default function AddTypeRatingModal({
  typeRatingOptions,
  handleNewTypeRating,
}: AddTypeRatingModalProps) {
  const { closeModal } = useModalContext();

  const [selectedTypeRating, setSelectedTypeRating] = useState<PlaneType>(
    typeRatingOptions[0]
  );

  const typeRatingOptionsAsStrings = typeRatingOptions.map(
    (pt) => pt.craftName
  );

  const onSelect = (craftName: string) => {
    const matchingTypeRating = typeRatingOptions.find(
      (pt) => pt.craftName === craftName
    );
    if (matchingTypeRating) {
      setSelectedTypeRating(matchingTypeRating);
    }
  };

  const handleConfirm = () => {
    if (selectedTypeRating) {
      handleNewTypeRating(selectedTypeRating);
    }
    closeModal();
  };

  return (
    <div className="flex flex-col gap-4 mb-24">
      <Heading level={3}>Add a Type Rating or Aircraft Qualification</Heading>
      <Select
        options={typeRatingOptionsAsStrings}
        onChange={onSelect}
        defaultvalue={
          selectedTypeRating?.craftType || typeRatingOptionsAsStrings[0]
        }
      />
      <div className="flex gap-4">
        <StateButton
          outline
          status="error"
          className="btn-sm"
          onClick={closeModal}
        >
          Cancel
        </StateButton>
        <PrimaryButton className="btn-sm" onClick={handleConfirm}>
          Confirm
        </PrimaryButton>
      </div>
    </div>
  );
}
