import { Navigate } from 'react-router-dom';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import UserRole from '../../types/UserRole';
import Card from '../../components/molecules/Card/Card';
import Loader from '../../components/molecules/Loader';

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredRole?: UserRole | 'user';
}

function ProtectedRoute({
  children,
  requiredRole = 'user',
}: ProtectedRouteProps): JSX.Element {
  const { user, role, hasKey } = useUser();

  if (!user) {
    if (!hasKey()) {
      // User hasn't been loaded, and the UserProvider isn't
      // loading it redirect to the login page
      return <Navigate to="/login" />;
    }
    return (
      <Card responsive>
        <Loader level={3} caption="Loading Your Account..." />
      </Card>
    );
  }
  if (requiredRole !== 'user' && role !== requiredRole) {
    // The User has the wrong role active for this route
    // TODO use this redirect in this case when the backend has roles working
    // return <Navigate to="/trips" />;
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default ProtectedRoute;
