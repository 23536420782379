import { ReactNode } from 'react';

function PrimaryTripLayout({ children }: { children: ReactNode }) {
  return (
    <div className="relative grid grid-cols-1 gap-4 lg:gap-8 lg:grid-cols-3 overflow-y-scroll">
      {children}
    </div>
  );
}

export default PrimaryTripLayout;
