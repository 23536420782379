import format from 'date-fns/format';
import User from '../../../types/User';
import Badge from '../../atoms/Badge/Badge';
import Heading from '../../typography/Heading';
import Paragraph from '../../typography/Paragraph';
import Card from '../Card/Card';

// TODO replace with real user data, or at least dynamic data
import planeImage from '../../../assets/images/HeroImage1.png';
import userImage from '../../../assets/images/HeroCTAImage1.png';
import Avatar from '../Avatar/Avatar';
import Rule from '../../atoms/Rule';
import AvatarModal from '../AvatarModal/AvatarModal';
import { useModalContext } from '../../../hooks/ModalProvider/ModalProvider';

interface UserCardProps {
  className?: string;
  user: User;
  userTitle?: string;
}

function ConsumerCard({ user, className, userTitle }: UserCardProps) {
  const { openModal } = useModalContext();

  const openAvatarModal = () => {
    if (user.avatar?.image) openModal(AvatarModal(user, user.avatar.image));
  };

  return (
    <Card
      responsive
      noPadding
      noGap
      className={`@container border-primary border-4 ${className}`}
    >
      <div className="relative flex flex-col gap-4 p-4">
        <button onClick={openAvatarModal} type="button" className="text-left">
          <Avatar
            initials={`${user.firstName[0]}${user.lastName[0]}`}
            avatarUrl={user.avatar?.image}
          />
        </button>
        <Heading level={3} className="font-bold">
          {user.firstName} {user.lastName}
        </Heading>
        <Paragraph>
          <span>
            <span className="font-bold">Member since: </span>
            {new Date(user.dateJoined).toLocaleDateString()}
          </span>
          <br />
          {user.consumerRole.managementCompany && (
            <>
              <span>
                <span className="font-bold">Management company: </span>
                {user.consumerRole.managementCompany}
              </span>
              <br />
            </>
          )}
          {user.consumerRole.position && (
            <>
              <span>
                <span className="font-bold">Position: </span>
                {user.consumerRole.position}
              </span>
              <br />
            </>
          )}
        </Paragraph>
      </div>
    </Card>
  );
}

export default ConsumerCard;
