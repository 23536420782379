import fetchAPI from '../util';
import { endpoints } from '../endpoints';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';

export interface SetupIntentDetails {
  clientSecret: string;
  connectAccountId: string;
  accountLinkUrl: string;
  requiresStripeOnboarding: boolean;
  onboardingRequirements: string[];
}

/**
 * @returns a clientSecret associated with a SetupIntent
 */
export default async function createSetupIntent(): Promise<SetupIntentDetails> {
  const response = await fetchAPI(endpoints.STRIPE, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
  });
  return remapKeysToCamelCase(response) as SetupIntentDetails;
}
