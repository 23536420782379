import PilotCertificateLimit from '../../types/PilotCertificateLimit';

const endpoint = `/api/pilot_cert_limits/`;

async function addPilotCertificateLimit(
  limitOptionId: number,
  certificateId: number
) {
  try {
    const optionResponse = await fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ limit: limitOptionId, pilot_cert: certificateId }),
    });

    const option = await optionResponse.json();
    return option as PilotCertificateLimit;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default addPilotCertificateLimit;
