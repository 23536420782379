/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import classNames from 'classnames';
import { noop } from '../../../utilities';
import Loader from '../../molecules/Loader';

import SemanticKind from '../../../types/SemanticKind';
import SemanticSize from '../../../types/SemanticSize';

import './Button.css';
// ^ this import makes disabled buttons gray instead of transparent

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: SemanticSize;
  isLoading?: boolean;
};

function Button({
  children,
  type = 'button',
  className,
  disabled = false,
  size = 'regular',
  isLoading = false,
  style,
  onBlur,
  onClick = noop,
  onFocus,
}: ButtonProps) {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled && !isLoading && onClick) onClick(event);
  };
  const handleFocus = (event: React.FocusEvent<HTMLButtonElement>) => {
    if (!disabled && !isLoading && onFocus) onFocus(event);
  };

  const sizeStyles = {
    small: 'btn-sm',
    regular: '',
    large: 'btn-lg',
  };

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classNames('btn capitalize', sizeStyles[size], className, {
        disabled,
      })}
      onBlur={onBlur}
      onClick={handleClick}
      onFocus={handleFocus}
      aria-disabled={disabled || isLoading}
      style={style}
      disabled={disabled || isLoading}
    >
      {isLoading ? <Loader /> : children}
    </button>
  );
}

type ButtonStyles = ButtonProps & {
  outline?: boolean;
};
function PrimaryButton({ className, outline = false, ...rest }: ButtonStyles) {
  return (
    <Button
      className={classNames(
        'btn-primary',
        { 'btn-outline': outline },
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}

function SecondaryButton({
  className,
  outline = false,
  ...rest
}: ButtonStyles) {
  return (
    <Button
      className={classNames(
        'btn-secondary',
        { 'btn-outline': outline },
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}
function AccentButton({ className, outline = true, ...rest }: ButtonStyles) {
  return (
    <Button
      className={classNames(
        'btn-accent text-white',
        { 'btn-outline': outline },
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}

type StateButtonStyles = ButtonProps & {
  outline?: boolean;
  status: 'error' | 'success' | 'warning';
  inverse?: boolean;
};
function StateButton({
  className,
  outline = false,
  status,
  inverse,
  ...rest
}: StateButtonStyles) {
  let statusClass = '';

  switch (status) {
    case 'error':
      statusClass = 'btn-error';
      break;
    case 'success':
      statusClass = 'btn-success text-white';
      break;
    case 'warning':
      statusClass = 'btn-warning';
      break;
    default:
      statusClass = 'btn-info';
  }
  return (
    <Button
      className={classNames(
        statusClass,
        { 'btn-outline': outline },
        { 'bg-white': inverse },
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
  );
}

function CancelButton({
  className,
  outline = false,
  onClick,
  ...rest
}: ButtonStyles) {
  return (
    <StateButton
      outline
      status="error"
      className={`rounded-full ${className}`}
      onClick={onClick}
      {...rest}
    >
      <i className="fa-solid fa-xmark" />
    </StateButton>
  );
}
function EditButton({
  className,
  outline = true,
  onClick,
  ...rest
}: ButtonStyles) {
  return (
    <SecondaryButton
      outline
      className={`rounded-full ${className}`}
      onClick={onClick}
      {...rest}
    >
      <i className="fa-solid fa-pencil" />
    </SecondaryButton>
  );
}
function SaveButton({
  className,
  outline = true,
  onClick,
  ...rest
}: ButtonStyles) {
  return (
    <StateButton
      outline
      status="success"
      className={`rounded-full ${className}`}
      onClick={onClick}
      {...rest}
    >
      <i className="fa-solid fa-floppy-disk" />
    </StateButton>
  );
}

export default Button;
export {
  PrimaryButton,
  SecondaryButton,
  StateButton,
  AccentButton,
  EditButton,
  CancelButton,
  SaveButton,
};
