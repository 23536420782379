import { Expense } from '../../types/Expense';
import isEmptyObject from '../../utilities/isEmptyObject';
import fetchAPI from '../util';

// async function handleRemovedExpenses(expenses: number[]) {
//   if (expenses.length > 0) {
//     const removedExpensesPromises = expenses.map(async (tripId) => {
//       const response = await fetchAPI(`/api/trips/expense/${tripId}/`, {
//         method: 'DELETE',
//       });

//       if (response.status !== 204) {
//         throw new Error('Unable to remove expense');
//       }
//     });

//     await Promise.all(removedExpensesPromises);
//   }
// }

async function submitFinalExpenses(
  tripId: string,
  itemizedExpenses: Expense[]
) {
  try {
    if (isEmptyObject(itemizedExpenses)) return;

    const expensesResponse = await fetchAPI(
      `/api/trips/as_pilot/${tripId}/expense/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(itemizedExpenses),
      }
    );

    // then take those itemizedExpenses and submit the receipts with expense id
    if (expensesResponse && expensesResponse.expenses.length > 0) {
      // if expenses exist, submit the relevant receipts
      try {
        const receiptsPromises = expensesResponse.expenses.map(
          async (expense: Expense, i: number) => {
            const formData = new FormData();
            // add receipt as "document"
            formData.append('document', itemizedExpenses[i].receipt);
            formData.append('expense_id', String(expense.id));

            const requestURL = `/api/receipts/`;

            const dataResponse = await fetchAPI(`${requestURL}`, {
              method: 'POST',
              body: formData,
            });

            if (dataResponse.status !== 201 && dataResponse.status !== 200) {
              throw new Error('Unable to create receipt');
            } else {
              return dataResponse.json();
            }
          }
        );

        await Promise.all(receiptsPromises);
      } catch (e) {
        console.log(e);
        throw new Error('Unable to submit expenses');
      }
    }
  } catch (err) {
    console.log(err);
  }
}

export default submitFinalExpenses;
