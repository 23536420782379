import PilotCertificateRating from '../../types/PilotCertificateRating';
import { endpoints } from '../endpoints';

interface PilotCertificateRatingsResponse {
  ratings: PilotCertificateRating[];
  statusCode: number;
}

/** http://flyingcompany.xyz/admin/flying_api/pilotcertrating */
const getPilotCertificateRatings =
  async (): Promise<PilotCertificateRatingsResponse | null> => {
    try {
      const pilotCertificateRatings = await fetch(
        endpoints.PILOT_CERTIFICATE_RATINGS,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Authorization: `Token ${localStorage.getItem('key')}`,
          },
        }
      );

      const ratings = await pilotCertificateRatings.json();

      return {
        ratings,
        statusCode: pilotCertificateRatings.status,
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

export default getPilotCertificateRatings;
