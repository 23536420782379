import React from 'react';

import * as Yup from 'yup';
import { FormikHelpers } from 'formik';

import Form from '../../../../components/organisms/Form/Form';
import { FileSelection } from '../../../../types/FileSelection';
import ConfirmHireFormFields from './ConfirmHireFields';
import { useUser } from '../../../../hooks/UserProvider/UserProvider';

export interface ConfirmHireFields {
  signature: FileSelection;
  hasViewedAgreement: boolean;
}

interface ConfirmHireFormProps {
  bidId: number;
  isBid: boolean;
  handleCancel: () => void;
  handleSubmit: (
    values: ConfirmHireFields,
    helpers: FormikHelpers<ConfirmHireFields>
  ) => void;
}

const validConfirmedHire = Yup.object<ConfirmHireFields>().shape({
  signature: Yup.mixed<File>().required('Required'),
  hasViewedAgreement: Yup.boolean().oneOf(
    [true],
    `You must agree to the Pilot Service Agreement to use our service.`
  ),
});

export default function ConfirmHireForm({
  bidId,
  isBid,
  handleCancel,
  handleSubmit,
}: ConfirmHireFormProps) {
  const { role } = useUser();

  const initialValues: ConfirmHireFields = {
    signature: null,
    hasViewedAgreement: false,
  };

  return (
    <Form
      title="Services Agreement"
      isCancellable
      isValid={false}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      initialValues={initialValues}
      validationSchema={validConfirmedHire}
      submitLabel={role === 'consumer' ? 'Request Hire' : 'Confirm Hire'}
      cancelLabel={role === 'consumer' ? "I'm not ready" : 'Decline Hire'}
    >
      <ConfirmHireFormFields bidId={bidId} isBid={isBid} />
    </Form>
  );
}
