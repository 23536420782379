import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { differenceInDays, parseISO, set } from 'date-fns';
import postQuote from '../../../API/Trips/createQuote';
import {
  PrimaryButton,
  StateButton,
} from '../../../components/atoms/Button/Button';
import TripCard from '../../../components/organisms/TripCard/TripCard';
import Heading from '../../../components/typography/Heading';
import { useModalContext } from '../../../hooks/ModalProvider/ModalProvider';
import { useToast } from '../../../hooks/ToastProvider';
import { useUser } from '../../../hooks/UserProvider/UserProvider';
import useForm from '../../../hooks/useForm';
import { createQuote, PER_DIEM_DEFAULT, Quote } from '../../../types/Quote';
import ConfirmQuoteModal from '../ConfirmQuoteModal';
import Trip from '../../../types/Trip';
import { ROUTES } from '../../routerConfig';
import getPaymentMethods from '../../../API/Payments/getPaymentMethods';
import Alert from '../../../components/atoms/Alert/Alert';
import Paragraph from '../../../components/typography/Paragraph';
import QuoteForm from '../subcomponents/QuoteForm/QuoteForm';
import ControlBar from '../../../components/molecules/ControlBar/ControlBar';

interface AvailableTripProps {
  trip: Trip;
  id: string;
}

function AvailableTrip({ trip, id }: AvailableTripProps) {
  const { user } = useUser();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const { openModal, closeModal } = useModalContext();

  const [creatingQuote, setCreatingQuote] = useState(false);
  const [flyingDays, setFlyingDays] = useState(0);

  const initialForm = createQuote({
    flyingDayRate:
      user?.pilotRole.statistics.planeTypeStatistics.find(
        (pts) => pts.planeType === trip?.planeType.id
      )?.pricePerDay ||
      user?.pilotRole.defaultAircraftPricePerDay ||
      0,
    travelDayRate: user?.pilotRole.defaultTravelPricePerDay || 0,
    flyingDays:
      differenceInDays(
        set(parseISO(trip.endDate), {
          hours: 23,
          minutes: 59,
          seconds: 59,
          milliseconds: 0,
        }),
        set(parseISO(trip.startDate), {
          hours: 0,
          minutes: 0,
          seconds: 1,
          milliseconds: 0,
        })
      ) + 1,
  });

  const { inputs, handleChange, resetForm } = useForm(initialForm);

  const navigateToTrips = () => navigate(ROUTES.TRIPS);

  const [hasPaymentMethods, setHasPaymentMethods] = useState<boolean>(false);
  const [loadingPaymentMethods, setLoadingPaymentMethods] = useState(true);

  useEffect(() => {
    const getAndSetHasPaymentMethods = async () => {
      const paymentMethodResponse = await getPaymentMethods();
      if (paymentMethodResponse.bank_accounts.length) {
        setHasPaymentMethods(true);
      }
      setLoadingPaymentMethods(false);
    };
    getAndSetHasPaymentMethods();
  }, []);

  const handleSubmit = async (values: Quote) => {
    openModal(
      <ConfirmQuoteModal
        quote={values}
        onConfirm={async () => {
          if (!user?.pilotRole) return;
          const createdBid = await postQuote(user.pilotRole, id, values);
          console.log('Quote API Response:', createdBid);

          addToast(<span>Submitted quote!</span>, 'success');
          closeModal();
          // eslint-disable-next-line no-restricted-globals
          location.reload(); // refresh page TODO just reload the trip instead
        }}
        onContinueEditing={() => {
          closeModal();
        }}
      />
    );
  };

  const handleCancelCreateQuote = () => {
    resetForm();
    setCreatingQuote(false);
  };

  return (
    <>
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" /> Trips
        </PrimaryButton>
        {!creatingQuote && (
          <StateButton
            status="success"
            className="ml-auto"
            size="small"
            onClick={() => setCreatingQuote(true)}
            disabled={
              loadingPaymentMethods ||
              (!loadingPaymentMethods && !hasPaymentMethods)
            }
          >
            {loadingPaymentMethods ? 'Loading...' : '+ Create Quote'}
          </StateButton>
        )}
        {creatingQuote && (
          <StateButton
            size="small"
            className="ml-auto"
            status="error"
            onClick={handleCancelCreateQuote}
          >
            Cancel Quote
          </StateButton>
        )}
      </ControlBar>
      <div className="flex flex-col gap-4">
        <Heading level={2}>Viewing &ldquo;{trip.name}&rdquo;</Heading>
        {!loadingPaymentMethods && !hasPaymentMethods && (
          <Alert
            message={
              <Paragraph>
                Set up a{' '}
                <Link to="/billing-and-payments" className="underline">
                  payment method
                </Link>{' '}
                to provide a quote on this trip.
              </Paragraph>
            }
            className="max-w-fit"
          />
        )}
        <div className={`grid gap-4 ${creatingQuote ? 'lg:grid-cols-2' : ''}`}>
          <div className="max-w-2xl">
            <TripCard
              className="max-w-full h-full"
              trip={trip}
              showStatus={false}
              expanded="always"
              interactive={false}
            />
          </div>
          {creatingQuote && (
            <div>
              <QuoteForm
                initialForm={createQuote({
                  flyingDayRate:
                    user?.pilotRole.statistics.planeTypeStatistics.find(
                      (pts) => pts.planeType === trip?.planeType.id
                    )?.pricePerDay ||
                    user?.pilotRole.defaultAircraftPricePerDay ||
                    0,
                  travelDayRate: user?.pilotRole.defaultTravelPricePerDay || 0,
                  flyingDays:
                    differenceInDays(
                      set(parseISO(trip.endDate), {
                        hours: 23,
                        minutes: 59,
                        seconds: 59,
                        milliseconds: 0,
                      }),
                      set(parseISO(trip.startDate), {
                        hours: 0,
                        minutes: 0,
                        seconds: 1,
                        milliseconds: 0,
                      })
                    ) + 1,
                  perDiemRate: PER_DIEM_DEFAULT,
                  travelDays: 0,
                  airTravelCosts: 0,
                  airTravelNotes: '',
                  groundTravelCosts: 0,
                  groundTravelNotes: '',
                  hotelCosts: 0,
                  hotelNotes: '',
                  otherCosts: 0,
                  otherCostsNotes: '',
                })}
                handleCancel={handleCancelCreateQuote}
                handleSubmit={handleSubmit}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AvailableTrip;
