/**
 * A certificate type is something like Air Transport,
 * Instructor, etc
 */

import PilotCertificateType from '../../types/PilotCertificateType';

const endpoint = `/api/cert_types/`;

interface CertificateTypesResponse {
  certificateTypes: PilotCertificateType[];
  statusCode: number;
}

const getCertificateTypes =
  async (): Promise<CertificateTypesResponse | null> => {
    try {
      const certificateTypesResponse = await fetch(endpoint, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Token ${localStorage.getItem('key')}`,
        },
      });

      const certificateTypes = await certificateTypesResponse.json();

      return {
        certificateTypes,
        statusCode: certificateTypesResponse.status,
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

export default getCertificateTypes;
