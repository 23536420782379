// Create a new component called AircraftLocation following the pattern in AircraftMaintenance.tsx
import React from 'react';
import FormikInput from '../../../atoms/Form/FormikInput';
import FormikField from '../../../atoms/Form/FormikField';
import FormikTextArea from '../../../atoms/Form/FormikTextArea';
import FormikSearchableDropdown from '../../../atoms/Form/FormikSearchableDropdown';
import { useAirports } from '../../../../hooks/AirportsProvider';

function AircraftLocation() {
  const { airports, getAirportDisplayName, findAirportByDisplayName, loading } =
    useAirports();

  const airportOptions = airports.map((airport) => {
    return {
      label: getAirportDisplayName(airport),
      value: airport.id,
    };
  });

  return (
    <>
      <FormikSearchableDropdown
        name="location.airport"
        label="Airport"
        placeholder="Start typing your airport..."
        options={airportOptions}
        isLoading={loading}
      />
      <FormikField
        name="location.fixedBaseOperator"
        label="Fixed Base Operator"
        placeholder="Enter your fixed base operator..."
        component={FormikInput}
        optional
      />
      <FormikField
        name="location.details"
        label="Details"
        placeholder="Inside Hangar A, passcode is 1234, etc"
        component={FormikTextArea}
        optional
      />
    </>
  );
}

export default AircraftLocation;
