/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useRef, useState } from 'react';
import PlusIcon from '../../assets/icons/PlusIcon';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import updateUser from '../../API/User/updateUser';
import ErrorMessage from '../../components/molecules/ErrorMessage';
import { isValidPhoneNumber } from '../../utilities/validation';
import FormField from '../../components/wrappers/FormField';
import useForm from '../../hooks/useForm';
import Card from '../../components/molecules/Card/Card';
import Label from '../../components/atoms/Label';
import Input from '../../components/atoms/Input';
import Heading from '../../components/typography/Heading';
import { useToast } from '../../hooks/ToastProvider';
import SettingsCard from '../../components/molecules/Card/SettingsCard';
import Avatar from '../../components/molecules/Avatar/Avatar';

export default function ConsumerView() {
  const { user } = useUser();
  const initialForm = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    phoneNumber:
      user?.consumerRole?.phoneNumber ?? user?.pilotRole?.phoneNumber ?? '',
    email: user?.email ?? '',
    position: user?.consumerRole?.position ?? '',
    managementCompany: user?.consumerRole?.managementCompany ?? '',
  };
  const { inputs, handleChange } = useForm(initialForm);
  const ref = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string[]>([]);
  const [initials, setInitials] = useState('AA');
  const [avatar, setAvatar] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(true);

  const { addToast } = useToast();

  useEffect(() => {
    if (user) {
      setAvatarUrl(user?.avatar?.image ?? null);
      setInitials(`${user?.firstName[0]}${user?.lastName[0]}`);
    }
  }, [user]);

  const handleSave = async () => {
    if (!user?.id) return;

    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      position,
      managementCompany,
    } = inputs;

    if (!firstName?.length) {
      setError([...error, 'First Name is required']);
      return;
    }
    if (!lastName?.length) {
      setError([...error, 'Last Name is required']);
      return;
    }
    if (!isValidPhoneNumber(phoneNumber)) {
      setError([...error, 'Phone number must be valid']); // TODO make better error message
      return;
    }
    if (!email.length) {
      setError([...error, 'Please provide an email address']);
      return;
    }
    if (!position.length) {
      setError([...error, 'Please provide your position']);
      return;
    }

    try {
      // TODO: only send changed fields
      setError([]);
      await updateUser(user?.id, avatar, inputs);
      addToast(<span>Successfully updated account!</span>, 'success');
      setDisabled(true);
    } catch (err) {
      console.error(err);
      setError(['Unable to Update profile. Please try again later.']);
    }
  };

  return (
    <SettingsCard
      disabled={disabled}
      onEdit={() => setDisabled(false)}
      onCancel={() => setDisabled(true)}
      onSave={handleSave}
      responsive
    >
      <div className="flex flex-col gap-4">
        <Heading level={2}>General Information</Heading>
        {error?.map((er) => (
          <ErrorMessage key={er}>{er}</ErrorMessage>
        ))}
      </div>
      <div className="mt-8">
        <Label htmlFor="avatar">Avatar</Label>
        <button
          type="button"
          className="indicator"
          onClick={() => ref.current?.click()}
          disabled={disabled}
        >
          <div className="indicator-item indicator-bottom">
            <div
              className={`btn btn-sm btn-circle ${
                disabled ? 'btn-disabled' : ''
              }`}
            >
              <PlusIcon />
            </div>
          </div>
          <Avatar initials={initials} avatarUrl={avatarUrl!} />
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <input
            ref={ref}
            type="file"
            accept="image/png, image/jpeg"
            style={{ display: 'none' }}
            onChange={(event) => {
              const file = (event.currentTarget.files || [])?.[0] ?? null;

              if (file) {
                setAvatarUrl(URL.createObjectURL(file));
                setAvatar(file);
              }
            }}
          />
        </button>
      </div>
      <FormField>
        <Label htmlFor="firstName" required>
          First Name
        </Label>
        <Input
          id="firstName"
          disabled={disabled}
          value={inputs.firstName}
          onChange={handleChange}
        />
      </FormField>
      <FormField>
        <Label htmlFor="lastName" required>
          Last Name
        </Label>
        <Input
          id="lastName"
          disabled={disabled}
          value={inputs.lastName}
          onChange={handleChange}
        />
      </FormField>
      <FormField>
        <Label htmlFor="phoneNumber" required>
          Phone Number
        </Label>
        <Input
          id="phoneNumber"
          disabled={disabled}
          value={inputs.phoneNumber}
          onChange={handleChange}
        />
      </FormField>
      <FormField>
        <Label htmlFor="email" required>
          Email
        </Label>
        <Input
          id="email"
          disabled={disabled}
          value={inputs.email}
          onChange={handleChange}
        />
      </FormField>
      <FormField>
        <Label htmlFor="position" required>
          Position
        </Label>
        <Input
          id="position"
          disabled={disabled}
          placeholder="e.g. Director of Aviation"
          value={inputs.position}
          onChange={handleChange}
        />
      </FormField>
      <FormField>
        <Label htmlFor="managementCompany">Management Company</Label>
        <Input
          id="managementCompany"
          disabled={disabled}
          value={inputs.managementCompany}
          onChange={handleChange}
        />
      </FormField>
    </SettingsCard>
  );
}
