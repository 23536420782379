import { AnyObject } from '../../types/util';
import isEmptyObject from '../../utilities/isEmptyObject';
import { remapKeysToSnakeCase } from '../../utilities/remapKeys';
import fetchAPI from '../util';

// TODO change backend update route to standardize this type

async function uploadFile(image: File) {
  // differentiate liceanse, certificate, etc.
  if (image.size > 5000000) {
    throw new Error(
      'Your photo needs to be less than 5MB and in JPG, PNG, or GIF format'
    );
  }
  try {
    const formData = new FormData();
    formData.append('image', image);

    await fetchAPI('/api/avatars/', {
      method: 'POST',
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      body: formData,
    });

    //  if (response && response.image)
    //  { type: AVATAR_UPLOAD_SUCCESS, avatar: response }
    //  handleChange('avatar', response.image));
    //  toast('Updated successfully')
  } catch (err) {
    console.log(err);
  }
}

export default async function createPilot(data?: AnyObject) {
  try {
    if (isEmptyObject(data)) return;

    const formattedData = remapKeysToSnakeCase(data as AnyObject) as Record<
      string,
      unknown
    >;
    const filteredData = Object.fromEntries(
      Object.entries(formattedData).filter(([key, value]) => value !== '')
    );

    if (
      Object.prototype.hasOwnProperty.call(filteredData, 'phone_number') &&
      typeof filteredData?.phone_number === 'string' &&
      !filteredData?.phone_number.startsWith('+1')
    ) {
      if (filteredData?.phone_number.startsWith('1')) {
        filteredData.phone_number = `+${filteredData?.phone_number}`;
      } else {
        filteredData.phone_number = `+1${filteredData?.phone_number}`;
      }
    }

    await fetchAPI(`/api/pilots/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filteredData),
    });
  } catch (err) {
    console.log(err);
  }
}
