import PlaneType from '../../types/PlaneType';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';

const endpoint = `/api/planetypes/`;

interface PlaneTypesResponse {
  planes: PlaneType[];
  statusCode: number;
}

const getPlaneTypes = async (): Promise<PlaneTypesResponse | null> => {
  try {
    const planeTypesResponse = await fetch(endpoint, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
    });

    const planeTypes = await planeTypesResponse.json();

    return {
      planes: remapKeysToCamelCase(planeTypes) as PlaneType[],
      statusCode: planeTypesResponse.status,
    };
  } catch (error) {
    return null;
  }
};

export default getPlaneTypes;
