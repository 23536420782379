import classNames from 'classnames';
import { ReactNode } from 'react';
import Heading from '../../typography/Heading';

export interface CardProps {
  responsive?: boolean;
  className?: string;
  children: ReactNode;
  title?: string;
  noPadding?: boolean;
  noGap?: boolean;
}

export default function Card({
  responsive = false,
  children,
  className,
  title,
  noPadding = false,
  noGap = false,
}: CardProps) {
  return (
    <div
      className={classNames(
        `card relative ${responsive ? 'lg:card-side' : 'max-w-sm'}`,
        'bg-base-100 border-2',
        className
      )}
    >
      <div
        className={`card-body ${noPadding ? 'p-0 m-0' : 'p-4 @lg:p-8'} ${
          noGap ? 'gap-0' : ''
        }`}
      >
        {title && (
          <Heading level={2} className="card-title">
            {title}
          </Heading>
        )}
        {children}
      </div>
    </div>
  );
}
