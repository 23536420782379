import { useEffect, useRef, useState } from 'react';
import Heading from '../../typography/Heading';
import Card from '../../molecules/Card/Card';
import Message from '../../../types/Message';
import { useUser } from '../../../hooks/UserProvider/UserProvider';
import Loader from '../../molecules/Loader';
import Conversation from '../../../types/Conversation';
import ChatMessageBubble from './ChatMessageBubble';

interface ChatProps {
  tripId: number;
  conversation: Conversation | null;
  className?: string;
  title?: string;
}

function ChatHistory({ tripId, conversation, className, title }: ChatProps) {
  const { user, role } = useUser();

  if (user && conversation) {
    return (
      <Card responsive noPadding noGap className={`${className || ''} h-full`}>
        <div className="py-2 background bg-primary rounded-t-lg">
          <Heading
            level={4}
            className="uppercase text-center text-white font-normal py-2"
          >
            {title || 'Chat'}
          </Heading>
        </div>
        <div className="w-full flex flex-col gap-4 p-4 max-h-[600px] overflow-y-scroll">
          {conversation?.messages.map((m) =>
            ChatMessageBubble({
              message: m,
              user,
              otherUser: conversation.user,
            })
          )}
        </div>
      </Card>
    );
  }
  return null;
}

export default ChatHistory;
