import { useEffect, useRef, useState } from 'react';
import Heading from '../../typography/Heading';
import Card from '../../molecules/Card/Card';
import Message from '../../../types/Message';
import { useUser } from '../../../hooks/UserProvider/UserProvider';
import Loader from '../../molecules/Loader';
import Conversation from '../../../types/Conversation';
import Input from '../../atoms/Input';
import { SecondaryButton } from '../../atoms/Button/Button';
import createNewMessage from '../../../API/Trips/createNewMessage';
import getTripAsConsumer from '../../../API/Trips/getTripAsConsumer';
import getTripAsPilot from '../../../API/Trips/getTripAsPilot';
import ChatMessageBubble from './ChatMessageBubble';
import ConversationView from './Conversation';

interface ChatProps {
  tripId: number;
  conversation: Conversation | null;
  className?: string;
  title?: string;
}

function Chat({ tripId, conversation, className, title }: ChatProps) {
  const { user, role } = useUser();

  if (user && conversation)
    return ConversationView({
      title,
      user,
      conversation,
      tripId,
    });

  return <Loader caption="Loading chat..." />;
}

export default Chat;
