import { PaymentMethod } from '@stripe/stripe-js';
import Card from '../../molecules/Card/Card';
import Heading from '../../typography/Heading';
import Paragraph from '../../typography/Paragraph';

export type CardBrand =
  | `amex`
  | `diners`
  | `discover`
  | `jcb`
  | `mastercard`
  | `unionpay`
  | `visa`
  | `unknown`;

function CardBrandLogo({ brand }: { brand: CardBrand }) {
  if (brand === 'amex') return <i className="fa-brands fa-cc-amex" />;
  if (brand === 'diners') return <i className="fa-brands fa-cc-diners-club" />;
  if (brand === 'discover') return <i className="fa-brands fa-cc-discover" />;
  if (brand === 'jcb') return <i className="fa-brands fa-cc-jcb" />;
  if (brand === 'mastercard')
    return <i className="fa-brands fa-cc-mastercard" />;
  if (brand === 'unionpay') return <i className="fa-solid fa-credit-card" />;
  if (brand === 'visa') return <i className="fa-brands fa-cc-visa" />;
  return <i className="fa-solid fa-credit-card" />;
}

function CreditCard({
  children,
  card,
}: {
  children: React.ReactNode;
  card: PaymentMethod.Card;
}) {
  return (
    <Card className="bg-primary text-white w-96 min-w-96">
      <Heading level={2} className="-mb-2">
        <CardBrandLogo brand={card.brand as CardBrand} />
      </Heading>
      <Paragraph>
        <span className="font-semibold">
          <span className="capitalize">{card.brand}</span> ending in{' '}
          {card.last4}
        </span>
        <br />
        Expires {card.exp_month}/{card.exp_year}
      </Paragraph>
      {children}
    </Card>
  );
}

export default CreditCard;
