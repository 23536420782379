/**
 * @returns the Stripe public key
 */
export default function getStripeKey(): string {
  if (process.env.NODE_ENV === 'production') {
    return 'pk_live_51PLla805MxUPv3f6YXjSleiljjpm8K3algSFiNeJz1i3ts6Jp9BwImJ4B6ewoD6P4rPaPqqWRA0MHjTIhbhCfag100MD2VsEZ0';
  }

  return 'pk_live_51PLla805MxUPv3f6YXjSleiljjpm8K3algSFiNeJz1i3ts6Jp9BwImJ4B6ewoD6P4rPaPqqWRA0MHjTIhbhCfag100MD2VsEZ0';
}
