import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../components/atoms/Button/Button';
import Heading from '../../components/typography/Heading';
import Section from '../../components/wrappers/Section';
import { ROUTES } from '../routerConfig';
import CreateTripForm from '../../components/organisms/CreateTripForm/CreateTripForm';

function CreateTripPage() {
  const navigate = useNavigate();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);

  return (
    <Section className="mx-auto max-w-screen-xl">
      <div className="flex gap-2 mb-4">
        <PrimaryButton onClick={navigateToTrips}>Back to Trips</PrimaryButton>
      </div>
      <Heading level={1} className="mb-4">
        Create New Trip
      </Heading>
      <CreateTripForm className="mt-4" />
    </Section>
  );
}

export default CreateTripPage;
