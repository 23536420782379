import React from 'react';
import classNames from 'classnames';
import { ButtonProps, PrimaryButton } from '../../atoms/Button/Button';
import CancelIcon from '../../../assets/icons/cancel';

export default function CloseButton({ className, ...rest }: ButtonProps) {
  return (
    <PrimaryButton
      outline
      className={classNames('btn-circle p-4 mb-4', className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <CancelIcon />
    </PrimaryButton>
  );
}
