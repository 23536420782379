import * as Yup from 'yup';
import i18n from '../../../../strings/i18n';

const validAircraftTypeSchema = Yup.object({
  make: Yup.string().required(
    i18n({ validation: 'requiredDynamic' }, { field: 'Make' })
  ),
  model: Yup.string().required(
    i18n({ validation: 'requiredDynamic' }, { field: 'Model' })
  ),
});

export default validAircraftTypeSchema;
