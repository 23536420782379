import { ReactNode } from 'react';
import Heading from '../../typography/Heading';

interface Props {
  className?: string;
  children: ReactNode;
}

export default function CardTitle({ children, className }: Props) {
  return (
    <Heading level={3} className={className}>
      {children}
    </Heading>
  );
}
