const endpoint = (tripId: number) => `/api/trips/as_pilot/${tripId}/invoice/`;

function getTripInvoicePdf(tripId: number) {
  const requestURL = endpoint(tripId);
  const options = {
    headers: {
      Authorization: `Token ${localStorage.getItem('key')}`,
      'content-type': 'application/json',
    },
    method: 'GET',
  };
  fetch(requestURL, options)
    .then((res) => res.blob())
    .then(
      (result) => {
        const blob = new Blob([result]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `invoice.pdf`;
        link.click();
      },
      (error) => {
        console.error(error);
      }
    );
}

export default getTripInvoicePdf;
