import PilotCertificate from '../../types/PilotCertificate';

const endpoint = `/api/licenses/`;

async function createCertificateLicense(certificateId: number) {
  const response = await fetch(`${endpoint}`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
    body: JSON.stringify({ pilot_cert: certificateId }),
  });

  const license = await response.json();

  return {
    statusCode: response.status,
    license: license as PilotCertificate['license'],
  };
}

export default createCertificateLicense;
