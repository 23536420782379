import Message from '../../../types/Message';
import User from '../../../types/User';

import OtherUserMessage from './OtherUserMessage';
import UserMessage from './UserMessage';
import SystemMessage from './SystemMessage';

type MessageType = 'user' | 'otherUser' | 'system';

function ChatMessageBubble({
  message,
  user,
  otherUser,
}: {
  message: Message;
  user: User;
  otherUser: User;
}) {
  // Deduce message type
  let messageType: MessageType = 'system';
  if (!message.isNotification) {
    if (message.sender === user?.id) messageType = 'user';
    else messageType = 'otherUser';
  }

  if (user) {
    if (messageType === 'otherUser')
      return OtherUserMessage({ message, user: otherUser });
    if (messageType === 'user') return UserMessage({ message, user });
  }
  return SystemMessage({ message });
}

export default ChatMessageBubble;
