import React, { FormEvent, useEffect, useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ROUTES } from '../../../routes/routerConfig';
import ErrorMessage from '../../molecules/ErrorMessage';
import { PrimaryButton } from '../../atoms/Button/Button';

function PaymentMethodForm() {
  const currentUrlWithoutQueryParams = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<undefined | string>(undefined);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // for redirecting back to our app when authing some payment methods
        return_url: currentUrlWithoutQueryParams,
        // return_url: ROUTES.BILLING_AND_PAYMENTS,
      },
    });

    if (result.error) {
      setError(result.error.message);
    } else {
      console.log('Payment method added successfully!');
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <PaymentElement />
        <PrimaryButton type="submit" disabled={!stripe} className="self-end">
          Save Payment Method
        </PrimaryButton>
      </form>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}

export default PaymentMethodForm;
