import { Airplane } from '../types/Airplane';

const initialAirplane: Airplane = {
  id: null,
  make: 0,
  model: 0,
  isValid: null,
  identification: {
    id: null,
    aircraft: null,
    ownerOperator: [
      {
        name: '',
        type: '' as Airplane['identification']['ownerOperator'][0]['type'],
      },
    ],
    deletedOwnerOperators: [],
    serialNumber: '',
    tailNumber: '',
    managementCompanyAddress: '',
    managementCompanyName: '',
    usCustomsDecalNumber: '',
    usCustomsDecalYear: '',
  },
  configuration: {
    id: null,
    aircraft: null,
    avionics: null,
    basicEmptyWeight: 0,
    lavatoryType: null,
    isRvsmCertified: false,
    seatingConfiguration: null,
    propellerType: null,
  },
  location: {
    id: null,
    aircraft: null,
    airport: '',
    fixedBaseOperator: '',
    details: '',
  },
  maintenance: {
    id: null,
    aircraft: null,
    contactName: '',
    contactPhoneNumber: '',
    preferredMaintenanceFacility:
      '' as Airplane['maintenance']['preferredMaintenanceFacility'],
    // maintenanceFacilityLocation: '',
    squawks: '',
  },
  insurance: {
    id: null,
    aircraft: null,
    companyName: '',
    insuranceContactName: '',
    insuranceContactEmail: '',
    openPilotClause: {
      totalFlightHours: 0,
      planeTypeFlightHours: 0,
      multiEngineHours: null,
      turboJetHours: null,
      turbineHours: null,
    },
  },
  operatingProcedures: {
    id: null,
    aircraft: null,
    mandatoryDocumentStorageLocation: '',
    spareKeyLocation: '',
    fixedBaseOperatorPreference: '',
    flightLogProcedures: '',
    dressAndAppearance: '',
    catering: '',
    isCaaMember: false,
    fuelCards: [
      {
        cardName: '',
        isPreferred: false,
      },
    ],
    deletedFuelCards: [],
    additionalNotes: '',
  },
};

export default initialAirplane;
