/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { remapKeysToCamelCase } from '../../utilities/remapKeys';
import TripsAsPilotResponse from './TripsAsPilotResponse';
import mappedAircraft from '../Aircraft/util/mappedAircraft';

const endpoint = `/api/trips/as_pilot/`;

const getTripsAsPilot = async () => {
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
  });
  if (response.status !== 200) {
    throw new Error('Unable to get trips as pilot');
  } else {
    const tripData = await response.json();
    tripData.available.bids.forEach((trip) => {
      if (trip.aircraft) {
        trip.aircraft = mappedAircraft(trip.aircraft);
      }
    });
    tripData.available.upcoming.forEach((trip) => {
      if (trip.aircraft) {
        trip.aircraft = mappedAircraft(trip.aircraft);
      }
    });
    tripData.confirmed.past.forEach((trip) => {
      if (trip.aircraft) {
        trip.aircraft = mappedAircraft(trip.aircraft);
      }
    });
    tripData.confirmed.upcoming.forEach((trip) => {
      if (trip.aircraft) {
        trip.aircraft = mappedAircraft(trip.aircraft);
      }
    });
    return remapKeysToCamelCase(tripData) as TripsAsPilotResponse;
  }
};

export default getTripsAsPilot;
