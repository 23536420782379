import { ReactNode } from 'react';
import Paragraph from '../../typography/Paragraph';
import Heading from '../../typography/Heading';

export interface ChevronCardProps {
  title?: string;
  icon: ReactNode;
  className?: string;
  bgColor?: string;
  color?: string;
  children?: ReactNode;
}

export default function ChevronCard({
  title,
  children,
  className,
  bgColor = 'bg-cyan-300',
  color = 'text-white',
  icon,
}: ChevronCardProps) {
  return (
    <div className={`${className ?? ''} w-full`}>
      {title && <Heading level={3}>{title}</Heading>}
      <div className="relative">
        <div className={`${bgColor} w-full h-8 skew-x-[24deg]`} />
        <div className="absolute inset-0 z-10 w-full p-2 flex items-center justify-center">
          {icon && <div className={`w-10 h-10 ${color}`}>{icon}</div>}
        </div>
        <div className={`${bgColor} w-full h-8 -skew-x-[24deg]`} />
      </div>
      {children && <Paragraph>{children}</Paragraph>}
    </div>
  );
}
