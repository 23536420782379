import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import User from '../../../../types/User';
import Trip from '../../../../types/Trip';
import Alert from '../../../../components/atoms/Alert/Alert';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../components/atoms/Button/Button';
import PilotCard from '../../../../components/molecules/PilotCard/PilotCard';
import ControlBar from '../../../../components/molecules/ControlBar/ControlBar';
import Chat from '../../../../components/organisms/Chat/Chat';
import QuoteCard from '../../../../components/organisms/QuoteCard/QuoteCard';
import TripCard from '../../../../components/organisms/TripCard/TripCard';
import Heading from '../../../../components/typography/Heading';
import { Quote, createQuoteFromBid } from '../../../../types/Quote';
import { ROUTES } from '../../../routerConfig';
import GrandTotalEstimateCard from '../../../../components/organisms/GrandTotalEstimateCard/GrandTotalEstimateCard';
import Bid from '../../../../types/Bid';
import PrimaryTripLayout from '../../Layouts/PrimaryTripLayout';

function PilotInitialPaymentView({
  trip,
  user,
  openQuoteModal,
  openDetailsModal,
}: {
  trip: Trip;
  user: User;
  openQuoteModal: (quote: Quote) => unknown;
  openDetailsModal: (trip: Trip) => unknown;
}) {
  const navigate = useNavigate();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);
  const chatAreaRef = useRef<HTMLDivElement>(null);
  const tripAndQuoteAreaRef = useRef<HTMLDivElement>(null);
  const hiredBid = trip.conversations
    .map((c) => c.bid)
    .find((b) => b?.id === trip.hiredBid);
  if (!hiredBid) throw new Error(`No hired bid on PilotInitialPaymentView`);

  const hiredBidConversation = trip.conversations.find(
    (c) => c.bid?.id === trip.hiredBid
  );
  if (!hiredBidConversation)
    throw new Error(`No hired bid conversation on PilotInitialPaymentView`);

  const { bid, cost } = hiredBidConversation;

  return (
    <>
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" /> Trips
        </PrimaryButton>
        <SecondaryButton
          className="lg:hidden ml-auto"
          size="small"
          onClick={() => openDetailsModal(trip)}
        >
          <span>View Trip Details</span>
        </SecondaryButton>
        <SecondaryButton
          className="lg:hidden "
          size="small"
          onClick={() =>
            openQuoteModal(
              createQuoteFromBid(hiredBid, hiredBidConversation.user, trip)
            )
          }
        >
          Review Quote
        </SecondaryButton>
      </ControlBar>
      <div className="flex flex-col gap-4">
        <Heading level={2}>
          Initial payment has been made to {hiredBidConversation.user.firstName}{' '}
          {hiredBidConversation.user.lastName}.
        </Heading>
        <Alert message="Awaiting departure. The remaining balance will be paid once you accept the pilot's submitted expenses after the trip." />
        {/* Three column grid layout that collapses to 2 col, then 1 col */}
        <PrimaryTripLayout>
          {/** Pilot profile area */}
          {/* Small display version */}
          <div className="h-full">
            <PilotCard
              pilot={hiredBidConversation.user}
              className="h-full"
              planeType={trip.planeType}
              selected
              expanded="always"
              airplane={trip.aircraft}
              hired
            />
          </div>
          {/** Chat area */}
          {hiredBidConversation && (
            <div className="flex flex-col gap-4 h-full" ref={chatAreaRef}>
              {/** Quote card */}
              <GrandTotalEstimateCard
                cost={cost}
                expenses={(bid as Bid).quotedExpenses}
                className="h-full"
              />
              <Chat
                tripId={trip.id}
                title={
                  hiredBidConversation
                    ? `Chat with ${hiredBidConversation.user.firstName}`
                    : 'Loading'
                }
                conversation={hiredBidConversation}
              />
            </div>
          )}
          {/* Trip and Quote area  */}
          <div className="hidden lg:block h-full" ref={tripAndQuoteAreaRef}>
            {/** Trip card */}
            <TripCard
              trip={trip}
              showStatus={false}
              expanded="always"
              interactive={false}
              className="h-full"
            />
          </div>
        </PrimaryTripLayout>
      </div>
    </>
  );
}

export default PilotInitialPaymentView;
