import type { TripAlteration } from '../../types/TripAlteration';
import isEmptyObject from '../../utilities/isEmptyObject';
import fetchAPI from '../util';

async function submitTripAlteration(alteration: TripAlteration) {
  try {
    if (isEmptyObject(alteration)) return;

    const expensesResponse = await fetchAPI(
      `/api/trips/as_pilot/${alteration.id}/alteration/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          startDate: alteration.startDate,
          endDate: alteration.endDate,
          totalTravelDays: alteration.totalTravelDays,
        }),
      }
    );

    if (expensesResponse.status !== 201) {
      throw new Error('Unable to submit trip alteration');
    }
  } catch (err) {
    console.log(err);
  }
}

export default submitTripAlteration;
