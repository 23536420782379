import React, { useEffect, useRef } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import Button from '../../atoms/Button/Button';
import Label from '../../atoms/Label';

interface SignProps {
  handleSave: (signature: File) => void;
  handleClear?: () => void;
}

function SignaturePad({ handleSave, handleClear }: SignProps) {
  const canvasSignatureRef = useRef<any>(null);

  useEffect(() => {
    const adjustCanvas = () => {
      const canvas = canvasSignatureRef.current.getCanvas();
      if (canvas) {
        const ctx = canvas.getContext('2d');
        const ratio = Math.max(window.devicePixelRatio || 1, 1);
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.style.width = `${canvas.offsetWidth}px`;
        canvas.style.height = `${canvas.offsetHeight}px`;
        ctx.scale(ratio, ratio);
        canvasSignatureRef.current.clear();
      }
    };

    window.addEventListener('resize', adjustCanvas);
    adjustCanvas(); // Initial adjustment

    return () => window.removeEventListener('resize', adjustCanvas);
  }, []);

  const clearSignature = () => {
    if (canvasSignatureRef.current) {
      canvasSignatureRef.current.clear();
    }
    if (handleClear) {
      handleClear();
    }
  };

  const saveSignature = () => {
    if (canvasSignatureRef.current) {
      const canvas = canvasSignatureRef.current.getTrimmedCanvas();
      canvas.toBlob(function (blob: any) {
        handleSave(new File([blob as Blob], 'signature.png'));
      });
    }
  };

  return (
    <div className="relative h-[100px] lg:h-[120px] my-4">
      <Label htmlFor="canvas-signature" className="absolute top-3 left-3">
        Please sign here:
      </Label>
      <ReactSignatureCanvas
        penColor="black"
        canvasProps={{
          id: 'canvas-signature',
          className: 'w-full h-[100px] lg:h-[120px] bg-base-200',
        }}
        ref={canvasSignatureRef}
        onEnd={saveSignature}
      />
      <div className="absolute bottom-6 left-3 gap-4 inline-flex text-4xl w-9/12 pointer-events-none">
        <span>x</span>
        <div className="w-full border-b border-b-primary" />
      </div>
      <div
        style={{
          position: 'absolute',
          left: '345px',
          bottom: '30px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Button
          type="button"
          onClick={clearSignature}
          style={{ marginBottom: 10 }}
        >
          <i
            className="text-3xl fa-solid fa-eraser"
            aria-label="Erase signature"
          />
        </Button>
      </div>
    </div>
  );
}

export default SignaturePad;
