import classNames from 'classnames';
import { HTMLAttributes, ReactNode } from 'react';

export type Level = 1 | 2 | 3 | 4;

interface HeadingProps extends HTMLAttributes<HTMLHeadingElement> {
  className?: string;
  children: ReactNode;
  level: Level;
}

function Heading({ className, children, level, id }: HeadingProps) {
  if (level === 1)
    return (
      <h1
        id={id}
        className={classNames(
          `heading-${level} mb-3 font-bold text-3xl lg:text-4xl`,
          className
        )}
      >
        {children}
      </h1>
    );
  if (level === 2)
    return (
      <h2
        id={id}
        className={classNames(
          `heading-${level} mb-2 font-bold text-2xl lg:text-3xl`,
          className
        )}
      >
        {children}
      </h2>
    );
  if (level === 3)
    return (
      <h3
        id={id}
        className={classNames(
          `heading-${level} mb-1 font-bold text-xl lg:text-2xl`,
          className
        )}
      >
        {children}
      </h3>
    );
  if (level === 4)
    return (
      <h4
        id={id}
        className={classNames(`heading-${level} font-bold`, className)}
      >
        {children}
      </h4>
    );
  return (
    <h4 id={id} className={classNames(`heading-4 font-bold`, className)}>
      {children}
    </h4>
  );
}

export default Heading;
