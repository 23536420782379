import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import getTrainingFacilities from '../API/TrainingFacilities/getTrainingFacilities';
import { TrainingFacility } from '../types/TrainingFacility';

interface ITrainingFacilitiesContext {
  error: string;
  loading: boolean;
  facilities: TrainingFacility[];
  getTrainingFacilityByName: (name: string | null) => TrainingFacility | null;
  getTrainingFacilityById: (id: number | null) => TrainingFacility | null;
}

const TrainingFacilityContext = createContext<ITrainingFacilitiesContext>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!
);

export function TrainingFacilitiesProvider({
  children,
}: {
  children: ReactNode;
}) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [facilities, setFacilities] = useState<TrainingFacility[]>([]);

  const getTrainingFacilityByName = useCallback(
    (name: string | null) =>
      facilities.find((tf) => tf.facility_name === name) || null,
    [facilities]
  );

  const getTrainingFacilityById = useCallback(
    (id: number | null) => facilities.find((tf) => tf.id === id) || null,
    [facilities]
  );

  const attemptGetTrainingFacilities = async () => {
    try {
      setLoading(true);
      const response = await getTrainingFacilities();
      if (response && response.statusCode === 200) {
        setFacilities(response.facilities);
      } else {
        setError('Unable to retrieve training facilities.');
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
      setError(err as string);
    }
  };

  useEffect(() => {
    if (!facilities.length && !error) attemptGetTrainingFacilities();
  }, [facilities, error]);

  const value = useMemo(
    () => ({
      error,
      loading,
      facilities,
      getTrainingFacilityByName,
      getTrainingFacilityById,
    }),
    [
      error,
      loading,
      facilities,
      getTrainingFacilityByName,
      getTrainingFacilityById,
    ]
  );

  return (
    <TrainingFacilityContext.Provider value={value}>
      {children}
    </TrainingFacilityContext.Provider>
  );
}

export const useTrainingFacilities = () =>
  useContext<ITrainingFacilitiesContext>(TrainingFacilityContext);
