import getUser from '../User/getUser';

const endpoint = `/auth/login/`;

/*
  Attempt login using provided email and password; if successful update the user provider state.
*/
const login = async (email: string, password: string) => {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
    credentials: 'include',
  });

  const responseBody = await response.json();

  if (response.status !== 200) {
    throw new Error(
      'Unable to log in. Please double check your email and password.'
    );
  } else {
    localStorage.setItem('key', responseBody.key);
    const userData = await getUser();
    return userData;
  }
};

export default login;
