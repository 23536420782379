import { Outlet } from 'react-router-dom';
import { AirportsProvider } from '../../hooks/AirportsProvider';
import { ModalProvider } from '../../hooks/ModalProvider/ModalProvider';
import { UserProvider } from '../../hooks/UserProvider/UserProvider';
import { ScrollProvider } from '../../hooks/ScrollProvider/ScrollProvider';
import { PlaneTypesProvider } from '../../hooks/PlaneTypesProvider';
import { AircraftProvider } from '../../hooks/useAircraft';
import { ToastProvider } from '../../hooks/ToastProvider';
import { TrainingFacilitiesProvider } from '../../hooks/useTrainingFacilities';
import ToastContainer from '../atoms/Toast/ToastContainer';
import Header from '../organisms/Header';
import Footer from '../organisms/Footer';
import Main from '../wrappers/Main';
import PrimaryLayout from '../wrappers/PrimaryLayout';

function App() {
  return (
    <div id="app" className="flyco relative" data-theme="light">
      <ScrollProvider>
        <UserProvider user={null}>
          <AirportsProvider>
            <PlaneTypesProvider>
              <AircraftProvider>
                <ModalProvider>
                  <ToastProvider>
                    <TrainingFacilitiesProvider>
                      <PrimaryLayout />
                    </TrainingFacilitiesProvider>
                  </ToastProvider>
                </ModalProvider>
              </AircraftProvider>
            </PlaneTypesProvider>
          </AirportsProvider>
        </UserProvider>
      </ScrollProvider>
    </div>
  );
}

export default App;
