/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import Trip from '../../types/Trip';
import ButtonGroup from '../../components/molecules/ButtonGroup';
import TripCard from '../../components/organisms/TripCard/TripCard';
import getTripsAsPilot from '../../API/Trips/getTripsAsPilot';
import Loader from '../../components/molecules/Loader';
import Heading from '../../components/typography/Heading';
import Alert from '../../components/atoms/Alert/Alert';
import {
  getPilotOnboardingStatus,
  getOperatorOnboardingStatus,
  PilotOnboardingStatus,
  OperatorOnboardingStatus,
} from '../../components/molecules/OnboardingStatus/OnboardingStatus';
import { useModalContext } from '../../hooks/ModalProvider/ModalProvider';

enum TripTypes {
  NEW = 'New',
  BID = 'Bid',
  UPCOMING = 'Upcoming',
  PAST = 'Past',
}

function TripsAsPilot() {
  const { user } = useUser();
  const { closeModal } = useModalContext();
  // Pilot trip categories
  const [bidTrips, setBidTrips] = useState<Trip[]>([]);
  const [newTrips, setNewTrips] = useState<Trip[]>([]);
  const [pastTrips, setPastTrips] = useState<Trip[]>([]);
  const [upcomingTrips, setUpcomingTrips] = useState<Trip[]>([]);

  const [currentTrips, setCurrentTrips] = useState<Trip[]>(upcomingTrips);

  const [loading, setLoading] = useState(true);

  const [currentFilter, setCurrentFilter] = useState<TripTypes>(
    TripTypes.UPCOMING
  );

  const [loadingPilotOnboardingStatus, setLoadingPilotOnboardingStatus] =
    useState(true);
  const [pilotOnboardingStatus, setPilotOnboardingStatus] =
    useState<null | PilotOnboardingStatus>(null);

  // Get pilot onboarding status and set associated state
  useEffect(() => {
    const func = async () => {
      if (!user) return;
      const onboardingStatus = await getPilotOnboardingStatus(user, closeModal);
      setPilotOnboardingStatus(onboardingStatus);
      setLoadingPilotOnboardingStatus(false);
    };
    if (user) func();
  }, [closeModal, user]);

  const updateCurrentFilter = (newFilter: string) => {
    if (newFilter === TripTypes.NEW) {
      setCurrentFilter(TripTypes.NEW);
      setCurrentTrips(newTrips);
    } else if (newFilter === TripTypes.UPCOMING) {
      setCurrentFilter(TripTypes.UPCOMING);
      setCurrentTrips(upcomingTrips);
    } else if (newFilter === TripTypes.BID) {
      setCurrentFilter(TripTypes.BID);
      setCurrentTrips(bidTrips);
    } else if (newFilter === TripTypes.PAST) {
      setCurrentFilter(TripTypes.PAST);
      setCurrentTrips(pastTrips);
    } else setCurrentTrips([]);
  };

  // When trips are loaded, set the currentTrips value
  useEffect(() => {
    if (currentFilter === TripTypes.UPCOMING) setCurrentTrips(upcomingTrips);
    else if (currentFilter === TripTypes.PAST) setCurrentTrips(pastTrips);
    else if (currentFilter === TripTypes.BID) setCurrentTrips(bidTrips);
    else if (currentFilter === TripTypes.NEW) setCurrentTrips(newTrips);
  }, [upcomingTrips, pastTrips, bidTrips, newTrips, currentFilter]);

  // Load trips
  useEffect(() => {
    const getAndSetTrips = async () => {
      const loadedTrips = await getTripsAsPilot();
      setPastTrips(loadedTrips.confirmed.past);
      setUpcomingTrips(loadedTrips.confirmed.upcoming);
      setNewTrips(loadedTrips.available.upcoming);
      setBidTrips(loadedTrips.available.bids);
      setLoading(false);
    };
    getAndSetTrips();
  }, []);

  return loading ? (
    <Loader level={3} caption="Loading trips..." />
  ) : (
    <div className="flex flex-col gap-6">
      <Heading level={1} className="mb-4 mt-2">
        Welcome, {user?.firstName}!
      </Heading>
      {user &&
        !loadingPilotOnboardingStatus &&
        pilotOnboardingStatus?.statusContent && (
          <Alert
            status="warning"
            message={pilotOnboardingStatus?.statusContent}
            className="max-w-fit text-left"
          />
        )}
      <div className="flex gap-4">
        <ButtonGroup
          options={[
            {
              displayName: 'New',
              value: TripTypes.NEW,
              selected: currentFilter === TripTypes.NEW,
            },
            {
              displayName: 'Quotes',
              value: TripTypes.BID,
              selected: currentFilter === TripTypes.BID,
            },
            {
              displayName: 'Upcoming',
              value: TripTypes.UPCOMING,
              selected: currentFilter === TripTypes.UPCOMING,
            },
            {
              displayName: 'Past',
              value: TripTypes.PAST,
              selected: currentFilter === TripTypes.PAST,
            },
          ]}
          onChange={(value) => updateCurrentFilter(value)}
        />
      </div>
      {currentTrips.length > 0 &&
        currentTrips.map((trip) => (
          <div className="flex flex-col my-4">
            <TripCard trip={trip} key={trip.id} className="max-w-2xl" />
          </div>
        ))}
      {currentTrips.length === 0 && (
        <Alert message={`No ${currentFilter} trips available.`} />
      )}
    </div>
  );
}

export default TripsAsPilot;
