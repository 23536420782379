import fetchAPI from '../util';
import { endpoints } from '../endpoints';

export default function changeDefaultCard(stripeToken: string) {
  return fetchAPI(endpoints.STRIPE, {
    method: 'PUT',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      stripe_token: stripeToken,
      as_default: true,
    }),
  });
}
