import React from 'react';

import { Outlet } from 'react-router-dom';

import ToastContainer from '../../atoms/Toast/ToastContainer';
import Header from '../../organisms/Header';
import Main from '../Main';
import Footer from '../../organisms/Footer';

/**
 *
 * Good to reference when scaling/needing another Top Level Layout https://reactrouter.com/en/6.26.2/components/outlet
 */
function PrimaryLayout() {
  return (
    <>
      <ToastContainer className="z-50" directions={['top', 'center']} />
      <div className="relative grid grid-rows-primary-layout h-screen overflow-clip z-0">
        <Header />
        <Main>
          <Outlet />
          <Footer />
        </Main>
      </div>
    </>
  );
}

export default PrimaryLayout;
