import React, { useState } from 'react';

import { useFormikContext } from 'formik';

import getServicesAgreement from '../../../../API/Trips/getServicesAgreement';
import FormikCheckbox from '../../../../components/atoms/Form/FormikCheckbox';
import FormikField from '../../../../components/atoms/Form/FormikField';
import Loader from '../../../../components/molecules/Loader';
import openPdfInNewTab from '../../../../utilities/openPdfInNewTab';
import SignaturePad from '../../../../components/organisms/SignaturePad/SignaturePad';

export default function ConfirmHireFormFields({
  bidId,
  isBid,
}: {
  bidId: number;
  isBid: boolean;
}) {
  const [loadingPdf, setLoadingPdf] = useState(false);
  const { setFieldValue } = useFormikContext();

  const handleClick = async () => {
    // * Was trying a solution to avoid making multiple requests to the same PDF but ran into access issues
    // const existingPdf = localStorage.getItem('agreementPdfUrl');
    // if (existingPdf) {
    //   openPdfInNewTab(existingPdf);
    //   return;
    // }
    try {
      setLoadingPdf(true);

      const response = await getServicesAgreement(bidId as number, isBid);

      if (response.url) {
        setLoadingPdf(false);
        localStorage.setItem('agreementPdfUrl', response.url);
        openPdfInNewTab(response.url);
      }
    } catch (error) {
      setLoadingPdf(false);
      console.log(error);
    }
  };

  return (
    <>
      {loadingPdf ? (
        <Loader caption="Loading Agreement, please wait..." />
      ) : (
        <FormikField
          name="hasViewedAgreement"
          label={
            <span>
              I agree to the{' '}
              <button
                type="button"
                className="btn-ghost underline"
                onClick={handleClick}
              >
                Pilot Service Agreement
              </button>
            </span>
          }
          inline
          component={FormikCheckbox}
        />
      )}

      <SignaturePad
        handleSave={async (signature) => {
          setFieldValue('signature', signature);
        }}
        handleClear={() => setFieldValue('signature', null)}
      />
    </>
  );
}
