/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { FieldProps } from 'formik';
import React, { TextareaHTMLAttributes } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & FieldProps;

export default function FormikTextArea({
  className,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...standardTextAreaProps
}: TextAreaProps) {
  return (
    <textarea
      className={`textarea textarea-bordered w-full max-w-sm disabled:bg-base-200 disabled:text-neutral disabled:cursor-not-allowed ${className}`}
      cols={30}
      rows={4}
      {...standardTextAreaProps}
      {...field}
    />
  );
}
