/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Trip from '../../types/Trip';
import getTripAsConsumer from '../../API/Trips/getTripAsConsumer';
import cancelTrip from '../../API/Trips/cancelTrip';
import { useModalContext } from '../../hooks/ModalProvider/ModalProvider';
import { ROUTES } from '../routerConfig';
import TripDetailsModal from './PilotView/TripDetailsModal';
import ConfirmCancelModal from './OperatorView/ConfirmCancelModal';
import CreatedView from './OperatorView/TripsByStatus/CreatedView';
import QuotesReceivedView from './OperatorView/TripsByStatus/QuotesReceivedView';
import User from '../../types/User';
import PilotHiredView from './OperatorView/TripsByStatus/PilotHiredView';
import PilotConfirmedView from './OperatorView/TripsByStatus/PilotConfirmedView';
import PilotInitialPaymentView from './OperatorView/TripsByStatus/PilotInitialPaymentView';
import UnderwayView from './OperatorView/TripsByStatus/UnderwayView';
import ExpensesSubmittedView from './OperatorView/TripsByStatus/ExpensesSubmittedView';
import ExpensesApprovedView from './OperatorView/TripsByStatus/ExpensesApprovedView';
import CompleteView from './OperatorView/TripsByStatus/CompleteView';
import CancelledView from './OperatorView/TripsByStatus/CancelledView';
import Loader from '../../components/molecules/Loader';
import reviewTripAsConsumer, {
  type ReviewPilotFields,
} from '../../API/Trips/reviewTripAsConsumer';
import ReviewPilotModal from './OperatorView/ReviewPilotModal';
import { useToast } from '../../hooks/ToastProvider';
import Card from '../../components/molecules/Card/Card';

function OperatorView({ id, user }: { id: string; user: User }) {
  const { openModal, closeModal } = useModalContext();
  const { addToast } = useToast();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [trip, setTrip] = useState<Trip | null>(null);

  const getAndSetTrip = useCallback(async () => {
    const loadedTrip = await getTripAsConsumer(id as string);

    if (!loadedTrip.notes)
      loadedTrip.notes = 'No notes for this trip. Have a safe flight!';

    setTrip(loadedTrip);
    setLoading(false);
  }, [id]);

  useEffect(() => {
    getAndSetTrip();
  }, [id, user, getAndSetTrip]);

  const submitReview = async (values: ReviewPilotFields) => {
    try {
      if (!trip) return;

      await reviewTripAsConsumer(values, trip.id);

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const submitEmptyReview = () => {
    if (!trip) return;

    // TODO: this is a workaround due to the fact that the review is currently required on the backend
    // reviewTripAsPilot(
    //   { pilotConsumerRating: 0, pilotFlycoRating: 0, pilotNotes: '' },
    //   trip.id
    // );
    closeModal();
  };

  const openDetailsModal = (trip: Trip) => {
    openModal(<TripDetailsModal trip={trip} />);
  };

  const openReviewModal = () => {
    openModal(
      <ReviewPilotModal onClose={submitEmptyReview} onSubmit={submitReview} />
    );
  };

  const handleTripCancelled = async (reason: string) => {
    try {
      await cancelTrip(`${trip?.id}`, reason);
      closeModal();
      navigate(ROUTES.TRIPS);
    } catch (error) {
      closeModal();
      addToast(
        <span>Unable to cancel trip. Please contact support.</span>,
        'error'
      );
    }
  };

  const handleCancel = () => {
    openModal(
      <ConfirmCancelModal
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore-next-line
        onConfirm={handleTripCancelled}
        onClose={closeModal}
      />
    );
  };

  if (loading || !user)
    return (
      <Card responsive>
        <Loader level={3} caption="Loading Trip..." />
      </Card>
    );

  switch (trip?.status) {
    case 'Created':
      return <CreatedView trip={trip} handleCancel={handleCancel} />;
    case 'Quotes Received':
      return (
        <QuotesReceivedView
          trip={trip}
          handleCancel={handleCancel}
          openDetailsModal={openDetailsModal}
        />
      );
    case 'Pilot Hired':
      return <PilotHiredView trip={trip} openDetailsModal={openDetailsModal} />;
    case 'Pilot Confirmed':
      return (
        <PilotConfirmedView trip={trip} openDetailsModal={openDetailsModal} />
      );
    case 'Pilot Initial Payment':
      return (
        <PilotInitialPaymentView
          trip={trip}
          openDetailsModal={openDetailsModal}
        />
      );
    case 'Underway':
      return <UnderwayView trip={trip} openDetailsModal={openDetailsModal} />;
    case 'Expenses Submitted':
      return (
        <ExpensesSubmittedView
          trip={trip}
          openDetailsModal={openDetailsModal}
        />
      );
    case 'Expenses Approved':
      return (
        <ExpensesApprovedView
          trip={trip}
          openDetailsModal={openDetailsModal}
          openReviewModal={openReviewModal}
        />
      );
    // review FlyCo and the Pilot
    case 'Pilot Final Payment':
      return (
        <ExpensesApprovedView
          trip={trip}
          openDetailsModal={openDetailsModal}
          openReviewModal={openReviewModal}
        />
      );
    case 'Complete':
      return <CompleteView trip={trip} openDetailsModal={openDetailsModal} />;
    case 'Cancelled':
      return <CancelledView trip={trip} />;
    default:
      throw new Error(`Invalid trip status: ${trip?.status}`);
  }
}

export default OperatorView;
