const endpoint = `/api/pilot_certs/`;

async function deletePilotCertificate(certificateId: number) {
  if (!certificateId) return;

  await fetch(`${endpoint}${certificateId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
    credentials: 'include',
  });
}

export default deletePilotCertificate;
