import Heading from '../../../components/typography/Heading';
import {
  PrimaryButton,
  StateButton,
} from '../../../components/atoms/Button/Button';
import Trip from '../../../types/Trip';

export default function DeclineHireModal(
  trip: Trip | null,
  onConfirm: () => unknown,
  onDecline: () => unknown
) {
  return (
    <div className="flex flex-col gap-4 mb-24 pt-4">
      <Heading level={3}>
        Are you sure you want to cancel your quote for your trip with{' '}
        {trip?.createdByUser?.consumerRole.fullName || 'this client'}?
      </Heading>
      <div className="grid grid-cols-2 gap-4 w-full">
        <PrimaryButton onClick={onDecline}>No, Go Back</PrimaryButton>
        <StateButton className="text-white" status="error" onClick={onConfirm}>
          Yes, Cancel Quote
        </StateButton>
      </div>
    </div>
  );
}
