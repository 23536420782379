import React from 'react';
import classNames from 'classnames';
import Direction from '../../../types/Direction';

export interface TooltipProps {
  children?: React.ReactNode;
  direction?: Exclude<Direction, 'center' | 'start' | 'end' | 'middle'>;
  trigger: string | React.ReactNode;
}
// TODO: revisit daisy styles, add more padding and margin

/** Desktop only (for now) tooltip */
function Tooltip({ direction = 'top', children, trigger }: TooltipProps) {
  const tooltipDirection = {
    top: 'tooltip-top',
    bottom: 'tooltip-bottom',
    left: 'tooltip-left',
    right: 'tooltip-right',
  };

  return (
    <div
      className={classNames(
        `tooltip ${tooltipDirection[direction]} cursor-pointer hidden lg:inline-block`,
        typeof trigger === 'string' &&
          'underline underline-offset-8 decoration-dotted lg:inline'
      )}
      data-tip={children}
    >
      {/* I think adding the space before/after is fine but it can be conditional later when `trigger` is not a string */}
      &nbsp;{trigger}&nbsp;
    </div>
  );
}

export default Tooltip;
