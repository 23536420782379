import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PaymentMethod } from '@stripe/stripe-js';

import User from '../../../../types/User';
import Trip from '../../../../types/Trip';
import Conversation from '../../../../types/Conversation';
import ConfirmHireModal from '../ConfirmHireModal';
import { useModalContext } from '../../../../hooks/ModalProvider/ModalProvider';
import requestHire from '../../../../API/Trips/requestHire';
import { Quote, createQuoteFromBid } from '../../../../types/Quote';
import {
  PrimaryButton,
  SecondaryButton,
  StateButton,
} from '../../../../components/atoms/Button/Button';
import PilotCard from '../../../../components/molecules/PilotCard/PilotCard';
import Chat from '../../../../components/organisms/Chat/Chat';
import TripCard from '../../../../components/organisms/TripCard/TripCard';
import Heading from '../../../../components/typography/Heading';
import { useScroll } from '../../../../hooks/ScrollProvider/ScrollProvider';
import { useToast } from '../../../../hooks/ToastProvider';
import ControlBar from '../../../../components/molecules/ControlBar/ControlBar';
import { ROUTES } from '../../../routerConfig';
import GrandTotalEstimateCard from '../../../../components/organisms/GrandTotalEstimateCard/GrandTotalEstimateCard';
import getPaymentMethods from '../../../../API/Payments/getPaymentMethods';
import PrimaryTripLayout from '../../Layouts/PrimaryTripLayout';
import Rule from '../../../../components/atoms/Rule';
import QuoteModal from '../../PilotView/QuoteModal';

function QuotesReceivedView({
  trip,
  handleCancel,
  openDetailsModal,
}: {
  trip: Trip;
  handleCancel: () => unknown;
  openDetailsModal: (trip: Trip) => unknown;
}) {
  if (!trip.conversations.length)
    throw new Error(
      `No conversations on Trip when trying to render QuotesReceivedView`
    );

  if (!trip.conversations[0].bid)
    throw new Error(`No bid on Trip when trying to render QuotesReceivedView`);

  const { openModal, closeModal } = useModalContext();
  const { scrollToTop } = useScroll();
  const { addToast } = useToast();
  const [currentConversation, setCurrentConversation] =
    useState<Conversation | null>(
      trip && trip.conversations.length ? trip.conversations[0] : null
    );
  const [quote, setQuote] = useState<Quote>(
    createQuoteFromBid(
      trip.conversations[0].bid,
      trip.conversations[0].user,
      trip
    )
  );
  const [quoteIndex, setQuoteIndex] = useState(0);
  const navigate = useNavigate();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);
  const navigateToCards = () => {
    closeModal();
    navigate(ROUTES.BILLING_AND_PAYMENTS);
  };

  // New states for payment methods
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] =
    useState<string>('');

  // Fetch payment methods on component mount
  useEffect(() => {
    getPaymentMethods()
      .then((result) => {
        setPaymentMethods(result.payment_methods);
        setDefaultPaymentMethodId(result.default_payment_method_id);
      })
      .catch((error) =>
        console.error('Error fetching payment methods:', error)
      );
  }, []);

  const pilotAreaRef = useRef<HTMLDivElement>(null);
  const chatAreaRef = useRef<HTMLDivElement>(null);
  const tripAndQuoteAreaRef = useRef<HTMLDivElement>(null);
  const destinationAccountErrors = [
    'insufficient_capabilities_for_transfer',
    'account_closed',
    'account_frozen',
    'invalid_account_number',
    'no_account',
    'payouts_not_allowed',
    'destination account',
  ];
  const cardErrors = [
    'card_declined',
    'incorrect_cvc',
    'expired_card',
    'insufficient_funds',
    'incorrect_number',
    'authentication_required',
  ];

  const viewQuote = (i: number) => {
    // TODO make this scroll to top work when selecting a different quote
    if (pilotAreaRef.current) pilotAreaRef.current.scrollTop = 0;
    if (chatAreaRef.current) chatAreaRef.current.scrollTop = 0;
    if (tripAndQuoteAreaRef.current) tripAndQuoteAreaRef.current.scrollTop = 0;
    scrollToTop();
    setQuoteIndex(i);
    setCurrentConversation(
      trip && trip.conversations.length ? trip.conversations[i] : null
    );
    if (!trip.conversations[i].bid)
      throw new Error(
        `No bid on conversation when trying to render QuotesReceivedView`
      );
    setQuote(
      createQuoteFromBid(
        trip.conversations[i].bid!,
        trip.conversations[i].user,
        trip
      )
    );
  };

  const onCancelConfirmHire = () => {
    closeModal();
  };

  const onConfirmRequestHire = async () => {
    const response = await requestHire({
      bidId: currentConversation!.bid!.id,
      tripId: trip!.id,
    });

    closeModal();

    if (response.success) {
      window.location.reload();
    } else {
      try {
        if (
          destinationAccountErrors.some((error) =>
            response.detail.includes(error)
          )
        ) {
          addToast(
            <span>
              Payment Failed. Please message the pilot and have them check their
              bank settings in Billing and Payments.
            </span>,
            'error',
            10000
          );
        } else if (
          cardErrors.some((error) => response.detail.includes(error))
        ) {
          addToast(
            <span>
              Payment Failed. Please update your payment details in Billing and
              Payments.
            </span>,
            'error',
            10000
          );
        } else {
          addToast(
            <span>Payment Failed. Please try again or contact support.</span>,
            'error',
            10000
          );
        }
      } catch (err) {
        console.error(err);
        addToast(
          <span>Payment Failed. Please try again or contact support.</span>,
          'error',
          10000
        );
      }
    }
  };

  const openAcceptBidConfirmationModal = () => {
    if (!currentConversation || !quote || !trip) return;

    openModal(
      <ConfirmHireModal
        conversation={currentConversation}
        trip={trip}
        onCancel={onCancelConfirmHire}
        onConfirm={onConfirmRequestHire}
        defaultPaymentMethod={paymentMethods.find(
          (method) => method.id === defaultPaymentMethodId
        )}
        navigateToCards={navigateToCards}
      />
    );
  };

  const openQuoteModal = () => {
    if (!currentConversation) return;
    openModal(
      <QuoteModal conversation={currentConversation} isQuoted isPilot={false} />
    );
  };

  return (
    <>
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" />
          Trips
        </PrimaryButton>
        <Rule className="md:hidden" />
        <SecondaryButton
          className="lg:hidden gap-0"
          size="small"
          onClick={openQuoteModal}
        >
          <span className="hidden md:inline">View&nbsp;</span>Quote Details
        </SecondaryButton>
        <SecondaryButton
          className="lg:hidden gap-0"
          size="small"
          onClick={() => openDetailsModal(trip)}
        >
          <span className="hidden md:inline">View&nbsp;</span>Trip Details
        </SecondaryButton>
        <Rule className="md:hidden" />
        <StateButton
          className="md:ml-auto"
          size="small"
          onClick={handleCancel}
          status="error"
        >
          Cancel Trip
        </StateButton>
        <StateButton
          status="success"
          size="small"
          onClick={openAcceptBidConfirmationModal}
        >
          <span className="lg:hidden">Hire</span>
          <span className="hidden lg:inline">Hire Pilot</span>
        </StateButton>
        {trip.conversations.length > 1 && (
          <div className="ml-auto md:ml-0 flex gap-2">
            <SecondaryButton
              size="small"
              onClick={() => {
                if (quoteIndex > 0) viewQuote(quoteIndex - 1);
              }}
              disabled={quoteIndex === 0}
            >
              <i className="fa-solid fa-chevron-left" />
              {quoteIndex > 0 && (
                <span className="hidden md:inline">
                  {quoteIndex}/{trip.conversations.length}
                </span>
              )}
            </SecondaryButton>
            <SecondaryButton
              size="small"
              onClick={() => {
                if (quoteIndex < trip.conversations.length - 1)
                  viewQuote(quoteIndex + 1);
              }}
              disabled={quoteIndex > trip.conversations.length - 2}
            >
              {quoteIndex < trip.conversations.length - 1 && (
                <span className="hidden md:inline">
                  {quoteIndex + 2}/{trip.conversations.length}
                </span>
              )}
              <i className="fa-solid fa-chevron-right" />
            </SecondaryButton>
          </div>
        )}
      </ControlBar>
      <div className="flex flex-col gap-4">
        <Heading level={2}>
          Viewing quote from {currentConversation?.user.firstName}{' '}
          {currentConversation?.user.lastName}
        </Heading>
        {/* Three column grid layout that collapses to 2 col, then 1 col */}
        <PrimaryTripLayout>
          {/** Pilot profile area */}
          {/* Small display version */}
          <div className="h-full">
            <PilotCard
              pilot={trip.conversations[quoteIndex].user}
              className="h-full"
              planeType={trip.planeType}
              bidNumber={quoteIndex + 1}
              selected
              expanded="always"
              airplane={trip.aircraft}
              tripId={trip.id}
            />
          </div>
          {/** Chat area */}
          {currentConversation && (
            <div className="flex flex-col gap-4 h-full" ref={chatAreaRef}>
              {/** Quote card */}
              {trip.conversations.length && currentConversation && quote ? (
                <GrandTotalEstimateCard
                  isQuoted
                  expenses={currentConversation.bid!.quotedExpenses}
                  cost={currentConversation.cost}
                />
              ) : (
                <Heading level={4}>No quotes yet!</Heading>
              )}
              <Chat
                className="flex-grow"
                tripId={trip.id}
                title={
                  currentConversation
                    ? `Chat with ${currentConversation.user.firstName}`
                    : 'Loading'
                }
                conversation={currentConversation}
              />
            </div>
          )}
          {/* Trip and Quote area  */}
          <div className="hidden lg:block" ref={tripAndQuoteAreaRef}>
            {/** Trip card */}
            <TripCard
              trip={trip}
              showStatus={false}
              expanded="always"
              interactive={false}
              className="h-full"
            />
          </div>
        </PrimaryTripLayout>
      </div>
    </>
  );
}

export default QuotesReceivedView;
