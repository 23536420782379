import { PrimaryButton } from '../../components/atoms/Button/Button';
import Rule from '../../components/atoms/Rule';
import Card from '../../components/molecules/Card/Card';
import Heading from '../../components/typography/Heading';
import Paragraph from '../../components/typography/Paragraph';
import { useModalContext } from '../../hooks/ModalProvider/ModalProvider';
import { usePlaneTypes } from '../../hooks/PlaneTypesProvider';
import PlaneType from '../../types/PlaneType';
import { PlaneTypeStatistic } from '../../types/PlaneTypeStatistic';
import AddModelModal from './AddModelModal';
import PlaneModelStatistics from './PlaneModelStatistics';

export default function TypeRatingTab({
  typeRating,
  statistics,
  handleAddModel,
}: {
  typeRating: PlaneType;
  statistics: PlaneTypeStatistic[];
  handleAddModel: (modelPlaneType: PlaneType) => unknown;
}) {
  const { getModels, getCraftNameById } = usePlaneTypes();
  const { openModal } = useModalContext();

  // Get all of the models associated with this type rating, unless
  // it's a standalone type rating/model, in which case just put it
  // in a list and use it as the associated model
  const associatedModels =
    getModels(typeRating.id).length > 0
      ? getModels(typeRating.id)
      : [typeRating];

  // Find all user data associated with the relevant models
  const associatedModelStatistics = statistics.filter((stat) =>
    associatedModels.find((m) => m.id === stat.planeType)
  );

  // If no training cert / PlaneTypeStat, add an empty one
  // so the UI is easier to wrangle
  associatedModelStatistics.forEach((stat) => {
    if (!stat.planeTypeCert)
      // eslint-disable-next-line no-param-reassign
      stat.planeTypeCert = {
        certDocument: null,
        diffDocument: null,
        trainingFacility: null,
        certificationDate: null,
      };
  });

  // The models available to add will be whichever of the complete list
  // for this type rating, minus any that already have statistics created
  const addModelOptions = associatedModels.filter(
    (am) => !statistics.find((s) => s.planeType === am.id)
  );

  const openModelModal = () => {
    openModal(
      <AddModelModal
        modelOptions={addModelOptions}
        handleNewModel={handleAddModel}
      />
    );
  };

  const handleModelStatisticChange = (statistics: PlaneTypeStatistic) => {
    alert(`updated stats for ${getCraftNameById(statistics.planeType)}`);
  };

  /**
   * There are two scenarios when rendering a type rating;
   *   1) the type rating has sub-models
   *   2) the type rating is also a standalone model
   * The way to tell the difference is whether or not the type rating's
   * relatedPlaneTypes property is an empty array or not. If it's empty,
   * the type rating is also a standalone model. If it's not empty, each
   * item in the list is a model (related to the type rating).
   */
  return (
    <Card responsive className="flex flex-col gap-4">
      <div className="flex flex-col gap-4 justify-between">
        <Heading level={3}>{typeRating.craftType}</Heading>
        {addModelOptions.length > 0 && (
          <PrimaryButton className="max-w-fit" onClick={openModelModal}>
            Add Model
          </PrimaryButton>
        )}
        <div>
          {associatedModelStatistics.length ? (
            <div className="flex flex-col gap-4">
              {associatedModelStatistics.map((modelStats) => (
                <PlaneModelStatistics
                  statistics={modelStats}
                  onChange={handleModelStatisticChange}
                  key={modelStats.id}
                />
              ))}
            </div>
          ) : (
            <Paragraph>Add a model above to get started.</Paragraph>
          )}
        </div>
      </div>
    </Card>
  );
}
