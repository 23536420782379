import PilotCertificateLimit from '../../types/PilotCertificateLimit';
import { endpoints } from '../endpoints';

interface PilotCertificateLimitsResponse {
  limits: PilotCertificateLimit[];
  statusCode: number;
}

/** http://flyingcompany.xyz/admin/flying_api/pilotcertrating */
const getPilotCertificateLimits =
  async (): Promise<PilotCertificateLimitsResponse | null> => {
    try {
      const pilotCertificateLimits = await fetch(
        endpoints.PILOT_CERTIFICATE_LIMITS,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            Authorization: `Token ${localStorage.getItem('key')}`,
          },
        }
      );

      const limits = await pilotCertificateLimits.json();

      return {
        limits,
        statusCode: pilotCertificateLimits.status,
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  };

export default getPilotCertificateLimits;
