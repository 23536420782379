import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PilotCertificate from '../../types/PilotCertificate';
import { useToast } from '../ToastProvider';
import getPilotCertificateLimits from '../../API/Pilot/getPilotCertificateLimits';
import getPilotCertificateRatings from '../../API/Pilot/getPilotCertificateRatings';
import PilotCertificateRating from '../../types/PilotCertificateRating';
import PilotCertificateLimit from '../../types/PilotCertificateLimit';
import createPilotCertificate from '../../API/Pilot/createPilotCertificate';
import PilotCertificateType from '../../types/PilotCertificateType';
import updatePilotCertificate from '../../API/Pilot/updatePilotCertificate';
import { PilotRole } from '../../types/PilotRole';
import updateMedicalAPI from '../../API/Pilot/updateMedical';
import { useUser } from '../UserProvider/UserProvider';
import CertificateRatingOption from '../../types/CertificateRatingOption';
import { useCertificateMetadata } from './CertificateMetadataProvider';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';
import createCertificateLicense from '../../API/Pilot/createCertificateLicense';

/**
 * This Context provides a utility wrapper around
 * the logged-in user's Pilot certificates. It coordinates with the
 * CertificateMetadataProvider to create a higher level API over the
 * various backend API calls.
 *
 * TODO consider expanding this to cover all Pilot role API interactions,
 * as a layer of extra capabilities on top of what UserProvider provides.
 */
interface PilotProviderProps {
  children: React.ReactNode;
}

interface IPilotCertificateContext {
  refreshPilot: (userRefresh?: boolean) => Promise<unknown>;
  addCertificate: (certificateType: PilotCertificateType) => Promise<unknown>;
  updateCertificate: (newCertificate: PilotCertificate) => Promise<unknown>;
  updateMedical: ({
    medicalClass,
    dateMedical,
    medicalDocument,
  }: {
    medicalClass?: PilotRole['medicalClass'];
    dateMedical?: PilotRole['dateMedical'];
    medicalDocument?: PilotRole['medicalDocument'];
  }) => Promise<unknown>;
  pilotLimits: PilotCertificateLimit[];
  pilotRatings: PilotCertificateRating[];
  loadingPilotCertificates: boolean;
  getCraftRatingDisplayName: (
    certificateRatingId: PilotCertificate['craftRatings'][number]
  ) => string;
  transformCraftRatingsToCertificateRatingOptionIds: (
    craftRatings: PilotCertificate['craftRatings']
  ) => CertificateRatingOption['id'][];
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const PilotCertificateContext = createContext<IPilotCertificateContext>(
  undefined!
);

export function PilotCertificateProvider({ children }: PilotProviderProps) {
  const { addToast } = useToast();
  const { user, refreshUser } = useUser();
  const { ratingsOptions, certificateTypes } = useCertificateMetadata();

  const [loadingPilotCertificates, setLoadingPilotCertificates] =
    useState(true);

  const [pilotLimits, setPilotLimits] = useState<PilotCertificateLimit[]>([]);
  const [pilotRatings, setPilotRatings] = useState<PilotCertificateRating[]>(
    []
  );

  const loadPilotData = useCallback(async () => {
    const getPilotCertificateLimitsResponse = await getPilotCertificateLimits();
    const getPilotCertificateRatingsResponse =
      await getPilotCertificateRatings();

    if (
      !getPilotCertificateLimitsResponse ||
      getPilotCertificateLimitsResponse.statusCode !== 200
    ) {
      addToast(<span>Unable to load pilot limits.</span>, 'error');
      return;
    }
    if (
      !getPilotCertificateRatingsResponse ||
      getPilotCertificateRatingsResponse.statusCode !== 200
    ) {
      addToast(<span>Unable to load pilot ratings.</span>, 'error');
      return;
    }
    setPilotLimits(
      remapKeysToCamelCase(
        getPilotCertificateLimitsResponse.limits
      ) as PilotCertificateLimit[]
    );
    setPilotRatings(
      remapKeysToCamelCase(
        getPilotCertificateRatingsResponse.ratings
      ) as PilotCertificateRating[]
    );

    const idForCommercialCertificate = certificateTypes.find(
      (ct) => ct.name === 'Commercial'
    )?.id;

    const idForATPCertificate = certificateTypes.find(
      (ct) => ct.name === 'Air Transport Pilot'
    )?.id;

    const commercialCertificate = user?.pilotRole.licenseCerts.find(
      (c) => c.type === idForCommercialCertificate
    );
    const ATPCertificate = user?.pilotRole.licenseCerts.find(
      (c) => c.type === idForATPCertificate
    );

    // Catch invalid commerical combination
    if (commercialCertificate && ATPCertificate) {
      throw new Error(
        `Invalid certificate state for user: ${user?.id}; cannot have Commerical Certificate and ATP Certificate`
      );
    }

    // TODO get sorted cert options
  }, [addToast, certificateTypes, user?.id, user?.pilotRole.licenseCerts]);

  /** External methods */

  const getCraftRatingDisplayName = useCallback(
    (certificateRatingId: PilotCertificate['craftRatings'][number]) => {
      const matchingPilotRating = pilotRatings.find(
        (ro) => ro.id === certificateRatingId
      );
      if (!matchingPilotRating)
        throw new Error(
          `No matching pilot certificate option for id: ${certificateRatingId}`
        );
      const matchingCertificateRatingOption = ratingsOptions.find(
        (ro) => ro.id === matchingPilotRating.rating
      );
      if (!matchingCertificateRatingOption) {
        throw new Error(
          `No matching certificate rating option for id: ${certificateRatingId}`
        );
      }
      return matchingCertificateRatingOption.name;
    },
    [pilotRatings, ratingsOptions]
  );

  /** Loads ratings and limits for a pilot's certificates */
  const refreshPilot = useCallback(
    async (userRefresh = false) => {
      if (!user) return;
      setLoadingPilotCertificates(true);
      if (userRefresh) await refreshUser();
      await loadPilotData();
      setLoadingPilotCertificates(false);
    },
    [loadPilotData, user, refreshUser]
  );

  /** Adds a new Certificate of the selected type for the logged in user's pilot role and refreshes the pilot state */
  const addCertificate = useCallback(
    async (certificateType: PilotCertificateType) => {
      if (!user) return;
      const newCert = await createPilotCertificate(user.pilotRole.id, {
        type: certificateType.id,
        craftRatings: [],
      });
      if (newCert && newCert.id) {
        await createCertificateLicense(newCert.id);
      }
    },
    [user]
  );

  /** Applies updates on one of the logged in user's pilot certificates and refreshes the pilot state */
  const updateCertificate = useCallback(
    async (updatedCertificate: PilotCertificate) => {
      if (!user) return;
      await updatePilotCertificate(user.pilotRole, updatedCertificate);
      await refreshPilot();
    },
    [refreshPilot, user]
  );

  /** Updates one or more properties of the pilot's medical certificate and refreshes the pilot state */
  const updateMedical = useCallback(
    async ({
      medicalClass,
      dateMedical,
      medicalDocument,
    }: {
      medicalClass?: PilotRole['medicalClass'];
      dateMedical?: PilotRole['dateMedical'];
      medicalDocument?: PilotRole['medicalDocument'];
    }) => {
      if (!user) return;
      const payload = {
        medicalClass: medicalClass || user.pilotRole.medicalClass,
        dateMedical: dateMedical || user.pilotRole.dateMedical,
        medicalDocument: medicalDocument || user.pilotRole.medicalDocument,
      };
      await updateMedicalAPI(user.pilotRole.id, payload);
      await refreshPilot();
    },
    [refreshPilot, user]
  );

  const transformCraftRatingsToCertificateRatingOptionIds = useCallback(
    (craftRatings: PilotCertificate['craftRatings']) =>
      craftRatings.map((craftRatingId) => {
        const matchingPilotRating = pilotRatings.find(
          (pr) => pr.id === craftRatingId
        );
        if (!matchingPilotRating)
          throw new Error(
            `No matching pilot certificate rating for craft rating id value: ${craftRatingId}`
          );
        const matchingCertificateRatingOption = ratingsOptions.find(
          (ro) => ro.id === matchingPilotRating.rating
        );
        if (!matchingCertificateRatingOption)
          throw new Error(
            `No matching certificate rating option for pilot rating rating value: ${matchingPilotRating.rating}`
          );
        return matchingCertificateRatingOption.id;
      }),
    [pilotRatings, ratingsOptions]
  );

  /** Initialize */
  useEffect(() => {
    const init = async () => {
      await refreshPilot();
    };
    init();
  }, [refreshPilot]);

  /** Expose public interface */

  const value = useMemo(() => {
    return {
      refreshPilot,
      addCertificate,
      updateCertificate,
      updateMedical,
      pilotLimits,
      pilotRatings,
      loadingPilotCertificates,
      getCraftRatingDisplayName,
      transformCraftRatingsToCertificateRatingOptionIds,
      // availableATPRatings,
      // availableCommericalRatings,
    };
  }, [
    addCertificate,
    pilotLimits,
    pilotRatings,
    refreshPilot,
    updateCertificate,
    updateMedical,
    loadingPilotCertificates,
    getCraftRatingDisplayName,
    transformCraftRatingsToCertificateRatingOptionIds,
    // availableATPRatings,
    // availableCommericalRatings,
  ]);

  return (
    <PilotCertificateContext.Provider value={value}>
      {children}
    </PilotCertificateContext.Provider>
  );
}

export const usePilotCertificates = () =>
  useContext<IPilotCertificateContext>(PilotCertificateContext);
