import { endpoints } from '../endpoints';

async function deletePilotCertificateRating(
  certificateRatingIdToDelete: number
) {
  await fetch(
    `${endpoints.PILOT_CERTIFICATE_RATINGS}${certificateRatingIdToDelete}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      credentials: 'include',
    }
  );
}

export default deletePilotCertificateRating;
