/* eslint-disable jsx-a11y/click-events-have-key-events */
// Import React
import React, { useState, useCallback } from 'react';
// Import packages
import { isAfter } from 'date-fns';
// Import shared app components and types
import { PrimaryButton } from '../../atoms/Button/Button';
import Paragraph from '../../typography/Paragraph';
import Heading from '../../typography/Heading';
import Trip from '../../../types/Trip';
import { PilotRole } from '../../../types/PilotRole';
import Card from '../../molecules/Card/Card';
// Import utilities and hooks
import { ROUTES } from '../../../routes/routerConfig';
import { CREW_TYPE_OPTIONS } from '../../../types/CrewType';
import TripStatusIndicator from './TripStatusIndicator';
import ItineraryTable from './ItineraryTable';
import QuoteBanner from './QuoteBanner';
import Location from './Location';
import IconAndCraft from './IconAndCraft';
import getServicesAgreement from '../../../API/Trips/getServicesAgreement';
import openPdfInNewTab from '../../../utilities/openPdfInNewTab';
import getTripInvoicePdf from '../../../API/Trips/getTripInvoicePdf';

interface TripCardProps {
  trip: Trip;
  className?: string;
  showStatus?: boolean;
  expanded?: 'true' | 'false' | 'never' | 'always';
  interactive?: boolean;
  showQuoteCount?: boolean;
}

function InteractiveWrapper({
  interactive,
  children,
  href,
}: {
  interactive: boolean;
  children: React.ReactNode;
  href?: string;
}) {
  return interactive ? (
    <a href={href || undefined} tabIndex={-1}>
      {children}
    </a>
  ) : (
    <div>{children}</div>
  );
}

function TripCard({
  trip,
  className,
  showStatus = true,
  expanded = 'false',
  interactive = true,
  showQuoteCount = false,
}: TripCardProps) {
  const [loadingPdf, setLoadingPdf] = useState(false);
  const startDate = new Date(trip.startDate);
  const endDate = new Date(trip.endDate);
  const now = new Date();
  const tripCraftName = trip.planeType?.craftName || 'Unknown Aircraft';
  const tripAircraft = trip.aircraft;

  const status =
    trip.status === 'Pilot Initial Payment' && isAfter(now, startDate)
      ? 'Underway'
      : trip.status;

  const [expandedState, setExpandedState] = useState(
    !!(expanded === 'true' || expanded === 'always')
  );

  const expand = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setExpandedState(!expandedState);
  };

  const quoteCount = trip.conversations.filter((c) => c.bid).length;

  const getPilotProfilePdf = useCallback(() => {
    if (trip.pilot && typeof trip.pilot !== 'number') {
      const pilot = trip.pilot as PilotRole; // Type assertion to PilotRole
      const options = {
        headers: {
          Authorization: `Token ${localStorage.getItem('key')}`,
          'content-type': 'application/json',
        },
        method: 'GET',
      };
      const requestURL = `/api/pilots/${pilot.id}/pdf/?trip_id=${trip.id}`;
      fetch(requestURL, options)
        .then((res) => res.blob())
        .then(
          (result) => {
            const blob = new Blob([result]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${pilot.fullName}_pilot_profile.pdf`;
            link.click();
          },
          (error) => {
            console.log(error);
          }
        );
    }
  }, [trip.id, trip.pilot]);

  const getAircraftPdf = useCallback(() => {
    const options = {
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
        'content-type': 'application/json',
      },
      method: 'GET',
    };
    const requestURL = `/api/trips/as_pilot/${trip.id}/aircraft/`;
    fetch(requestURL, options)
      .then((res) => res.blob())
      .then(
        (result) => {
          const blob = new Blob([result]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `aircraft_sheet_${trip.id}.pdf`;
          link.click();
        },
        (error) => {
          console.log(error);
        }
      );
  }, [trip.id]);

  const getTripSheetPdf = useCallback(() => {
    const options = {
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
        'content-type': 'application/json',
      },
      method: 'GET',
    };
    const requestURL = `/api/trips/as_pilot/${trip.id}/tripsheet/`;
    fetch(requestURL, options)
      .then((res) => res.blob())
      .then(
        (result) => {
          const blob = new Blob([result]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `trip_sheet_${trip.id}.pdf`;
          link.click();
        },
        (error) => {
          console.log(error);
        }
      );
  }, [trip.id]);

  const openSignedAgreement = async () => {
    try {
      setLoadingPdf(true);

      const response = await getServicesAgreement(trip.id);

      if (response.url) {
        setLoadingPdf(false);
        localStorage.setItem('agreementPdfUrl', response.url);
        openPdfInNewTab(response.url);
      }
    } catch (error) {
      setLoadingPdf(false);
      console.log(error);
    }
  };

  return (
    <Card responsive noPadding className={`@container ${className || ''}`}>
      <InteractiveWrapper
        interactive={interactive}
        href={`${ROUTES.TRIPS}/${trip.id}`}
      >
        <div
          className={`py-2 rounded-t-lg ${
            status !== 'Cancelled' ? 'bg-primary' : 'bg-error'
          }`}
        >
          <Heading
            level={4}
            className="uppercase text-center text-white font-normal py-2"
          >
            {status === 'Cancelled' ? `${trip.name} - Cancelled` : trip.name}
          </Heading>
        </div>
        {!trip.hiredBid && (
          <QuoteBanner
            showQuoteCount={showQuoteCount}
            quoteCount={quoteCount}
          />
        )}
        {status === 'Pilot Hired' && (
          <Heading level={4} className="bg-info text-white p-2 text-center">
            Waiting for Pilot Confirmation
          </Heading>
        )}
        {status === 'Pilot Initial Payment' && (
          <Heading level={4} className="bg-info text-white p-2 text-center">
            Initial Pilot Payment Made
          </Heading>
        )}
        {status === 'Pilot Confirmed' && (
          <Heading level={4} className="bg-info text-white p-2 text-center">
            Initial Payment Pending
          </Heading>
        )}
        {status === 'Underway' && (
          <Heading level={4} className="bg-success text-white p-2 text-center">
            Enjoy Your Flight!
          </Heading>
        )}
        <div className="grid grid-cols-2 @md:grid-cols-3 px-6 mt-4">
          {/* Craft name */}
          <Paragraph className="@md:hidden col-span-2 text-lg mt-2 font-bold">
            {tripCraftName}
          </Paragraph>

          {/* Origin */}
          <Location
            displayName="Origin"
            icaoCode={trip.departureLocation.icaoCode}
            date={startDate}
          />

          {/* Flight icon and craft name */}
          <IconAndCraft craftName={tripCraftName} />

          {/* Destination */}
          <Location
            className="@md:text-right"
            displayName="Destination"
            icaoCode={trip.destinationLocation.icaoCode}
            date={endDate}
          />
        </div>
      </InteractiveWrapper>
      {/* Expanding section  */}
      {expandedState && (
        <div className="@container grid grid-cols-2 border-t border-b">
          <ItineraryTable legs={trip.legs} />
          <div className="col-span-2 @md:col-span-1 p-6 border">
            <Heading level={4}>Crew Type Needed</Heading>
            {CREW_TYPE_OPTIONS[trip.pilotJob as keyof typeof CREW_TYPE_OPTIONS]}
          </div>
          <div className="col-span-2 @md:col-span-1 p-6 border">
            <Heading level={4}>Aircraft</Heading>
            <div className="flex flex-col">
              <span>
                <span className="font-bold">Model:</span> {tripCraftName}
              </span>
              <span className="block">
                <span className="font-bold">SN:</span>{' '}
                {tripAircraft?.identification.serialNumber}
              </span>
              <span className="block">
                <span className="font-bold">Tail:</span>{' '}
                {tripAircraft?.identification.tailNumber}
              </span>
              <span>
                <span className="font-bold">Avionics:</span>{' '}
                {
                  trip.planeType.avionics.find(
                    (a) => a.id === tripAircraft!.configuration.avionics!
                  )!.avionicsType
                }
              </span>
              <span>
                <span className="font-bold">Configuration: </span>
                {
                  trip.planeType.seating.find(
                    (s) =>
                      s.id === tripAircraft!.configuration.seatingConfiguration!
                  )!.seatingType
                }
              </span>
            </div>
          </div>
          <div className="col-span-2 p-6 border">
            <Heading level={4}>Notes</Heading>
            {trip.notes}
          </div>
          {trip.pilot && (
            <div className="col-span-2 p-6 border">
              <Heading level={4}>Documents</Heading>
              <div className="flex flex-col gap-4 mt-4">
                <div className="flex gap-2 items-center">
                  <i className="fa-regular fa-circle-check text-success" />
                  <span className="text-center flex-grow">
                    Pilot History Form
                  </span>
                  <div
                    className="fa-solid fa-file-arrow-down"
                    style={{ cursor: 'pointer' }}
                    onClick={getPilotProfilePdf}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        getPilotProfilePdf();
                      }
                    }}
                    aria-label="Download Aircraft Sheet"
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <i className="fa-regular fa-circle-check text-success" />
                  <span className="text-center flex-grow">
                    {loadingPdf
                      ? 'Pilot Services Agreement loading...'
                      : 'Pilot Services Agreement'}
                  </span>
                  <div
                    className="fa-solid fa-file-arrow-down"
                    style={{ cursor: 'pointer' }}
                    onClick={openSignedAgreement}
                    role="button"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        openSignedAgreement();
                      }
                    }}
                    aria-label="Download Pilot Services Agreement"
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <i className="fa-regular fa-circle-check text-success" />
                  <span className="text-center flex-grow">Trip Sheet</span>
                  <div
                    className="fa-solid fa-file-arrow-down"
                    style={{ cursor: 'pointer' }}
                    onClick={getTripSheetPdf}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        getTripSheetPdf();
                      }
                    }}
                    aria-label="Download Trip Sheet"
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <i className="fa-regular fa-circle-check text-success" />
                  <span className="text-center flex-grow">Aircraft Sheet</span>
                  <div
                    className="fa-solid fa-file-arrow-down"
                    style={{ cursor: 'pointer' }}
                    onClick={getAircraftPdf}
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        getAircraftPdf();
                      }
                    }}
                    aria-label="Download Aircraft Sheet"
                  />
                </div>
                <div className="flex gap-2 items-center">
                  <i className="fa-regular fa-circle-check text-success" />
                  <span className="text-center flex-grow">
                    Download Invoice
                  </span>
                  <div
                    className="fa-solid fa-file-arrow-down"
                    style={{ cursor: 'pointer' }}
                    onClick={() => getTripInvoicePdf(trip.id)}
                    role="button"
                    tabIndex={0}
                    aria-label="Download Invoice"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {showStatus && status && (
        <div className="px-6">
          <TripStatusIndicator status={status} />
        </div>
      )}
      {expanded !== 'never' && expanded !== 'always' && (
        <PrimaryButton className="w-full rounded-t-none" onClick={expand}>
          <i
            className={`fa-solid fa-angle-down ${
              expandedState && 'rotate-180'
            }`}
          />
          &nbsp;Details&nbsp;
          <i
            className={`fa-solid fa-angle-down ${
              expandedState && 'rotate-180'
            }`}
          />
        </PrimaryButton>
      )}
    </Card>
  );
}

export default TripCard;
