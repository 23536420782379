// Import shared app components and types
import Heading from '../../../components/typography/Heading';
import Card from '../../../components/molecules/Card/Card';
import Conversation from '../../../types/Conversation';

function ChargesDueUponHire({ cost }: { cost: Conversation['cost'] }) {
  const { estimation } = cost;
  // totalDayCost: pricePerDay + dayDiem; dayOneTravelFee: total service fee
  const costsDueNow = (
    estimation.totalDayCost + estimation.dayOneTravelFee
  ).toFixed(2);
  const pilotFlyingRate = estimation.pricePerDay.toFixed(2);
  const dayOnePerDiem = estimation.dayDiem.toFixed(2);
  const dayOneServiceFee = estimation.dayOneTravelFee.toFixed(2);

  return (
    <Card responsive noPadding>
      <div className="py-2 background bg-primary rounded-t-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          Charges Due Upon Pilot Accepting Hire
        </Heading>
      </div>
      <div className="flex flex-col p-4 gap-2">
        <div className="flex">
          <span>Day 1 Flying Day-Rate</span>
          <span className="ml-auto font-semibold">${pilotFlyingRate}</span>
        </div>
        <div className="flex">
          <span>Day 1 Per-Diem</span>
          <span className="ml-auto font-semibold">${dayOnePerDiem}</span>
        </div>
        <div className="flex">
          <span>Day 1 Service Fee</span>
          <span className="ml-auto font-semibold">${dayOneServiceFee}</span>
        </div>
      </div>
      <div className="py-2 background bg-primary rounded-b-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          Due Now: ${costsDueNow}
        </Heading>
      </div>
    </Card>
  );
}

export default ChargesDueUponHire;
