/**
 * @param obj
 * @returns string
 * @description Convert an object with unknown keys to a string
 * @example
 * objectToString({ key: 'value', key2: 'value2' }) => 'key: value, key2: value2'
 */
export default function objectToString(obj: Record<string, any>): string {
  return Object.entries(obj)
    .map(([key, value]) => `${key}: ${value}`)
    .join(', ');
}
