import { isAfter } from 'date-fns';
import Trip from '../../types/Trip';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';
import mappedAircraft from '../Aircraft/util/mappedAircraft';

const endpoint = `/api/trips/as_consumer/`;

/*
  Get a specific trip as consumer role
*/
const getTripAsConsumer = async (id: string) => {
  const response = await fetch(`${endpoint}${id}`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
  });
  if (response.status !== 200) {
    throw new Error(`Unable to get trip id ${id} as consumer`);
  } else {
    const tripData = await response.json();

    if (tripData.trip.aircraft)
      tripData.trip.aircraft = mappedAircraft(tripData.trip.aircraft);
    const remappedData = remapKeysToCamelCase(tripData.trip) as Trip;
    const now = new Date();
    if (
      remappedData.status === 'Pilot Initial Payment' &&
      isAfter(now, new Date(remappedData.startDate))
    ) {
      remappedData.status = 'Underway';
    }
    return remappedData;
  }
};

export default getTripAsConsumer;
