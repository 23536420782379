import { PilotRole } from '../../types/PilotRole';
import updatePilot from './updatePilot';
import updateCert from './updateCert';
import { PlaneTypeStatistic } from '../../types/PlaneTypeStatistic';

type UpdatePilotPayload = {
  hoursPerPlaneType: {
    // id: number;
    timeInCraft: number;
    inCommandTime: number;
    lastNinetyDays: number;
    lastTwelveMonths: number;
    twelveCurrent: boolean;
    planeTypeId: number;
    planeTypeCert?: number | undefined;
  }[];
};

async function updateStats(pilot: PilotRole, stat: PlaneTypeStatistic) {
  const payload: UpdatePilotPayload = {
    hoursPerPlaneType: [],
  };

  const planeStatData = {
    timeInCraft: Number(stat.timeInCraft),
    inCommandTime: Number(stat.inCommandTime),
    lastNinetyDays: Number(stat.lastNinetyDays),
    lastTwelveMonths: Number(stat.lastTwelveMonths),
    twelveCurrent: Boolean(stat.twelveCurrent),
    planeTypeId: Number(stat.planeType),
    planeTypeCert: undefined as number | undefined,
    trainingFacility: Number(stat.planeTypeCert?.trainingFacility) || null,
  };

  if (
    stat.planeTypeCert &&
    !(
      stat.planeTypeCert.certDocument == null &&
      stat.planeTypeCert.certificationDate == null &&
      stat.planeTypeCert.diffDocument == null &&
      stat.planeTypeCert.trainingFacility == null
    )
  ) {
    console.log('stat cert', stat.planeTypeCert);
    // Update the existing cert
    const cert = await updateCert(stat.planeTypeCert);

    if (cert.statusCode !== 200) {
      return cert;
    }

    if (stat.planeTypeCert.id) {
      planeStatData.planeTypeCert = stat.planeTypeCert.id;
    } else {
      planeStatData.planeTypeCert = cert.id;
    }
  }

  if (planeStatData.planeTypeCert === 0) {
    planeStatData.planeTypeCert = undefined;
  }

  payload.hoursPerPlaneType.push(planeStatData);

  const result = await updatePilot(pilot.id, payload);
  return result;
}

export default updateStats;
