import React, { useState, useRef } from 'react';
import { useField } from 'formik';
import classNames from 'classnames';

import Input from '../Input';
import CancelIcon from '../../../assets/icons/cancel';
import { StateButton } from '../Button/Button';
import '../../molecules/SearchableDropdown/SearchableDropdown.css';
import ErrorMessage from '../../molecules/ErrorMessage';
import Loader from '../../molecules/Loader';

interface Option {
  label: string;
  value: string | number;
}
export interface FormikSearchableDropdownProps {
  label: string;
  name: string;
  options: readonly Option[];
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  maxResults?: number;
  isLoading?: boolean;
  optional?: boolean;
}

function FormikSearchableDropdown({
  label,
  name,
  options,
  placeholder,
  className,
  disabled = false,
  isLoading,
  maxResults = 15,
  optional = false,
}: FormikSearchableDropdownProps) {
  const [field, meta, helpers] = useField(name);
  const { value, touched, error } = meta;
  const { setValue } = helpers;
  const [filteredOptions, setFilteredOptions] = useState<readonly Option[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLUListElement>(null);
  const hasResults = filteredOptions.length > 0;
  const MAX_RESULTS = maxResults;
  const selectedOption = options.find((option) => option.value === value);

  const handleSearch = (search: string) => {
    if (options.length) {
      if (options.length > MAX_RESULTS) {
        setFilteredOptions(
          options
            .filter((option) =>
              option.label.toLowerCase().includes(search.toLowerCase())
            )
            .slice(0, MAX_RESULTS)
        );
      } else {
        setFilteredOptions(options);
      }
    }

    if (document.activeElement === inputRef.current) {
      setIsDropdownOpen(true);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const search = event.target.value;
    handleSearch(search);
    setSearchTerm(search);
    setValue(
      (prevValue: Option['value']) =>
        options.find((option) => option.label === search)?.value || prevValue
    );
  };

  // TODO: pass focus back to input on clickoption
  const handleOptionClick = (option: Option) => {
    setValue(option.value);
    setSearchTerm(option.label);
    setIsDropdownOpen(false);
  };

  const handleClearSearchTerm = () => {
    setValue('');
    setSearchTerm('');

    if (inputRef.current) inputRef.current.focus();
  };

  const handleFocus = () => {
    handleSearch(searchTerm || ' ');
    setIsDropdownOpen(true);
  };

  const handleCancelFocus = () => {
    setIsDropdownOpen(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    const element = event.relatedTarget as Element;

    if (!wrapperRef?.current?.contains(element)) {
      setIsDropdownOpen(false);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={classNames('searchable-dropdown relative', className)}>
      <label
        className="text-base text-left font-semibold block mb-1 max-w-fit"
        htmlFor={name}
      >
        {optional ? (
          <>
            {label} <span className="italic font-normal">optional</span>
          </>
        ) : (
          <>
            {label} <span className="text-error">&nbsp;*</span>
          </>
        )}
      </label>
      <div className="input-wrapper w-full max-w-xs">
        {isLoading ? (
          <Loader />
        ) : (
          <Input
            id={`${name}Search`}
            type="text"
            className="relative"
            value={selectedOption?.label || searchTerm || ''}
            placeholder={placeholder}
            onChange={handleSearchChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={inputRef}
            disabled={disabled}
            autoComplete="off"
            role="search"
          />
        )}
        {searchTerm && value && !disabled && (
          <div className="absolute right-2 top-1/2 -translate-y-1/2">
            <StateButton
              tabIndex={-1}
              className={classNames('btn btn-circle p-2')}
              size="small"
              status="error"
              onClick={handleClearSearchTerm}
              onBlur={handleBlur}
              onFocus={handleCancelFocus}
              disabled={disabled}
            >
              <CancelIcon />
            </StateButton>
          </div>
        )}
      </div>
      {isDropdownOpen && hasResults && (
        <ul
          id={`${name}Searches`}
          ref={wrapperRef}
          // className="dropdown-list z-[10000]"
          // TODO: fix stacking context from overlapping top level cards
          className="dropdown-list !pb-8"
          role="listbox"
        >
          {filteredOptions.map((option) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <li
              key={option.value}
              aria-selected={value === option.value}
              role="option"
              tabIndex={0}
              className={value === option.value ? 'selected' : ''}
              onClick={() => handleOptionClick(option)}
              onBlur={handleBlur}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
      {error && touched && (
        <div className="absolute w-[65ch]">
          <ErrorMessage>{error}</ErrorMessage>
        </div>
      )}
    </div>
  );
}

export default FormikSearchableDropdown;
