import React from 'react';
import { ErrorMessage as FormikError, FieldArray, FormikProps } from 'formik';
import { OwnersAndOperators } from '../../../../types/AirplaneAttributes/AirplaneAttributesOnHired';
import FormikInput from '../../../atoms/Form/FormikInput';
import FormikField from '../../../atoms/Form/FormikField';
import FormikDropdown from '../../../atoms/Form/FormikDropdown';
import { SecondaryButton, StateButton } from '../../../atoms/Button/Button';
import i18n from '../../../../strings/i18n';
import ErrorMessage from '../../../molecules/ErrorMessage';
import { Airplane } from '../../../../types/Airplane';
import Heading from '../../../typography/Heading';

export default function AircraftOwnersAndOperators() {
  return (
    <div className="py-8">
      <Heading level={3}>Registered Owners & Operators</Heading>
      <FieldArray name="identification.ownerOperator">
        {({ form, insert, remove, push }) => (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th className="pl-0">{i18n({ common: 'name' })}</th>
                  <th className="pl-0">{i18n({ common: 'type' })}</th>
                  <th className="pl-0">{i18n({ common: 'delete' })}?</th>
                </tr>
              </thead>
              <tbody>
                {form.values.identification?.ownerOperator?.map(
                  ({ id, name, type }: OwnersAndOperators, index: number) => (
                    <tr key={id || index}>
                      <td className="pl-0">
                        <FormikField
                          label="Name"
                          name={`identification.ownerOperator.${index}.name`}
                          placeholder={
                            type === 'operator'
                              ? i18n({ placeholder: 'operator' })
                              : i18n({ placeholder: 'registeredOwner' })
                          }
                          component={FormikInput}
                        />
                      </td>
                      <td>
                        <FormikDropdown
                          options={[
                            { label: 'Owner', value: 'owner' },
                            { label: 'Operator', value: 'operator' },
                            { label: 'Both', value: 'both' },
                          ]}
                          label="Type"
                          placeholder="Select a type"
                          name={`identification.ownerOperator.${index}.type`}
                          optional={false}
                        />
                      </td>
                      <td>
                        <StateButton
                          status="error"
                          onClick={() => {
                            form.setFieldValue(
                              'identification.deletedOwnerOperators',
                              [
                                ...form.values.identification
                                  .deletedOwnerOperators,
                                form.values.identification.ownerOperator[index]
                                  .id,
                              ]
                            );

                            remove(index);
                          }}
                        >
                          {i18n({ common: 'delete' })} {type !== 'both' && type}
                        </StateButton>
                      </td>
                    </tr>
                  )
                )}
                <tr>
                  <td colSpan={3} className="pl-0">
                    <SecondaryButton
                      onClick={() =>
                        push({
                          name: '',
                          type: '',
                        })
                      }
                    >
                      {i18n({ newAircraft: 'addOwnerOperator' })}
                    </SecondaryButton>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* @see https://formik.org/docs/api/fieldarray#fieldarray-validation-gotchas */}
            {typeof (form as FormikProps<Airplane>)?.errors?.identification
              ?.ownerOperator === 'string' ? (
              <FormikError
                component={ErrorMessage}
                name="identification.ownerOperator"
              />
            ) : null}
          </>
        )}
      </FieldArray>
    </div>
  );
}
