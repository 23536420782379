// Import packages
import classNames from 'classnames';
import { useFormikContext } from 'formik';
// Import shared app components and types
import Heading from '../../../../components/typography/Heading';
import Card from '../../../../components/molecules/Card/Card';
import { PER_DIEM_DEFAULT, Quote } from '../../../../types/Quote';

function QuoteReview() {
  const {
    values: {
      flyingDays,
      travelDayRate,
      travelDays,
      flyingDayRate,
      perDiemRate,
      airTravelCosts,
      groundTravelCosts,
      hotelCosts,
      otherCosts,
    },
  } = useFormikContext<Quote>();
  const FLYING_TOTAL = flyingDays * flyingDayRate;
  const TRAVEL_TOTAL = travelDays * travelDayRate;
  const PER_DIEM_DAYS = flyingDays + travelDays;
  const PER_DIEM_TOTAL = perDiemRate * PER_DIEM_DAYS;
  const EXPENSES_TOTAL =
    airTravelCosts + groundTravelCosts + hotelCosts + otherCosts;
  const GRAND_TOTAL =
    FLYING_TOTAL + TRAVEL_TOTAL + PER_DIEM_TOTAL + EXPENSES_TOTAL;

  return (
    <Card
      responsive
      noPadding
      className={classNames(
        '@container',
        'row-start-1 lg:col-start-2 h-fit max-w-full'
      )}
    >
      <div className="py-2 background bg-primary rounded-t-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          Quote Totals
        </Heading>
      </div>
      <div className="grid p-6 gap-4 @sm:grid-cols-2">
        <div>
          <Heading level={4}>Pilot Services</Heading>
          <div className="flex flex-col gap-1">
            <span>
              {flyingDays} days @ ${flyingDayRate}/day
            </span>
            <span className="underline">Total: ${FLYING_TOTAL}</span>
          </div>
        </div>
        <div>
          <Heading level={4}>Travel Days</Heading>
          <div className="flex flex-col gap-1">
            <span>
              {travelDays} days @ ${travelDayRate}/day
            </span>
            <span className="underline">Total: ${TRAVEL_TOTAL}</span>
          </div>
        </div>
        <div>
          <Heading level={4}>Per Diem</Heading>
          <span>
            {PER_DIEM_DAYS} days @ ${PER_DIEM_DEFAULT}/day
          </span>
          <br />
          <span className="underline">Total: ${PER_DIEM_TOTAL}</span>
        </div>
        <div>
          <Heading level={4}>Estimated Expenses</Heading>
          Air Travel: <span>${airTravelCosts}</span>
          <br />
          Ground Travel: <span>${groundTravelCosts}</span>
          <br />
          Hotel: <span>${hotelCosts}</span>
          <br />
          Other: <span>${otherCosts}</span>
          <br />
          <span className="underline">Total: ${EXPENSES_TOTAL}</span>
        </div>
      </div>
      <div className="py-2 background bg-primary rounded-b-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          Grand Total: ${GRAND_TOTAL}
        </Heading>
      </div>
    </Card>
  );
}

export default QuoteReview;
