import * as Yup from 'yup';
import { Quote } from '../../../../types/Quote';

export default Yup.object<Quote>().shape({
  flyingDays: Yup.number()
    .integer('Flying Days must be a whole number')
    .required()
    .min(0, 'Flying Days Must be a positive number'),
  travelDays: Yup.number()
    .integer('Travel Days must be a whole number')
    .required()
    .min(0, 'Travel Days must be a positive number'),
  flyingDayRate: Yup.number().required().min(0, 'Must be a positive number'),
  travelDayRate: Yup.number().required().min(0, 'Must be a positive number'),
  perDiemRate: Yup.number().required(),
  airTravelCosts: Yup.number().required().min(0, 'Must be a positive number'),
  airTravelNotes: Yup.string().optional(),
  groundTravelCosts: Yup.number()
    .required()
    .min(0, 'Must be a positive number'),
  groundTravelNotes: Yup.string().optional(),
  hotelCosts: Yup.number().required().min(0, 'Must be a positive number'),
  hotelNotes: Yup.string().optional(),
  otherCosts: Yup.number().required().min(0, 'Must be a positive number'),
  otherCostsNotes: Yup.string().optional(),
});
