import CertificateLimitOption from '../../types/CertificateLimitOption';

const endpoint = `/api/cert_limits/`;

interface LimitsOptionsResponse {
  limitOptions: CertificateLimitOption[];
  statusCode: number;
}

const getLimitOptions = async (): Promise<LimitsOptionsResponse | null> => {
  try {
    const limitOptionsResponse = await fetch(endpoint, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
    });

    const limitOptions = await limitOptionsResponse.json();

    return {
      limitOptions,
      statusCode: limitOptionsResponse.status,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default getLimitOptions;
