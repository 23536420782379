import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Trip from '../../../../types/Trip';
import Alert from '../../../../components/atoms/Alert/Alert';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../components/atoms/Button/Button';
import PilotCard from '../../../../components/molecules/PilotCard/PilotCard';
import ControlBar from '../../../../components/molecules/ControlBar/ControlBar';
import Chat from '../../../../components/organisms/Chat/Chat';
import TripCard from '../../../../components/organisms/TripCard/TripCard';
import Heading from '../../../../components/typography/Heading';
import { ROUTES } from '../../../routerConfig';
import GrandTotalEstimateCard from '../../../../components/organisms/GrandTotalEstimateCard/GrandTotalEstimateCard';
import Bid from '../../../../types/Bid';
import PrimaryTripLayout from '../../Layouts/PrimaryTripLayout';
import { useModalContext } from '../../../../hooks/ModalProvider/ModalProvider';
import QuoteModal from '../../PilotView/QuoteModal';

function UnderwayView({
  trip,
  openDetailsModal,
}: {
  trip: Trip;
  openDetailsModal: (trip: Trip) => unknown;
}) {
  const navigate = useNavigate();
  const { openModal } = useModalContext();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);
  const chatAreaRef = useRef<HTMLDivElement>(null);
  const tripAndQuoteAreaRef = useRef<HTMLDivElement>(null);
  const hiredBid = trip.conversations
    .map((c) => c.bid)
    .find((b) => b?.id === trip.hiredBid);
  if (!hiredBid) throw new Error(`No hired bid on UnderwayView`);

  const hiredBidConversation = trip.conversations.find(
    (c) => c.bid?.id === trip.hiredBid
  );
  if (!hiredBidConversation)
    throw new Error(`No hired bid conversation on UnderwayView`);

  const { bid, cost } = hiredBidConversation;

  const openQuoteModal = () => {
    openModal(
      <QuoteModal
        conversation={hiredBidConversation}
        isQuoted
        isPilot={false}
      />
    );
  };

  return (
    <>
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" />
          Trips
        </PrimaryButton>
        <SecondaryButton size="small" onClick={openQuoteModal}>
          View Quote Details
        </SecondaryButton>
        <SecondaryButton size="small" onClick={() => openDetailsModal(trip)}>
          View Trip Details
        </SecondaryButton>
      </ControlBar>
      <div className="flex flex-col gap-4">
        <Heading level={2}>Trip underway!</Heading>
        <Alert status="success" message="Have a great flight!" />
        {/* Three column grid layout that collapses to 2 col, then 1 col */}
        <PrimaryTripLayout>
          {/** Pilot profile area */}
          {/* Small display version */}
          <div className="h-full">
            <PilotCard
              pilot={hiredBidConversation.user}
              className="h-full"
              planeType={trip.planeType}
              selected
              expanded="always"
              airplane={trip.aircraft}
              hired
              tripId={trip.id}
            />
          </div>
          {/** Chat area */}
          {hiredBidConversation && (
            <div className="flex flex-col gap-4 h-full" ref={chatAreaRef}>
              {/** Quote card */}
              <GrandTotalEstimateCard
                isQuoted
                cost={cost}
                expenses={(bid as Bid).quotedExpenses}
                className="flex-grow"
              />
              <Chat
                tripId={trip.id}
                title={
                  hiredBidConversation
                    ? `Chat with ${hiredBidConversation.user.firstName}`
                    : 'Loading'
                }
                conversation={hiredBidConversation}
              />
            </div>
          )}
          {/* Trip and Quote area  */}
          <div className="hidden lg:block h-full" ref={tripAndQuoteAreaRef}>
            {/** Trip card */}
            <TripCard
              trip={trip}
              showStatus={false}
              expanded="always"
              interactive={false}
              className="h-full"
            />
          </div>
        </PrimaryTripLayout>
      </div>
    </>
  );
}

export default UnderwayView;
