import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import getPlaneTypes from '../API/Plane/getPlaneTypes';
import getPlaneMakes from '../API/Plane/getPlaneMakes';
import PlaneType from '../types/PlaneType';
import { useUser } from './UserProvider/UserProvider';
import getAllPlaneTypes from '../API/Plane/getAllPlaneTypes';
import { AirplaneMake } from '../types/AirplaneMake';

interface IPlaneTypesContext {
  error: string;
  loading: boolean;
  planeTypes: PlaneType[];
  planeMakes: AirplaneMake[];
  allPlaneTypes: PlaneType[];
  getPlaneTypeById: (id: number) => PlaneType | undefined;
  getPlaneTypeByCraftName: (craftName: string) => PlaneType | undefined;
  getCraftNameById: (id: number) => string | undefined;
  getCraftTypeById: (planeType: number) => string | undefined;
  getTypeRatings: () => PlaneType[];
  getModels: (typeRating?: PlaneType['id']) => PlaneType[];
  isTypeRating: (planeType: PlaneType) => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const PlaneTypesContext = createContext<IPlaneTypesContext>(undefined!);

export function PlaneTypesProvider({ children }: { children: ReactNode }) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [planeMakes, setPlaneMakes] = useState<AirplaneMake[]>([]);
  const [planeTypes, setPlaneTypes] = useState<PlaneType[]>([]);
  const [allPlaneTypes, setAllPlaneTypes] = useState<PlaneType[]>([]);
  const { user } = useUser();

  const attemptGetPlaneTypes = async () => {
    try {
      setLoading(true);
      const response = await getPlaneTypes();
      if (response?.statusCode === 200) {
        setPlaneTypes(response.planes);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      setError(e as string);
    }
  };

  const attemptGetAllPlaneTypes = async () => {
    try {
      const response = await getAllPlaneTypes();
      if (response?.statusCode === 200) {
        setAllPlaneTypes(response.planes);
      }
    } catch (e) {
      setError(e as string);
    }
  };

  const attemptGetPlaneMakes = async () => {
    try {
      const response = await getPlaneMakes();
      if (response?.statusCode === 200) {
        setPlaneMakes(response.data);
      }
    } catch (e) {
      setError(e as string);
    }
  };

  const getPlaneTypeById = useCallback(
    (id: number) => allPlaneTypes.find((pt) => pt.id === id),
    [allPlaneTypes]
  );

  const getPlaneTypeByCraftName = useCallback(
    (craftName: string) => planeTypes.find((pt) => pt.craftName === craftName),
    [planeTypes]
  );

  const getCraftNameById = useCallback(
    (id: number) => planeTypes.find((pt) => pt.id === id)?.craftName,
    [planeTypes]
  );

  const getCraftTypeById = useCallback(
    (planeType: number) => {
      return planeTypes[planeType]
        ? planeTypes[planeType].craftType
        : undefined;
    },
    [planeTypes]
  );

  // Type ratings are stored in the planeTypes DB as entries that
  // have 1 or more models related
  const getTypeRatings = useCallback(
    () => allPlaneTypes.filter((pt) => pt.relatedPlaneType.length > 0),
    [allPlaneTypes]
  );

  // Models do not have a relation of their own
  const getModels = useCallback(
    (typeRatingId?: PlaneType['id']) => {
      const models = allPlaneTypes.filter(
        (pt) => pt.relatedPlaneType.length === 0
      );
      if (typeRatingId !== undefined) {
        // Find the type rating object first
        const typeRating = allPlaneTypes.find((pt) => pt.id === typeRatingId);
        if (!typeRating)
          throw new Error(
            `No matching type rating for plane type id: ${typeRatingId}`
          );
        return models.filter((m) => typeRating.relatedPlaneType.includes(m.id));
      }
      return models;
    },
    [allPlaneTypes]
  );

  const isTypeRating = useCallback((planeType: PlaneType) => {
    return planeType.relatedPlaneType.length > 0;
  }, []);

  useEffect(() => {
    if (!planeTypes.length && user) attemptGetPlaneTypes();
    if (!planeMakes.length && user) attemptGetPlaneMakes();
    if (!allPlaneTypes.length && user) attemptGetAllPlaneTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const value = useMemo(
    () => ({
      error,
      loading,
      planeMakes,
      planeTypes,
      allPlaneTypes,
      getPlaneTypeById,
      getPlaneTypeByCraftName,
      getCraftNameById,
      getCraftTypeById,
      getTypeRatings,
      getModels,
      isTypeRating,
    }),
    [
      error,
      loading,
      planeMakes,
      planeTypes,
      allPlaneTypes,
      getPlaneTypeById,
      getPlaneTypeByCraftName,
      getCraftNameById,
      getCraftTypeById,
      getTypeRatings,
      getModels,
      isTypeRating,
    ]
  );

  return (
    <PlaneTypesContext.Provider value={value}>
      {children}
    </PlaneTypesContext.Provider>
  );
}

export const usePlaneTypes = () =>
  useContext<IPlaneTypesContext>(PlaneTypesContext);
