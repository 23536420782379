import classNames from 'classnames';

import Link from '../../atoms/Link';
import Paragraph from '../../typography/Paragraph';

import { ROUTES } from '../../../routes/routerConfig';
import LinkedIn from '../../atoms/Icons/Socials/LinkedIn';
import YouTube from '../../atoms/Icons/Socials/YouTube';
import XTwitter from '../../atoms/Icons/Socials/XTwitter';
import Instagram from '../../atoms/Icons/Socials/Instagram';
import Facebook from '../../atoms/Icons/Socials/Facebook';

function Footer({ className }: { className?: string }) {
  return (
    <footer
      className={classNames(
        'flex flex-col md:flex-row mt-4 items-center justify-center gap-8 p-8 border-t-2 text-center',
        className
      )}
    >
      <Paragraph>
        The Flying Company © {new Date().getFullYear()}. All rights Reserved.
      </Paragraph>
      <Link className="ul" to={ROUTES.PRIVACY_POLICY}>
        Privacy Policy
      </Link>
      <Link className="ul" to={ROUTES.TERMS_OF_SERVICE}>
        Terms of Service
      </Link>
      <div className="flex gap-4">
        <Link
          className="ul w-8"
          target="_blank"
          href="https://www.facebook.com/flyingcompanyusa"
        >
          <Facebook />
        </Link>
        <Link
          className="ul w-8"
          target="_blank"
          href="https://www.instagram.com/flyingcompanyusa"
        >
          <Instagram />
        </Link>
        <Link
          className="ul w-8"
          target="_blank"
          href="https://x.com/flyingcompany"
        >
          <XTwitter />
        </Link>
        <Link
          className="ul w-8"
          target="_blank"
          href="https://www.youtube.com/@flyingcompany"
        >
          <YouTube />
        </Link>
        <Link
          className="ul w-8"
          target="_blank"
          href="https://www.linkedin.com/company/flyingcompany"
        >
          <LinkedIn />
        </Link>
      </div>
    </footer>
  );
}

export default Footer;
