import fetchAPI from '../util';

export default async function resetPassword(
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string
) {
  return fetchAPI('/auth/password/change/', {
    body: JSON.stringify({
      new_password1: newPassword,
      new_password2: confirmNewPassword,
      old_password: oldPassword,
    }),
    headers: {
      'content-type': 'application/json',
    },
    method: 'POST',
  });
}
