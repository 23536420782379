import React from 'react';

function PaperAirplane() {
  return (
    <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="button/desktop/icon-+-send-message"
          transform="translate(-16.000000, -10.000000)"
          fill="#09B2CF"
        >
          <g id="Send-Message-Button">
            <g id="icon/send" transform="translate(16.000000, 10.000000)">
              <g id="send-icon">
                <path
                  d="M5.45759286,14.4654616 L0.140941728,11.6402687 C0.0579201224,11.5961522 0.0237100646,11.4876537 0.0645314254,11.3979305 C0.081039125,11.3616475 0.108310513,11.3323809 0.141998544,11.3147957 L21.7177546,0.0522599492 C21.8010607,0.00877422469 21.9012127,0.0465063012 21.9414504,0.136536901 C21.9590358,0.175883634 21.9628594,0.220776487 21.9522157,0.262931391 L16.8925879,20.3019031 C16.8682338,20.3983592 16.7761382,20.4552157 16.6868866,20.4288957 C16.6766117,20.4258656 16.6666277,20.4217835 16.65707,20.4167047 L10.7198164,17.2617325 L8.84120433,20.8795996 C8.7959292,20.9667913 8.69382304,20.9978088 8.61314384,20.948879 C8.57193854,20.9238891 8.54241517,20.8812545 8.53185018,20.8314833 L7.42845828,15.6334483 C7.41993758,15.5933077 7.42448386,15.5532227 7.4390799,15.5183957 L7.42549764,15.5111783 L12.7531782,9.90495665 C12.8098953,9.84527433 12.8202112,9.75083077 12.7779118,9.67851657 C12.7284774,9.59400439 12.6250094,9.56880309 12.5468096,9.62222788 L5.45759286,14.4654616 Z"
                  id="Path-4"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PaperAirplane;
