import React from 'react';

function FlightIcon({
  rotation = 0,
  size = '1.25em',
  className = '',
  style = {},
}) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 48 21"
      version="1.1"
      style={{
        transform: `rotate(${rotation}deg)`,
        ...style,
      }}
      className={className}
    >
      <g
        id="all---4/30"
        stroke="none"
        strokeWidth="1"
        fill="currentColor"
        fillRule="evenodd"
      >
        <g transform="translate(-111.000000, -474.000000)" fill="currentColor">
          <g transform="translate(79.000000, 447.000000)">
            <path
              d="M74.7108302,34.7384462 L63.2863605,37.7796598 L50.1382184,31.5806467 C49.4587277,31.0865624 46.929183,29.7730922 45.9717541,30.0339181 L42.6021325,30.9361241 C42.3334293,31.0067042 42.1203107,31.2276598 42.0647183,31.5007884 C42.0029428,31.776981 42.1110439,32.0593171 42.3334214,32.2403789 L52.881032,40.5506672 L41.5735823,43.558099 L34.5748394,39.4735036 C34.4018792,39.3722293 34.2042103,39.3476787 34.0065414,39.393716 L32.5518567,39.7773186 C32.3140374,39.8386991 32.1256352,40.019753 32.0453342,40.2499161 C31.9588502,40.4770074 31.9990007,40.7378568 32.1410771,40.9342618 L39.5969095,51.30366 C39.7389859,51.500065 39.9675386,51.6166825 40.205358,51.6166825 C40.2763922,51.6166825 40.3412593,51.607475 40.4030268,51.5921318 L76.6570778,41.9376478 C78.6584355,41.4098055 79.8444487,39.3659994 79.313273,37.3773595 C78.7753844,35.3890967 76.7184342,34.2044603 74.7108302,34.7384462 Z"
              transform="translate(55.720031, 40.808341) rotate(15.000000) translate(-55.720031, -40.808341) "
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FlightIcon;
