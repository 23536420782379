import React from 'react';
import { AllAirplaneAttributes } from '../../../types/AirplaneAttributes/AllAirplaneAttributes';
import Section from '../../wrappers/Section';
import Sidebar, { type SidebarData } from '../Sidebar/Sidebar';
import SettingsForm from '../SettingsForm';
import {
  AircraftConfiguration,
  AircraftIdentifiers,
  AircraftInsurance,
  AircraftLocation,
  AircraftMaintenance,
  AircraftOperatingProcedures,
} from '../AddAirplaneForm/steps';
import {
  validAircraftConfigurationSchema,
  validAircraftIdentificationSchema,
  validAircraftInsuranceSchema,
  validAircraftLocationSchema,
  validAircraftMaintenanceSchema,
  validAircraftOperatingProceduresSchema,
} from '../AddAirplaneForm/validation';
import PlaneType from '../../../types/PlaneType';
import {
  updateAircraftConfiguration,
  updateAircraftIdentifiers,
  updateAircraftInsurance,
  updateAircraftLocation,
  updateAircraftMaintenance,
  updateAircraftProcedures,
} from '../../../API/Aircraft/updateAircraft';
import { Airplane } from '../../../types/Airplane';
import initialAirplane from '../../../const/initialAirplane';
import { useAircraft } from '../../../hooks/useAircraft';
import { useToast } from '../../../hooks/ToastProvider';
import Alert from '../../atoms/Alert/Alert';

interface FullAirplaneDetailsProps {
  planeTypes: PlaneType[];
  airplane: AllAirplaneAttributes;
}

export default function FullAirplaneDetails({
  planeTypes,
  airplane,
}: FullAirplaneDetailsProps) {
  const { addToast } = useToast();
  const { refreshAirplanes } = useAircraft();
  const {
    id,
    make,
    model,
    identification: existingIdentification,
    insurance: existingInsurance,
    location: existingLocation,
    maintenance: existingMaintenance,
    operatingProcedures: existingOperatingProcedures,
    configuration: existingConfiguration,
  }: Partial<Airplane> = airplane;
  const currentPlaneType =
    planeTypes.find((plane) => plane.id === model) || ({} as PlaneType);
  const formGroups: SidebarData = {
    'Aircraft And Ownership': {
      content: (
        <SettingsForm
          title="Ownership & Identification"
          initialValues={{
            identification: {
              ...initialAirplane.identification,
              ...(existingIdentification ?? { aircraft: id }),
            },
          }}
          validationSchema={validAircraftIdentificationSchema}
          onSubmit={async (values, helpers) => {
            const updatedIdentifiers = await updateAircraftIdentifiers({
              ...values.identification,
            });

            helpers.setValues({
              identification: updatedIdentifiers,
            });

            await refreshAirplanes();

            addToast(
              <span>Aircraft and Ownership updated successfully.</span>,
              'success'
            );
          }}
        >
          <AircraftIdentifiers />
        </SettingsForm>
      ),
    },
    Configuration: {
      content: (
        <SettingsForm
          title="Configuration"
          initialValues={{
            configuration: {
              ...initialAirplane.configuration,
              ...(existingConfiguration ?? { aircraft: id }),
            },
          }}
          validationSchema={validAircraftConfigurationSchema}
          onSubmit={async (values, helpers) => {
            const updatedConfiguration = await updateAircraftConfiguration({
              ...values.configuration,
            });

            helpers.setValues({
              configuration: updatedConfiguration,
            });

            await refreshAirplanes();

            addToast(
              <span>Aircraft configuration updated successfully.</span>,
              'success'
            );
          }}
        >
          <AircraftConfiguration currentPlaneType={currentPlaneType} />
        </SettingsForm>
      ),
    },
    Maintenance: {
      content: (
        <SettingsForm
          title="Maintenance"
          initialValues={{
            maintenance: {
              ...initialAirplane.maintenance,
              ...(existingMaintenance ?? { aircraft: id }),
            },
          }}
          validationSchema={validAircraftMaintenanceSchema}
          onSubmit={async (values, helpers) => {
            const updatedMaintenance = await updateAircraftMaintenance({
              ...values.maintenance,
            });

            helpers.setValues({
              maintenance: updatedMaintenance,
            });

            await refreshAirplanes();

            addToast(<span>Maintenance updated successfully.</span>, 'success');
          }}
        >
          <AircraftMaintenance />
        </SettingsForm>
      ),
    },
    Location: {
      content: (
        <SettingsForm
          title="Location"
          initialValues={{
            location: {
              ...initialAirplane.location,
              ...(existingLocation ?? { aircraft: id }),
            },
          }}
          validationSchema={validAircraftLocationSchema}
          onSubmit={async (values, helpers) => {
            const updatedLocation = await updateAircraftLocation({
              ...values.location,
            });

            helpers.setValues({
              location: updatedLocation,
            });

            await refreshAirplanes();

            addToast(
              <span>Aircraft Location updated successfully.</span>,
              'success'
            );
          }}
        >
          <AircraftLocation />
        </SettingsForm>
      ),
    },
    'Operating Procedures': {
      content: (
        <SettingsForm
          title="Operating Procedures"
          initialValues={{
            operatingProcedures: {
              ...initialAirplane.operatingProcedures,
              ...(existingOperatingProcedures ?? { aircraft: id }),
            },
          }}
          validationSchema={validAircraftOperatingProceduresSchema}
          onSubmit={async (values, helpers) => {
            const updatedProcedures = await updateAircraftProcedures({
              ...values.operatingProcedures,
            });

            helpers.setValues({
              operatingProcedures: updatedProcedures,
            });

            await refreshAirplanes();

            addToast(
              <span>Operating Procedures updated successfully.</span>,
              'success'
            );
          }}
        >
          <AircraftOperatingProcedures />
        </SettingsForm>
      ),
    },
    Insurance: {
      content: (
        <SettingsForm
          title="Insurance"
          initialValues={{
            insurance: {
              ...initialAirplane.insurance,
              ...(existingInsurance ?? { aircraft: id }),
            },
          }}
          validationSchema={validAircraftInsuranceSchema}
          onSubmit={async (values, helpers) => {
            const updatedInsurance = await updateAircraftInsurance({
              ...values.insurance,
            });

            helpers.setValues({
              insurance: updatedInsurance,
            });

            await refreshAirplanes();
          }}
        >
          <AircraftInsurance currentPlaneType={currentPlaneType} />
        </SettingsForm>
      ),
    },
  };

  return (
    <div className="lg:flex flex-col gap-4">
      {!airplane.isValid && (
        <Alert
          status="error"
          message="This is not a valid aircraft. Please fill out all of the required
          fields, marked with an asterik (*)."
        />
      )}
      <Section className="flex flex-col lg:flex-row mb-8">
        <Sidebar menuItems={formGroups} menuTitle="Section" />
      </Section>
    </div>
  );
}
