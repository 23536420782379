import isEmptyObject from '../../utilities/isEmptyObject';
import PilotCertificate from '../../types/PilotCertificate';
import { remapKeysToSnakeCase } from '../../utilities/remapKeys';

// type UploadLicenseProps = {
//   frontSideImage: File | null;
//   backSideImage: File | null;
//   pilotId: string;
// };

const endpoint = {
  license: `/api/licenses/`,
  cert: `/api/pilot_certs/`,
  limits: `/api/pilot_cert_limits/`,
};

async function createPilotCertificate(
  pilotId: number,
  newCertificate: Partial<PilotCertificate>
) {
  if (isEmptyObject(newCertificate)) return null;

  const response = await fetch(endpoint.cert, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
    body: JSON.stringify({
      ...(remapKeysToSnakeCase(newCertificate) as Partial<PilotCertificate>),
      pilot: pilotId,
    }),
    credentials: 'include',
  });
  const newCert: PilotCertificate = await response.json();
  return newCert;
}

export default createPilotCertificate;
