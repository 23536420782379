import { AnyObject } from 'yup';
import { remapKeysToCamelCase } from '../../../utilities/remapKeys';
import { Airplane } from '../../../types/Airplane';

/**
 * @param aircraft An aircraft shaped with snake_case keys and property names from the backend
 * @returns An aircraft shaped with camelCase keys and property names for the frontend
 */
export default function mappedAircraft(aircraft: Airplane): Airplane {
  const mappedAirplane = remapKeysToCamelCase(aircraft) as AnyObject;
  const {
    id,
    make,
    model,
    isValid,
    aircraftIdentifier,
    aircraftConfiguration,
    aircraftInsurance,
    aircraftProcedure,
    aircraftLocation,
    maintainer,
  } = mappedAirplane;

  return {
    id,
    make,
    model,
    isValid,
    identification: aircraftIdentifier,
    configuration: aircraftConfiguration,
    location: aircraftLocation,
    maintenance: maintainer,
    insurance: aircraftInsurance,
    operatingProcedures: aircraftProcedure,
  };
}
