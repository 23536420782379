import React from 'react';
import FormikInput from '../../../atoms/Form/FormikInput';
import FormikField from '../../../atoms/Form/FormikField';
import FormikTextArea from '../../../atoms/Form/FormikTextArea';
import Tooltip from '../../../atoms/Tooltip/Tooltip';
import FormikCheckbox from '../../../atoms/Form/FormikCheckbox';
import AircraftFuelCards from './AircraftFuelCards';
import i18n from '../../../../strings/i18n';

function AircraftOperatingProcedures() {
  return (
    <>
      <FormikField
        name="operatingProcedures.mandatoryDocumentStorageLocation"
        label="Mandatory Document Storage Location"
        placeholder={i18n({ placeholder: 'documentStorageLocation' })}
        component={FormikInput}
        optional
      />
      <FormikField
        name="operatingProcedures.spareKeyLocation"
        label="Spare Key Location"
        placeholder={i18n({ placeholder: 'spareKeyLocation' })}
        component={FormikInput}
        optional
      />
      <FormikField
        name="operatingProcedures.fixedBaseOperatorPreference"
        label="Fixed Base Operator Preference"
        placeholder={i18n({ placeholder: 'fixedBaseOperator' })}
        component={FormikInput}
        optional
      />
      <FormikField
        name="operatingProcedures.flightLogProcedures"
        label="Flight Log Procedures"
        placeholder={i18n({ placeholder: 'flightLogProcedures' })}
        component={FormikInput}
        optional
      />
      <FormikField
        name="operatingProcedures.dressAndAppearance"
        label="Dress And Appearance"
        placeholder={i18n({ placeholder: 'dressAndAppearance' })}
        component={FormikInput}
      />
      <FormikField
        name="operatingProcedures.catering"
        label="Catering"
        placeholder={i18n({ placeholder: 'catering' })}
        component={FormikInput}
        optional
      />
      <FormikField
        name="operatingProcedures.isCaaMember"
        label={
          <>
            Are you a
            <Tooltip trigger="CAA">Corporate Aircraft Association</Tooltip>
            member?
          </>
        }
        inline
        component={FormikCheckbox}
        optional
      />
      <AircraftFuelCards />
      <FormikField
        name="operatingProcedures.additionalNotes"
        label="Additional Notes"
        placeholder={i18n({ placeholder: 'proceduresNotes' })}
        component={FormikTextArea}
        optional
      />
    </>
  );
}

export default AircraftOperatingProcedures;
