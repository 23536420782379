import { PilotRole } from '../../types/PilotRole';
import fetchAPI from '../util';

let requestMethod = 'POST';
let requestURL = `/api/certificates/`;

async function updateCert(cert: any) {
  const formData = new FormData();
  formData.append('training_facility', cert.trainingFacility);
  formData.append(
    'certification_date',
    new Date(cert.certificationDate).toISOString()
  );

  if (Object.prototype.hasOwnProperty.call(cert, 'id')) {
    requestMethod = 'PUT';
    requestURL = `/api/certificates/${cert.id}/`;
    formData.append('id', cert.id);
  }

  if (cert.certDocument instanceof File) {
    formData.append('cert_document', cert.certDocument);
  }

  if (cert.diffDocument instanceof File) {
    formData.append('diff_document', cert.diffDocument);
  }

  const result = await fetchAPI(requestURL, {
    method: requestMethod,
    body: formData,
  });

  return result;
}

export default updateCert;
