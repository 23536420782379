import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../components/atoms/Button/Button';
import ConsumerCard from '../../../components/molecules/ConsumerCard/ConsumerCard';
import ControlBar from '../../../components/molecules/ControlBar/ControlBar';
import Chat from '../../../components/organisms/Chat/Chat';
import TripCard from '../../../components/organisms/TripCard/TripCard';
import Heading from '../../../components/typography/Heading';
import { ROUTES } from '../../routerConfig';
import Bid from '../../../types/Bid';
import Trip from '../../../types/Trip';
import { createQuoteFromBid } from '../../../types/Quote';
import GrandTotalEstimateCard from '../../../components/organisms/GrandTotalEstimateCard/GrandTotalEstimateCard';
import Loader from '../../../components/molecules/Loader';
import Alert from '../../../components/atoms/Alert/Alert';
import Card from '../../../components/molecules/Card/Card';
import PrimaryTripLayout from '../Layouts/PrimaryTripLayout';
import { useModalContext } from '../../../hooks/ModalProvider/ModalProvider';
import QuoteModal from './QuoteModal';

interface PilotInitialPaymentViewProps {
  trip: Trip;
  openDetailsModal: () => unknown;
}

function PilotInitialPaymentView({
  trip,
  openDetailsModal,
}: PilotInitialPaymentViewProps) {
  const navigate = useNavigate();
  const { openModal } = useModalContext();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);

  const { bid } = trip.conversations[0];

  const hiredBid = trip.conversations
    .map((c) => c.bid)
    .find((b) => b?.id === trip.hiredBid);
  if (!hiredBid) throw new Error(`No hired bid on UnderwayView`);

  const hiredBidConversation = trip.conversations.find(
    (c) => c.bid?.id === trip.hiredBid
  );
  if (!hiredBidConversation)
    throw new Error(`No hired bid conversation on UnderwayView`);

  const { cost } = hiredBidConversation;

  const openQuoteModal = () => {
    openModal(
      <QuoteModal conversation={hiredBidConversation} isQuoted isPilot />
    );
  };

  return (
    <>
      {/* Mobile secondary top menu */}
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" /> Trips
        </PrimaryButton>
        <SecondaryButton
          className="lg:hidden ml-auto"
          size="small"
          onClick={openDetailsModal}
        >
          <span>View Trip Details</span>
        </SecondaryButton>
        <SecondaryButton
          className="lg:hidden "
          size="small"
          onClick={openQuoteModal}
        >
          Review Quote
        </SecondaryButton>
      </ControlBar>
      {trip ? (
        <div className="flex flex-col gap-4">
          <Heading level={2}>Initial payment received for {trip.name}.</Heading>
          <Alert message="Awaiting departure date. The remaining balance will be paid once your expenses are submitted and approved after the trip." />
          <PrimaryTripLayout>
            {/** Operator profile area */}
            <div className="h-full">
              <ConsumerCard
                user={trip.conversations[0].user}
                className="h-full"
                userTitle="Client"
              />
            </div>
            {/** Chat area */}
            <div className="h-full  flex flex-col gap-4">
              {/** Quote card... Bad trip duration */}
              {bid && cost && (
                <GrandTotalEstimateCard
                  isPilot
                  isQuoted
                  cost={cost}
                  expenses={(bid as Bid).quotedExpenses}
                  className="flex-grow"
                />
              )}
              <Chat tripId={trip.id} conversation={trip.conversations[0]} />
            </div>
            <div className="hidden lg:block h-full">
              {/** Trip card */}
              <TripCard
                trip={trip}
                showStatus={false}
                expanded="always"
                interactive={false}
                className="h-full"
              />
            </div>
          </PrimaryTripLayout>
        </div>
      ) : (
        <Card responsive>
          <Loader level={3} caption="Loading Trip..." />
        </Card>
      )}
    </>
  );
}

export default PilotInitialPaymentView;
