import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  StateButton,
} from '../../../components/atoms/Button/Button';
import QuoteCard from '../../../components/organisms/QuoteCard/QuoteCard';
import TripCard from '../../../components/organisms/TripCard/TripCard';
import Heading from '../../../components/typography/Heading';
import { useUser } from '../../../hooks/UserProvider/UserProvider';
import useForm from '../../../hooks/useForm';
import {
  PER_DIEM_DEFAULT,
  Quote,
  createQuoteFromBid,
} from '../../../types/Quote';
import Trip from '../../../types/Trip';
import { ROUTES } from '../../routerConfig';
import Bid from '../../../types/Bid';
import ConsumerCard from '../../../components/molecules/ConsumerCard/ConsumerCard';
import Chat from '../../../components/organisms/Chat/Chat';
import Rule from '../../../components/atoms/Rule';
import { useModalContext } from '../../../hooks/ModalProvider/ModalProvider';
import { useToast } from '../../../hooks/ToastProvider';
import TripDetailsModal from './TripDetailsModal';
import QuoteModal from './QuoteModal';
import ControlBar from '../../../components/molecules/ControlBar/ControlBar';
import confirmTripAsPilot from '../../../API/Trips/confirmTripAsPilot';
import makePayment from '../../../API/Payments/makePayment';
import ConfirmHireModal from './ConfirmHireModal';
import declineTripAsPilot from '../../../API/Trips/declineTripAsPilot';
import PrimaryTripLayout from '../Layouts/PrimaryTripLayout';

interface AvailableTripProps {
  trip: Trip;
  bid: Bid;
  openDetailsModal: () => unknown;
  openQuoteModal: () => unknown;
}

function QuoteAccepted({
  trip,
  bid,
  openDetailsModal,
  openQuoteModal,
}: AvailableTripProps) {
  const confirmWorked = useRef(false);
  const { user } = useUser();
  const { openModal, closeModal } = useModalContext();
  const { addToast } = useToast();
  const navigate = useNavigate();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);

  const initialForm = createQuoteFromBid(bid, user!, trip);

  const { inputs, handleChange, resetForm } = useForm(initialForm);

  const handleAcceptHire = useCallback(async () => {
    closeModal();

    try {
      await confirmTripAsPilot(trip.id);
      confirmWorked.current = true;
    } catch (error) {
      console.error('Error confirming trip:', error);
      addToast(
        <span>
          Failed to Accept Hire. Please try again or contact support.
        </span>,
        'error',
        10000
      );
    }

    if (confirmWorked.current) {
      try {
        await makePayment(trip.id);
        window.location.reload();
      } catch (error) {
        console.error('Error confirming trip:', error);
        addToast(
          <span>Payment Failed. Please try again or contact support.</span>,
          'error',
          10000
        );
      }
    }
  }, [closeModal, trip.id, addToast]);

  const handleDeclineHire = useCallback(async () => {
    try {
      await declineTripAsPilot(trip.id);
      closeModal();
    } catch (error) {
      console.error('Error cancelling trip:', error);
    }
  }, [closeModal, trip.id]);

  const openConfirmHireModal = useCallback(() => {
    openModal(
      <ConfirmHireModal
        trip={trip}
        bidId={bid.id}
        isBid
        onConfirmHire={handleAcceptHire}
        onDeclineHire={handleDeclineHire}
        onReviewTripDetails={closeModal}
      />
    );
  }, [
    closeModal,
    handleAcceptHire,
    handleDeclineHire,
    openModal,
    trip,
    bid.id,
  ]);

  useEffect(() => {
    if (!confirmWorked.current) {
      openConfirmHireModal();
    }
  }, [openConfirmHireModal]);

  return (
    <div className="relative">
      {/* Mobile secondary top menu */}
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" /> Trips
        </PrimaryButton>
        <StateButton
          className="text-white gap-0"
          status="success"
          size="small"
          onClick={openConfirmHireModal}
        >
          Confirm<span className="hidden md:inline">&nbsp;Hire</span>
        </StateButton>
        <StateButton
          className="gap-0"
          status="error"
          size="small"
          onClick={handleDeclineHire}
        >
          Decline<span className="hidden md:inline">&nbsp;Hire</span>
        </StateButton>
        <SecondaryButton
          className="lg:hidden md:ml-auto gap-0"
          size="small"
          onClick={openDetailsModal}
        >
          <span>
            <span className="hidden md:inline">View&nbsp;</span>Trip Details
          </span>
        </SecondaryButton>
        <SecondaryButton
          className="lg:hidden "
          size="small"
          onClick={openQuoteModal}
        >
          Review Quote
        </SecondaryButton>
      </ControlBar>
      <div className="flex flex-col gap-4">
        <Heading level={2}>
          Your quote for &ldquo;{trip.name}&rdquo; was accepted!
        </Heading>
        <PrimaryTripLayout>
          {/** Operator profile area */}
          <div className="relative h-full">
            <ConsumerCard
              user={trip.conversations[0].user}
              className="h-full"
              userTitle="Client"
            />
          </div>
          {/** Chat area */}
          <div className="h-full flex flex-col gap-4">
            {/** Quote card */}
            <QuoteCard quote={inputs} className="flex-grow" />
            <Chat tripId={trip.id} conversation={trip.conversations[0]} />
          </div>
          <div className="hidden lg:block h-full">
            {/** Trip card */}
            <TripCard
              trip={trip}
              showStatus={false}
              expanded="always"
              interactive={false}
              className="h-full"
            />
          </div>
        </PrimaryTripLayout>
      </div>
    </div>
  );
}

export default QuoteAccepted;
