import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../routerConfig';
import AddAirplaneForm from '../../components/organisms/AddAirplaneForm/AddAirplaneForm';
import { usePlaneTypes } from '../../hooks/PlaneTypesProvider';
import { PrimaryButton } from '../../components/atoms/Button/Button';
import Loader from '../../components/molecules/Loader';
import Card from '../../components/molecules/Card/Card';

export default function AddAircraftPage() {
  const { planeMakes, loading } = usePlaneTypes();
  const navigate = useNavigate();

  return (
    <>
      <PrimaryButton
        onClick={() => navigate(ROUTES.MY_AIRCRAFT)}
        size="small"
        className="mb-8"
      >
        Go Back
      </PrimaryButton>
      {loading ? (
        <Card responsive>
          <Loader level={3} caption="Loading airplane options..." />
        </Card>
      ) : (
        <AddAirplaneForm planeMakes={planeMakes} />
      )}
    </>
  );
}
