import { format } from 'date-fns';
import Message from '../../../types/Message';

function SystemMessage({ message }: { message: Message }) {
  return (
    <div className="chat chat-start" key={message.id}>
      <div className="chat-header">
        System{' '}
        <time className="text-xs opacity-50">
          {format(new Date(message.sentAt), 'M/d/yyyy h:mm aa')}
        </time>
      </div>
      <div className="chat-bubble bg-info text-white">{message.content}</div>
    </div>
  );
}

export default SystemMessage;
