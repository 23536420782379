import classNames from 'classnames';
import React from 'react';
import Heading from '../../typography/Heading';

interface StepperProps {
  steps: string[];
  activeStep?: string;
}

export default function Stepper({ steps, activeStep }: StepperProps) {
  const STEP_COUNT = steps.length;

  const getVisibleSteps = () => {
    const active = steps.findIndex((step) => step === activeStep);
    // show the first 3 steps, or show all steps if there are less than 3, or show the 3 steps around the activeStep
    // eslint-disable-next-line no-nested-ternary
    return steps.length <= 3
      ? steps
      : activeStep
      ? [...steps.slice(active - 1, active + 2)]
      : steps.slice(0, 3);
  };

  const visibleSteps = getVisibleSteps();
  return (
    <ol className="flex items-center justify-center text-sm font-medium text-center sm:text-base">
      {visibleSteps.map((step, index) => {
        return (
          // <li className="flex items-center last:justify-end md:w-full text-primary">
          <li className="flex items-center last:justify-end text-neutral-content sm:after:content-[''] after:w-12 after:h-1 after:border-b after:border-info after:border-1 after:hidden sm:after:inline-block after:mx-5 xl:after:mx-5 after:last:hidden after:last:w-0">
            {/* <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200"> */}
            <span
              className={classNames(
                'flex flex-col items-center',
                activeStep === step && 'font-bold text-secondary'
              )}
            >
              {activeStep === step && (
                <Heading level={3} className="me-2 shrink-0">
                  {steps.findIndex((allStep) => allStep === step) + 1} of{' '}
                  {STEP_COUNT}
                </Heading>
              )}
              <span className={classNames('min-w-[12ch]')}>{step}</span>
            </span>
          </li>
        );
      })}
    </ol>
  );
}
