import classNames from 'classnames';
import Trip from '../../../types/Trip';
import { TRIP_STATUSES } from '../../../types/TripStatus';

interface TripStatusProps {
  status: Trip['status'];
}

function TripStatusIndicator({ status }: TripStatusProps) {
  let shownStatus = status;
  if (status === 'Pilot Initial Payment') shownStatus = 'Pilot Hired';
  if (status === 'Pilot Confirmed') shownStatus = 'Pilot Hired';
  if (status === 'Pilot Hired') shownStatus = 'Quotes Received';
  if (status === 'Pilot Final Payment') shownStatus = 'Expenses Approved';

  return (
    <>
      {/* Large display view */}
      <div className="h-16 hidden @xl:grid join min-w-0 grid-cols-7">
        {Object.entries(TRIP_STATUSES).map(
          ([val, { showInUI }], i, arr) =>
            showInUI && (
              <span
                className={`flex flex-col justify-center border-2 border-primary join-item text-center text-sm ${classNames(
                  shownStatus === val && 'bg-primary text-white',
                  i !== arr.length - 1 && i !== 0 && 'border-l-0'
                )}`}
              >
                {val.split(' ').map((s) => (
                  <>
                    {s}
                    <br />
                  </>
                ))}
              </span>
            )
        )}
      </div>
      {/* Small display view */}
      <div className="@xl:hidden btn btn-s btn-outline w-full bg-primary text-white">
        {status}
      </div>
    </>
  );
}

export default TripStatusIndicator;
