function openPdfInNewTab(pdfUrl: string) {
  // Open the URL in a new tab
  window.open(pdfUrl, '_blank');

  // Revoke the object URL after the PDF is opened
  // You may want to delay this to ensure the new tab has time to load the PDF
  setTimeout(() => {
    URL.revokeObjectURL(pdfUrl);
  }, 5000); // 5 second delay to ensure the PDF loads before revoking
}

export default openPdfInNewTab;
