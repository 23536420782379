import React from 'react';

function HamburgerMenu() {
  return (
    <svg width="22px" height="22px" viewBox="0 0 22 22" version="1.1">
      <g
        id="kayak-pivot"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="0-Landing_Page_Hire_a_Pilot_Mobile_05"
          transform="translate(-328.000000, -25.000000)"
          stroke="#9BA4A6"
          strokeWidth="3"
        >
          <g
            id="ic_option"
            transform="translate(339.000000, 36.000000) scale(-1, 1) translate(-339.000000, -36.000000) translate(321.000000, 18.000000)"
          >
            <g id="options">
              <g transform="translate(9.000000, 7.500000)">
                <path d="M0,1.5 L18,1.5" id="Line" />
                <path d="M0,10.5 L18,10.5" id="Line-Copy" />
                <path d="M0,19.5 L18,19.5" id="Line-Copy-2" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HamburgerMenu;
