// Alert.tsx
import React, { ReactNode } from 'react';

function StatusIcon({ status }: { status: string | undefined }) {
  switch (status) {
    case 'info':
      return <i className="fas fa-info-circle" />;
    case 'error':
      return <i className="fas fa-exclamation-circle" />;
    case 'success':
      return <i className="fas fa-check-circle" />;
    case 'warning':
      return <i className="fas fa-exclamation-triangle" />;
    default:
      return <i className="fas fa-info-circle" />;
  }
}

interface AlertProps {
  message: ReactNode;
  status?: 'info' | 'error' | 'success' | 'warning';
  className?: string;
}

function Alert({ message, status, className = '' }: AlertProps) {
  const alertClasses = {
    info: 'alert-info',
    error: 'alert-error',
    success: 'alert-success',
    warning: 'alert-warning',
  };

  return (
    <div
      role="alert"
      className={`alert inline-block ${
        status && alertClasses[status]
      } ${className}`}
    >
      <StatusIcon status={status} />
      <span className="ml-2">{message}</span>
    </div>
  );
}

export default Alert;
