import { type Airplane } from '../../types/Airplane';
import {
  FuelCard,
  OwnersAndOperators,
} from '../../types/AirplaneAttributes/AirplaneAttributesOnHired';
import { AirplaneBaseAttributes } from '../../types/AirplaneAttributes/AirplaneBaseAttributes';
import isEmptyObject from '../../utilities/isEmptyObject';
import {
  remapKeysToCamelCase,
  remapKeysToSnakeCase,
} from '../../utilities/remapKeys';
import { endpoints } from '../endpoints';

type FilteredAirplane = Omit<
  Airplane,
  'id' | 'consumer' | 'make' | 'model' | 'planeType' | 'isValid'
>;
type AirplaneResponse = FilteredAirplane[keyof FilteredAirplane];

/**
 * Internal helper function to call the API to update an aircraft
 * @param airplane partial airplane object
 * @param endpoint API endpoint to call
 * @param method HTTP method to use
 * @returns updated airplane object
 * @throws Error if airplane object is invalid, API call fails, or airplane.id is null
 */
const updateBase = async (
  // airplane: Partial<Airplane>,
  airplaneBaseAttributes: Partial<AirplaneBaseAttributes>,
  endpoint: (typeof endpoints)[keyof typeof endpoints] = endpoints.AIRCRAFT
): Promise<Partial<AirplaneBaseAttributes>> => {
  try {
    const {
      id: aircraftId,
      isValid,
      ...restOfAirplane
    } = airplaneBaseAttributes;
    if (isEmptyObject(airplaneBaseAttributes) || aircraftId === null) {
      throw new Error(
        `Invalid airplaneBaseAttributes object: ${airplaneBaseAttributes}`
      );
    }

    const airplaneResponse = await fetch(
      `${endpoints.AIRCRAFT}${aircraftId}/`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('key')}`,
        },
        method: 'PATCH',
        credentials: 'include',
        body: JSON.stringify(
          remapKeysToSnakeCase({ id: aircraftId, ...restOfAirplane })
        ),
      }
    );

    if (airplaneResponse.status !== 201 && airplaneResponse.status !== 200) {
      console.info(
        'If you are trying to PATCH, make sure the `id` is defined.'
      );
      throw new Error('Unable to update aircraft data.');
    } else {
      const aircraft = await airplaneResponse.json();

      return remapKeysToCamelCase(aircraft) as AirplaneResponse;
    }
  } catch (e) {
    console.error(e);
    return airplaneBaseAttributes;
  }
};

/**
 * Internal helper function to call the API to update an aircraft
 * @param airplane partial airplane object
 * @param endpoint API endpoint to call
 * @param method HTTP method to use
 * @returns updated airplane object
 * @throws Error if airplane object is invalid, API call fails, or airplane.id is null
 */
const update = async (
  // airplane: Partial<Airplane>,
  airplaneCategory: FilteredAirplane[keyof FilteredAirplane],
  endpoint: (typeof endpoints)[keyof typeof endpoints] = endpoints.AIRCRAFT
): Promise<AirplaneResponse> => {
  try {
    let method = 'PATCH';
    const { aircraft: aircraftId } = airplaneCategory;

    if (isEmptyObject(airplaneCategory) || aircraftId === null) {
      console.error(airplaneCategory);
      throw new Error(`Invalid airplaneCategory object:`);
    }

    if (airplaneCategory.id === null) {
      method = 'POST';
    }

    const airplaneResponse = await fetch(
      // PATCH requests needs the id
      method === 'POST' ? endpoint : `${endpoint}${airplaneCategory.id}/`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${localStorage.getItem('key')}`,
        },
        method,
        credentials: 'include',
        body: JSON.stringify(remapKeysToSnakeCase(airplaneCategory)),
      }
    );

    if (airplaneResponse.status !== 201 && airplaneResponse.status !== 200) {
      throw new Error(
        'Unable to get aircraft data. If you are trying to PATCH, make sure the {aircraftCategory}.id is defined.'
      );
    } else {
      const aircraft = await airplaneResponse.json();

      return remapKeysToCamelCase(aircraft) as AirplaneResponse;
    }
  } catch (e) {
    console.error(e);
    return airplaneCategory;
  }
};

/**
 * Update **only** the base attributes of an aircraft
 * @param airplane- airplane base attributes
 */
export default async function updateAircraft(
  airplane: Partial<AirplaneBaseAttributes>
): Promise<Partial<AirplaneBaseAttributes>> {
  try {
    return await updateBase(airplane);
  } catch (e) {
    console.error(e);
    return airplane;
  }
}

/**
 * Update **only** the identification attributes of an aircraft
 * @param airplane- airplane identification attributes
 */
export async function updateAircraftIdentifiers(
  aircraftIdentifier: Airplane['identification']
): Promise<Airplane['identification']> {
  try {
    const ownerOperators: OwnersAndOperators[] = [];
    let aircraftIdentifierId = aircraftIdentifier.id;

    if (aircraftIdentifierId === null) {
      const aircraftIdentifierWithId = (await update(
        aircraftIdentifier,
        endpoints.AIRCRAFT_IDENTIFIER
      )) as Airplane['identification'];
      aircraftIdentifierId = aircraftIdentifierWithId.id;
    }

    // DELETE ownerOperators w/ id in deletedOwnerOperators[]
    if (aircraftIdentifier.deletedOwnerOperators.length > 0) {
      try {
        const ownerOperatorIdsPromises: Promise<void>[] =
          aircraftIdentifier.deletedOwnerOperators.map(
            async (ownerOperatorId) => {
              const dataResponse = await fetch(
                `${endpoints.OWNERS_OPERATORS}${ownerOperatorId}/`,
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Token ${localStorage.getItem('key')}`,
                  },
                  method: 'DELETE',
                  credentials: 'include',
                }
              );

              if (dataResponse.status !== 201 && dataResponse.status !== 200) {
                throw new Error('Unable to delete fuel card');
              } else {
                return dataResponse.json();
              }
            }
          );

        await Promise.all(ownerOperatorIdsPromises);
      } catch (e) {
        throw new Error('Unable to delete fuel card');
      }
    }

    // PATCH ownerOperators that have an id
    const ownerOperatorsWithIds = aircraftIdentifier.ownerOperator.filter(
      (o) => o.id !== undefined
    );

    // POST ownerOperators that dont' have an id
    const ownerOperatorsWithoutIds = aircraftIdentifier.ownerOperator.filter(
      (o) => o.id === undefined
    );

    if (ownerOperatorsWithIds.length > 0) {
      try {
        const ownerOperatorsPromises: Promise<OwnersAndOperators>[] =
          ownerOperatorsWithIds.map(async (o) => {
            const dataResponse = await fetch(
              `${endpoints.OWNERS_OPERATORS}${o.id}/`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('key')}`,
                },
                method: 'PATCH',
                credentials: 'include',
                body: JSON.stringify(
                  remapKeysToSnakeCase({
                    ...o,
                    aircraftIdentifier: aircraftIdentifierId,
                  })
                ),
              }
            );

            if (dataResponse.status !== 201 && dataResponse.status !== 200) {
              throw new Error('Unable to get aircraft data');
            } else {
              return dataResponse.json();
            }
          });

        ownerOperators.push(...(await Promise.all(ownerOperatorsPromises)));
      } catch (e) {
        throw new Error('Unable to create owner operator');
      }
    }

    if (ownerOperatorsWithoutIds.length > 0) {
      try {
        const ownerOperatorsPromises: Promise<OwnersAndOperators>[] =
          ownerOperatorsWithoutIds.map(async (o) => {
            const dataResponse = await fetch(`${endpoints.OWNERS_OPERATORS}`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('key')}`,
              },
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(
                remapKeysToSnakeCase({
                  ...o,
                  aircraftIdentifier: aircraftIdentifierId,
                })
              ),
            });

            if (dataResponse.status !== 201 && dataResponse.status !== 200) {
              throw new Error('Unable to get aircraft data');
            } else {
              return dataResponse.json();
            }
          });

        ownerOperators.push(...(await Promise.all(ownerOperatorsPromises)));
      } catch (e) {
        throw new Error('Unable to create owner operator');
      }
    }

    console.log(ownerOperators);

    const newData = {
      ...aircraftIdentifier,
      // eslint-disable-next-line no-return-assign
      ownerOperator: ownerOperators,
      id: aircraftIdentifierId,
      // ownerOperator: ownerOperators,
    };

    return (await update(
      newData,
      `${endpoints.AIRCRAFT_IDENTIFIER}`
    )) as Airplane['identification'];
  } catch (e) {
    console.error(e);
    return aircraftIdentifier;
  }
}

/**
 * Update **only** the configuration attributes of an aircraft
 * @param airplane- airplane configuration attributes
 */
export async function updateAircraftConfiguration(
  airplaneConfiguration: Airplane['configuration']
): Promise<Airplane['configuration']> {
  try {
    return (await update(
      airplaneConfiguration,
      endpoints.AIRCRAFT_CONFIGURATION
    )) as Airplane['configuration'];
  } catch (e) {
    console.error(e);
    return airplaneConfiguration;
  }
}

/**
 * Update **only** the location attributes of an aircraft
 * @param airplane- airplane location attributes
 */
export async function updateAircraftLocation(
  airplane: Airplane['location']
): Promise<Airplane['location']> {
  try {
    return (await update(
      airplane,
      endpoints.AIRCRAFT_LOCATION
    )) as Airplane['location'];
  } catch (e) {
    console.error(e);
    return airplane;
  }
}

/**
 * Update **only** the insurance attributes of an aircraft
 * @param airplane- airplane insurance attributes
 */
export async function updateAircraftInsurance(
  airplane: Airplane['insurance']
): Promise<Airplane['insurance']> {
  try {
    return (await update(
      airplane,
      endpoints.AIRCRAFT_INSURANCE
    )) as Airplane['insurance'];
  } catch (e) {
    console.error(e);
    return airplane;
  }
}

/**
 * Update **only** the maintenance attributes of an aircraft
 * @param airplane- airplane maintenance attributes
 */
export async function updateAircraftMaintenance(
  airplane: Airplane['maintenance']
): Promise<Airplane['maintenance']> {
  try {
    return (await update(
      airplane,
      endpoints.MAINTAINER
    )) as Airplane['maintenance'];
  } catch (e) {
    console.error(e);
    return airplane;
  }
}

/**
 * Update **only** the operating procedures of an aircraft
 * @param airplane- airplane operating procedures
 */
export async function updateAircraftProcedures(
  aircraftProcedures: Airplane['operatingProcedures']
): Promise<Airplane['operatingProcedures']> {
  try {
    // return (await update(
    //   airplane,
    //   endpoints.AIRCRAFT_PROCEDURE
    // )) as Airplane['operatingProcedures'];
    const fuelCards: FuelCard[] = [];
    let aircraftProceduresId = aircraftProcedures.id;

    if (aircraftProceduresId === null) {
      const aircraftProceduresWithId = (await update(
        aircraftProcedures,
        endpoints.AIRCRAFT_PROCEDURE
      )) as Airplane['operatingProcedures'];
      aircraftProceduresId = aircraftProceduresWithId.id;
    }

    // PATCH fuelCards that have an id
    const fuelCardsWithIds = aircraftProcedures.fuelCards?.filter(
      (o) => o.id !== undefined
    );

    // POST fuelCards that dont' have an id
    const fuelCardsWithoutIds = aircraftProcedures.fuelCards?.filter(
      (o) => o.id === undefined
    );

    // DELETE fuelCards w/ id in deletedFuelCards[]
    if (aircraftProcedures.deletedFuelCards.length > 0) {
      try {
        const fuelCardIdPromises: Promise<void>[] =
          aircraftProcedures.deletedFuelCards.map(async (cardId) => {
            const dataResponse = await fetch(
              `${endpoints.FUEL_CARDS}${cardId}/`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('key')}`,
                },
                method: 'DELETE',
                credentials: 'include',
              }
            );

            if (dataResponse.status !== 201 && dataResponse.status !== 200) {
              throw new Error('Unable to delete fuel card');
            } else {
              return dataResponse.json();
            }
          });

        await Promise.all(fuelCardIdPromises);
      } catch (e) {
        throw new Error('Unable to delete fuel card');
      }
    }

    if (fuelCardsWithIds && fuelCardsWithIds.length > 0) {
      try {
        const fuelCardsPromises: Promise<FuelCard>[] = fuelCardsWithIds.map(
          async (o) => {
            const dataResponse = await fetch(
              `${endpoints.FUEL_CARDS}${o.id}/`,
              {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Token ${localStorage.getItem('key')}`,
                },
                method: 'PATCH',
                credentials: 'include',
                body: JSON.stringify(
                  remapKeysToSnakeCase({
                    ...o,
                    aircraftProcedure: aircraftProceduresId,
                  })
                ),
              }
            );

            if (dataResponse.status !== 201 && dataResponse.status !== 200) {
              throw new Error('Unable to get aircraft data');
            } else {
              return dataResponse.json();
            }
          }
        );

        fuelCards.push(...(await Promise.all(fuelCardsPromises)));
      } catch (e) {
        throw new Error('Unable to create owner operator');
      }
    }

    if (fuelCardsWithoutIds && fuelCardsWithoutIds.length > 0) {
      try {
        const fuelCardsPromises: Promise<FuelCard>[] = fuelCardsWithoutIds.map(
          async (o) => {
            const dataResponse = await fetch(`${endpoints.FUEL_CARDS}`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Token ${localStorage.getItem('key')}`,
              },
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify(
                remapKeysToSnakeCase({
                  ...o,
                  aircraftProcedure: aircraftProceduresId,
                })
              ),
            });

            if (dataResponse.status !== 201 && dataResponse.status !== 200) {
              throw new Error('Unable to get aircraft data');
            } else {
              return dataResponse.json();
            }
          }
        );

        fuelCards.push(...(await Promise.all(fuelCardsPromises)));
      } catch (e) {
        throw new Error('Unable to create owner operator');
      }
    }

    const newData = {
      ...aircraftProcedures,
      // eslint-disable-next-line no-return-assign
      fuelCards,
      id: aircraftProceduresId,
      // ownerOperator: ownerOperators,
    };

    return (await update(
      newData,
      `${endpoints.AIRCRAFT_PROCEDURE}`
    )) as Airplane['operatingProcedures'];
  } catch (e) {
    console.error(e);
    return aircraftProcedures;
  }
}
