/* eslint-disable react/destructuring-assignment */
import { ReactNode, useEffect, useState } from 'react';
import { FileSelection } from '../../../types/FileSelection';
import { PrimaryButton } from '../Button/Button';
import { Receipt } from '../../../types/Receipt';
import HEICToJPEG from '../../../utilities/HEICToJPEG';
import compressImage from '../../../utilities/compressImage';
import Loader from '../../molecules/Loader';

type FileSelectProps = {
  id?: string;
  value: FileSelection;
  onChange?: (f: File | null) => unknown;
  disabled?: boolean;
  label?: ReactNode;
};

const imageStyle = 'max-w-full md:max-h-48 rounded-lg block mt-4';

function PDFRender(file: File) {
  const url = URL.createObjectURL(file);

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <embed src={url} aria-label="Document Preview" className={imageStyle} />
    </a>
  );
}

function ImageRender(image: string | Receipt) {
  if (typeof image !== 'string' && typeof image?.document === 'string') {
    return (
      <a href={image.document} target="_blank" rel="noopener noreferrer">
        <img
          src={image.document}
          alt="Document Preview"
          className={imageStyle}
        />
      </a>
    );
  }
  if (typeof image === 'string') {
    return (
      <a href={image} target="_blank" rel="noopener noreferrer">
        <img src={image} alt="Document Preview" className={imageStyle} />
      </a>
    );
  }

  // Optionally handle the case where image is neither a string nor a valid Receipt
  throw new Error('Invalid image type');
}

function FilePreview(file: FileSelection) {
  if (!file) return null;

  // File is either a File or a string (if already uploaded to AWS)
  if (file instanceof File) {
    // Render as a PDF or image depending on the file type, extract the URL
    // eslint-disable-next-line react/destructuring-assignment
    if (file.type.startsWith('application/pdf')) return PDFRender(file);
    return ImageRender(URL.createObjectURL(file));
  }
  // Render as image with URL
  return ImageRender(file);
}

function FileSelect({
  id,
  value = null,
  onChange,
  disabled,
  label = 'Change',
}: FileSelectProps) {
  const [filename, setFilename] = useState(
    value instanceof File ? value.name : value || ''
  );

  const [change, setChange] = useState(false);
  const [converting, setConverting] = useState(false);

  // Initialize the file name display value
  useEffect(() => {
    if (value instanceof File) {
      setFilename(value.name);
    } else if (typeof value === 'string') {
      setFilename(value);
    } else setFilename('');
  }, [value]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let file = event?.target.files?.[0];

    if (file) {
      setConverting(true);
      if (file?.name.toLowerCase().includes('.heic')) {
        try {
          file = await HEICToJPEG(file);
        } catch (error) {
          console.log('error', error);
          setConverting(false);
          if (onChange) onChange(null);
          return;
        }
      }

      if (!file?.name.toLowerCase().includes('.pdf')) {
        try {
          // Compress image
          const compressedBlob = await compressImage(file);
          file = new File([compressedBlob], file.name, { type: 'image/jpeg' });
          setFilename(file.name);
        } catch (error) {
          console.log('error', error);
          setConverting(false);
          if (onChange) onChange(null);
          return;
        }
      }

      setConverting(false);

      if (onChange) onChange(file);
    } else if (onChange) {
      onChange(null);
    }
  };

  return (
    <>
      {change && (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <input
          id={id}
          type="file"
          className="file-input file-input-bordered file-input-primary w-full max-w-xs"
          onChange={handleChange}
          disabled={disabled || converting}
          name={typeof filename === 'string' ? filename : ''}
          hidden={converting}
        />
      )}
      {!change && (
        <PrimaryButton disabled={disabled} onClick={() => setChange(true)}>
          {converting ? 'Converting image...' : label}
        </PrimaryButton>
      )}
      {change && converting && <Loader caption="Converting image..." />}
      {value && !converting && FilePreview(value)}
    </>
  );
}

export default FileSelect;
