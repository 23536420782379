/* eslint-disable no-param-reassign */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { remapKeysToCamelCase } from '../../utilities/remapKeys';
import TripsAsConsumerResponse from './TripsAsConsumerResponse';
import mappedAircraft from '../Aircraft/util/mappedAircraft';

const endpoint = `/api/trips/as_consumer/`;

/*
  Get trips as consumer role
*/
const getTripsAsConsumer = async () => {
  const response = await fetch(endpoint, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
  });
  if (response.status !== 200) {
    throw new Error('Unable to get trips as consumer');
  } else {
    const tripData = await response.json();
    tripData.trips.past.forEach((trip) => {
      if (trip.aircraft) {
        trip.aircraft = mappedAircraft(trip.aircraft);
      }
    });
    tripData.trips.upcoming.forEach((trip) => {
      if (trip.aircraft) {
        trip.aircraft = mappedAircraft(trip.aircraft);
      }
    });
    return remapKeysToCamelCase(tripData) as TripsAsConsumerResponse;
  }
};

export default getTripsAsConsumer;
