import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Input from '../../components/atoms/Input';
import Card from '../../components/molecules/Card/Card';
import Heading from '../../components/typography/Heading';
import { usePlaneTypes } from '../../hooks/PlaneTypesProvider';
import { useToast } from '../../hooks/ToastProvider';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import useForm from '../../hooks/useForm';
import { ROUTES } from '../routerConfig';
import updateRates from '../../API/Pilot/updateRates';
import SettingsCard from '../../components/molecules/Card/SettingsCard';
import PlaneType from '../../types/PlaneType';

function PlaneRateTable() {
  const { user, refreshUser } = useUser();
  const { getCraftNameById, getTypeRatings, planeTypes } = usePlaneTypes();
  const { addToast } = useToast();

  const [disabled, setDisabled] = useState(true);
  const [typeRatings, setTypeRatings] = useState<PlaneType[]>(getTypeRatings());

  // If planeTypes haven't finished loading, this will refresh the typeRatings local list
  useEffect(() => {
    setTypeRatings(getTypeRatings());
  }, [planeTypes, getTypeRatings]);

  type PlaneTypeRateMap = Record<number, number>;

  const { inputs, updateFields, resetForm } = useForm(
    user?.pilotRole?.statistics?.planeTypeStatistics.reduce(
      (planeTypeRateMap, planeTypeStat) => {
        if (!typeRatings.find((tr) => tr.id === planeTypeStat.planeType)) {
          // eslint-disable-next-line no-param-reassign
          planeTypeRateMap[planeTypeStat.planeType] = planeTypeStat.pricePerDay;
        }
        return planeTypeRateMap;
      },
      {} as PlaneTypeRateMap
    ) || {}
  );

  const handleCancel = () => {
    resetForm();
    setDisabled(true);
  };

  const handleRateChange = (planeTypeId: number, newRate: number) => {
    updateFields({ [planeTypeId]: newRate });
  };

  const handleSave = async () => {
    if (!user?.pilotRole) return;
    await updateRates(user.pilotRole, inputs);
    addToast(<span>Saved rates.</span>, 'success');
    setDisabled(true);
    await refreshUser();
  };

  return user && user.pilotRole.statistics.planeTypeStatistics.length ? (
    <SettingsCard
      disabled={disabled}
      onEdit={() => setDisabled(false)}
      onCancel={handleCancel}
      onSave={handleSave}
      responsive
    >
      <Heading level={3}>Default Flying Day Rate</Heading>
      <table className="mt-8 max-w-2xl table table-lg border">
        <thead>
          <tr>
            <th className="p-2">Aircraft Model</th>
            <th className="p-2">Pilot Services Day Rate (USD)</th>
          </tr>
        </thead>
        <tbody>
          {user.pilotRole?.statistics.planeTypeStatistics
            .filter((pts) => !typeRatings.find((tr) => tr.id === pts.planeType))
            .map((stat) => (
              <tr key={stat.id}>
                <td className="p-2">{getCraftNameById(stat.planeType)}</td>
                <td className="p-2">
                  <Input
                    disabled={disabled}
                    type="number"
                    value={inputs[stat.planeType]}
                    onChange={(e) =>
                      handleRateChange(
                        stat.planeType,
                        e.target.value as unknown as number
                      )
                    }
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </SettingsCard>
  ) : (
    <Card responsive>
      <Heading level={4}>
        Add a type rating or aircraft qualification on the{' '}
        <Link to={ROUTES.CERTIFICATES_AND_RATINGS} className="underline">
          Certificates and Ratings page
        </Link>{' '}
        to set your rates here.
      </Heading>
    </Card>
  );
}

export default PlaneRateTable;
