import React, { useState } from 'react';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import Debug from '../../atoms/Form/Debug';
import Stepper from '../../molecules/Stepper/Stepper';
import Card from '../../molecules/Card/Card';
import Heading from '../../typography/Heading';
import { PrimaryButton, SecondaryButton } from '../../atoms/Button/Button';
import i18n from '../../../strings/i18n';
import Paragraph from '../../typography/Paragraph';

/**
 * MultiStepForm is a single Formik instance whose children are each page of the
multi-step form. The form is submitted on each forward transition (can only
progress with valid input), whereas a backwards step is allowed with
incomplete data. A snapshot of form state is used as initialValues after each
transition. Each page has an optional submit handler, and the top-level
submit is called when the final page is submitted.

```
<MultiStepForm
        title="My Form"
        initialValues={initialValues}
        onSubmit={(values) => console.log('Full form submit', values)}
      >
        <FormStep
          title="Aircraft Type"
          onSubmit={() => console.log('Step1 onSubmit')}
          validationSchema={validSchema}
        >
          <AircraftType />
        </FormStep>
        ...
      </MultiStepForm>
```
`
 */
function MultiStepForm<T extends FormikValues>({
  children,
  initialValues,
  onSubmit,
  debug = false,
  title,
}: {
  children: React.ReactNode[];
  initialValues: T;
  onSubmit: (values: T, helpers: FormikHelpers<T>) => void;
  debug?: boolean;
  title?: string;
}) {
  const [stepNumber, setStepNumber] = useState(0);
  const [snapshot, setSnapshot] = useState(initialValues);
  const steps = React.Children.toArray(children) as React.ReactElement[];
  // get an array of props.title from steps
  const stepNames = steps.map((step) => step?.props?.title);

  // step should always be a jsx element
  const step = steps[stepNumber] as React.ReactElement;
  const totalSteps = steps.length;
  const isLastStep = stepNumber === totalSteps - 1;
  const stepName = step.props?.title;
  console.log(step.props);

  const next = (values: T) => {
    setSnapshot(values);
    setStepNumber(Math.min(stepNumber + 1, totalSteps - 1));
  };

  const previous = (values: T) => {
    setSnapshot(values);
    setStepNumber(Math.max(stepNumber - 1, 0));
  };

  // https://formik.org/docs/guides/form-submission#submission
  const handleSubmit = (values: T, helpers: FormikHelpers<T>) => {
    if (step.props.onSubmit) {
      // partial update
      console.log('partial submit', values);
      step.props.onSubmit(values, helpers);
      helpers.setSubmitting(false);
    }
    if (isLastStep) {
      // final submit
      console.log('full submit', values);
      onSubmit(values, helpers);
      helpers.setSubmitting(false);
      return;
    }
    helpers.setTouched({});
    next(values);
  };

  return (
    <>
      <div className="flex flex-col gap-4 relative mb-2">
        <div className="w-full bottom-2">
          <Stepper steps={stepNames} activeStep={stepName} />
        </div>
        {title && (
          <Heading level={1} className="shrink-0">
            {title}
          </Heading>
        )}
        <Paragraph>{i18n({ common: 'allRequired' })}</Paragraph>
      </div>
      <Card className="lg:rounded-l-none lg:border-l-0" responsive>
        <Formik
          initialValues={snapshot}
          onSubmit={handleSubmit}
          validationSchema={step.props.validationSchema}
        >
          {(formik) => (
            <Form className="flex flex-col">
              {step}
              <div className="flex w-full gap-3 my-3 justify-end">
                {stepNumber > 0 && (
                  <PrimaryButton onClick={() => previous(formik.values)}>
                    Back
                  </PrimaryButton>
                )}
                <div>
                  <SecondaryButton disabled={formik.isSubmitting} type="submit">
                    {isLastStep ? 'Submit' : 'Next'}
                  </SecondaryButton>
                </div>
              </div>
              {debug && <Debug />}
            </Form>
          )}
        </Formik>
      </Card>
    </>
  );
}

export function FormStep<T extends FormikValues>({
  children,
}: {
  children: JSX.Element;
  onSubmit?: (values: T, helpers: FormikHelpers<T>) => void;
  title: string;
  validationSchema: Yup.ObjectSchema<T>;
}): JSX.Element {
  return children;
}

export default MultiStepForm;
