import React, { ReactNode } from 'react';
import classNames from 'classnames';

import './Hero.css';

interface HeroProps {
  className?: string;
  children: ReactNode;
  backgroundImage?: string;
  minHeight?: string;
}

function Hero({ className, children, backgroundImage, minHeight }: HeroProps) {
  return (
    <div
      className={classNames('hero', className)}
      style={{
        backgroundImage: `url(${backgroundImage})`,
        minHeight,
      }}
    >
      {children}
    </div>
  );
}

export default Hero;
