import { useEffect, useState } from 'react';
import Badge from '../../components/atoms/Badge/Badge';
import { useCertificateMetadata } from '../../hooks/PilotCertificateProvider/CertificateMetadataProvider';
import SearchableDropdown from '../../components/molecules/SearchableDropdown/SearchableDropdown';
import { PrimaryButton } from '../../components/atoms/Button/Button';
import Rule from '../../components/atoms/Rule';
import Label from '../../components/atoms/Label';

interface LimitationSelectProps {
  className?: string;
  disabled?: boolean;
  limitValues: string[];
  updateLimits: (newState: string[]) => void;
}

function LimitationSelect({
  className,
  disabled,
  limitValues,
  updateLimits,
}: LimitationSelectProps) {
  const { limitsOptions } = useCertificateMetadata();

  const [selectedLimit, setSelectedLimit] = useState<string | null>(null);

  const availableLimits = limitsOptions
    .filter((lo) => lo.forAllPilots && !limitValues.includes(lo.name))
    .map((lo) => lo.name);

  const handleAddLimit = () => {
    if (selectedLimit) updateLimits([...limitValues, selectedLimit]);
  };

  const handleRemoveLimit = (l: string) => {
    updateLimits(limitValues.filter((lim) => lim !== l));
  };

  useEffect(() => {
    setSelectedLimit(null);
  }, [limitValues]);

  return (
    <div className={`p-2 border-2 rounded-lg ${className}`}>
      <Label htmlFor="limit-select">Add Limitation(s)</Label>
      <div className="flex gap-2">
        <SearchableDropdown
          id="limit-select"
          options={availableLimits}
          onChange={(t) => {
            setSelectedLimit(t);
          }}
          onSelect={(t) => {
            setSelectedLimit(t);
          }}
          disabled={disabled}
        />
        <PrimaryButton onClick={handleAddLimit} disabled={disabled}>
          Add
        </PrimaryButton>
      </div>
      {!!limitValues.length && (
        <>
          <Rule className="my-4" />
          <div className="flex gap-2 flex-wrap">
            {limitValues.map((l) => (
              <Badge
                key={l}
                value={l}
                disabled={disabled}
                onRemove={() => handleRemoveLimit(l)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default LimitationSelect;
