import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useMemo,
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import loginApi from '../../API/Auth/login';
import {
  register as registerApi,
  RegistrationError,
  RegistrationFields,
} from '../../API/Auth/register';
import getUser from '../../API/User/getUser';
import User from '../../types/User';
import GetUserResponse from '../../API/User/GetUserResponse';
import { ROUTES, UNPROTECTED_ROUTES } from '../../routes/routerConfig';
import getPaymentMethods from '../../API/Payments/getPaymentMethods';
import Link from '../../components/atoms/Link';
import Paragraph from '../../components/typography/Paragraph';
import Heading from '../../components/typography/Heading';
import getAircraft from '../../API/Aircraft/getAircraft';

type LogInFunction = (email: string, password: string) => void;

interface UserProviderProps {
  user: User | null;
  children: ReactNode;
}

type UserRole = 'pilot' | 'consumer';

type SetUserRoleFunction = (newRole: UserRole) => void;

interface IUserContext {
  user: User | null;
  refreshUser: () => Promise<unknown>;
  setUser: Dispatch<SetStateAction<User | null>>;
  login: LogInFunction;
  role: UserRole;
  setUserRole: SetUserRoleFunction;
  hasKey: () => boolean;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const UserContext = createContext<IUserContext>(undefined!);

export function UserProvider({ user: propsUser, children }: UserProviderProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<User | null>(propsUser);
  const [role, setRole] = useState<UserRole>(
    (localStorage.getItem('role') as UserRole) || 'consumer'
  );

  const setUserRole = (newRole: UserRole) => {
    localStorage.setItem('role', newRole);
    setRole(newRole);
  };

  const getAndSetUser = async () => {
    const getUserResponse = await getUser();
    // set user
    setUser(getUserResponse);

    if (!localStorage.getItem('role')) {
      // set initial role in localStorage
      setUserRole(getUserResponse.pilotRole ? 'pilot' : 'consumer');
    } else {
      // get role from localStorage
      localStorage.getItem('role');
    }
  };

  useEffect(() => {
    // User is not logged in, but we have a session key
    // Use it to get the user
    if (!user && localStorage.getItem('key')) {
      getAndSetUser();
      // navigate to login if not accessing an unprotected route
    } else if (!Object.values(UNPROTECTED_ROUTES).includes(location.pathname)) {
      navigate(ROUTES.LOGIN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshUser = async () => {
    await getAndSetUser();
  };

  const register = async ({
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
  }: RegistrationFields) => {
    const registerResult = await registerApi({
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    });

    if (registerResult.statusCode === 201) {
      setUser(registerResult as GetUserResponse);
      return { success: true, errors: [] };
    }
    const errors = Object.values(registerResult as RegistrationError);
    return { success: false, errors };
  };

  const login = async (email: string, password: string) => {
    const loginResult = await loginApi(email, password);
    setUser(loginResult);
  };

  const hasKey = () => !!localStorage.getItem('key');

  const value = useMemo(() => {
    return {
      user,
      refreshUser,
      setUser,
      login,
      register,
      setUserRole,
      role,
      hasKey,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, role]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const useUser = () => useContext<IUserContext>(UserContext);
