// Import shared app components and types
import Conversation from '../../../types/Conversation';
import { Expense } from '../../../types/Expense';
import Rule from '../../atoms/Rule';
import Card from '../../molecules/Card/Card';
import Heading from '../../typography/Heading';

function GrandTotalEstimateCard({
  isPilot = false,
  cost,
  expenses,
  className = '',
}: {
  isPilot?: boolean;
  cost: Conversation['cost'];
  expenses: Expense[];
  className?: string;
}) {
  const { estimation } = cost;
  const {
    flightDays,
    flyingCost,
    travelDays,
    travelCost,
    pricePerDay: pilotFlyingRate,
    dayDiem: dayPerDiem,
    diem: perDiemTotal,
    platformFee: serviceFee,
    totalCharge: totalCost,
  } = estimation;
  const pilotTravelDayRate = estimation.travelDays
    ? estimation.travelCost / estimation.travelDays
    : 0;
  const tripDuration = flightDays + travelDays;
  const pilotTotal: number = totalCost - serviceFee;

  return (
    <Card responsive noPadding className={className}>
      <div className="py-2 background bg-primary rounded-t-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          Quote Estimate
        </Heading>
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 p-4">
          <div className="flex">
            <span>
              Flying Day-Rate ({flightDays} days, ${pilotFlyingRate.toFixed(2)}
              /day):
            </span>
            <span className="ml-auto font-semibold">
              ${flyingCost.toFixed(2)}
            </span>
          </div>
          <div className="flex">
            <span>
              Travel Day-Rate ({travelDays} days
              {pilotTravelDayRate
                ? `, $${pilotTravelDayRate.toFixed(2)}/day)`
                : ')'}
              :
            </span>
            <span className="ml-auto font-semibold">
              ${travelCost.toFixed(2)}
            </span>
          </div>
          <div className="flex">
            <span>
              Per-Diem ({tripDuration} days, ${dayPerDiem.toFixed(2)}/day):
            </span>
            <span className="ml-auto font-semibold">
              ${perDiemTotal.toFixed(2)}
            </span>
          </div>
          {!isPilot && (
            <div className="flex">
              <span>Service Fee:</span>
              <span className="ml-auto font-semibold">
                ${serviceFee.toFixed(2)}
              </span>
            </div>
          )}
        </div>
        <div className="py-2 background bg-primary">
          <Heading
            level={4}
            className="uppercase text-center text-white font-normal py-2"
          >
            Pre-trip total: $
            {isPilot ? pilotTotal.toFixed(2) : totalCost.toFixed(2)}
          </Heading>
        </div>
        <div className="flex flex-col gap-2 p-4">
          <div className="flex">
            <span className="italic text-xs">
              <i className="fas fa-plus-circle" />{' '}
              <span>
                The pre-trip total is the estimated cost for pilot services,
                travel days, per diem, and base service fee, subject to mission
                changes.
              </span>
            </span>
          </div>
          <Heading level={4} className="underline font-semibold">
            Estimated Expenses:
          </Heading>
          {expenses.map((expense) => (
            <div className="flex">
              <span>{expense.title}:</span>
              <span
                key={expense.id}
                className="ml-auto font-semibold border-b border-black border-dotted"
              >
                {expense.cost.toFixed(2)}
              </span>
            </div>
          ))}
          <div className="flex">
            <span className="italic text-xs">
              <i className="fas fa-plus-circle" />{' '}
              <span>
                Actual expenses, such as hotel, ground travel, air travel, and
                any additional costs, will be added after the trip and may vary
                from the estimates provided. These expenses will incur an
                additional service fee.
              </span>
            </span>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default GrandTotalEstimateCard;
