/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { TextareaHTMLAttributes } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

export default function TextArea({
  className,
  name,
  id,
  disabled,
  value,
  placeholder = '',
  onChange,
  rows = 4,
  cols = 30,
}: TextAreaProps) {
  return (
    <textarea
      className={`textarea textarea-bordered w-full max-w-sm disabled:bg-base-200 disabled:text-neutral disabled:cursor-not-allowed ${className}`}
      name={name}
      id={id ?? undefined}
      value={value as string}
      disabled={disabled}
      onChange={onChange}
      cols={cols}
      rows={rows}
      placeholder={placeholder}
    />
  );
}
