interface ServicesAgreementResponse {
  url: string | null;
  error?: any;
}

/**
 * tripOrBidId is normally the `trip.id`
 * It is ONLY `bid.id` if `isBid` is true
 */
const getServicesAgreement = async (
  tripOrBidId: number,
  isBid = false
): Promise<ServicesAgreementResponse> => {
  const accessToken = localStorage.getItem('token');
  const options = {
    headers: {
      Authorization: `Token ${localStorage.getItem('key')}`,
      'content-type': 'application/json',
    },
    method: 'GET',
  };
  let requestURL = `/api/trips/as_pilot/${tripOrBidId}/agreement/`;

  if (isBid) {
    requestURL = `/api/trips/as_pilot/${tripOrBidId}/agreement/?is_bid=true`;
  }

  try {
    const response = await fetch(requestURL, options);
    const result = await response.blob();
    const blob = new Blob([result], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);

    return { url };
  } catch (error) {
    console.log(error);
    return { url: null, error };
  }
};

export default getServicesAgreement;
