import React from 'react';
import FormikInput from '../../../atoms/Form/FormikInput';
import FormikField from '../../../atoms/Form/FormikField';
import Heading from '../../../typography/Heading';
import PlaneType from '../../../../types/PlaneType';
import i18n from '../../../../strings/i18n';

interface AircraftInsuranceProps {
  currentPlaneType: PlaneType | null;
}

function AircraftInsurance({ currentPlaneType }: AircraftInsuranceProps) {
  const renderInsuranceFields = () => {
    if (!currentPlaneType) return null;
    if (currentPlaneType?.craftName.includes('PC-12')) {
      return (
        <FormikField
          name="insurance.openPilotClause.turbineHours"
          label="Turbine Hours"
          placeholder="Enter your turbine hours..."
          component={FormikInput}
          type="number"
        />
      );
    }
    if (currentPlaneType?.craftName.includes('PC-24')) {
      return (
        <>
          <FormikField
            name="insurance.openPilotClause.multiEngineHours"
            label="Multi-Engine Hours"
            placeholder="Enter your multi-engine hours..."
            component={FormikInput}
            type="number"
          />
          <FormikField
            name="insurance.openPilotClause.turboJetHours"
            label="Turbo Jet Hours"
            placeholder="Enter your turbo jet hours..."
            component={FormikInput}
            type="number"
          />
        </>
      );
    }
    return null;
  };

  return (
    <>
      <Heading level={2}>Insurance</Heading>

      <FormikField
        name="insurance.companyName"
        label="Insurance Broker"
        placeholder={i18n({ placeholder: 'companyName' })}
        component={FormikInput}
      />
      <FormikField
        name="insurance.insuranceContactName"
        label="Contact Name"
        placeholder={i18n({ placeholder: 'contactName' })}
        component={FormikInput}
        optional
      />
      <FormikField
        name="insurance.insuranceContactEmail"
        type="email"
        label="Contact Email"
        placeholder={i18n({ placeholder: 'contactEmail' })}
        component={FormikInput}
        optional
      />
      <Heading level={3}>Open Pilot Hours</Heading>
      <FormikField
        name="insurance.openPilotClause.totalFlightHours"
        label="Total Hours"
        placeholder="Enter your total hours..."
        component={FormikInput}
        type="number"
      />
      {renderInsuranceFields()}
      <FormikField
        name="insurance.openPilotClause.planeTypeFlightHours"
        label={`${currentPlaneType?.craftType || "This plane's hours"} Hours`}
        placeholder={`Enter your ${
          currentPlaneType?.craftType || "plane's"
        } Hours`}
        component={FormikInput}
        type="number"
      />
    </>
  );
}

export default AircraftInsurance;
