import React from 'react';

function PilotHat(props: { fillColor: string }) {
  let { fillColor } = props;

  if (!fillColor) {
    fillColor = '#09B2CF';
  }

  return (
    <svg width="21px" height="20px" viewBox="0 0 21 15" version="1.1">
      <g
        id="all---4/30"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Pilot-Onboarding---temp-form"
          transform="translate(-1070.000000, -114.000000)"
          fill={fillColor}
        >
          <g id="your-information" transform="translate(943.000000, 21.000000)">
            <g id="Trip-Details">
              <g id="Group" transform="translate(20.000000, 39.000000)">
                <g id="Group-8">
                  <g id="Group-6">
                    <g id="Page-1" transform="translate(107.000000, 54.000000)">
                      <path
                        d="M10.5,0 C4.22205,0 2.34276,6.1236 0,6.74205 C0,9.92481 2.033556,10.19067 2.43138,10.19067 C2.4978261,11.45067 2.4978261,11.759937 2.4978261,12.71067 C2.4978261,12.71067 5.8357761,14.368515 10.5221361,14.368515 C15.1864461,14.368515 18.4800861,12.71067 18.4800861,12.71067 L18.4800861,10.36791 C19.2757971,10.03569 21.000084,9.084936 21.000084,6.74205 C18.5021361,6.277761 16.7780361,0 10.5000861,0 L10.5,0 Z M10.5221487,13.2636 C6.4107687,13.2636 3.4485087,11.981445 3.4485087,11.981445 C7.8477987,10.102113 13.1528187,10.412178 17.5512687,11.981445 C17.5512687,11.981445 14.6333187,13.2636 10.5219387,13.2636 L10.5221487,13.2636 Z M17.5514787,11.03067 C14.1914787,9.748515 8.2008087,9.284226 3.4487187,11.03067 L3.4487187,10.079937 C7.8480087,8.200605 13.1530287,8.51067 17.5514787,10.079937 L17.5514787,11.03067 Z M17.9271897,9.306381 C13.1965197,7.383579 7.4492397,7.184331 3.0942597,9.3285297 C2.7628587,9.3285297 1.10499186,9.4614198 1.10499186,7.1178597 C3.0277947,6.6092607 5.3713527,0.7958097 10.4999727,0.7958097 C15.6285906,0.7958097 17.8392627,6.8087397 19.8949506,7.2064797 C19.8949506,9.1735917 18.3470196,9.3064797 17.9270196,9.3064797 L17.9271897,9.306381 Z M6.12276,4.11138 C5.902092,4.1335287 5.70276,4.2664188 5.614161,4.487091 C5.525562,4.7077632 5.5477149,4.929246 5.6806071,5.127759 C5.8134972,5.3270952 6.0341631,5.414871 6.2556501,5.392716 L9.0627201,5.392716 L10.1020521,6.541983 C10.2127935,6.6748731 10.3891641,6.7413192 10.5663411,6.7413192 C10.7435181,6.7413192 10.9198971,6.6748731 11.0306301,6.541983 L12.0699621,5.392716 L14.8770321,5.392716 C15.2305881,5.392716 15.5177001,5.105604 15.5177001,4.752048 C15.5177001,4.398492 15.2305881,4.11138 14.8770321,4.11138 L11.7154821,4.11138 C11.5382946,4.1335287 11.3619261,4.2221214 11.2511931,4.376337 L10.5219261,5.172048 L9.7483701,4.332048 C9.6376287,4.1991579 9.4612581,4.11138 9.2840811,4.11138 L6.2334111,4.11138 L6.1226697,4.11138 L6.12276,4.11138 Z"
                        id="Fill-1"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default PilotHat;
