import { FileSelection } from './FileSelection';
import { TrainingFacility } from './TrainingFacility';

export interface PlaneTypeCertificate {
  id?: number;
  certDocument?: FileSelection | null;
  diffDocument?: FileSelection | null;
  trainingFacility?: TrainingFacility['id'] | null;
  certificationDate?: string | null;
}

export function createPlaneTypeCert(): PlaneTypeCertificate {
  return {
    certDocument: null,
    diffDocument: null,
    trainingFacility: null,
    certificationDate: null,
  };
}
