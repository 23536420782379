/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import EditAndSaveControls, {
  EditAndSaveControlsProps,
} from '../EditAndSaveControls/EditAndSaveControls';
import Card, { CardProps } from './Card';

type SettingsCardProps = CardProps &
  EditAndSaveControlsProps & { children: React.ReactNode };

/** Standard experience for cards that contain settings controls */
function SettingsCard(props: SettingsCardProps) {
  return (
    <Card {...props} responsive>
      <EditAndSaveControls
        {...props}
        className={`z-10 relative md:absolute right-0 md:mr-8 ${props.className}`}
      />
      {props.children}
    </Card>
  );
}

export default SettingsCard;
