/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React, { useState } from 'react';
import { FormikHelpers, FormikValues } from 'formik';
import { useNavigate } from 'react-router-dom';
import Section from '../../wrappers/Section';
import MultiStepForm, { FormStep } from '../MultiStepForm/MultiStepForm';
import {
  AircraftType,
  AircraftIdentifiers,
  AircraftConfiguration,
  AircraftLocation,
  AircraftMaintenance,
  AircraftInsurance,
  AircraftOperatingProcedures,
} from './steps';
import {
  validAircraftTypeSchema,
  validAircraftIdentificationSchema,
  validAircraftConfigurationSchema,
  validAircraftMaintenanceSchema,
  validAircraftLocationSchema,
  validAircraftInsuranceSchema,
  validAircraftOperatingProceduresSchema,
} from './validation';
import { type Airplane } from '../../../types/Airplane';
import createAircraft from '../../../API/Aircraft/createAircraft';
import PlaneType from '../../../types/PlaneType';
import updateAircraft, {
  updateAircraftConfiguration,
  updateAircraftIdentifiers,
  updateAircraftInsurance,
  updateAircraftLocation,
  updateAircraftMaintenance,
  updateAircraftProcedures,
} from '../../../API/Aircraft/updateAircraft';
import initialAirplane from '../../../const/initialAirplane';
import { ROUTES } from '../../../routes/routerConfig';
import { useAircraft } from '../../../hooks/useAircraft';
import { useUser } from '../../../hooks/UserProvider/UserProvider';

interface AddAirplaneFormProps {
  planeMakes: AirplaneMake[];
}

export default function AddAirplaneForm({ planeMakes }: AddAirplaneFormProps) {
  const { refreshAirplanes } = useAircraft();
  const navigate = useNavigate();
  const [currentPlaneType, setCurrentPlaneType] = useState<PlaneType>(
    {} as PlaneType
  );
  const { refreshUser } = useUser();

  /**
   * This function is called when the user selects a new plane type and advances to the next step
   * @param {Airplane} airplane - The airplane object from the formik values
   * @param {FormikHelpers<Airplane>} formikHelpers - The formik helpers object
   */
  const handleCreateOrUpdatePlane = async <Airplane extends FormikValues>(
    values: Airplane,
    { setFieldValue }: FormikHelpers<Airplane>
  ) => {
    try {
      const { id: planeId, make, model } = values;
      const chosenMake = planeMakes.find((mk) => mk.id === make);
      const newPlaneType = chosenMake.aircraftModels.find(
        (plane) => plane.id === model
      );

      if (newPlaneType) {
        setCurrentPlaneType(newPlaneType);

        // Create/update the aircraft based on whether the planeId is null
        if (planeId === null) {
          const airplaneResponseData = await createAircraft({
            make,
            model,
            planeType: newPlaneType.id,
          });
          setFieldValue('id', airplaneResponseData.id);
          setFieldValue('identification.aircraft', airplaneResponseData.id);
          setFieldValue('configuration.aircraft', airplaneResponseData.id);
          setFieldValue('location.aircraft', airplaneResponseData.id);
          setFieldValue('maintenance.aircraft', airplaneResponseData.id);
          setFieldValue('insurance.aircraft', airplaneResponseData.id);
          setFieldValue(
            'operatingProcedures.aircraft',
            airplaneResponseData.id
          );
        } else {
          // TODO: capture only the values that have changed
          const airplaneResponseData = await updateAircraft({
            id: newPlaneType.id,
            make: newPlaneType.make,
            model: newPlaneType.model,
          });
        }
        setFieldValue('planeType', newPlaneType.id);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleSubmit = async (values: Airplane) => {
    try {
      await updateAircraft({
        ...values,
      });
      console.log('Full form submit', values);

      await refreshAirplanes();
      await refreshUser();
      navigate(ROUTES.MY_AIRCRAFT);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Section>
      <MultiStepForm
        title="New Aircraft"
        initialValues={initialAirplane}
        onSubmit={handleSubmit}
      >
        <FormStep
          title="Aircraft Type"
          onSubmit={handleCreateOrUpdatePlane}
          validationSchema={validAircraftTypeSchema}
        >
          <AircraftType planeMakes={planeMakes} />
        </FormStep>
        <FormStep
          title="Aircraft Ownership & Identification"
          onSubmit={async (values, helpers) => {
            const updatedIdentifiers = await updateAircraftIdentifiers({
              ...values.identification,
            });

            helpers.setValues({
              ...values,
              identification: updatedIdentifiers,
            });
          }}
          validationSchema={validAircraftIdentificationSchema}
        >
          <AircraftIdentifiers />
        </FormStep>
        <FormStep
          title="Aircraft Configuration"
          onSubmit={async (values, helpers) => {
            const updatedConfiguration = await updateAircraftConfiguration({
              ...values.configuration,
            });

            helpers.setValues({
              ...values,
              configuration: updatedConfiguration,
            });
          }}
          validationSchema={validAircraftConfigurationSchema}
        >
          <AircraftConfiguration currentPlaneType={currentPlaneType} />
        </FormStep>
        <FormStep
          title="Aircraft Location"
          onSubmit={async (values, helpers) => {
            const updatedLocation = await updateAircraftLocation({
              ...values.location,
            });

            helpers.setValues({
              ...values,
              location: updatedLocation,
            });
          }}
          validationSchema={validAircraftLocationSchema}
        >
          <AircraftLocation />
        </FormStep>
        <FormStep
          title="Aircraft Maintenance"
          onSubmit={async (values, helpers) => {
            const updatedMaintenance = await updateAircraftMaintenance({
              ...values.maintenance,
            });

            helpers.setValues({
              ...values,
              maintenance: updatedMaintenance,
            });
          }}
          validationSchema={validAircraftMaintenanceSchema}
        >
          <AircraftMaintenance />
        </FormStep>
        <FormStep
          title="Aircraft Insurance"
          onSubmit={async (values, helpers) => {
            const updatedInsurance = await updateAircraftInsurance({
              ...values.insurance,
            });

            helpers.setValues({
              ...values,
              insurance: updatedInsurance,
            });
          }}
          validationSchema={validAircraftInsuranceSchema}
        >
          <AircraftInsurance currentPlaneType={currentPlaneType} />
        </FormStep>
        <FormStep
          title="Aircraft Operating Procedures"
          onSubmit={async (values, helpers) => {
            const updatedProcedures = await updateAircraftProcedures({
              ...values.operatingProcedures,
            });

            helpers.setValues({
              ...values,
              operatingProcedures: updatedProcedures,
            });
          }}
          validationSchema={validAircraftOperatingProceduresSchema}
        >
          <AircraftOperatingProcedures />
        </FormStep>
      </MultiStepForm>
    </Section>
  );
}
