// Import React dependencies
import { useEffect, useRef, useState } from 'react';
// Import common app components and types
import FormField from '../../components/wrappers/FormField/FormField';
import Paragraph from '../../components/typography/Paragraph';
import Heading from '../../components/typography/Heading';
import { AirportId } from '../../types/AirportLocation';
import TextArea from '../../components/atoms/TextArea';
import Label from '../../components/atoms/Label';
import Input from '../../components/atoms/Input';
// Import sub-components and views
// Import hooks and utilities
import { useUser } from '../../hooks/UserProvider/UserProvider';
import updatePilot from '../../API/Pilot/updatePilot';
import updateUser from '../../API/User/updateUser';
import createPilot from '../../API/Pilot/createPilot';
import { useToast } from '../../hooks/ToastProvider';
import useForm from '../../hooks/useForm';
import AirportSelect from '../../components/molecules/AirportSelect/AirportSelect';
import DayPicker from '../../components/molecules/DayPicker/DayPicker';
import SettingsCard from '../../components/molecules/Card/SettingsCard';
import Alert from '../../components/atoms/Alert/Alert';
import PlusIcon from '../../assets/icons/PlusIcon';
import { isValidPhoneNumber } from '../../utilities/validation';
import Tooltip from '../../components/atoms/Tooltip/Tooltip';
import Avatar from '../../components/molecules/Avatar/Avatar';

const BIO_CHAR_LIMIT = 500;

export default function PilotView() {
  const { addToast } = useToast();
  const { user, refreshUser } = useUser();

  const initialForm = {
    // user fields
    firstName: user?.firstName,
    lastName: user?.lastName,

    // pilot fields
    phoneNumber: user?.pilotRole?.phoneNumber ?? '',
    email: user?.email ?? '',
    dateOfBirth: user?.pilotRole.dateOfBirth
      ? new Date(user?.pilotRole.dateOfBirth)
      : undefined,
    website: user?.pilotRole?.website ?? '',
    address: user?.pilotRole?.address ?? '',
    city: user?.pilotRole?.city ?? '',
    state: user?.pilotRole?.state ?? '',
    zip: user?.pilotRole?.zip ?? '',
    employer: user?.pilotRole?.employer ?? '',
    country: user?.pilotRole?.country ?? '',
    jobTitle: user?.pilotRole?.jobTitle ?? '',
    homeLocationId: user?.pilotRole?.homeLocation?.id ?? '',
    description: user?.pilotRole?.description ?? '',
  };

  type CardOption = 'identity' | 'personal' | 'contact' | null;

  const [activeCard, setActiveCard] = useState<CardOption>(null);

  const { inputs, handleChange, updateFields, resetForm } =
    useForm(initialForm);
  const ref = useRef<HTMLInputElement | null>(null);
  const [initials, setInitials] = useState('AA');
  const [avatar, setAvatar] = useState<File | null>(null);
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (user) {
      setAvatarUrl(user?.avatar?.image ?? null);
      setInitials(`${user?.firstName[0]}${user?.lastName[0]}`);
    }
  }, [user]);

  const handleAirportChange = (airportId: number) => {
    const field: Record<string, AirportId> = {
      homeLocationId: airportId,
    };
    updateFields(field);
  };

  const editCard = (card: CardOption) => {
    setDisabled(false);
    resetForm();
    setActiveCard(card);
  };

  const handleSave = async () => {
    if (!user) return;

    try {
      await updateUser(user?.id, null, inputs);
      // TODO: only send changed fields
      await updateUser(user?.id, avatar, inputs);

      if (user.pilotRole) {
        const payload = inputs;
        if (inputs.dateOfBirth)
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          inputs.dateOfBirth = inputs.dateOfBirth.toISOString();

        await updatePilot(user.pilotRole.id, payload);
      } else {
        await createPilot(inputs);
      }
    } catch (err) {
      console.log(err);
      addToast(<span>{(err as Error).message}</span>, 'error');
      return;
    }

    await refreshUser();

    setDisabled(true);
    addToast(<span>Pilot general information saved!</span>, 'success');
    setActiveCard(null);
  };
  const handleCancel = () => {
    setDisabled(true);
    resetForm();
    setActiveCard(null);
  };

  const charLimitStyle = (limit: number) => {
    if (inputs.description) {
      if (inputs.description.length > limit) return 'text-error';
      if (inputs.description.length > limit - 20) return 'text-warning';
      return '';
    }
    return '';
  };

  return (
    <>
      <Heading level={1}>General Information</Heading>
      <div className="flex flex-col gap-4">
        {/* Identity */}
        <SettingsCard
          onEdit={() => editCard('identity')}
          onCancel={handleCancel}
          onSave={handleSave}
          disabled={activeCard !== 'identity'}
          responsive
        >
          <Heading level={3}>Identity</Heading>
          <div className="mt-8">
            {/* <Label htmlFor="avatar">Avatar</Label> */}
            <Label htmlFor="avatar">
              <Tooltip trigger="Avatar" direction="right">
                FYI, Pilots are more likely to be hired if they have a
                professional photo.
              </Tooltip>
            </Label>
            <button
              type="button"
              className="indicator"
              onClick={() => ref.current?.click()}
              disabled={disabled}
            >
              <div className="indicator-item indicator-bottom">
                <div
                  className={`btn btn-sm btn-circle ${
                    disabled ? 'btn-disabled' : ''
                  }`}
                >
                  <PlusIcon />
                </div>
              </div>
              <Avatar initials={initials} avatarUrl={avatarUrl || undefined} />
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <input
                ref={ref}
                type="file"
                accept="image/png, image/jpeg"
                style={{ display: 'none' }}
                onChange={(event) => {
                  const file = (event.currentTarget.files || [])?.[0] ?? null;

                  if (file) {
                    setAvatarUrl(URL.createObjectURL(file));
                    setAvatar(file);
                  }
                }}
              />
            </button>
          </div>
          <FormField>
            <Label htmlFor="firstName" required>
              First Name
            </Label>
            <Input
              id="firstName"
              disabled={activeCard !== 'identity'}
              value={inputs.firstName}
              onChange={handleChange}
            />
          </FormField>
          <FormField>
            <Label htmlFor="lastName" required>
              Last Name
            </Label>
            <Input
              id="lastName"
              disabled={activeCard !== 'identity'}
              value={inputs.lastName}
              onChange={handleChange}
            />
          </FormField>
          <FormField>
            <Label htmlFor="dateOfBirth" required>
              Date of Birth
            </Label>
            <DayPicker
              value={
                (inputs.dateOfBirth && new Date(inputs.dateOfBirth)) || null
              }
              id="dateOfBirth"
              disabled={activeCard !== 'identity'}
              onChange={(newDob) => updateFields({ dateOfBirth: newDob })}
            />
          </FormField>
        </SettingsCard>
        {/* Contact Info */}
        <SettingsCard
          onEdit={() => editCard('contact')}
          onCancel={handleCancel}
          onSave={handleSave}
          disabled={activeCard !== 'contact'}
          responsive
        >
          <Heading level={3}>Contact Info</Heading>
          <FormField>
            <Label htmlFor="phone" required>
              Phone
            </Label>
            <Input
              id="phoneNumber"
              value={inputs.phoneNumber}
              onChange={handleChange}
              disabled={activeCard !== 'contact'}
            />
          </FormField>
          <FormField>
            <Label htmlFor="email" required>
              Email
            </Label>
            <Input
              id="email"
              value={inputs.email}
              onChange={handleChange}
              disabled={activeCard !== 'contact'}
            />
          </FormField>
          <FormField>
            <Label htmlFor="website">Website</Label>
            <Input
              id="website"
              value={inputs.website}
              onChange={handleChange}
              disabled={activeCard !== 'contact'}
            />
          </FormField>
          <FormField>
            <Label htmlFor="address" required>
              Address
            </Label>
            <Input
              id="address"
              value={inputs.address}
              onChange={handleChange}
              disabled={activeCard !== 'contact'}
            />
          </FormField>
          <FormField>
            <Label htmlFor="city" required>
              City
            </Label>
            <Input
              id="city"
              value={inputs.city}
              onChange={handleChange}
              disabled={activeCard !== 'contact'}
            />
          </FormField>
          <FormField>
            <Label htmlFor="state" required>
              State
            </Label>
            <Input
              id="state"
              value={inputs.state}
              onChange={handleChange}
              disabled={activeCard !== 'contact'}
            />
          </FormField>
          <FormField>
            <Label htmlFor="zip" required>
              Zip
            </Label>
            <Input
              id="zip"
              value={inputs.zip}
              onChange={handleChange}
              disabled={activeCard !== 'contact'}
            />
          </FormField>
          <FormField>
            <Label htmlFor="country" required>
              Country
            </Label>
            <Input
              id="country"
              value={inputs.country}
              onChange={handleChange}
              disabled={activeCard !== 'contact'}
            />
          </FormField>
        </SettingsCard>
        {/* Personal Details */}
        <SettingsCard
          onEdit={() => editCard('personal')}
          onCancel={handleCancel}
          onSave={handleSave}
          disabled={activeCard !== 'personal'}
          responsive
        >
          <Heading level={3}>Personal Details</Heading>
          <FormField>
            <Label htmlFor="employer">Employer</Label>
            <Input
              id="employer"
              value={inputs.employer}
              onChange={handleChange}
              disabled={activeCard !== 'personal'}
            />
          </FormField>
          <FormField>
            <Label htmlFor="jobTitle">Job Title</Label>
            <Input
              id="jobTitle"
              value={inputs.jobTitle}
              onChange={handleChange}
              disabled={activeCard !== 'personal'}
            />
          </FormField>
          <FormField>
            <Label htmlFor="homeLocationId" required>
              Home Airport
            </Label>
            <AirportSelect
              id="homeLocationId"
              onSelect={handleAirportChange}
              defaultValue={user?.pilotRole?.homeLocation?.id}
              disabled={activeCard !== 'personal'}
            />
          </FormField>
          <FormField className="!max-w-lg">
            <Label htmlFor="bio">About You</Label>
            <TextArea
              id="description"
              value={inputs.description}
              onChange={handleChange}
              maxLength={BIO_CHAR_LIMIT}
              className={`${charLimitStyle(BIO_CHAR_LIMIT)} !max-w-lg`}
              disabled={activeCard !== 'personal'}
            />
            <Paragraph className={charLimitStyle(BIO_CHAR_LIMIT)}>
              {`${inputs.description.length}/${BIO_CHAR_LIMIT} characters`}
            </Paragraph>
          </FormField>
        </SettingsCard>
      </div>
    </>
  );
}
