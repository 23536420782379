// Import packages
import { useParams } from 'react-router-dom';

// Import hooks and utilities
import { useUser } from '../../hooks/UserProvider/UserProvider';
// Import sub-components and views
import OperatorView from './OperatorView';
import PilotView from './PilotView';
import Loader from '../../components/molecules/Loader';

function TripPage() {
  const { id } = useParams();
  const { user, role } = useUser();

  return (
    <>
      {!user && <Loader caption="Loading your account..." />}
      {(id &&
        user &&
        (role === 'consumer' ? (
          <OperatorView id={id} user={user} />
        ) : (
          <PilotView id={id} />
        ))) ||
        null}
    </>
  );
}

export default TripPage;
