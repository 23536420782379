import User from '../../types/User';

const getPilotHistoryForm = async (user: User) => {
  const requestURL = `/api/pilots/${user?.pilotRole.id}/pdf/`;
  const options = {
    headers: {
      Authorization: `Token ${localStorage.getItem('key')}`,
      'content-type': 'application/json',
    },
    method: 'GET',
  };
  fetch(requestURL, options)
    .then((res) => res.blob())
    .then(
      (result) => {
        const blob = new Blob([result]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `${user?.pilotRole.fullName}_pilot_profile.pdf`;
        link.click();
      },
      (error) => {
        console.error(error);
      }
    );
};

export default getPilotHistoryForm;
