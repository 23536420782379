import React from 'react';
import classNames from 'classnames';

import HowItWorksImage from '../../../../assets/images/HowItWorks.png';
import HowItWorksPilotImage from '../../../../assets/images/HowItWorksPilot.png';

import './HowItWorks.css';

function HowItWorks({
  className,
  kind = 'owner',
}: {
  className?: string;
  kind?: string;
}) {
  return (
    <div className={classNames('howItWorks', className)}>
      <img
        src={kind === 'owner' ? HowItWorksImage : HowItWorksPilotImage}
        alt="Create Your Itinerary, Select Your Favorite Bid, the Pilot Flies for You"
      />
      {kind === 'owner' ? (
        <ul>
          <li>Create Your Itinerary</li>
          <li>Pilots Bid on Your Itinerary</li>
          <li>Select Your Favorite Pilot/Bid</li>
          <li>When Pilot Accepts, They Fly for You</li>
          <li>Once Your Trip is Done, They&apos;re Paid</li>
        </ul>
      ) : (
        <ul>
          <li>Register with Your Pilot&apos;s License</li>
          <li>Bid On Jobs that Match Your Qualifications and Your Schedule</li>
          <li>Get Notified When Your Bids are Accepted</li>
          <li>Complete Your Trip</li>
          <li>Submit Your Expenses</li>
          <li>Get Paid for Your Trip and Expenses</li>
        </ul>
      )}
    </div>
  );
}

export default HowItWorks;
