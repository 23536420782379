import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import User from '../../../../types/User';
import Trip from '../../../../types/Trip';
import Conversation from '../../../../types/Conversation';
import { Quote, createQuoteFromBid } from '../../../../types/Quote';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../components/atoms/Button/Button';
import QuoteCard from '../../../../components/organisms/QuoteCard/QuoteCard';
import PilotCard from '../../../../components/molecules/PilotCard/PilotCard';
import TripCard from '../../../../components/organisms/TripCard/TripCard';
import Heading from '../../../../components/typography/Heading';
import ControlBar from '../../../../components/molecules/ControlBar/ControlBar';
import { ROUTES } from '../../../routerConfig';
import ChatHistory from '../../../../components/organisms/Chat/ChatHistory';
import Rule from '../../../../components/atoms/Rule';
import Alert from '../../../../components/atoms/Alert/Alert';
import PrimaryTripLayout from '../../Layouts/PrimaryTripLayout';

function CancelledView({ trip }: { trip: Trip }) {
  const [currentConversation, setCurrentConversation] =
    useState<Conversation | null>(
      trip && trip.conversations.length ? trip.conversations[0] : null
    );
  const [quote, setQuote] = useState<Quote | null>(
    trip.conversations.length && trip.conversations[0].bid
      ? createQuoteFromBid(
          trip.conversations[0].bid,
          trip.conversations[0].user,
          trip
        )
      : null
  );
  const [quoteIndex, setQuoteIndex] = useState(0);
  const navigate = useNavigate();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);

  const pilotAreaRef = useRef<HTMLDivElement>(null);
  const chatAreaRef = useRef<HTMLDivElement>(null);
  const tripAndQuoteAreaRef = useRef<HTMLDivElement>(null);

  const viewQuote = (i: number) => {
    // TODO make this scroll to top work when selecting a different quote
    if (pilotAreaRef.current) pilotAreaRef.current.scrollTop = 0;
    if (chatAreaRef.current) chatAreaRef.current.scrollTop = 0;
    if (tripAndQuoteAreaRef.current) tripAndQuoteAreaRef.current.scrollTop = 0;
    setQuoteIndex(i);
    setCurrentConversation(
      trip && trip.conversations.length ? trip.conversations[i] : null
    );
    if (!trip.conversations[i].bid)
      throw new Error(
        `No bid on conversation when trying to render CancelledView`
      );
    setQuote(
      createQuoteFromBid(
        trip.conversations[i].bid!,
        trip.conversations[i].user,
        trip
      )
    );
  };

  return (
    <>
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" />
          Trips
        </PrimaryButton>
        {trip.conversations.length > 1 && (
          <div className="flex gap-2 ml-auto">
            <SecondaryButton
              size="small"
              onClick={() => {
                if (quoteIndex > 0) viewQuote(quoteIndex - 1);
              }}
            >
              <i className="fa-solid fa-chevron-left" />
            </SecondaryButton>
            <SecondaryButton
              size="small"
              onClick={() => {
                if (quoteIndex < trip.conversations.length - 1)
                  viewQuote(quoteIndex + 1);
              }}
            >
              <i className="fa-solid fa-chevron-right" />
            </SecondaryButton>
          </div>
        )}
      </ControlBar>
      <div className="@container flex flex-col gap-4">
        {/* Three column grid layout that collapses to 2 col, then 1 col */}
        {trip.conversations.length ? (
          <>
            <div className="@container">
              <Heading level={2} className="my-8 max-w-prose">
                Viewing {trip.name}
              </Heading>
              <Rule className="mt-4 mb-8" />
              <Alert status="error" message="This trip has been cancelled." />
            </div>
            <PrimaryTripLayout>
              {/** Pilot profile area */}
              {/* Small display version */}
              <div className="h-full">
                <PilotCard
                  pilot={trip.conversations[quoteIndex].user}
                  className="h-full"
                  planeType={trip.planeType}
                  bidNumber={quoteIndex + 1}
                  selected
                  expanded="always"
                  airplane={trip.aircraft}
                />
              </div>
              {/** Chat area */}
              {currentConversation && (
                <div className="h-full flex flex-col gap-4" ref={chatAreaRef}>
                  {/** Quote card */}
                  {trip.conversations.length && currentConversation && quote ? (
                    <QuoteCard quote={quote} className="flex-grow" />
                  ) : (
                    <Heading level={4}>No quotes yet!</Heading>
                  )}
                  <ChatHistory
                    tripId={trip.id}
                    title={
                      currentConversation
                        ? `Chat with ${currentConversation.user.firstName}`
                        : 'Loading'
                    }
                    conversation={currentConversation}
                  />
                </div>
              )}
              {/* Trip and Quote area  */}
              <div className="hidden lg:block h-full" ref={tripAndQuoteAreaRef}>
                {/** Trip card */}
                <TripCard
                  trip={trip}
                  showStatus={false}
                  expanded="always"
                  interactive={false}
                  className="h-full"
                />
              </div>
            </PrimaryTripLayout>
          </>
        ) : null}
        {!trip.conversations.length && (
          <div className="@container">
            <Heading level={2} className="my-8 max-w-prose">
              Viewing {trip.name}
            </Heading>
            <Rule className="mt-4 mb-8" />
            <div className="flex flex-col gap-4 max-w-2xl">
              <Alert status="error" message="This trip has been cancelled." />
              <TripCard trip={trip} expanded="true" />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default CancelledView;
