/* eslint-disable react/destructuring-assignment */
import format from 'date-fns/format';
import { ReactNode, useEffect, useState } from 'react';
import User from '../../../types/User';
import Badge from '../../atoms/Badge/Badge';
import Heading from '../../typography/Heading';
import Paragraph from '../../typography/Paragraph';
import Card from '../Card/Card';
import { useAirports } from '../../../hooks/AirportsProvider';
import { useModalContext } from '../../../hooks/ModalProvider/ModalProvider';
import { usePlaneTypes } from '../../../hooks/PlaneTypesProvider';
import { PrimaryButton } from '../../atoms/Button/Button';
import Avatar from '../Avatar/Avatar';
import PlaneType from '../../../types/PlaneType';
import { useCertificateMetadata } from '../../../hooks/PilotCertificateProvider/CertificateMetadataProvider';
import Label from '../../atoms/Label';
import PilotCertificate from '../../../types/PilotCertificate';
import Loader from '../Loader';
import getAllPilotRatings from '../../../API/Pilot/getAllPilotRatings';
import PilotHistoryFormButton from '../PilotHistoryFormButton/PilotHistoryFormButton';
import qualifiesForOPP from '../../../utilities/qualifiesForOPP';
import { Airplane } from '../../../types/Airplane';
import { ProjectedPilotRating } from '../../../types/ProjectedPilotRating';
import AvatarModal from '../AvatarModal/AvatarModal';

// TODO: gracefully handle when pilotRole.homeLocation is null
interface PilotCardProps {
  pilot: User;
  planeType: PlaneType;
  className?: string;
  children?: ReactNode;
  bidNumber?: number;
  selected?: boolean;
  expanded?: 'true' | 'false' | 'always' | 'never';
  airplane?: Airplane;
  hired?: boolean;
}

function CertificateRatings({
  pilotRatings,
  certificate,
  certificateDisplayName,
}: {
  pilotRatings: ProjectedPilotRating[];
  certificate: PilotCertificate;
  certificateDisplayName: string;
}) {
  const { airTransportCertificateType, commercialCertificateType } =
    useCertificateMetadata();

  const commercialPrivilegesRatings = pilotRatings.filter(
    (pr) => pr.type === 'Commercial Pilot'
  );

  const filteredRatings =
    certificate.type === commercialCertificateType
      ? commercialPrivilegesRatings
      : pilotRatings.filter(
          (pr) => pr.cert === certificate.id && pr.type !== 'Commercial Pilot'
        );

  const formatAbbreviation = (s: string) => {
    const match = s.match(/\(([^)]+)\)/);
    return match ? match[1] : s;
  };

  return (
    <div className="my-2 flex flex-col gap-4 p-4 rounded-md bg-slate-100">
      <div>
        <Label htmlFor={`${certificateDisplayName}-ratings`}>
          {certificateDisplayName}
        </Label>
        <div className="flex gap-2 flex-wrap">
          {filteredRatings.map((r) => (
            <Badge value={formatAbbreviation(r.name)} titleValue={r.name} />
          ))}
        </div>
      </div>
      {!!(
        certificate.type === airTransportCertificateType &&
        commercialPrivilegesRatings.length
      ) && (
        <div>
          <Label htmlFor="commercial-privileges">Commercial Privileges</Label>
          <div className="flex gap-2 flex-wrap">
            {commercialPrivilegesRatings.map((r) => (
              <Badge value={formatAbbreviation(r.name)} titleValue={r.name} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

function PilotCard({
  pilot,
  planeType,
  className,
  children,
  bidNumber,
  selected = true,
  expanded = 'true',
  airplane,
  hired = false,
}: PilotCardProps) {
  const { airports, getAirportFromId } = useAirports();
  const { openModal } = useModalContext();
  const { getTypeRatings, planeTypes, getCraftNameById } = usePlaneTypes();
  const { certificateTypes } = useCertificateMetadata();

  if (!pilot.pilotRole)
    throw new Error(
      `Error rendering PilotCard: user is not pilot: ${pilot.id}`
    );

  const atpCertificate = pilot.pilotRole.licenseCerts.find(
    (l) =>
      l.type ===
      certificateTypes.find((ct) => ct.name === 'Air Transport Pilot')?.id
  );
  const commericalCertificate = pilot.pilotRole.licenseCerts.find(
    (l) =>
      l.type ===
      certificateTypes.find((ct) => ct.name === 'Commercial Pilot')?.id
  );
  const flightInstructorCertificate = pilot.pilotRole.licenseCerts.find(
    (l) =>
      l.type ===
      certificateTypes.find((ct) => ct.name === 'Flight Instructor')?.id
  );

  const [pilotTypeRatings, setPilotTypeRatings] = useState<string[]>([]);

  useEffect(() => {
    if (planeTypes.length) {
      const typeRatingListIds = getTypeRatings().map((tr) => tr.id);
      setPilotTypeRatings(
        pilot.pilotRole.statistics.planeTypeStatistics
          .filter((pts) => typeRatingListIds.includes(pts.planeType))
          .map((pts) => getCraftNameById(pts.planeType) || '')
      );
    }
  }, [
    getCraftNameById,
    getTypeRatings,
    planeTypes,
    pilot.pilotRole.statistics.planeTypeStatistics,
  ]);

  const openAvatarModal = () => {
    if (pilot.avatar?.image) openModal(AvatarModal(pilot, pilot.avatar.image));
  };

  const relevantPlaneTypeStats =
    pilot.pilotRole.statistics.planeTypeStatistics.find(
      (pts) => pts.planeType === planeType?.id
    ) || null;

  const [expandedState, setExpandedState] = useState(expanded);
  const [pilotRatings, setPilotRatings] = useState<ProjectedPilotRating[]>([]);
  const [loadingPilotRatings, setLoadingPilotRatings] = useState(true);

  useEffect(() => {
    const loadPilotRatings = async () => {
      try {
        const pilotRatingsResponse = await getAllPilotRatings(pilot.pilotRole);
        setPilotRatings(pilotRatingsResponse.pilotRatings);
        setLoadingPilotRatings(false);
      } catch (err) {
        console.error(`Unable to retrieve pilot ratings`);
        setLoadingPilotRatings(false);
      }
    };
    loadPilotRatings();
  }, [pilot.pilotRole]);

  return (
    <Card
      responsive
      noPadding
      noGap
      className={`@container ${className} ${
        !selected ? 'grayscale' : 'border-primary border-4'
      }`}
    >
      {bidNumber ? (
        <div className="py-2 background bg-primary rounded-t-lg">
          <Heading
            level={4}
            className="uppercase text-center text-white font-normal py-2"
          >
            Quote #{bidNumber}
          </Heading>
        </div>
      ) : null}
      {/* Top section of card and default view */}
      <div className="relative flex flex-col gap-4 p-4">
        {/* Collapsible below this section */}
        {/* Open Pilot Hours badge */}
        <div>
          {!!airplane && !!pilot && qualifiesForOPP(pilot, airplane) && (
            <Badge
              className="bg-gold text-black border-2 border-black absolute top-2 right-2"
              value="Open Pilot Hours"
              titleValue="This pilot meets the Open Pilot hours based on your aircraft profile."
            />
          )}
        </div>
        <button onClick={openAvatarModal} type="button" className="text-left">
          <Avatar
            initials={`${pilot.firstName[0]}${pilot.lastName[0]}`}
            avatarUrl={pilot.avatar?.image}
          />
        </button>
        <Heading level={3}>
          {hired && <i className="fa-solid fa-star" />} {pilot.firstName}{' '}
          {pilot.lastName}{' '}
        </Heading>
        <Paragraph>
          <span>
            <b>Location: </b>
            {pilot.pilotRole?.city && pilot.pilotRole?.state
              ? `${pilot.pilotRole?.city}, ${pilot.pilotRole?.state}`
              : 'Not provided'}
          </span>
          <br />
          <span>
            <b>Commercial Airport: </b>
            {airports.length &&
              getAirportFromId(pilot.pilotRole?.homeLocation.id).icaoCode}
          </span>
          <br />
          <span>
            <b>Member since: </b>
            {format(new Date(pilot.dateJoined), 'yyyy')}
          </span>
          <br />
        </Paragraph>
        <PilotHistoryFormButton user={pilot} />
      </div>

      {(expandedState === 'true' || expandedState === 'always') && (
        <div>
          {/* Certificates and Ratings, Type Ratings row */}
          <div className="border-t-2">
            <div className="p-4">
              <Heading level={4}>Certificates and Ratings</Heading>
              {!!loadingPilotRatings && (
                <Loader caption="Loading pilot ratings..." />
              )}
              {!!atpCertificate && (
                <CertificateRatings
                  certificate={atpCertificate}
                  certificateDisplayName="ATP"
                  pilotRatings={pilotRatings}
                />
              )}
              {!!commericalCertificate && (
                <CertificateRatings
                  certificate={commericalCertificate}
                  certificateDisplayName="COMM"
                  pilotRatings={pilotRatings}
                />
              )}
              {!!flightInstructorCertificate && (
                <CertificateRatings
                  certificate={flightInstructorCertificate}
                  certificateDisplayName="CFI"
                  pilotRatings={pilotRatings}
                />
              )}
            </div>
            <div className="p-4">
              <Heading level={4}>
                Type Ratings / Aircraft Qualifications
              </Heading>
              <div className="flex flex-wrap gap-2 mt-1">
                {pilotTypeRatings.map((tr) => <Badge value={tr || ''} />) ||
                  'None provided.'}
              </div>
            </div>
          </div>
          {/* Hours and About Me row */}
          <div className="border-t-2">
            <div className="p-4">
              <Heading level={4}>Hours</Heading>
              <Paragraph>
                {relevantPlaneTypeStats && planeType ? (
                  <>
                    <span>Total: {pilot.pilotRole?.allAircraftHours}</span>
                    <br />
                    <span>
                      Multi-engine: {pilot.pilotRole?.multiEngineHours}
                    </span>
                    <br />
                    <span>Turbine: {pilot.pilotRole.turbineHours}</span>
                    <br />
                    <span>Turbojet: {pilot.pilotRole?.turbojetHours}</span>
                    <br />
                    <span>
                      {planeType.craftType} (TT):{' '}
                      {relevantPlaneTypeStats.timeInCraft}
                    </span>
                    <br />
                    <span>
                      {planeType.craftType} (12m):{' '}
                      {relevantPlaneTypeStats.lastTwelveMonths}
                    </span>
                    <br />
                    <span>
                      {planeType.craftType} (90d):{' '}
                      {relevantPlaneTypeStats.lastNinetyDays}
                    </span>
                    <br />
                  </>
                ) : null}
              </Paragraph>
            </div>
            <div className="p-4">
              <Heading level={4}>About Me</Heading>
              <Paragraph>
                {pilot.pilotRole?.description || 'None provided.'}
              </Paragraph>
            </div>
          </div>
          {/* Optional extra content */}
          {children}
        </div>
      )}
      {expandedState !== 'always' && expandedState !== 'never' && (
        <PrimaryButton
          className="w-full rounded-t-none mt-auto"
          onClick={() =>
            setExpandedState(expandedState === 'true' ? 'false' : 'true')
          }
        >
          <i
            className={`fa-solid fa-angle-down ${
              expandedState === 'true' && 'rotate-180'
            }`}
          />
          &nbsp;Details&nbsp;
          <i
            className={`fa-solid fa-angle-down ${
              expandedState === 'true' && 'rotate-180'
            }`}
          />
        </PrimaryButton>
      )}
    </Card>
  );
}

export default PilotCard;
