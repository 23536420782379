import { useEffect, useState } from 'react';
import PilotCertificate from '../../types/PilotCertificate';
import FormField from '../../components/wrappers/FormField';
import Label from '../../components/atoms/Label';
import Input from '../../components/atoms/Input';
import Card from '../../components/molecules/Card/Card';
import DayPicker from '../../components/molecules/DayPicker/DayPicker';
import FileSelect from '../../components/atoms/FileSelect/FileSelect';
import Heading from '../../components/typography/Heading';
import Checkbox from '../../components/atoms/Checkbox';
import updatePilotCertificate from '../../API/Pilot/updatePilotCertificate';
import CertificateRatingOption from '../../types/CertificateRatingOption';
import { StateButton } from '../../components/atoms/Button/Button';
import createLimitOption from '../../API/Pilot/createLimitOption';
import deletePilotCertificate from '../../API/Pilot/deletePilotCertificate';
import SettingsCard from '../../components/molecules/Card/SettingsCard';
import { useToast } from '../../hooks/ToastProvider';
import removeLimitOnPilotCertificate from '../../API/Pilot/removeLimitOnPilotCertificate';
import { usePilotCertificates } from '../../hooks/PilotCertificateProvider/PilotCertificateProvider';
import { useCertificateMetadata } from '../../hooks/PilotCertificateProvider/CertificateMetadataProvider';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import RatingSelect from './RatingSelect';
import TypeRatingSelect from './TypeRatingSelect';
import addNewRatingOnPilotCertificate from '../../API/Pilot/addNewRatingOnPilotCertificate';
import deletePilotCertificateRating from '../../API/Pilot/deletePilotCertificateRating';
import LimitationSelect from './LimitationSelect';
import addPilotCertificateLimit from '../../API/Pilot/addPilotCertificateLimit';
import Loader from '../../components/molecules/Loader';

interface CertificateProps {
  certificate: PilotCertificate;
  certificateName: string;
}

/**
 * Render a single certificate
 */
function Certificate({ certificate, certificateName }: CertificateProps) {
  const { addToast } = useToast();
  const { user, refreshUser } = useUser();
  const {
    certificateTypes,
    limitsOptions,
    refreshCertificateOptions,
    commercialCertificateType: commercialRatingType,
  } = useCertificateMetadata();
  const {
    refreshPilot,
    transformCraftRatingsToCertificateRatingOptionIds,
    pilotRatings,
  } = usePilotCertificates();

  const DEBUG_CERT_UI = false;

  const [state, setState] = useState({
    ...certificate,
    craftRatings: transformCraftRatingsToCertificateRatingOptionIds(
      certificate.craftRatings
    ),
    limits: certificate.limits
      .map((l) => limitsOptions.find((lo) => lo.id === l.limit)?.name)
      .filter((l) => l !== undefined),
  });

  useEffect(() => {
    setState({
      ...certificate,
      craftRatings: transformCraftRatingsToCertificateRatingOptionIds(
        certificate.craftRatings
      ),
      limits: certificate.limits
        .map((l) => limitsOptions.find((lo) => lo.id === l.limit)?.name)
        .filter((l) => l !== undefined),
    });
  }, [
    certificate,
    certificateName,
    limitsOptions,
    transformCraftRatingsToCertificateRatingOptionIds,
  ]);

  const [disabled, setDisabled] = useState(true);
  const [saving, setSaving] = useState(false);

  const certificateType = certificateTypes.find(
    (ct) => ct.id === certificate.type
  );

  let certificateAbbreviation = 'ATP';
  if (certificateName === 'Commercial Pilot') certificateAbbreviation = 'COMM';
  if (certificateName === 'Flight Instructor') certificateAbbreviation = 'CFI';

  if (!certificateType) {
    throw new Error(
      `Unable to find certificate type in certificateTypes for given certificate.id ${certificate.id}`
    );
  }

  if (!user)
    throw new Error(
      `User is null. Only render Certificate when User is loaded.`
    );
  if (!user.pilotRole)
    throw new Error(
      `User has no pilot role. Only render Certificate for Pilot users.`
    );

  if (!certificateType) {
    throw new Error(`Invalid certificate type, cert id: ${certificate.id}`);
  }

  function updateProperty<T>(prop: keyof typeof certificate, value: T) {
    setState({ ...state, [prop]: value });
  }

  const handleAddCertificateFront = (file: File | null) => {
    if (!file) {
      addToast(<span>Invalid file type or contents.</span>, 'error');
    } else {
      updateProperty('license', {
        ...state.license,
        front: file,
      });
    }
  };

  const handleAddCertificateBack = (file: File | null) => {
    if (!file) {
      addToast(<span>Invalid file type or contents.</span>, 'error');
    } else {
      updateProperty('license', {
        ...state.license,
        back: file,
      });
    }
  };

  const handleUpdateTypeRatings = (
    typeRatings: PilotCertificate['typeRatings']
  ) => {
    updateProperty('typeRatings', typeRatings);
  };

  const handleSave = async () => {
    setDisabled(true);
    setSaving(true);

    /** Ratings */

    /**
     * In this context, state.craftRatings is *not* a list of
     * PilotCertRating record IDs. It's been transformed into a list
     * of CertificateRatingOption IDs. We have to transform it back
     * before saving the certificate.
     *
     * To do that, we need to figure out which craft ratings are new,
     * which are removed, and perform the preliminary operations
     * accordingly.
     *
     */

    const staleCraftRatingsAsRecordIds = certificate.craftRatings;
    const staleCraftRatingsAsOptionIds = staleCraftRatingsAsRecordIds
      .map((craftRatingRecordId) => {
        const matchingPilotRating = pilotRatings.find(
          (pr) => pr.id === craftRatingRecordId
        );
        if (matchingPilotRating) return matchingPilotRating.rating;
        return undefined;
      })
      .filter((p) => p !== undefined);

    // Add the ratings that weren't new, or removed
    let pilotCertRatingIdsToSave = [...staleCraftRatingsAsRecordIds];

    /**
     * Ok, now we have the stale version of the selected craft ratings
     * as staleCraftRatingsAsOptionIDs. The next step is finding the new
     * options. This should just be the current state ones minus any
     * that were already present in the stale list.
     */
    const newRatingOptionIdsSelected = state.craftRatings.filter(
      (craftRatingOptionId) =>
        !staleCraftRatingsAsOptionIds.includes(craftRatingOptionId)
    );

    /**
     * Now we just need to get the removed ones. This will be the
     * stale list, minus any in the current state list.
     */
    const removedRatingOptionIds = staleCraftRatingsAsOptionIds.filter(
      (staleCraftRatingId) => !state.craftRatings.includes(staleCraftRatingId!)
    );

    if (newRatingOptionIdsSelected.length) {
      // Create new records for each new rating option selected,
      // and add each resulting id to ratingIdsToSave
      const newRatingsPromises = newRatingOptionIdsSelected.map(
        (newRatingOptionId) => {
          if (!certificate.id) {
            setSaving(false);
            throw new Error(`No id on certificate`);
          }
          if (DEBUG_CERT_UI)
            console.log(`Adding new rating ${newRatingOptionId}`);
          return addNewRatingOnPilotCertificate(
            newRatingOptionId,
            certificate.id
          );
        }
      );
      const newRatingsRecords = await Promise.all(newRatingsPromises);
      pilotCertRatingIdsToSave = [
        ...pilotCertRatingIdsToSave,
        ...newRatingsRecords.map((r) => r.id),
      ];
    }

    if (removedRatingOptionIds.length) {
      // Find all the PilotCertRating IDs matching the removed options
      const ratingIdsToRemove = removedRatingOptionIds
        .map((r) => pilotRatings.find((pr) => pr.rating === r)?.id)
        .filter((r) => r !== undefined);

      pilotCertRatingIdsToSave = pilotCertRatingIdsToSave.filter(
        (crId) => !ratingIdsToRemove.includes(crId)
      );

      const deleteRatingsPromises = ratingIdsToRemove.map((ratingId) => {
        if (DEBUG_CERT_UI) console.log(`Deleting rating ${ratingId}`);
        return deletePilotCertificateRating(ratingId!);
      });
      await Promise.all(deleteRatingsPromises);
    }

    /** Limits */

    const newLimits = state.limits.filter((limitName) => {
      const matchingLimitOption = limitsOptions.find(
        (lo) => lo.name === limitName
      );
      if (!matchingLimitOption) return true;
      const matchingCurrentCertLimit = certificate.limits.find(
        (limit) => limit.limit === matchingLimitOption.id
      );
      if (!matchingCurrentCertLimit) return true;
      return false;
    });

    const removedLimits: string[] = [];

    certificate.limits.forEach((certLimit) => {
      const matchingLimitOption = limitsOptions.find(
        (lo) => lo.id === certLimit.limit
      );
      if (!matchingLimitOption) {
        setSaving(false);
        throw new Error(
          `No matching limit option for limit ID: ${certLimit.limit}`
        );
      }
      if (!state.limits.includes(matchingLimitOption.name)) {
        removedLimits.push(matchingLimitOption.name);
      }
    });

    if (DEBUG_CERT_UI) console.log({ newLimits, removedLimits });

    const addNewLimitsPromises: Promise<unknown>[] = [];

    // Add new limits
    for (let i = 0; i < newLimits.length; i++) {
      let matchingLimitOption = limitsOptions.find(
        (lo) => lo.name === newLimits[i]
      );
      if (matchingLimitOption === undefined) {
        // This must be a new limit option
        // Add it via the API

        if (DEBUG_CERT_UI) console.log(`Creating limit option ${newLimits[i]}`);

        // eslint-disable-next-line no-await-in-loop
        const newLimitOption = await createLimitOption(
          newLimits[i] as string,
          user.pilotRole.id
        );
        if (!newLimitOption) {
          setSaving(false);
          throw new Error(`Unable to create new limit option: ${newLimits[i]}`);
        }

        matchingLimitOption = newLimitOption;
      }
      if (!matchingLimitOption) {
        setSaving(false);
        throw new Error(`Unable to add limit: ${newLimits[i]}`);
      }

      if (DEBUG_CERT_UI)
        console.log(`Adding limit to certificate ${matchingLimitOption.name}`);

      addNewLimitsPromises.push(
        addPilotCertificateLimit(matchingLimitOption.id, certificate.id!)
      );
    }

    // Add the new limits on the pilot certificate
    await Promise.all(addNewLimitsPromises);

    // Remove limits
    if (removedLimits.length) {
      const removeLimitsPromises = removedLimits.map((limitValue) => {
        const matchingLimitOption = limitsOptions.find(
          (lo) => lo.name === limitValue
        );
        if (!matchingLimitOption) {
          setSaving(false);
          throw new Error(
            `Unable to remove limit; can't find limit option for ${limitValue}`
          );
        }
        const matchingLimit = certificate.limits.find(
          (limit) => limit.limit === matchingLimitOption.id
        );
        if (!matchingLimit) {
          setSaving(false);
          throw new Error(
            `Unable to remove limit; can't find certificate limit for matching option ID: ${matchingLimitOption.id}`
          );
        }
        if (DEBUG_CERT_UI) console.log(`Removing limit ${matchingLimit.id}`);
        return removeLimitOnPilotCertificate(matchingLimit.id);
      });
      await Promise.all(removeLimitsPromises);
    }

    if (DEBUG_CERT_UI) console.log(`Type ratings: ${state.typeRatings}`);

    if (DEBUG_CERT_UI) console.log({ state });

    const response = await updatePilotCertificate(user.pilotRole, {
      ...state,
      craftRatings: pilotCertRatingIdsToSave,
      limits: [],
    });

    if (response?.statusCode !== 200) {
      setSaving(false);
      throw new Error(
        `Error while updating certificate: ${response?.statusText}`
      );
    }
    await refreshCertificateOptions();
    await refreshUser();
    setSaving(false);

    addToast(<span>Saved certificate changes.</span>, 'success');
  };

  const handleAddRating = (ratingOptionId: CertificateRatingOption['id']) => {
    updateProperty('craftRatings', [...state.craftRatings, ratingOptionId]);
  };

  const handleRemoveRating = (
    ratingOptionId: CertificateRatingOption['id']
  ) => {
    updateProperty(
      'craftRatings',
      state.craftRatings.filter((cr) => cr !== ratingOptionId)
    );
  };

  return (
    <SettingsCard
      disabled={disabled}
      onCancel={() => {
        setDisabled(true);
        refreshPilot();
      }}
      onEdit={() => setDisabled(false)}
      onSave={handleSave}
      responsive
      title={certificateName}
    >
      {!!saving && (
        <div className="absolute z-10 left-0 top-0 right-0 bottom-0 bg-white/90 rounded-2xl">
          <Loader
            caption="Saving certificate..."
            level={2}
            className="mt-2 flex justify-center"
          />
        </div>
      )}
      <fieldset disabled={disabled}>
        <FormField>
          <Label htmlFor="certificateNumber">Certificate Number</Label>
          <Input
            id="certificateNumber"
            value={state.certificateNumber}
            onChange={(e) =>
              updateProperty('certificateNumber', e.target.value)
            }
          />
        </FormField>
        <FormField>
          <Label htmlFor="issueDate">Issue Date</Label>
          <DayPicker
            value={state.issueDate ? new Date(state.issueDate) : null}
            id="issueDate"
            onChange={(newDate) =>
              updateProperty('issueDate', newDate.toISOString())
            }
          />
        </FormField>
      </fieldset>
      <Card responsive className="flex-grow-2 flex flex-col gap-4 rounded-md">
        <Heading level={4}>Upload Certificate</Heading>
        <div className="flex flex-col md:flex-row gap-4">
          <div>
            <Label htmlFor="front">Front Side</Label>
            <FileSelect
              disabled={disabled}
              onChange={handleAddCertificateFront}
              value={state.license?.front || null}
              id="front"
            />
          </div>
          <div>
            <Label htmlFor="back">Back Side</Label>
            <FileSelect
              disabled={disabled}
              onChange={handleAddCertificateBack}
              value={state.license?.back || null}
              id="back"
            />
          </div>
        </div>
      </Card>
      {certificateName === 'Air Transport Pilot' && (
        <div className="flex align-middle gap-2">
          <Heading level={4}>Commercial Privileges?</Heading>
          <Checkbox
            id="commercial-privileges"
            value={state.commercialPrivileges}
            onChange={() =>
              updateProperty(
                'commercialPrivileges',
                !state.commercialPrivileges
              )
            }
            disabled={disabled}
          />
        </div>
      )}
      <div className="grid grid-cols-1 gap-2 w-full">
        <RatingSelect
          className="w-full"
          disabled={disabled}
          certificateType={certificate.type}
          addRating={handleAddRating}
          removeRating={handleRemoveRating}
          ratings={state.craftRatings}
          label={`Add ${certificateAbbreviation} Rating(s)`}
        />
        {state.commercialPrivileges && commercialRatingType && (
          <RatingSelect
            className="w-full"
            disabled={disabled}
            certificateType={commercialRatingType}
            addRating={handleAddRating}
            removeRating={handleRemoveRating}
            ratings={state.craftRatings}
            label="Add Commercial Privilege(s)"
          />
        )}
        {/* “Type Ratings” only apply to ATP & Commercial Certificates.
        We do not need to list them under the Flight Instructor Certificate */}
        {certificateName !== 'Flight Instructor' && (
          <TypeRatingSelect
            className="w-full"
            disabled={disabled}
            pilotTypeRatings={state.typeRatings}
            updateTypeRatings={handleUpdateTypeRatings}
          />
        )}
        <LimitationSelect
          disabled={disabled}
          limitValues={state.limits as string[]}
          updateLimits={(newState) => updateProperty('limits', newState)}
        />
      </div>
      <div className="flex w-full justify-end">
        <StateButton
          status="error"
          onClick={async () => {
            await deletePilotCertificate(certificate.id as number);
            await refreshUser();
          }}
          disabled={disabled}
        >
          Delete Certificate
        </StateButton>
      </div>
    </SettingsCard>
  );
}

export default Certificate;
