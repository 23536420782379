import React from 'react';

function FlightIconConfirmed() {
  return (
    <svg width="46px" height="48px" viewBox="0 0 46 21" version="1.1">
      <g
        id="Symbols"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="plane-status/confirmed/desktop"
          transform="translate(-15.000000, -27.000000)"
          fill="#09B2CF"
        >
          <g id="Page-1">
            <path
              d="M60.1252459,34.2147859 C59.6126928,32.3182166 57.6508984,31.1883822 55.7361672,31.6976656 L44.8402,34.5981908 L32.3002984,28.6859476 C31.6522413,28.21472 29.2397148,26.9620117 28.326577,27.2107716 L25.1128361,28.071241 C24.8565633,28.138556 24.6533036,28.3492901 24.600283,28.6097837 C24.5413652,28.8731994 24.6444656,29.1424745 24.8565557,29.3151603 L34.9162279,37.2410099 L24.1318672,40.1093162 L17.4568902,36.2136765 C17.2919311,36.1170872 17.1034066,36.0936723 16.914882,36.1375799 L15.5274918,36.5034368 C15.3006741,36.5619778 15.1209875,36.7346561 15.0444013,36.9541717 C14.961918,37.1707577 15.0002111,37.41954 15.1357151,37.6068592 L22.2466364,47.4965632 C22.3821403,47.6838824 22.60012,47.795105 22.8269377,47.795105 C22.8946859,47.795105 22.9565521,47.7863235 23.0154623,47.7716901 L57.5923803,38.563828 C59.5011541,38.0604039 60.6323016,36.1111455 60.1256984,34.2145012 L60.1252459,34.2147859 Z"
              id="Stroke-2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default FlightIconConfirmed;
