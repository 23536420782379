// Import shared app components and types
import Conversation from '../../../types/Conversation';
import { FinalExpenses } from '../../../types/FinalExpenses';
import Card from '../../molecules/Card/Card';
import Heading from '../../typography/Heading';

function GrandTotalCard({
  cost,
  expenses,
  className = '',
}: {
  cost: Conversation['cost'];
  expenses: FinalExpenses;
  className?: string;
}) {
  const { estimation } = cost;
  const { flightDays, travelDays } = estimation;
  const pilotFlyingRate = estimation.pricePerDay.toFixed(2);
  const flyingCost = estimation.flyingCost.toFixed(2);
  const pilotTravelDayRate = estimation.travelDays
    ? estimation.travelCost / estimation.travelDays
    : 0;
  const travelCost = estimation.travelCost.toFixed(2);
  const dayPerDiem = estimation.dayDiem.toFixed(2);
  const tripDuration = flightDays + travelDays;
  const perDiemTotal = estimation.diem.toFixed(2);
  const serviceFee =
    estimation.alteredPlatformFee.toFixed(2) ??
    estimation.platformFee.toFixed(2);
  const totalCost = estimation.totalCharge.toFixed(2);

  return (
    <Card responsive noPadding className={className}>
      <div className="py-2 background bg-primary rounded-t-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          Total Trip Cost
        </Heading>
      </div>
      <div className="flex flex-col p-4 gap-2">
        <div className="flex">
          <span>
            Flying Day-Rate ({flightDays} days, ${pilotFlyingRate}
            /day):
          </span>
          <span className="ml-auto font-semibold">${flyingCost}</span>
        </div>
        <div className="flex">
          <span>
            Travel Day-Rate ({travelDays} days
            {pilotTravelDayRate
              ? `, $${pilotTravelDayRate.toFixed(2)}/day)`
              : ')'}
            :
          </span>
          <span className="ml-auto font-semibold">${travelCost}</span>
        </div>
        <div className="flex">
          <span>
            Per-Diem ({tripDuration} days, ${dayPerDiem}/day):
          </span>
          <span className="ml-auto font-semibold">${perDiemTotal}</span>
        </div>
        <div className="flex">
          <span>Service Fee:</span>
          <span className="ml-auto font-semibold">${serviceFee}</span>
        </div>
        <Heading level={4} className="underline font-normal pt-2">
          Final Expenses:
        </Heading>
        {expenses.itemizedExpenses.map((expense) => (
          <div className="flex">
            <span>{expense.title}:</span>
            <span key={expense.id} className="ml-auto font-semibold">
              {expense.cost.toFixed(2)}
            </span>
          </div>
        ))}
      </div>
      <div className="mt-auto py-2 background bg-primary rounded-b-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          Grand Total: ${totalCost}
        </Heading>
      </div>
    </Card>
  );
}

export default GrandTotalCard;
