import { useCallback, useEffect, useRef, useState } from 'react';
import { SecondaryButton } from '../../atoms/Button/Button';
import Input from '../../atoms/Input';
import Card from '../../molecules/Card/Card';
import Loader from '../../molecules/Loader';
import Heading from '../../typography/Heading';
import User from '../../../types/User';
import Message from '../../../types/Message';
import Conversation from '../../../types/Conversation';
import createNewMessage from '../../../API/Trips/createNewMessage';
import Trip from '../../../types/Trip';
import { useUser } from '../../../hooks/UserProvider/UserProvider';
import getTripAsConsumer from '../../../API/Trips/getTripAsConsumer';
import getTripAsPilot from '../../../API/Trips/getTripAsPilot';
import ChatMessageBubble from './ChatMessageBubble';
import Rule from '../../atoms/Rule';

interface ConversationProps {
  className?: string;
  title?: string;
  user: User;
  conversation: Conversation;
  tripId: Trip['id'];
}

function ConversationView({
  className,
  title,
  user,
  conversation,
  tripId,
}: ConversationProps) {
  const [message, setMessage] = useState('');
  const chatRef = useRef<HTMLDivElement>(null);
  const { role } = useUser();

  const [messages, setMessages] = useState<Conversation['messages']>(
    conversation?.messages || []
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setMessages(conversation?.messages || []);
  }, [conversation]);

  // Scroll to bottom of chat
  const scrollToBottom = useCallback(() => {
    if (chatRef.current) {
      chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }
  }, [chatRef]);

  const sendMessage = async () => {
    setLoading(true);
    if (user && conversation) {
      const response = await createNewMessage({
        content: message,
        trip: tripId,
        recipient: conversation.user?.id,
      });
      if (response.message && response.statusCode === 201) {
        // Update conversation
        setMessages((prev) => {
          setMessage('');
          return [...prev, response.message as Message];
        });
      }
      setLoading(false);
    }
  };

  // Scroll to bottom when there are new messages
  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  // Load trip and ping for chat updates
  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (conversation && conversation.messages.length > 0) {
      interval = setInterval(async () => {
        const updatedTrip =
          role === 'consumer'
            ? await getTripAsConsumer(tripId.toString())
            : await getTripAsPilot(tripId.toString());

        if (updatedTrip.conversations[0].messages.length > messages.length) {
          setMessages(updatedTrip.conversations[0].messages || []);
        }
      }, 15000);
    }

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <Card responsive noPadding noGap className={`${className || ''}`}>
      <div className="py-2 background bg-primary rounded-t-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          {title || 'Chat'}
        </Heading>
      </div>
      <div
        ref={chatRef}
        className="w-full flex flex-col gap-4 p-4 max-h-[480px] overflow-y-scroll"
      >
        {user ? (
          messages.map((m) => (
            <ChatMessageBubble
              message={m}
              user={user}
              otherUser={conversation.user}
            />
          ))
        ) : (
          <Loader caption="Loading user..." />
        )}
      </div>

      <div className="inline-flex p-2">
        <Input
          value={message}
          onKeyDown={(e) => {
            if (e.key === 'Enter') sendMessage();
          }}
          onChange={(e) => setMessage(e.target.value)}
          type="text"
          className="w-full !max-w-none p-2 border border-gray-300 rounded-lg"
          placeholder="Type a message..."
        />
        <SecondaryButton
          isLoading={loading}
          onClick={sendMessage}
          className="ml-2"
        >
          Send
        </SecondaryButton>
      </div>
      <Rule className="hidden lg:block" />
    </Card>
  );
}

export default ConversationView;
