import { Airplane } from '../../types/Airplane';
import { type AirplaneBaseAttributes } from '../../types/AirplaneAttributes/AirplaneBaseAttributes';
import { AnyObject } from '../../types/util';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';
import { endpoints } from '../endpoints';
import mappedAircraft from './util/mappedAircraft';

export default async function createAircraft(
  airplane: Required<Omit<AirplaneBaseAttributes, 'consumer' | 'id'>>
) {
  try {
    const response = await fetch(`${endpoints.AIRCRAFT}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      body: JSON.stringify(airplane),
    });

    if (response.status !== 201) {
      throw new Error('Unable to get aircraft data');
    } else {
      const aircraft = await response.json();

      console.log('new returned Aircraft', aircraft);

      return mappedAircraft(aircraft);
    }
  } catch (e) {
    console.error(e);
    return null;
  }
}
