import { ReactNode } from 'react';
import Tooltip from '../Tooltip/Tooltip';

interface InfoProps {
  className?: string;
  children: ReactNode;
}

function Info({ className = '', children }: InfoProps) {
  return (
    <Tooltip
      trigger={
        <i
          className={`text-white bg-primary fa-solid fa-question border-primary border-2 rounded-full p-1 px-[7px] absolute -top-1 ${className}`}
        />
      }
    >
      {children}
    </Tooltip>
  );
}

export default Info;
