import React, { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import FormikDropdown, { Option } from '../../../atoms/Form/FormikDropdown';
import { AirplaneMake } from '../../../../types/AirplaneMake';
import { Airplane } from '../../../../types/Airplane';

interface AircraftTypeProps {
  planeMakes: AirplaneMake[];
}

function AircraftType({ planeMakes }: AircraftTypeProps) {
  const {
    values: { make },
    touched,
    setFieldValue,
  } = useFormikContext<Airplane>();
  const planeMakesAllowed = planeMakes.filter((mk) => mk.allowedForOperators);
  const [planeModels, setPlaneModels] = useState<Option[]>([]);

  useEffect(() => {
    if (!!make && touched.make) {
      const chosenMake = planeMakes.find((mk) => mk.id === make);

      if (chosenMake) {
        setPlaneModels(
          chosenMake.aircraftModels.map((model) => ({
            label: model.craftName,
            value: model.id,
          }))
        );
      }
    }
  }, [planeMakes, make, touched.make]);

  return (
    <>
      <FormikDropdown
        name="make"
        label="Make"
        placeholder="Choose your Make"
        options={planeMakesAllowed.map(({ manufacturerName, id }) => ({
          label: manufacturerName,
          value: id,
        }))}
        optional={false}
      />
      <FormikDropdown
        name="model"
        label="Model"
        placeholder="Choose your Model"
        options={planeModels}
        optional={false}
      />
    </>
  );
}

export default AircraftType;
