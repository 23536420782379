import React from 'react';

import { PaymentMethod } from '@stripe/stripe-js';
import { FormikHelpers } from 'formik';

import Heading from '../../../components/typography/Heading';
import Conversation from '../../../types/Conversation';
import Trip from '../../../types/Trip';
import ChargesDueUponHire from './ChargesDueUponHire';
import signAgreement from '../../../API/Trips/signAgreement';
import ConfirmHireForm, {
  ConfirmHireFields,
} from '../subcomponents/ConfirmHireForm/ConfirmHireForm';
import Bid from '../../../types/Bid';
import GrandTotalEstimateCard from '../../../components/organisms/GrandTotalEstimateCard/GrandTotalEstimateCard';
import CreditCard from '../../../components/organisms/PaymentMethodsList/CreditCard';

import { PrimaryButton } from '../../../components/atoms/Button/Button';

function ConfirmHireModal({
  conversation,
  trip,
  onCancel,
  onConfirm,
  defaultPaymentMethod,
  navigateToCards,
}: {
  conversation: Conversation;
  trip: Trip;
  onCancel: () => unknown;
  onConfirm: () => unknown;
  defaultPaymentMethod?: PaymentMethod;
  navigateToCards: () => unknown;
}) {
  const { bid, cost, user } = conversation;
  const pilotName = `${user.firstName} ${user.lastName}`;

  const handleConfirmHire = async (
    values: ConfirmHireFields,
    helpers: FormikHelpers<ConfirmHireFields>
  ) => {
    try {
      helpers.setSubmitting(true);

      if (values.signature instanceof File) {
        await signAgreement(trip.id, values.signature, user.id.toString());
        await onConfirm();
      } else {
        helpers.setFieldError('signature', 'Please sign the agreement');
      }
      helpers.setSubmitting(false);
    } catch (error) {
      helpers.setSubmitting(false);
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col gap-4 mb-24">
      <Heading level={3}>
        Accept {pilotName}&apos;s offer to be your pilot for this trip?
      </Heading>
      <div className="w-full flex flex-col gap-4">
        <GrandTotalEstimateCard
          isQuoted
          cost={cost}
          expenses={(bid as Bid).quotedExpenses}
        />
        <ChargesDueUponHire cost={cost} />
        {defaultPaymentMethod && (
          <div>
            <Heading level={4}>Default Payment Method</Heading>
            <CreditCard card={defaultPaymentMethod.card!}>
              {/* Display default payment method if available */}
              <PrimaryButton
                className="bg-white"
                outline
                size="small"
                onClick={navigateToCards}
              >
                Change
              </PrimaryButton>
            </CreditCard>
          </div>
        )}

        <ConfirmHireForm
          handleSubmit={handleConfirmHire}
          handleCancel={onCancel}
          bidId={bid?.id as number}
          isBid
        />
      </div>
    </div>
  );
}

export default ConfirmHireModal;
