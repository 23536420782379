import * as Yup from 'yup';
import i18n from '../../../../strings/i18n';

const validAircraftOperatingProceduresSchema = Yup.object({
  operatingProcedures: Yup.object().shape({
    mandatoryDocumentStorageLocation: Yup.string().optional(),
    spareKeyLocation: Yup.string().optional(),
    fixedBaseOperatorPreference: Yup.string().optional(),
    flightLogProcedures: Yup.string().optional(),
    dressAndAppearance: Yup.string().required('Required'),
    catering: Yup.string().optional(),
    isCaaMember: Yup.boolean(),
    fuelCards: Yup.array()
      .required()
      .of(
        Yup.object().shape({
          cardName: Yup.string().required('Required'),
          isPreferred: Yup.boolean(),
        })
      )
      // only one fuel card is default
      .test(
        'only-one-default',
        'Only one fuel card can be default',
        (value) => {
          const defaultFuelCards =
            value?.filter((fuelCard) => fuelCard.isPreferred) || [];
          return !(defaultFuelCards?.length > 1);
        }
      ),
    deletedFuelCards: Yup.array().of(Yup.number()).optional(),
  }),
});

export default validAircraftOperatingProceduresSchema;
