import React from 'react';
import { ROUTES } from '../routerConfig';
import Heading from '../../components/typography/Heading';
import Paragraph from '../../components/typography/Paragraph';
import Section from '../../components/wrappers/Section';
import Link from '../../components/atoms/Link';

export default function PrivacyPolicyPage() {
  return (
    <>
      <Heading level={1}>Privacy Policy</Heading>
      <div>
        <Section className="mb-12">
          <Paragraph>
            Flying Company, LLC provides this Privacy Policy to let you know our
            policies and procedures regarding the collection, use and disclosure
            of information through www.flyingcompany.com (the &ldquo;
            <span className="font-bold">Site</span>&rdquo;), and any other
            websites, features, applications, widgets or online services that
            are owned or controlled by Flying Company, LLC and that post a link
            to this Privacy Policy (together with the Site, the &ldquo;
            <span className="font-bold">Service</span>&rdquo;), as well as any
            information Flying Company, LLC collects offline in connection with
            the Service. It also describes the choices available to you
            regarding the use of, your access to, and how to update and correct
            your personal information.
            <span className="italic">.</span>{' '}
            <span className="font-bold">
              Note that we combine the information we collect from you from the
              Site, through the Service generally, or offline.
            </span>
          </Paragraph>
          <Paragraph>
            By accessing or using the Service, you consent to the information
            collection, disclosure and use practices described in this Privacy
            Policy. Please note that certain features or services referenced in
            this Privacy Policy may not be offered on the Service at all times.
            Please also review our{' '}
            <Link to={ROUTES.TERMS_OF_SERVICE}>Terms of Service</Link>, which
            governs your use of the Service, and which is accessible at{' '}
            <Link to={ROUTES.TERMS_OF_SERVICE}>
              www.flyingcompany.com/terms
            </Link>
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2}>Table of Contents</Heading>
          <ol className="list-decimal">
            <li>
              <Heading level={3}>
                <Link relative href="#InformationCollection">
                  Information Collection
                </Link>
              </Heading>
            </li>
            <ul className="pl-8">
              <li className="font-bold">Information You Provide to Us</li>
              <ul className="pl-8">
                <li>Personal Information</li>
                <li>Payment Information</li>
                <li>Identity Verification</li>
                <li>General Audience Service</li>
                <li>Non-Identifying Information</li>
                <li>Combination of Personal and Non-Identifying Information</li>
                <li>Collection of the Third Party Personal Information</li>
              </ul>
              <li>
                <span className="font-bold">
                  Information Received from Third Parties
                </span>
              </li>
              <li>
                <span className="font-bold">
                  Information Collected from Users Automatically
                </span>
              </li>
              <ul className="pl-8">
                <li>Cookies</li>
                <li>Web Beacons</li>
                <li>Embedded Scripts</li>
              </ul>
              <li>
                <span className="font-bold">
                  How We Respond to Do Not Track Signals
                </span>
              </li>
              <li>
                <span className="font-bold">Work Diaries and Work View</span>
              </li>
              <li>
                <span className="font-bold">User Profiles</span>
              </li>
              <li>
                <span className="font-bold">Community Forums</span>
              </li>
              <li>
                <span className="font-bold">Testimonials</span>
              </li>
              <li>
                <span className="font-bold">
                  Work Listings Through the Service
                </span>
              </li>
              <li>
                <span className="font-bold">Feedback</span>
              </li>
              <li>
                <span className="font-bold">
                  Email to Friends and Referral Program
                </span>
              </li>
              <li>
                <span className="font-bold">Social Networking Services</span>
              </li>
            </ul>
            <li className="font-bold mt-4">
              <Heading level={3}>
                <Link relative href="#Use and Retention of Information">
                  Use and Retention of Information
                </Link>
              </Heading>
            </li>
            <li className="font-bold mt-4">
              <Heading level={3}>
                <Link relative href="#Information Sharing and Disclosure">
                  Information Sharing and Disclosure
                </Link>
              </Heading>
            </li>
            <ul className="pl-8">
              <li className="font-bold">
                Information about Pilots Shared with Clients and Agencies or
                Teams
              </li>
              <li className="font-bold">
                Information about Clients and Agencies Shared with Pilots
              </li>
              <li className="font-bold">Service Providers</li>
              <li className="font-bold">
                What Happens if You Agree to Receive Information from Third
                Parties or Request That We Share Your Information
              </li>
              <li className="font-bold">Legal and Investigative Purposes</li>
              <li className="font-bold">Internal and Business Transfers</li>
              <li className="font-bold">
                Sweepstakes, Contests and Promotions
              </li>
            </ul>
            <li className="mt-4">
              <Heading level={3}>
                <Link
                  relative
                  href="#Third Party Analytics Providers, Ad Servers and Similar Third Parties"
                >
                  <span className="font-bold">
                    Third Party Analytics Providers, Ad Servers and Similar
                    Third Parties
                  </span>
                </Link>
              </Heading>
            </li>
            <li className="mt-4">
              <Heading level={3}>
                <Link relative href="#YourChoicesandOptingOut">
                  <span className="font-bold">Your Choices and Opting Out</span>
                </Link>
              </Heading>
            </li>
            <li className="mt-4">
              <Heading level={3}>
                <Link
                  relative
                  href="#Changing Your Information or Closing Your Account"
                >
                  <span className="font-bold">
                    Changing Your Information or Closing Your Account
                  </span>
                </Link>
              </Heading>
            </li>
            <li className="mt-4">
              <Heading level={3}>
                <Link relative href="#Security">
                  <span className="font-bold">Security</span>
                </Link>
              </Heading>
            </li>
            <li className="mt-4">
              <Heading level={3}>
                <Link
                  relative
                  href="#International Transfers of Personal Information"
                >
                  <span className="font-bold">
                    International Transfers of Personal Information
                  </span>
                </Link>
              </Heading>
            </li>
            <li className="mt-4">
              <Heading level={3}>
                <Link relative href="#Links to Other Sites">
                  <span className="font-bold">Links to Other Sites</span>
                </Link>
              </Heading>
            </li>
            <li className="mt-4">
              <Heading level={3}>
                <Link relative href="#Public Profile">
                  <span className="font-bold">Public Profile</span>
                </Link>
              </Heading>
            </li>
            <li className="mt-4">
              <Heading level={3}>
                <Link relative href="#Phishing">
                  <span className="font-bold">Phishing</span>
                </Link>
              </Heading>
            </li>
            <li className="mt-4">
              <Heading level={3}>
                <Link relative href="#Your California Privacy Rights">
                  <span className="font-bold">
                    California Residents &ndash; Your California Privacy Rights
                  </span>
                </Link>
              </Heading>
            </li>
            <li className="mt-4">
              <Heading level={3}>
                <Link relative href="#Changes to this Policy">
                  <span className="font-bold">Changes to this Policy</span>
                </Link>
              </Heading>
            </li>
            <li className="mt-4">
              <Heading level={3}>
                <Link relative href="#Contacting Us">
                  <span className="font-bold">Contacting Us</span>
                </Link>
              </Heading>
            </li>
          </ol>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="InformationCollection">
            1. INFORMATION COLLECTION
          </Heading>
          <Paragraph>
            Users of the Service may be{' '}
            <span className="font-bold">Clients</span> (anyone utilizing the
            Flying Company, LLC platform to request services to be performed by
            a Pilot) or <span className="font-bold">Pilots</span> (anyone
            utilizing the Flying Company, LLC platform to offer services to
            Clients).{' '}
          </Paragraph>
          <Paragraph>
            <span className="font-bold">
              <span className="italic">Information You Provide to Us</span>
            </span>
          </Paragraph>
          <ul className="pl-8">
            <li>
              <span className="font-bold">Personal Information:</span> In the
              course of using the Service (whether as a Client or Pilot), we may
              require or otherwise collect information that identifies you as a
              specific individual and can be used to contact or identify you
              (&ldquo;
              <span className="font-bold">Personal Information</span>
              &rdquo;). Examples of Personal Information include your name,
              email address, company address, billing address, and phone number.
            </li>
            <li>
              <span className="font-bold">Payment Information:</span> If you use
              the Service to make or receive payments, we will also collect
              certain payment information, such as credit card, PayPal or other
              financial account information, and billing address.
            </li>
            <li>
              <span className="font-bold">Identity Verification:</span> We may
              collect Personal Information, such as your date of birth or
              taxpayer identification number, to validate your identity or as
              may be required by law, such as to complete tax filings. We may
              request documents to verify this information, such as a copy of
              your government-issued identification or photo or a billing
              statement.
            </li>
            <li>
              <span className="font-bold">General Audience Service:</span> The
              Service is general audience and intended for users 18 and older.
              We do not knowingly collect Personal Information from anyone
              younger than age 18. If we become aware that a child younger than
              18 has provided us with Personal Information, we will use
              commercially reasonable efforts to delete such information from
              our files. If you are the parent or legal guardian of a child
              younger than age 18 and believe that Flying Company, LLC has
              collected Personal Information from your child, please contact us
              at{' '}
              <Link href="mailto:info@flyingcompany.com">
                info@flyingcompany.com
              </Link>
              .
            </li>
            <li>
              <span className="font-bold">
                Non-Identifying Information/Usernames:
              </span>{' '}
              We also may collect other information, such as zip codes,
              demographic data, information regarding your use of the Service,
              and general flight-related data (&ldquo;
              <span className="font-bold">Non-Identifying Information</span>
              &rdquo;). We may aggregate information collected from Contract
              Flying Company, LLC registered and non-registered users
              (&ldquo;Flying Company, LLC{' '}
              <span className="font-bold">Users</span>&rdquo;). We consider
              usernames to be Non-Identifying Information. Usernames are made
              public through the Service and are viewable by other Contract
              Flying Company, LLC Users.
            </li>
          </ul>
          <Paragraph>
            In some cases, we may render Personal Information (generally, email
            address) into a form of Non-Identifying Information referred to in
            this Privacy Policy as &ldquo;
            <span className="font-bold">Hashed Information</span>.&rdquo; This
            is typically accomplished using a mathematical process (commonly
            known as a hash function) to convert information into a code. The
            code does not identify you directly, but it may be used to connect
            your activity and interests.
          </Paragraph>
          <ul className="pl-8">
            <li>
              <span className="font-bold">
                Combination of Personal and Non-Identifying Information:
              </span>{' '}
              Certain Non-Identifying Information would be considered a part of
              your Personal Information if it were combined with other
              identifiers in a way that enables you to be identified (for
              example, combining information with your name). But the same
              pieces of information are considered Non-Identifying Information
              when they are taken alone or combined only with other
              non-identifying information (for example, your viewing
              preferences). We may combine your Personal Information with
              Non-Identifying Information, but Flying Company, LLC will treat
              the combined information as Personal Information.
            </li>
            <li>
              <span className="font-bold">
                Collection of the Third Party Personal Information:{' '}
              </span>
              We may collect the following personal information from you about
              your contacts: First, last name and email address when you provide
              it to us for the purpose of adding your contacts to the Flying
              Company, LLC platform.
            </li>
          </ul>
          <Paragraph>
            <span className="font-bold">
              <span className="italic">
                Information Received from Third Parties
              </span>
            </span>
          </Paragraph>
          <Paragraph>
            We also may receive information about you from third parties. For
            example, we may supplement the information we collect with outside
            records or third parties may provide information in connection with
            a co-marketing agreement or at your request (such as if you choose
            to sign in with a third-party service). If we combine the
            information we receive from others with information we collect
            through the Service, we will treat the combined information as
            described in this Privacy Policy.
          </Paragraph>
          <Paragraph>
            <span className="font-bold">
              <span className="italic">
                Information Collected from Users Automatically
              </span>
            </span>
          </Paragraph>
          <Paragraph>
            We and our third party service providers, including analytics and
            third party content providers, may automatically collect certain
            information from you whenever you access or interact with the
            Service. This information may include, among other information, the
            browser and operating system you are using, the URL or advertisement
            that referred you to the Service, the search terms you entered into
            a search engine that led you to the Service, areas within the
            Service that you visited, and other information commonly shared when
            browsers communicate with websites. We may combine this
            automatically collected log information with other information we
            collect about you. We do this to improve services we offer you, to
            improve marketing, analytics, and site functionality.
          </Paragraph>
          <Paragraph>
            The information we collect also includes the Internet Protocol
            (&ldquo;
            <span className="font-bold">IP</span>&rdquo;) address or other
            unique device identifier (&ldquo;
            <span className="font-bold">Device Identifier</span>&rdquo;) for any
            device (computer, mobile phone, tablet, etc.) used to access the
            Service. A Device Identifier is a number that is automatically
            assigned or connected to the device you use to access the Service,
            and our servers identify your device by its Device Identifier. Some
            mobile service providers may also provide us or our third party
            service providers with information regarding the physical location
            of the device used to access the Service.
          </Paragraph>
          <Paragraph>
            <span className="font-bold">
              <span className="italic">
                The technology used to collect information automatically from
                Flying Company, LLC Users may include the following:
              </span>
            </span>
          </Paragraph>
          <ul className="pl-8">
            <li>
              <span id="cookies" className="font-bold">
                Cookies:
              </span>{' '}
              Like many websites, we and our marketing partners, affiliates,
              analytics, and service providers use “cookies” to collect
              information. A cookie is a small data file that we transfer to
              your computer&apos;s hard disk for record-keeping purposes. We use
              both persistent cookies that remain on your computer or similar
              device (such as to save your registration ID and login password
              for future logins to the Service and to track your compliance with
              the Flying Company, LLC Terms of Service) and session ID cookies,
              which expire at the end of your browser session (for example, to
              enable certain features of the Service, to better understand how
              Flying Company, LLC Users interact with the Service and to monitor
              aggregate usage by Flying Company, LLC Users and web traffic
              routing on the Service). You may be able to instruct your browser,
              by changing its options, to stop accepting cookies or to prompt
              you before accepting a cookie from the websites you visit. If you
              do not accept cookies, however, you may not be able to use all
              functionality of the Service.
            </li>
          </ul>
          <ul className="pl-8">
            <li>
              <span id="web-beacons" className="font-bold">
                Web Beacons:{' '}
              </span>
              We and our marketing partners, affiliates, analytics, and service
              providers may also employ software technology known as “web
              beacons” and/or “tracking tags” to help us keep track of what
              content on our Service is effective and to serve relevant
              advertising to you. Web beacons are small graphics with a unique
              identifier that may be invisible to you, and which are used to
              track the online activity of Internet users. Web beacons are
              embedded in the web pages you review or email messages you
              receive. Web beacons or similar technologies may be used for a
              number of purposes, including, without limitation, to count
              visitors to our Service, to monitor how Flying Company, LLC Users
              navigate the Service, to count how many emails that were sent were
              actually opened, or to count how many particular articles or links
              were actually viewed.
            </li>
            <li>
              <span className="font-bold">Embedded Scripts:</span> We and our
              marketing partners, affiliates, analytics, and service providers
              may also employ software technology known as an Embedded Script.
              An Embedded Script is programming code that is designed to collect
              information about your interactions with the Service, such as the
              links you click on. The code is temporarily downloaded onto your
              computer or other device and is deactivated or deleted when you
              disconnect from the Service.
            </li>
          </ul>
          <Paragraph>
            In addition, we and our marketing partners, affiliates, analytics,
            and service providers may use a variety of other technologies (such
            as tags) that collect similar information for security and fraud
            detection purposes and we may use third parties to perform these
            services on our behalf.
          </Paragraph>
          <Paragraph>
            <span className="font-bold">
              HOW WE RESPOND TO DO NOT TRACK SIGNALS
            </span>
          </Paragraph>
          <Paragraph>
            Please note that your browser setting may allow you to automatically
            transmit a “Do Not Track” signal to websites and online service you
            visit. There is no consensus among industry participants as to what
            “Do Not Track” means in this context. Like many websites and online
            services, Flying Company, LLC does not alter its practices when it
            receives a “Do Not Track” signal from a visitor&apos;s browser. To
            find out more about “Do Not Track,” please visit{' '}
            <Link href="https://www.allaboutdnt.com/">
              http://www.allaboutdnt.com
            </Link>
            .
          </Paragraph>
          <Paragraph>
            <span className="font-bold">
              <span className="italic">User Profiles </span>
            </span>
            You may have the opportunity to create a profile, which consists of
            information about you, and may include Personal Information,
            photographs, information on work previously performed via the
            Service and outside the Service, skills, experience, training, pay
            rates, feedback/rating information and other information, including
            your username (&ldquo;
            <span className="font-bold">Profile</span>
            &rdquo;). The information in your Profile may be visible to all
            Flying Company, LLC Users and the general public. You may edit
            certain information in your Profile via your account. Clients may
            also have the opportunity to create an organization Profile. If, in
            any case, you believe that an unauthorized profile has been created
            about you, you can request for it to be removed by contacting us at{' '}
            <Link href="mailto:info@flyingcompany.com">
              info@flyingcompany.com
            </Link>
            .
          </Paragraph>
          <Paragraph>
            <span className="font-bold">
              <span className="italic">Community Forums</span>
            </span>{' '}
            The Service may provide you the opportunity to participate and post
            content publicly in forums, on blogs, through interactive features
            and through other communication functionality (&ldquo;
            <span className="font-bold">Community Forums</span>&rdquo;). You may
            choose, through such features or otherwise, to submit or post
            questions, comments, or other content (collectively, &ldquo;
            <span className="font-bold">User Forum Content</span>
            &rdquo;). Please note that certain information, such as your name
            and Profile may be publicly displayed on the Service along with your
            User Forum Content. Please note that your use of Community Forums is
            subject to the Flying Company, LLC Forum Rules and our Terms of
            Service.
          </Paragraph>
          <Paragraph>
            <span className="font-bold">
              Note that anything you post to a Community Forum is public{' '}
            </span>
            <span className="font-bold">
              <span className="italic">&mdash; </span>
            </span>
            <span className="font-bold">
              others will have access to your User Forum Content and may use it
              or share it with third parties. If you choose to voluntarily
              disclose Personal Information in your User Forum Content or use
              Community Forums to link to your Profile, that information will be
              considered public information and the protections of this Privacy
              Policy will not apply.
            </span>
          </Paragraph>

          <Paragraph>
            <span className="italic">
              To request removal of your personal information from our blog or
              community forum, contact us at{' '}
            </span>
            <Link href="mailto:info@flyingcompany.com">
              <span className="italic">info@flyingcompany.com</span>
            </Link>
            <span className="italic">. </span>&nbsp;In some cases, we may not be
            able to remove your Personal Information, in which case we will let
            you know if we are unable to do so and why.
          </Paragraph>
          <Paragraph>
            <span className="font-bold">
              <span className="italic">Testimonials</span>
            </span>{' '}
            We may display personal testimonials on our Service, in addition to
            other endorsements. We may post your testimonial along with your
            name. If you wish to update or delete your testimonial, you can
            contact us at{' '}
            <Link href="mailto:info@flyingcompany.com">
              info@flyingcompany.com
            </Link>
            .
          </Paragraph>
          <Paragraph>
            <span className="font-bold">
              <span className="italic">
                Flight Requests Through the Service
              </span>
            </span>
          </Paragraph>
          <Paragraph>
            If you choose to post a flight request via the Service, the contents
            of such request may be viewable publicly. Work listings include
            information such as date, aircraft type, Client feedback and rating,
            start point and end point of the trip, and other information.
          </Paragraph>
          <Heading level={4} className="italic">
            Feedback
          </Heading>
          <Paragraph>
            We collect feedback from Flying Company, LLC Users about their
            experience with other Flying Company, LLC Users of our Service.
            Please note that any feedback you provide via the Service or
            feedback provided about may be publicly viewable via the Service. On
            very rare occasions, we may remove feedback.
          </Paragraph>
          <Paragraph>
            <span className="font-bold">
              <span className="italic">
                Email to Clients and Referral Program
              </span>
            </span>
          </Paragraph>
          <Paragraph>
            Flying Company, LLC lets Pilots send flight requests to clients via
            email. Flying Company, LLC also offers the ability to send contacts
            emails about the Service. If you choose to use either feature, your
            Client or contact&apos;s email address will be used to send the
            requested information and your email address may be used to copy you
            on the message to your Client or contact or to send the message on
            your behalf. Contract Flying Company, LLC stores this information
            for the sole purpose of sending this one-time email and tracking the
            success of our referral program.
          </Paragraph>
          <Paragraph>
            Your Client or contact may contact us at{' '}
            <Link href="mailto:info@flyingcompany.com">
              info@flyingcompany.com
            </Link>{' '}
            to request that we remove this information from our database.
          </Paragraph>
          <Paragraph>
            <span className="font-bold">
              <span className="italic">Social Networking Services</span>
            </span>
          </Paragraph>
          <Paragraph>
            You may register to join the Service directly via the Service or by
            logging into your account with a third party social networking
            service (&ldquo;<span className="font-bold">SNS</span>&rdquo;) via
            our Service (e.g., Facebook, LinkedIn and other third party services
            that let you sign in using your existing credentials with those
            services). If you choose to register via an SNS, or to later link
            your account with the Service to your account with an SNS, we will
            use the Personal Information you have provided to the SNS (such as
            your name, email address, gender and other information you make
            publicly available via the SNS) to create your account. Note that
            the information we collect from and through an SNS may depend on the
            privacy settings you have set with the SNS and the permissions you
            grant to us in connection with linking your account with the Service
            to your account with an SNS. Other than what we may share with the
            SNS as described below, the Personal Information an SNS has about
            you is obtained by the SNS independent of our Service, and Flying
            Company, LLC is not responsible for it.
          </Paragraph>
          <Paragraph>
            The Flying Company, LLC Service also may permit additional
            interactions between it and a third party website, service, or other
            content provider, such as enabling you to “like” or share content to
            a third party SNS. If you choose to “like” or share content, or to
            otherwise share information from or via our Service with a third
            party site or service, that information may be publicly displayed,
            and the third party may have access to information about you and
            your use of our Service (and we may have access to information about
            you from that third party). These features may collect your IP
            address, which page you are visiting on our site, and may set a
            cookie to enable the feature to function properly. Your interactions
            with third parties through an SNS or similar features are governed
            by the respective privacy policies of those third parties.
          </Paragraph>
          <Paragraph>
            You represent that you are entitled to use your SNS account for the
            purposes described herein without breach by you of any of the terms
            and conditions that govern the SNS, and without obligating Flying
            Company, LLC to pay any fees or making Contract Flying Company, LLC
            subject to any usage limitations imposed by such SNS. You can
            disable the link between your Contract Flying Company, LLC account
            and your SNS account at any time though the “Settings” section of
            our Service. Please note that your relationship with any SNS is
            governed solely by your agreement(s) with such SNS. If your SNS
            account terminates, then functions enabled through the link between
            your Flying Company, LLC account and your SNS account will terminate
            as well.
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="Use and Retention of Information">
            2. USE AND RETENTION OF INFORMATION
          </Heading>
          <Paragraph>WE USE INFORMATION WE COLLECT:</Paragraph>
          <ul className="pl-8">
            <li>
              To provide and improve the Service, complete your transactions,
              and address your inquiries, process your registration, verify the
              information you provide is valid and for compliance and internal
              business purposes;
            </li>
            <li>
              To contact you with administrative communications and Contract
              Flying Company, LLC newsletters, marketing or promotional
              materials (on behalf of Flying Company, LLC or third parties) and
              other information that may be of interest to you. If you decide at
              any time that you no longer wish to receive such communications
              from us, please follow the instructions in the{' '}
              <Link relative href="#YourChoicesandOptingOut">
                Your Choices and Opting Out
              </Link>{' '}
              section, below;
            </li>
            <li>
              To tailor content we display to you and offers we may present to
              you, both on the Service and elsewhere online;
            </li>
            <li>
              To administer and develop our business relationship with you and,
              if applicable, the corporation or other legal entity you
              represent;
            </li>
            <li>
              To assess your proposal to perform a flight and prepare related
              governmental and internal statistics reports;
            </li>
            <li>
              To enforce and comply with the law, including to conduct an
              investigation, to protect the property and rights of Contract
              Flying Company, LLC or a third party, to protect the safety of the
              public or any person, or to prevent or stop activity we may
              consider to be, or to pose a risk of being, illegal, fraudulent,
              unethical or legally actionable activity. We may also use Device
              Identifiers to identify Flying Company, LLC Users; and
            </li>
            <li>
              For the purposes disclosed at the time you provide your
              information, with your consent, and as further described in this
              Privacy Policy.
            </li>
          </ul>
          <Paragraph>
            UNLESS YOU REQUEST THAT WE DELETE CERTAIN INFORMATION (SEE{' '}
            <Link relative href="#ChangingYourInformationorClosingYourAccount">
              CHANGING YOUR INFORMATION OR CLOSING YOUR ACCOUNT
            </Link>{' '}
            BELOW), WE RETAIN THE INFORMATION WE COLLECT FOR AT LEAST 5 YEARS
            AND MAY RETAIN THE INFORMATION FOR AS LONG AS NEEDED FOR OUR
            BUSINESS AND LEGAL PURPOSES.
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="Information Sharing and Disclosure">
            3. INFORMATION SHARING AND DISCLOSURE
          </Heading>
          <Paragraph>
            We may share aggregated Non-Identifying Information and we may
            otherwise We may share aggregated Non-Identifying Information and we
            may otherwise disclose Non-Identifying Information (including,
            without limitation, Hashed Information) to third parties. We do not
            share your Personal Information with third parties for those third
            parties&apos; marketing purposes unless we first provide you with
            the opportunity to opt-in to or opt-out of such sharing. We may also
            share the information we have collected about you, including
            Personal Information, as disclosed at the time you provide your
            information, with your consent, as otherwise described in this
            Privacy Policy, or in the following circumstances:
          </Paragraph>
          <ul className="pl-8">
            <li>
              <span className="font-bold">
                Information about Pilots Shared with Clients:{' '}
              </span>
              We share information regarding Pilots with Clients. Note that if a
              Pilot is suspended from the Flying Company, LLC Service, we may
              share that information with Clients for whom that Pilot has worked
              or applied for work. If you choose to apply for work as a Pilot
              via the Service, we will share information relevant to your
              application with the applicable Client(s), including, but not
              limited to, the information contained in your Pilot Profile.
            </li>
          </ul>
          <ul className="pl-8">
            <li>
              <span className="font-bold">
                Information about Clients Shared with Pilots: If you have
                entered into a service contract with another user, we may
                provide him/her with your name, company address, billing
                address, or tax ID or VAT number in order to complete the
                transaction or to facilitate the resolution of a claim or
                dispute. The user receiving your information is not allowed to
                use it for purposes unrelated to the transaction, such as to
                contact you for marketing purposes, unless you have expressly
                consented to it.
              </span>
            </li>
          </ul>
          <ul className="pl-8">
            <li>
              <span className="font-bold">Service Providers: </span>
              We may employ third party companies and individuals to facilitate
              our Service, to provide the Service on our behalf, to perform
              Service-related services (e.g., without limitation, maintenance
              services, database management, web analytics and online
              advertising, payment processing, fraud detection and improvement
              of Flying Company, LLC&apos;s features) or to assist us in
              analyzing how our Service is used. These third parties may have
              access to your Personal Information in order to perform these
              tasks on our behalf.
            </li>
            <li>
              <span className="font-bold">
                What Happens If You Agree to Receive Information From Third
                Parties or Request That We Share Your Information:{' '}
              </span>
              You may be presented with an opportunity to receive information
              and/or marketing offers from one or more third parties. If you
              agree at that time to have your Personal Information shared, your
              Personal Information will be disclosed to that third party (or
              parties) and will be subject to the privacy policy and practices
              of that third party. We are not responsible for the privacy
              policies and practices of third parties, and, if you later decide
              that you no longer want to receive communications from a third
              party, you will need to contact that third party directly. You
              also may request, sometimes through your use of an SNS or similar
              interactive feature or third party application, that we share
              information about you with a third party and we will typically do
              so under those circumstances.
            </li>
            <li>
              <span className="font-bold">
                Legal and Investigative Purposes:{' '}
              </span>
              Flying Company, LLC will share information with government
              agencies as required by law, including without limitation, in
              connection with reporting earnings. We cooperate with government
              and law enforcement officials and private parties to enforce and
              comply with the law. We will disclose information about you to
              government or law enforcement officials or private parties as we,
              in our sole discretion, believe necessary or appropriate to
              respond to claims and legal process (including but not limited to
              subpoenas), at the request of governmental authorities or other
              third parties conducting an investigation, to protect the property
              and rights of Contract Flying Company, LLC or a third party, to
              protect the safety of the public or any person, or to prevent or
              stop activity we may consider to be, or to pose a risk of being,
              illegal, fraudulent, unethical or legally actionable activity. We
              may also use Device Identifiers to identify Flying Company, LLC
              Users, and may do so in cooperation with third parties at our
              discretion.
            </li>
            <li>
              <span className="font-bold">
                Internal and Business Transfers:{' '}
              </span>
              Flying Company, LLC may share information, including Personal
              Information, with subsidiaries and affiliates, primarily for
              business and operational purposes. We may sell, transfer or
              otherwise share some or all of our assets, including your Personal
              Information, in connection with a merger, acquisition,
              reorganization or sale of assets (including, in each case, as part
              of the due-diligence process with any potential acquiring entity)
              or in the event of bankruptcy. You will be notified via email
              and/or a prominent notice on our website of any change in
              ownership or uses of your personal information, as well as any
              choices you may have regarding your personal information.
            </li>
            <li>
              <span className="font-bold">
                Sweepstakes, Contests and Promotions:
              </span>{' '}
              We may offer sweepstakes, contests, and other promotions (any, a
              &ldquo;
              <span className="font-bold">Promotion</span>&rdquo;) that may
              require registration. By participating in a Promotion, you are
              agreeing to the official rules that govern that Promotion, which
              may contain specific requirements of you, including, except where
              prohibited by law, allowing the sponsor(s) of the Promotion to use
              your name, voice, likeness or other indicia of persona in
              advertising or marketing associated with the Promotion. If you
              choose to enter a Promotion, your Personal Information may be
              disclosed to third parties or the public in connection with the
              administration of such Promotion, including, without limitation,
              in connection with winner selection, prize fulfillment, and as
              required by law or permitted by the Promotion&rsquo;s official
              rules, such as on a winner&rsquo;s list.
            </li>
          </ul>
        </Section>

        <Section className="mb-12">
          <Heading
            level={2}
            id="Third Party Analytics Providers, Ad Servers and Similar Third Parties"
          >
            4. THIRD PARTY ANALYTICS PROVIDERS, AD SERVERS AND SIMILAR THIRD
            PARTIES
          </Heading>
          <Paragraph>
            Flying Company, LLC works with (or may in the future work with)
            network advertisers, ad agencies, analytics service providers and
            other vendors to provide us with information regarding traffic on
            the Service, including pages viewed and the actions taken when
            visiting the Service; to serve our advertisements on other web
            sites, within mobile apps and elsewhere online; and to provide us
            with information regarding the use of the Service and the
            effectiveness of our advertisements. Our service providers may
            collect certain information about your visits to and activity on the
            Service as well as other websites or services, they may set and
            access their own tracking technologies on your device (including{' '}
            <Link relative href="#cookies">
              cookies
            </Link>{' '}
            and{' '}
            <Link relative href="#web-beacons">
              web beacons
            </Link>
            ), and may use that information to show you targeted advertisements.
            Some of these parties may collect Personal Information when you
            visit the Service or other online websites and services. We may also
            share certain Non-Identifying Information with these parties,
            including Hashed Information, in connection with the services they
            provide to us.
          </Paragraph>
          <Paragraph>
            While we may use a variety of service providers to perform
            advertising services, some of these companies are members of the
            Network Advertising Initiative (&ldquo;
            <span className="font-bold">NAI</span>&rdquo;) or the Digital
            Advertising Alliance (&ldquo;
            <span className="font-bold">DAA</span>
            &rdquo;) Self-Regulatory Program for Online Behavioral Advertising.
            You may want to visit{' '}
            <Link href="https://www.networkadvertising.org/managing/opt_out.asp">
              http://www.networkadvertising.org/managing/opt_out.asp
            </Link>
            , which provides information regarding targeted advertising and the
            &ldquo;opt-out&rdquo; procedures of NAI members. You may also want
            to visit or{' '}
            <Link href="https://www.aboutads.info/choices/">
              http://www.aboutads.info/choices/
            </Link>
            , which provides information regarding targeted advertising and
            offers an &ldquo;opt-out&rdquo; by participating companies in the
            DAA Self-Regulatory Program. You may also opt-out by clicking{' '}
            <Link href="https://preferences-mgr.truste.com/">here</Link> (or if
            located in the European Union click{' '}
            <Link href="https://www.youronlinechoices.eu/">here</Link>). Please
            note that opting out through these mechanisms does not opt you out
            of being served advertising, and you will continue to receive
            generic ads while online.
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="Your Choices and Opting Out">
            5. YOUR CHOICES AND OPTING OUT
          </Heading>
          <Paragraph>
            Registered Flying Company, LLC Users may update their choices
            regarding the types of communications you receive from us through
            your online account. You also may opt-out of receiving marketing
            emails from us by following the opt-out instructions provided in
            those emails. Please note that we reserve the right to send you
            certain communications relating to your account or use of the
            Service (for example, administrative and service announcements) via
            email and other means and these transactional account messages may
            be unaffected if you opt-out from receiving marketing
            communications. You may opt-out of receiving text messages by
            replying “STOP” to any text message received. Registered Flying
            Company, LLC Users who access the Service by using an Flying
            Company, LLC mobile application may, with permission, receive push
            notifications. Similarly, registered Flying Company, LLC Users who
            access the Service by using certain desktop browsers may, with
            permission, receive push notifications. Notification preferences can
            be modified in the settings menu for the mobile application or the
            applicable browser.
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="ChangingYourInformationorClosingYourAccount">
            6. CHANGING YOUR INFORMATION OR CLOSING YOUR ACCOUNT
          </Heading>
          <Paragraph>
            Upon request Flying Company, LLC will provide you with information
            about whether we hold any of your personal information. You are
            responsible for maintaining the accuracy of the information you
            submit to us, such as your contact information. You may access,
            correct, or request deletion of your personal information by making
            updates to that information or by contacting Flying Company, LLC
            through your online account. If you request to access all personal
            information you&apos;ve submitted, we will respond to your request
            to access within 30 days. If you completely delete all such
            information, then your account may become deactivated. If your
            account is deactivated or you ask to close your account, you will no
            longer be able to use the Service. If you would like us to delete
            your account in our system, you can do so through the Contract
            Flying Company, LLC Service (once you logged in, visit settings/
            user settings, and then click on the close my account link). We will
            use commercially reasonable efforts to honor your request; however,
            certain information will actively persist on the Service even if you
            close your account. In addition, your Personal Information may
            remain in our archives and information you update or delete, or
            information within a closed account, may persist internally or for
            our administrative purposes. It is not always possible to completely
            remove or delete information from our databases. In addition, we
            typically will not remove information you posted publicly through or
            on the Service. Bear in mind that neither you nor Flying Company,
            LLC can delete all copies of information that has been previously
            shared with others on the Service.
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="Security">
            7. SECURITY
          </Heading>
          <Paragraph>
            Flying Company, LLC takes commercially reasonable steps to help
            protect and secure the information it collects and stores about
            Flying Company, LLC Users. All access to the Site is encrypted using
            industry-standard transport layer security technology (TLS). When
            you enter sensitive information (such as tax identification number),
            we encrypt the transmission of that information using secure socket
            layer technology (SSL). We also use HTTP strict transport security
            to add an additional layer of protection for our Flying Company, LLC
            Users. But remember that no method of transmission over the
            Internet, or method of electronic storage, is 100% secure. Thus,
            while we strive to protect your personal data, Flying Company, LLC
            cannot ensure and does not warrant the security of any information
            you transmit to us.
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading
            level={2}
            id="International Transfers of Personal Information"
          >
            8. INTERNATIONAL TRANSFER OF PERSONAL INFORMATION
          </Heading>
          <Paragraph>
            Flying Company, LLC is a United States company. If you are located
            outside the United States and choose to provide information to us,
            Flying Company, LLC transfers Personal Information to the United
            States for processing. The U.S. may not have the same data
            protection laws as the country in which you initially provided the
            information. When we transfer your information to the U.S., we will
            protect it as described in this Privacy Policy. By visiting the
            Service, or providing Contract Flying Company, LLC with any
            information, you fully understand and unambiguously consent to this
            transfer, processing and storage of your information in the United
            States.
          </Paragraph>
          <Paragraph>
            Flying Company, LLC protects personally identifiable information
            from residents of the European Union, other European Economic Area
            countries, and Switzerland in accordance with applicable law
            (including, as applicable, reliance upon your consent and
            EU-approved standard contract clauses).
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="Links to Other Sites">
            9. LINKS TO OTHER SITES
          </Heading>
          <Paragraph>
            Our Service contains links to other websites. If you choose to click
            on a third party link, you will be directed to that third
            party&apos;s website. The fact that we link to a website is not an
            endorsement, authorization or representation of our affiliation with
            that third party, nor is it an endorsement of their privacy or
            information security policies or practices. We do not exercise
            control over third party websites. These other websites may place
            their own cookies or other files on your computer, collect data or
            solicit Personal Information from you. We encourage you to read the
            privacy policies or statements of the other websites you visit.
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="Public Profile">
            10. PUBLIC PROFILE
          </Heading>
          <Paragraph>
            The profile you create on our site will be publicly accessible
            unless otherwise indicated. You may change the privacy settings of
            your profile through your account portal.
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="Phishing">
            11. PHISHING
          </Heading>
          <Paragraph>
            Phishing websites imitate legitimate websites in order to obtain
            personal or financial information. Identity theft and the practice
            currently known as “phishing” are of great concern to Contract
            Flying Company, LLC. For more information about phishing, visit the
            website of the Federal Trade Commission at{' '}
            <Link href="https://www.consumer.ftc.gov/articles/0003-phishing">
              http://www.consumer.ftc.gov/articles/0003-phishing
            </Link>
            . In addition, if you believe you have received an email or had a
            similar interaction with a third party pretending to be Contract
            Flying Company, LLC, please report it at{' '}
            <Link href="mailto:info@flyingcompany.com">
              info@flyingcompany.com
            </Link>
            .
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="Your California Privacy Rights">
            12. CALIFORNIA RESIDENTS &ndash; YOUR CALIFORNIA PRIVACY RIGHTS
          </Heading>
          <Paragraph>
            Pursuant to California Civil Code Section 1798.83, California
            residents who provide us with personal information in obtaining
            products or services for personal, family, or household use are
            entitled to request and obtain from us, one time per calendar year,
            information about the customer information we shared, if any, with
            other businesses for their own direct marketing uses. Alternatively,
            the law provides that a company may comply, as Flying Company, LLC
            does, by disclosing in its privacy policy that it provides consumers
            choice (opt-in or opt-out) regarding sharing Personal Information
            with third parties for those third parties&apos; direct marketing
            purposes, and information on how to exercise that choice. As stated
            above in this Privacy Policy, Flying Company, LLC provides you
            choice prior to sharing your personal information with third parties
            for their direct marketing purposes. If you do not opt-in or if you
            choose to opt-out at the time Flying Company, LLC offers that
            choice, Flying Company, LLC does not share your information with
            that identified third party for its direct marketing purposes.
          </Paragraph>
          <Paragraph>
            If you are a California resident and you have questions about our
            practices with respect to sharing information with third parties for
            their direct marketing purposes and your ability to exercise choice,
            please send your request to the following email address:{' '}
            <Link href="mailto:info@flyingcompany.com">
              info@flyingcompany.com
            </Link>{' '}
            or write to us at the following mailing address:
          </Paragraph>
          <Paragraph>
            Flying Company, LLC
            <br />
            310 NW O St, Bentonville, AR, 72712
          </Paragraph>
          <Paragraph>
            You must put the statement &ldquo;Your California Privacy
            Rights&rdquo; in the subject field of your email or include it in
            your writing if you choose to write to us at the designated mailing
            address. You must include your name, street address, city, state,
            and ZIP code. We will respond to you at your mailing address or, at
            our option, your email address. We are not responsible for notices
            that are not labeled or sent properly, or do not have complete
            information.
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="Changes to this Policy">
            13. CHANGES TO THIS POLICY
          </Heading>
          <Paragraph>
            Flying Company, LLC may update this Privacy Policy at any time and
            any changes will be effective upon posting. In the event that there
            are material changes to the way we treat your Personal Information,
            we will display a notice through the Services prior to the change
            becoming effective. We may also notify you by email, in our
            discretion. However, we will use your Personal Information in a
            manner consistent with the Privacy Policy in effect at the time you
            submitted the information, unless you consent to the new or revised
            policy.
          </Paragraph>
        </Section>

        <Section className="mb-12">
          <Heading level={2} id="Contacting Us">
            14. CONTACTING US
          </Heading>
          <Paragraph>
            If you have any questions about this Privacy Policy, please contact
            us at{' '}
            <Link href="mailto:info@flyingcompany.com">
              info@flyingcompany.com
            </Link>
            ; or by mail addressed to Flying Company, LLC, Attn: Legal, 310 NW O
            St, Bentonville, AR, 72712. Alternatively, by telephone, call
            +1.201.970.6488
          </Paragraph>
        </Section>
      </div>
    </>
  );
}
