import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react';

import { useUser } from './UserProvider/UserProvider';

import { AirportId, AirportLocation } from '../types/AirportLocation';
import getAirports from '../API/Airport/getAirports';

interface IAirportsProvider {
  loading: boolean;
  airports: AirportLocation[];
  getAirportFromId: (id: AirportId) => AirportLocation;
  getAirportDisplayName: (airport: AirportLocation) => string;
  getAirportDisplayNameFromId: (id: AirportId) => string;
  findAirportById: (id: AirportId) => AirportLocation;
  findAirportByDisplayName: (displayName: string) => AirportLocation;
}

const AirportsContext = createContext<IAirportsProvider>(undefined!);

export function useAirports() {
  return useContext(AirportsContext);
}

export function AirportsProvider({ children }: { children: ReactNode }) {
  const [loading, setLoading] = useState(false);
  const [airports, setAirports] = useState<AirportLocation[]>([]);

  const { user } = useUser();

  const getAirportFromId = useCallback(
    (id: AirportId) => {
      const ap = airports.find((airport) => airport.id === id);
      if (!ap) throw new Error(`Unable to find airport matching id: ${id}`);
      return ap;
    },
    [airports]
  );

  const getAirportDisplayName = useCallback(
    (airport: AirportLocation) =>
      `${airport.name} (${airport.iataCode}) (${airport.icaoCode})`,
    []
  );

  const getAirportDisplayNameFromId = useCallback(
    (id: AirportId) => {
      const ap = airports.find((a) => a.id === id);
      if (!ap) throw new Error(`Unable to find airport with id: ${id}`);
      return getAirportDisplayName(ap);
    },
    [airports, getAirportDisplayName]
  );

  const findAirportById = useCallback(
    (id: AirportId) => {
      const airport = airports.find((ap) => ap.id === id);
      if (!airport) throw new Error(`Unable to find airport with id: ${id}`);
      return airport;
    },
    [airports]
  );

  const findAirportByDisplayName = useCallback(
    (displayName: string) => {
      const airport = airports.find(
        (ap) => getAirportDisplayName(ap) === displayName
      );
      if (!airport)
        throw new Error(
          `Unable to find airport with display name: ${displayName}`
        );
      return airport;
    },
    [airports, getAirportDisplayName]
  );

  useEffect(() => {
    const getAndSetAirports = async () => {
      try {
        setLoading(true);
        let airports: AirportLocation[] = [];
        let loadedAirports = await getAirports();
        while (loadedAirports.next) {
          airports = airports.concat(loadedAirports.airports);
          const params = new URLSearchParams(loadedAirports.next.toString());
          const offset = Number(params.get('offset'));
          // eslint-disable-next-line no-await-in-loop
          loadedAirports = await getAirports(undefined, offset);
        }
        setAirports(airports);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };
    if (!airports.length && user) getAndSetAirports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const value = useMemo(
    () => ({
      airports,
      loading,
      getAirportFromId,
      getAirportDisplayName,
      getAirportDisplayNameFromId,
      findAirportById,
      findAirportByDisplayName,
    }),
    [
      airports,
      loading,
      getAirportFromId,
      getAirportDisplayName,
      getAirportDisplayNameFromId,
      findAirportById,
      findAirportByDisplayName,
    ]
  );

  return (
    <AirportsContext.Provider value={value}>
      {children}
    </AirportsContext.Provider>
  );
}
