// eslint-disable-next-line import/prefer-default-export
export function isValidPhoneNumber(phone: string) {
  // TODO
  return !!phone;
}

export function isValidCertificateNumber(certificateNumber: string) {
  return certificateNumber.length === 7;
}

export function isValidIssueDate(issueDate: Date | string | null) {
  if (issueDate == null) return false;
  let convertedDate = issueDate;

  if (typeof issueDate === 'string') convertedDate = new Date(issueDate);
  return convertedDate <= new Date();
}
