import isEmptyObject from '../../utilities/isEmptyObject';
import fetchAPI from '../util';

export interface ReviewOperatorFields {
  pilotConsumerRating: number;
  pilotFlycoRating: number;
  pilotNotes: string;
}

interface NullableReviewOperatorFields {
  pilotConsumerRating: number | null;
  pilotFlycoRating: number | null;
  pilotNotes: string | null;
}

async function reviewTripAsPilot(
  fields: NullableReviewOperatorFields,
  id: number
) {
  try {
    if (isEmptyObject(fields)) return;

    await fetchAPI(`/api/trips/as_pilot/${id}/review/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        stars: fields.pilotConsumerRating,
        flyco_stars: fields.pilotFlycoRating,
        notes: fields.pilotNotes || 'No additional notes provided.',
      }),
    });
  } catch (err) {
    console.log(err);
  }
}

export default reviewTripAsPilot;
