import { ROUTES } from './routerConfig';

import Home from './Home/Home';
import Error from './Error/Error';
import Login from './Login/Login';
import Register from './Register/Register';
import Trips from './Trips/Trips';
import Logout from './Logout/Logout';
import CreateTrip from './CreateTrip/CreateTrip';
import Pilots from './Pilots/Pilots';
import Trip from './Trip/Trip';
import BillingAndPaymentsPage from './BillingAndPayments/BillingAndPayments';
import CertificatesAndRatingsPage from './CertificatesAndRatings/CertificatesAndRatingsPage';
import GeneralInformationPage from './GeneralInformation/GeneralInformation';
import HoursPage from './HoursAndTraining/HoursAndTraining';
import NotificationSettingsPage from './NotificationSettings/NotificationSettings';
import AccountSecurityPage from './AccountSecurity/AccountSecurity';
import QuestionsPage from './Questions/Questions';
import PrivacyPolicyPage from './PrivacyPolicy/PrivacyPolicy';
import TermsOfUsePage from './TermsOfUse/TermsOfUse';
import MyAircraftPage from './MyAircraft/MyAircraft';
import AddAircraftPage from './AddAircraft/AddAircraft';

import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import { CertificateMetadataProvider } from '../hooks/PilotCertificateProvider/CertificateMetadataProvider';
import { PilotCertificateProvider } from '../hooks/PilotCertificateProvider/PilotCertificateProvider';

const routes = [
  {
    path: ROUTES.HOME,
    element: <Home />,
  },
  {
    path: ROUTES.ADD_AIRCRAFT,
    element: (
      <ProtectedRoute requiredRole="consumer">
        <AddAircraftPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.ERROR,
    element: <Error />,
  },
  {
    path: ROUTES.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTES.LOGOUT,
    element: <Logout />,
  },
  {
    path: ROUTES.PILOTS,
    element: <Pilots />,
  },
  {
    path: ROUTES.REGISTER,
    element: <Register />,
  },
  {
    path: ROUTES.TRIPS,
    element: <Trips />,
  },
  {
    path: ROUTES.CREATE_TRIP,
    element: (
      <ProtectedRoute requiredRole="consumer">
        <CreateTrip />
      </ProtectedRoute>
    ),
  },
  {
    path: `${ROUTES.TRIPS}/:id`,
    element: (
      <ProtectedRoute>
        <CertificateMetadataProvider>
          <Trip />
        </CertificateMetadataProvider>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.BILLING_AND_PAYMENTS,
    element: (
      <ProtectedRoute>
        <BillingAndPaymentsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.CERTIFICATES_AND_RATINGS,
    element: (
      <ProtectedRoute requiredRole="pilot">
        <CertificateMetadataProvider>
          <PilotCertificateProvider>
            <CertificatesAndRatingsPage />
          </PilotCertificateProvider>
        </CertificateMetadataProvider>
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.GENERAL_INFORMATION,
    element: (
      <ProtectedRoute>
        <GeneralInformationPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.MY_AIRCRAFT,
    element: (
      <ProtectedRoute requiredRole="consumer">
        <MyAircraftPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.HOURS_TRAINING,
    element: (
      <ProtectedRoute requiredRole="pilot">
        <HoursPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.NOTIFICATION_SETTINGS,
    element: (
      <ProtectedRoute>
        <NotificationSettingsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.ACCOUNT_SECURITY,
    element: (
      <ProtectedRoute>
        <AccountSecurityPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.QUESTIONS,
    element: (
      <ProtectedRoute requiredRole="pilot">
        <QuestionsPage />
      </ProtectedRoute>
    ),
  },
  {
    path: ROUTES.PRIVACY_POLICY,
    element: <PrivacyPolicyPage />,
  },
  {
    path: ROUTES.TERMS_OF_SERVICE,
    element: <TermsOfUsePage />,
  },
  {
    path: ROUTES.VERIFY_EMAIL,
    element: (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1 className="text-center">
              Your Email Address has been validated!
            </h1>
          </div>
          <div className="col-md-12">
            <p className="text-center">You may close this page.</p>
          </div>
        </div>
      </div>
    ),
  },
];

export default routes;
