/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames';
import React, { InputHTMLAttributes, forwardRef, ForwardedRef } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface InputProps extends InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef(
  (
    { className, ...standardInputProps }: InputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <input
        className={classNames(
          'input input-bordered w-full max-w-xs disabled:bg-base-200 disabled:text-neutral disabled:cursor-not-allowed',
          className
        )}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...standardInputProps}
        onWheel={() => (document.activeElement as HTMLElement).blur()}
      />
    );
  }
);

export default Input;
