import { useEffect } from 'react';

/**
 * This hook observes the disabled attribute of a fieldset element and updates the state accordingly.
 * Then, custom components can use this state to have a proper disabled state.
 * See SettingsForm for an example of how to use this hook and FormikDropdown for an example of how to use the disabled state.
 * @param ref The reference to the fieldset element.
 * @param setDisabled The state setter for the disabled state.
 */
const useDisabledObserver = (
  ref: React.RefObject<HTMLFieldSetElement>,
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>
) => {
  useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'disabled') {
          setDisabled(ref.current?.disabled || false);
        }
      });
    });

    const currentRef = ref.current;
    if (currentRef) {
      observer.observe(currentRef, { attributes: true });
      setDisabled(currentRef.disabled); // Set initial disabled state
    }

    return () => {
      if (currentRef) {
        observer.disconnect();
      }
    };
  }, [ref, setDisabled]);
};

export default useDisabledObserver;
