import classNames from 'classnames';
import {
  CancelButton,
  EditButton,
  SaveButton,
} from '../../atoms/Button/Button';

export interface EditAndSaveControlsProps {
  className?: string;
  disabled: boolean;
  onEdit: () => void;
  onSave?: () => void;
  onCancel: () => void;
}

/** Renders Edit or Save/Cancel buttons depending on `disabled` status  */
export default function EditAndSaveControls({
  className,
  disabled = true,
  onEdit,
  onSave,
  onCancel,
}: EditAndSaveControlsProps) {
  const handleSave = () => {
    if (onSave) {
      onSave();
    }
  };

  return (
    <div className={classNames('flex gap-4 ml-auto', className)}>
      {disabled ? (
        <EditButton aria-label="Edit" onClick={onEdit} />
      ) : (
        // <SecondaryButton outline className="btn-sm" onClick={onEdit}>
        //   {editCaption}
        // </SecondaryButton>
        <div className="flex gap-4">
          <CancelButton aria-label="Cancel changes" onClick={onCancel} />
          {/* <StateButton
            outline
            status="error"
            className="btn-sm"
            onClick={onCancel}
          >
            {cancelCaption}
          </StateButton> */}
          <SaveButton aria-label="Save changes" onClick={handleSave} />
          {/* <PrimaryButton type="submit" className="btn-sm" onClick={handleSave}>
            {saveCaption}
          </PrimaryButton> */}
        </div>
      )}
    </div>
  );
}
