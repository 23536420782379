import User from '../../../types/User';
import Heading from '../../typography/Heading';

function AvatarModal(user: User, imageUrl: string) {
  return (
    <div className="flex flex-col gap-4">
      <Heading level={3}>
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {user.firstName} {user.lastName}
      </Heading>
      <div className="w-full">
        <img className="w-full object-cover" src={imageUrl} alt="pilot" />
      </div>
    </div>
  );
}

export default AvatarModal;
