import { SecondaryButton } from '../../../components/atoms/Button/Button';
import QuoteCard from '../../../components/organisms/QuoteCard/QuoteCard';
import Heading from '../../../components/typography/Heading';
import { useModalContext } from '../../../hooks/ModalProvider/ModalProvider';
import { Quote } from '../../../types/Quote';

interface QuoteModalProps {
  quote: Quote;
}

function QuoteModal({ quote }: QuoteModalProps) {
  const { closeModal } = useModalContext();

  return (
    <div className="flex flex-col gap-4 mb-24">
      <Heading level={3}>Quote Details</Heading>
      <QuoteCard quote={quote} />
      <SecondaryButton onClick={closeModal}>Return</SecondaryButton>
    </div>
  );
}

export default QuoteModal;
