async function compressImage(
  blob: Blob,
  quality = 0.7,
  maxWidth = 800,
  maxHeight = 600
) {
  // Create an ImageBitmap from the Blob
  const imageBitmap = await createImageBitmap(blob);

  // Calculate new dimensions while maintaining aspect ratio
  let { width, height } = imageBitmap;
  const aspectRatio = width / height;

  if (width > maxWidth) {
    width = maxWidth;
    height = width / aspectRatio;
  }

  if (height > maxHeight) {
    height = maxHeight;
    width = height * aspectRatio;
  }

  // Create a canvas to draw the resized image
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx!.drawImage(imageBitmap, 0, 0, width, height);

  // Convert the canvas back to a Blob with the desired quality
  const compressedBlob: Blob = await new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        if (blob) resolve(blob);
      },
      'image/jpeg',
      quality // Quality ranges from 0 (lowest) to 1 (highest)
    );
  });

  return compressedBlob;
}

export default compressImage;
