import PlaneTypeStatsGrouped from '../../types/PlaneTypeStatsGrouped';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';

const endpoint = `/api/plane-type-stats/grouped-totals/`;

interface PlaneTypeStatsGroupedResponse {
  data: PlaneTypeStatsGrouped[];
  statusCode: number;
}

const getPlaneTypeStatsGrouped =
  async (): Promise<PlaneTypeStatsGroupedResponse | null> => {
    try {
      const planeTypeStatsGroupedResponse = await fetch(endpoint, {
        method: 'GET',
      });

      const planeTypes = await planeTypeStatsGroupedResponse.json();

      return {
        data: remapKeysToCamelCase(planeTypes) as PlaneTypeStatsGrouped[],
        statusCode: planeTypeStatsGroupedResponse.status,
      };
    } catch (error) {
      return null;
    }
  };

export default getPlaneTypeStatsGrouped;
