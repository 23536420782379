import translations from './lang';

// create TranslationObject from <lang>.ts file
type TranslationObject = (typeof translations)['en'];
type TranslationPhraseGroup = keyof TranslationObject;
type AnyObject = Record<string, any>;

interface StringsDictionary {
  [key: string]: { [key: string]: string };
}

type StringObject = {
  [key in keyof TranslationObject]: keyof TranslationObject[key];
};

/**
 * find a string from  <language>.ts, then replace its slots with values, returning a fully formatted string for the UI
 * @param {Object} phraseLookup - an object with keys and values that map to strings
 * @param {Object} data - values of slots to replace in the string. Key is the slot name, value is the value to replace. This parameter is optional
 * @returns {string} the formatted string
 */
export default (phraseLookup: Partial<StringObject>, data?: AnyObject) => {
  // can store/lookup in localStorage or cookies eventually if we want i18n
  const language = 'en';
  const strings: StringsDictionary = translations[language];
  const key: TranslationPhraseGroup = Object.keys(
    phraseLookup
  )[0] as keyof TranslationObject;
  // find the string from the translations object
  let translation = strings[key][(phraseLookup as StringObject)[key]];
  // if there are slots to replace
  if (data) {
    // for each slot
    const slots = Object.keys(data);
    slots.forEach((slot) => {
      // replace each match with the provided value
      translation = translation.replaceAll(`%${slot}%`, data[slot]);
    });
  }
  // return the formatted string
  return translation;
};
