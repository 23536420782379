import { AnyObject } from '../types/util';

/** This recursively remaps objects with snake_case to use camelCase */
export function remapKeysToCamelCase(item: unknown): unknown {
  if (Array.isArray(item)) {
    return item.map((el: unknown) => remapKeysToCamelCase(el));
  }
  if (typeof item === 'function' || item !== Object(item)) {
    return item;
  }
  return Object.fromEntries(
    Object.entries(item as Record<string, unknown>).map(
      ([key, value]: [string, unknown]) => [
        key.replace(/([-_][a-z])/gi, (c) =>
          c.toUpperCase().replace(/[-_]/g, '')
        ),
        remapKeysToCamelCase(value),
      ]
    )
  );
}

/** This recursively remaps objects with camelCase to use snake_case */
export function remapKeysToSnakeCase(item: unknown): unknown {
  if (Array.isArray(item)) {
    return item.map((el: unknown) => remapKeysToSnakeCase(el));
  }
  if (typeof item === 'function' || item !== Object(item)) {
    return item;
  }
  return Object.fromEntries(
    Object.entries(item as Record<string, unknown>).map(
      ([key, value]: [string, unknown]) => [
        key
          .replace(/\.?([A-Z]+)/g, (x, y) => `_${y.toLowerCase()}`)
          .replace(/^_/, ''),
        remapKeysToSnakeCase(value),
      ]
    )
  );
}
