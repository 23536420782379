import React from 'react';

function Envelope() {
  return (
    <svg width="20px" height="16px" viewBox="0 0 20 16" version="1.1">
      <g
        id="kayak-pivot"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Profile-+-Settings-Client"
          transform="translate(-291.000000, -579.000000)"
          fill="#C3C8CA"
        >
          <g id="profile" transform="translate(231.000000, 226.000000)">
            <g id="Group-2" transform="translate(45.000000, 108.000000)">
              <g id="Form" transform="translate(0.000000, 156.000000)">
                <g id="ic_email" transform="translate(14.000000, 86.000000)">
                  <path
                    d="M18.3333333,3.66666667 L3.66666667,3.66666667 C2.65833333,3.66666667 1.8425,4.49166667 1.8425,5.5 L1.83333333,16.5 C1.83333333,17.5083333 2.65833333,18.3333333 3.66666667,18.3333333 L18.3333333,18.3333333 C19.3416667,18.3333333 20.1666667,17.5083333 20.1666667,16.5 L20.1666667,5.5 C20.1666667,4.49166667 19.3416667,3.66666667 18.3333333,3.66666667 L18.3333333,3.66666667 Z M18.3333333,7.33333333 L11,11.9166667 L3.66666667,7.33333333 L3.66666667,5.5 L11,10.0833333 L18.3333333,5.5 L18.3333333,7.33333333 L18.3333333,7.33333333 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Envelope;
