import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
  StateButton,
} from '../../../components/atoms/Button/Button';
import QuoteCard from '../../../components/organisms/QuoteCard/QuoteCard';
import TripCard from '../../../components/organisms/TripCard/TripCard';
import Heading from '../../../components/typography/Heading';
import { useUser } from '../../../hooks/UserProvider/UserProvider';
import useForm from '../../../hooks/useForm';
import {
  PER_DIEM_DEFAULT,
  Quote,
  createQuoteFromBid,
} from '../../../types/Quote';
import Trip from '../../../types/Trip';
import { ROUTES } from '../../routerConfig';
import Bid from '../../../types/Bid';
import ConsumerCard from '../../../components/molecules/ConsumerCard/ConsumerCard';
import Chat from '../../../components/organisms/Chat/Chat';
import Rule from '../../../components/atoms/Rule';
import { useModalContext } from '../../../hooks/ModalProvider/ModalProvider';
import ControlBar from '../../../components/molecules/ControlBar/ControlBar';
import declineTripAsPilot from '../../../API/Trips/declineTripAsPilot';
import DeclineHireModal from './DeclineHireModal';
import PrimaryTripLayout from '../Layouts/PrimaryTripLayout';

interface AvailableTripProps {
  trip: Trip;
  bid: Bid;
  openDetailsModal: () => unknown;
  openQuoteModal: () => unknown;
}

function QuotedTrip({
  trip,
  bid,
  openDetailsModal,
  openQuoteModal,
}: AvailableTripProps) {
  const { user } = useUser();
  const navigate = useNavigate();
  const { closeModal, openModal } = useModalContext();

  const initialForm = createQuoteFromBid(bid, user!, trip);

  const { inputs, handleChange, resetForm } = useForm(initialForm);

  const navigateToTrips = () => navigate(ROUTES.TRIPS);

  const handleDeclineHire = useCallback(async () => {
    try {
      await declineTripAsPilot(trip.id);
      closeModal();
    } catch (error) {
      console.error('Error cancelling trip:', error);
    }
  }, [closeModal, trip.id]);

  const openDeclineHireModal = useCallback(() => {
    openModal(DeclineHireModal(trip, handleDeclineHire, closeModal));
  }, [closeModal, handleDeclineHire, openModal, trip]);

  return (
    <div className="relative">
      {/* Mobile secondary top menu */}
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" /> Trips
        </PrimaryButton>
        <Rule className="md:hidden" />
        <SecondaryButton size="small" onClick={openQuoteModal}>
          Review Quote
        </SecondaryButton>
        <SecondaryButton
          size="small"
          onClick={openDetailsModal}
          className="lg:hidden"
        >
          View Trip
        </SecondaryButton>
        <Rule className="md:hidden" />
        <StateButton
          className="md:ml-auto"
          size="small"
          status="error"
          onClick={openDeclineHireModal}
        >
          Cancel Quote
        </StateButton>
      </ControlBar>
      <div className="flex flex-col gap-4">
        <Heading level={2}>Viewing quote on &ldquo;{trip.name}&rdquo;</Heading>
        <PrimaryTripLayout>
          {/** Operator profile area */}
          <div className="relative h-full">
            <ConsumerCard
              user={trip.conversations[0].user}
              className="h-full"
              userTitle="Client"
            />
          </div>
          {/** Chat area */}
          <div className="h-full flex flex-col gap-4">
            {/** Quote card */}
            <QuoteCard quote={inputs} className="flex-grow" />
            <Chat tripId={trip.id} conversation={trip.conversations[0]} />
          </div>
          <div className="hidden lg:block h-full">
            {/** Trip card */}
            <TripCard
              trip={trip}
              showStatus={false}
              expanded="always"
              interactive={false}
              className="h-full"
            />
          </div>
        </PrimaryTripLayout>
      </div>
    </div>
  );
}

export default QuotedTrip;
