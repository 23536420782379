import { PilotRole } from '../../types/PilotRole';
import updatePilot from './updatePilot';

type Rates = Record<number, number>;
type UpdatePilotPayload = {
  hoursPerPlaneType: {
    pricePerDay: number;
    planeTypeId: number;
  }[];
};

async function updateRates(pilot: PilotRole, rates: Rates) {
  const payload: UpdatePilotPayload = {
    hoursPerPlaneType: [],
  };

  // wrap updatePilot with an hours_per_plane_type payload
  Object.entries(rates).forEach(([planeTypeId, rate]) => {
    const planeTypeStat = pilot.statistics.planeTypeStatistics.find(
      (pts) => pts.planeType === Number(planeTypeId)
    );
    if (planeTypeStat) {
      payload.hoursPerPlaneType.push({
        pricePerDay: Number(rate),
        planeTypeId: Number(planeTypeStat.planeType),
      });
    }
  });

  console.log('UPDATE RATES');
  console.log(payload);
  await updatePilot(pilot.id, payload);
}

export default updateRates;
