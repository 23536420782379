import { endpoints } from '../endpoints';

async function removeLimitOnPilotCertificate(limitId: number) {
  try {
    await fetch(`${endpoints.PILOT_CERTIFICATE_LIMITS}${limitId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      credentials: 'include',
    });
  } catch (error) {
    console.error(error);
  }
}

export default removeLimitOnPilotCertificate;
