import React from 'react';

export default function Cycle() {
  return (
    <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
      <g
        id="current---May-15-2018"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="1-Owner/Operator_Upcoming-Trips-(button-Expanded-Copy-12"
          transform="translate(-1169.000000, -829.000000)"
          fill="#717F82"
          fillRule="nonzero"
          stroke="#717F82"
          strokeWidth="0.5"
        >
          <g
            id="button/desktop/create-new-trip-copy-5"
            transform="translate(1108.000000, 808.000000)"
          >
            <g id="Plus-Button-(pill)">
              <g id="Group-17" transform="translate(62.000000, 18.000000)">
                <path
                  d="M0.5,10 C0.224,10 0,9.776 0,9.5 L0,6.5 C0,6.224 0.224,6 0.5,6 C0.776,6 1,6.224 1,6.5 L1,8.021 C2.121,5.603 4.515,4 7.25,4 C10.474,4 13.244,6.215 13.987,9.386 L13.977,9.389 C13.986,9.425 14,9.46 14,9.5 C14,9.776 13.776,10 13.5,10 C13.263,10 13.074,9.832 13.023,9.611 L13.013,9.614 C12.377,6.897 10.007,5 7.25,5 C4.708,5 2.515,6.621 1.686,9 L3.5,9 C3.776,9 4,9.224 4,9.5 C4,9.776 3.776,10 3.5,10 L0.5,10 Z M13.5,12 C13.776,12 14,12.224 14,12.5 L14,15.5 C14,15.776 13.776,16 13.5,16 C13.224,16 13,15.776 13,15.5 L13,13.979 C11.878,16.398 9.484,18 6.75,18 C3.525,18 0.755,15.785 0.013,12.614 L0.023,12.612 C0.014,12.575 0,12.54 0,12.5 C0,12.224 0.224,12 0.5,12 C0.736,12 0.925,12.167 0.977,12.388 L0.987,12.386 C1.623,15.103 3.992,17 6.75,17 C9.29,17 11.485,15.379 12.314,13 L10.5,13 C10.224,13 10,12.776 10,12.5 C10,12.224 10.224,12 10.5,12 L13.5,12 Z"
                  id="Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
