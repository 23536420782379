import React from 'react';
import Arrow from './Arrow';

// TODO: revisit typings
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function ActionIcon(props: { [index: string]: any }) {
  const { action } = props;

  // eslint-disable-next-line react/jsx-props-no-spreading
  if (action === 'go') return <Arrow {...props} />;
  return null;
}
