import React, { ReactNode, useEffect } from 'react';
import classNames from 'classnames';
import Button from '../../atoms/Button';
import Rule from '../../atoms/Rule';
import { useScroll } from '../../../hooks/ScrollProvider/ScrollProvider';

import './SlidingPanel.css';
import CancelIcon from '../../../assets/icons/cancel';
import { PrimaryButton, SecondaryButton } from '../../atoms/Button/Button';
import CloseButton from '../../molecules/CloseButton/CloseButton';
import UserRole from '../../../types/UserRole';

interface SlidingPanelProps {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  closeHandler: VoidFunction;
  from: 'top' | 'right';
  role: UserRole;
}

function SlidingPanel({
  children,
  className,
  isOpen,
  closeHandler,
  from = 'top',
  role,
}: SlidingPanelProps) {
  const { lockBodyScroll, unlockBodyScroll } = useScroll();

  // If opened, lock scroll on body
  // If closed, unlock scroll on body
  useEffect(() => {
    if (isOpen && from === 'top') lockBodyScroll();
    else unlockBodyScroll();
  }, [isOpen, from, lockBodyScroll, unlockBodyScroll]);

  return (
    <div
      className={classNames(
        'slidingPanel flex gap-2 z-40 py-8 px-6 flex-col absolute top-0 transform transition-transform duration-300 ease-in-out',
        from,
        from === 'right' ? 'shadow-md' : '',
        isOpen ? 'active' : '',
        {
          'bg-white': role === 'consumer',
          'bg-accent': role === 'pilot',
        }
      )}
    >
      {/* <PrimaryButton
        outline
        className="btn-circle p-4 mb-4 self-start"
        onClick={closeHandler}
      >
        <CancelIcon />
      </PrimaryButton> */}
      <CloseButton className="self-start" onClick={closeHandler} />
      {children}
      <Rule className="mt-8" />
      <SecondaryButton className="mt-4" onClick={closeHandler}>
        Close
      </SecondaryButton>
    </div>
  );
}

export default SlidingPanel;
