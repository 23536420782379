/**
 * Reusable UI pattern for Tabbed Navigation
 */

import { ReactNode, useState } from 'react';

/** Key to lookup tab content in the TabData prop */
export type TabId = number | string;

/** The display name (optional but recommended) and content of a given tab */
export type TabContent = {
  displayName?: ReactNode;
  content: ReactNode;
};

/** TabData is a mapping of TabId to TabContent */
export type TabData = Record<TabId, TabContent>;

/**
 * tabs - the content of the tabs, mapped by TabId
 *
 * initialTab - (optional) Initial tab to display on Tabs render
 *
 * onTabChange - (optional) listener for tab change events
 */
export interface TabsProps {
  initialTab?: TabId;
  tabs: TabData;
  onTabChange?: (previousTab: TabId, newTab: TabId) => void;
  tabStyle?: 'lifted' | 'boxed';
  className?: string;
}

/**
 * Standard UI representation of a tabbed navigation interface.
 */
function Tabs({
  tabs,
  initialTab: initialTabId = Object.keys(tabs)[0],
  onTabChange,
  tabStyle = 'lifted',
  className,
}: TabsProps) {
  const [currentTabId, setCurrentTabId] = useState(initialTabId);

  const handleTabClick = (tabId: TabId) => {
    if (onTabChange) onTabChange(currentTabId, tabId);
    setCurrentTabId(tabId);
  };

  const tabsStyle = {
    lifted: 'tabs-lifted',
    boxed: 'tabs-boxed',
  };

  return (
    <>
      <div
        className={`tabs ${tabsStyle[tabStyle]} items-center max-w-fit ${className}`}
        role="tablist"
      >
        {Object.keys(tabs).map((tabId, i) => (
          <button
            type="button"
            role="tab"
            key={tabId}
            className={`tab ${
              currentTabId === tabId && 'tab-active'
            } h-fit group`}
            onClick={() => handleTabClick(tabId)}
          >
            {tabs[tabId].displayName || i}
          </button>
        ))}
      </div>
      <div>{tabs[currentTabId]?.content}</div>
    </>
  );
}

export default Tabs;
