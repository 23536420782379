import React from 'react';
import FormikInput from '../../../atoms/Form/FormikInput';
import FormikField from '../../../atoms/Form/FormikField';
import FormikTextArea from '../../../atoms/Form/FormikTextArea';
import i18n from '../../../../strings/i18n';

function AircraftMaintenance() {
  return (
    <>
      {/* create a FormikField for each property in Aircraft.maintenance */}
      <FormikField
        name="maintenance.contactName"
        label="Contact Name"
        placeholder={i18n({ placeholder: 'contactName' })}
        component={FormikInput}
      />
      <FormikField
        name="maintenance.contactPhoneNumber"
        label="Contact Phone"
        placeholder={i18n({ placeholder: 'phoneNumber' })}
        component={FormikInput}
      />
      {/* TODO: fetch from a backend list when ready */}
      <FormikField
        name="maintenance.preferredMaintenanceFacility"
        label="Preferred Maintenance Facility"
        placeholder={i18n({ placeholder: 'preferredMaintenanceFacility' })}
        component={FormikInput}
      />
      {/* TODO: future - will be a reference to an airport */}
      {/* <FormikField
        name="maintenance.maintenanceFacilityLocation"
        label="Maintenance Facility Location"
        placeholder="Enter your preferred maintenance facility..."
        component={FormikInput}
      /> */}
      <FormikField
        name="maintenance.squawks"
        label="Squawks"
        placeholder={i18n({ placeholder: 'squawks' })}
        component={FormikTextArea}
        optional
      />
    </>
  );
}

export default AircraftMaintenance;
