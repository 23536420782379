import { Form, Formik, FormikHelpers } from 'formik';
import {
  SecondaryButton,
  StateButton,
} from '../../../components/atoms/Button/Button';
import Heading from '../../../components/typography/Heading';
import FormikTextArea from '../../../components/atoms/Form/FormikTextArea';
import FormikField from '../../../components/atoms/Form/FormikField';
import FormikRating from '../../../components/atoms/Form/FormikRating';
import FormField from '../../../components/wrappers/FormField';
import { ReviewOperatorFields } from '../../../API/Trips/reviewTripAsPilot';

function ReviewOperatorModal({
  onSubmit,
  onClose,
  debug = false,
}: {
  onSubmit: (
    values: ReviewOperatorFields,
    helpers: FormikHelpers<ReviewOperatorFields>
  ) => void;
  debug?: boolean;
  onClose: () => unknown;
}) {
  return (
    <div className="w-full flex flex-col gap-4 mb-24">
      <Heading level={3}>Review your trip</Heading>
      <Heading level={4}>
        How did you fly? If you had any major concerns, please email us at
        info@flyingcompany.com
      </Heading>
      <Formik
        initialValues={{
          pilotConsumerRating: 0,
          pilotFlycoRating: 0,
          pilotNotes: '',
        }}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form className="flex flex-col">
            <FormField>
              <FormikRating
                name="pilotConsumerRating"
                label="Review Operator"
              />
            </FormField>
            <FormField>
              <FormikRating
                name="pilotFlycoRating"
                label="Review Flying Company"
              />
            </FormField>
            <FormikField
              name="pilotNotes"
              label="Additional Notes"
              placeholder="Please share some feedback on your experiences with the Operator and Flying Company..."
              component={FormikTextArea}
              type="number"
            />
            <div className="w-full grid grid-cols-2 gap-4">
              <StateButton status="error" onClick={onClose}>
                Cancel
              </StateButton>
              <SecondaryButton type="submit" isLoading={formik.isSubmitting}>
                Submit feedback
              </SecondaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ReviewOperatorModal;
