import React from 'react';
import { useFormikContext } from 'formik';

/**
 * Nest this component inside your form to see the Formik state
 * @example
 * <Formik>
 *  <Form>
 *    ...
 *    <Debug />
 *  </Form>
 * </Formik>
 */
export default function Debug() {
  const { ...fullContext } = useFormikContext();

  return (
    <div className="my-12 mx-4 bg-base-200 shadow-inner rounded-md">
      <div className="uppercase text-xs font-semibold p-2 bg-primary text-base-100 tracking-[1px] rounded-t-md">
        Formik State
      </div>
      <pre className="text-sm py-1 px-2 overflow-scroll max-h-[500px]">
        {JSON.stringify(fullContext, null, 2)}
      </pre>
    </div>
  );
}
