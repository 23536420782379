import { ReactNode } from 'react';

interface ControlBarProps {
  children: ReactNode;
  className?: string;
}

function ControlBar({ children, className }: ControlBarProps) {
  return (
    <div
      className={`sticky top-[-2rem] mt-[-4rem] mb-12 flex flex-wrap gap-2 p-2 z-10 bg-primary border-2 border-t-0 rounded-b-xl ${
        className || ''
      }`}
    >
      {children}
    </div>
  );
}

export default ControlBar;
