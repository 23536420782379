import { ReactNode, useState } from 'react';
import classNames from 'classnames';
import useClickOutside from '../../../hooks/useClickOutside';

/** Key to lookup tab content in the SidebarData prop */
export type SidebarId = number | string;

/** The display name (optional but recommended) and content of a given tab */
export type SidebarContent = {
  displayName?: string;
  content: ReactNode;
};

/** SidebarData is a mapping of SidebarId to SidebarContent */
export type SidebarData = Record<SidebarId, SidebarContent>;

/**
 * menuItems - the content of the tabs, mapped by SidebarId
 *
 * initialSidebar - (optional) Initial tab to display on Sidebar render
 *
 * onSidebarChange - (optional) listener for tab change events
 */
export interface SidebarProps {
  initialSidebar?: SidebarId;
  menuItems: SidebarData;
  menuTitle?: string;
  onSidebarChange?: (previousSidebar: SidebarId, newSidebar: SidebarId) => void;
}

/**
 * Standard UI representation of a sidebar navigation interface.
 */
function Sidebar({
  menuItems,
  menuTitle,
  initialSidebar = Object.keys(menuItems)[0],
  onSidebarChange,
}: SidebarProps) {
  const [currentSidebar, setCurrentSidebar] = useState(initialSidebar);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useClickOutside(() => {
    setIsOpen(false);
  });

  const handleOpenSidebar = () => {
    if (isOpen === false) {
      setIsOpen(true);
    }
  };

  const handleSidebarClick = (tabId: SidebarId) => {
    if (onSidebarChange) onSidebarChange(currentSidebar, tabId);
    setCurrentSidebar(tabId);
  };

  return (
    <>
      <button
        data-drawer-target="default-sidebar"
        data-drawer-toggle="default-sidebar"
        aria-controls="default-sidebar"
        type="button"
        className="inline-flex items-center p-2 mt-2 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
        onClick={handleOpenSidebar}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            clipRule="evenodd"
            fillRule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
          />
        </svg>
      </button>

      <aside
        id="default-sidebar"
        className={classNames(
          'fixed lg:static left-0 z-40 max-w-64 transition-transform -translate-x-full lg:translate-x-0',
          isOpen && 'transform-none'
        )}
        aria-label="Sidebar"
        ref={ref}
      >
        <ul className="h-full menu bg-base-200 lg:w-56 lg:rounded-l-2xl">
          {menuTitle && <li className="menu-title">{menuTitle}</li>}
          {Object.keys(menuItems).map((tabId) => (
            <li key={menuItems[tabId].displayName || `menu-${tabId}`}>
              <button
                type="button"
                onClick={() => handleSidebarClick(tabId)}
                className={classNames(
                  'flex items-center p-2 rounded-lg',
                  currentSidebar === tabId && 'active !bg-primary'
                )}
              >
                <span className="ms-3">
                  {menuItems[tabId].displayName || tabId}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </aside>

      <div className="h-full w-full lg:w-3/4">
        {menuItems[currentSidebar]?.content}
      </div>
    </>
  );
}

export default Sidebar;
