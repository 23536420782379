import classNames from 'classnames';
import React, { InputHTMLAttributes } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value'> {
  value: boolean | 'true' | 'false';
  inverse?: boolean;
}
function Checkbox({
  className,
  name,
  id,
  value,
  disabled,
  inverse = false,
  onChange,
}: CheckboxProps) {
  const valueAsBool =
    typeof value === 'boolean' ? value : Boolean(value && value !== 'false');

  return (
    // eslint-disable-next-line jsx-a11y/control-has-associated-label
    <input
      id={id}
      name={name || id}
      type="checkbox"
      disabled={disabled}
      className={classNames(
        'checkbox p-3 disabled:checked:!opacity-100 disabled:[--chkbg:oklch(var(--b2))] disabled:checked:[--chkfg:oklch(var(--n))]',
        {
          '[--fallback-bc:oklch(var(--b3))] [--chkbg:oklch(var(--b3))] [--chkfg:oklch(var(--bc))]':
            inverse,
        }
      )}
      value={String(value) ?? String(false)}
      checked={valueAsBool}
      onChange={onChange}
    />
  );
}

export default Checkbox;
