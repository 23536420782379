import { useState } from 'react';
import { useModalContext } from '../../../hooks/ModalProvider/ModalProvider';
import { PrimaryButton, StateButton } from '../../atoms/Button/Button';
import Heading from '../../typography/Heading';
import Loader from '../../molecules/Loader';
import Paragraph from '../../typography/Paragraph';

interface ConfirmPilotConversionModalProps {
  handleYes: () => Promise<void>;
}

export default function ConfirmPilotConversionModal({
  handleYes,
}: ConfirmPilotConversionModalProps) {
  const { closeModal } = useModalContext();
  const [loading, setLoading] = useState(false);

  const handleConfirm = async () => {
    setLoading(true);
    await handleYes();
    setLoading(false);
    closeModal();
  };

  return (
    <div className="flex flex-col gap-4">
      {loading ? (
        <Loader level={3} caption="Creating a pilot account..." />
      ) : (
        <>
          <Heading level={3}>Create a Pilot Account</Heading>
          <Paragraph>
            When you choose to Create a Pilot Account, you unlock the ability to
            create a detailed pilot profile and provide quotes for contract
            trips. This allows you to seamlessly switch between your Operator
            and Pilot roles, giving you flexibility to manage both aspects of
            your aviation business as necessary.
          </Paragraph>
          <div className="flex gap-4">
            <StateButton status="error" size="small" onClick={closeModal}>
              Cancel
            </StateButton>
            <PrimaryButton onClick={handleConfirm} size="small">
              Confirm
            </PrimaryButton>
          </div>
        </>
      )}
    </div>
  );
}
