import React from 'react';

// https://formik.org/docs/api/connect#example
interface Props {
  /** marking optional for the case of using as `component` with Formik's ErrorMessage */
  children?: React.ReactNode;
}
export default function ErrorMessage({ children }: Props) {
  return (
    <span className="text-error py-2">
      {Array.isArray(children)
        ? children.map((child) => <span>{child}</span>)
        : children}
    </span>
  );
}
