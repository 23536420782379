import Heading from '../../components/typography/Heading';
import Paragraph from '../../components/typography/Paragraph';

function TermsOfUsePage() {
  return (
    <>
      <Heading level={1}>Terms of Service</Heading>
      <Heading level={3}>How it Works</Heading>
      <ul className="pl-8 list-disc">
        <li>
          Aircraft Owners and Operators (Clients) and Pilots coordinate
          scheduling and payments through the Flying Company, LLC platform.{' '}
        </li>
        <li>
          Flying Company, LLC does not employ Pilots. Pilots are independent
          contractors and are hired by Clients.
        </li>
        <li>
          Clients are responsible for verifying that a Pilot is qualified to
          perform a flight. Flying Company, LLC does not vet or supervise Pilots
          in any way.
        </li>
        <li>Read our Terms of Service and Privacy Policy.</li>
      </ul>
      <Paragraph>Last updated: November 26th, 2022</Paragraph>
      <Paragraph>
        These Terms of Service constitute a legally binding agreement
        (“Agreement”) between you and Flying Company, LLC (“Company”) governing
        your use of the Flying Company, LLC Platform (as defined below) and the
        Company&apos;ss website (FlyingCompany.com or the “Site”). The platform
        services provided by Company and the Site together are hereinafter
        collectively referred to as the “Platform.”
      </Paragraph>
      <Paragraph>
        Your use of the Platform constitutes your acceptance of and agreement to
        all of the terms and conditions in these Terms of Service and the
        Privacy Policy (the “Privacy Policy”) available here, and any future
        amendments and additions to this Agreement (as defined below) as we may
        publish from time to time. The Privacy Policy is incorporated by
        reference into these Terms of Service and together form and are
        hereinafter referred to as this “Agreement”.
      </Paragraph>
      <Paragraph>A few highlights of these Terms of Service include:</Paragraph>
      <ul className="pl-8 list-disc">
        <li>
          Your agreement that the Platform is provided “as is” and without
          warranty (Section 17).
        </li>
        <li>
          Your agreement that Company has no liability regarding the Platform
          (Section 18).
        </li>
        <li>
          Your acknowledgment and agreement that Company does not supervise,
          direct or control a Pilot&apos;ss work or the Flights in any manner
          (Section 1).
        </li>
        <li>
          Your acknowledgement and agreement that Clients (as defined below) are
          responsible for determining if the Pilot they hire is qualified to
          perform the Flight. You further acknowledge and agree that Company
          does not determine whether any particular Pilot holds a license,
          certification, accreditation or insurance.
        </li>
        <li>
          Your agreement that Company has no liability regarding the performance
          of Flights. Notwithstanding that, Company provides Users the Happiness
          Pledge (Section 13) to ensure your satisfaction with the Platform,
          which provides protection for Company&apos;ss Users.
        </li>
        <li>
          Your consent to release Company from liability based on claims between
          Users (Section 2) and generally (Section 18).
        </li>
        <li>
          Your agreement to indemnify Company from claims due to your use or
          inability to use the Platform or content submitted from your account
          to the Platform (Section 19).
        </li>
        <li>
          Your consent to submit any claims against Company to binding
          arbitration on an individual basis only (Section 20).
        </li>
      </ul>
      <Paragraph>
        <strong>1. Platform Connects Pilots and Clients</strong>
      </Paragraph>
      The Platform is a web-based communications platform which enables the
      connection between Clients and Pilots. “Clients” are individuals and/or
      businesses seeking to obtain Flight services (“Flights”) from Pilots and
      are therefore clients of Pilots, and “Pilots” are individuals and/or
      businesses seeking to perform Flights for Clients. Clients and Pilots
      together are hereinafter referred to as “Users.” If you agree on the terms
      of a Flight with another User, you and such other User form a Service
      Agreement directly between the two of you as set forth in more detail in
      Section 3 below. PILOTS ARE INDEPENDENT CONTRACTORS AND NOT EMPLOYEES OF
      COMPANY. COMPANY DOES NOT PERFORM FLIGHTS AND DOES NOT EMPLOY INDIVIDUALS
      TO PERFORM FLIGHTS. USERS HEREBY ACKNOWLEDGE THAT COMPANY DOES NOT
      SUPERVISE, DIRECT, CONTROL OR MONITOR A PILOT&apos;sS WORK AND IS NOT
      RESPONSIBLE FOR THE WORK PERFORMED OR THE FLIGHTS IN ANY MANNER. The
      Platform only enables connections between Users for the fulfillment of
      Flights. Company is not responsible for the performance of Users, nor does
      it have control over the quality, timing, legality, failure to provide, or
      any other aspect whatsoever of Flights, Pilots, Clients, nor of the
      integrity, responsibility, qualifications or any of the actions or
      omissions whatsoever of any Users. Company makes no representations about
      the suitability, reliability, timeliness, or accuracy of the Flights
      requested and services provided by Users identified through the Platform.
      <Paragraph>
        <strong>2. User Vetting</strong>
      </Paragraph>
      User Vetting In Company&apos;ss sole discretion, Users may be subject to
      an extensive vetting process before they can register for and during their
      use of the Platform, including but not limited to a verification of
      identity and a comprehensive criminal background check, at the Country,
      State and local level, using third party services as appropriate. Users
      hereby give consent to Company to conduct background checks as often as
      required in compliance with federal and state laws, including, without
      limitation, the Fair Credit Reporting Act. Although Company may perform
      background checks of Users, as outlined above, Company is not required to
      do so and cannot confirm that each User is who they claim to be and
      therefore, Company cannot and does not assume any responsibility for the
      accuracy or reliability of identity or background check information or any
      information provided through the Platform. When interacting with other
      Users, you should exercise caution and common sense to protect your
      personal safety and property, just as you would when interacting with
      other persons whom you don&apos;st know. NEITHER COMPANY NOR ITS
      AFFILIATES, OR ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, MEMBERS,
      PARTNERS, SHAREHOLDERS, EMPLOYEES, AGENTS, LICENSORS OR SERVICE PROVIDERS,
      IS RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE OR OFFLINE, OF ANY USER OF
      THE PLATFORM AND YOU HEREBY RELEASE COMPANY AND ITS AFFILIATES OR ANY OF
      THEIR RESPECTIVE OFFICERS, DIRECTORS, MEMBERS, PARTNERS, SHAREHOLDERS,
      EMPLOYEES, AGENTS, LICENSORS OR SERVICE PROVIDERS FROM ANY LIABILITY
      RELATED THERETO. COMPANY AND ITS AFFILIATES AND LICENSORS WILL NOT BE
      LIABLE FOR ANY CLAIM, INJURY OR DAMAGE ARISING IN CONNECTION WITH YOUR USE
      OF THE PLATFORM.
      <Paragraph>
        <strong>3. User Representation and Warranties</strong>
      </Paragraph>
      You represent and warrant that: (1) you are at least of the legally
      required age in the jurisdiction in which you reside, and are otherwise
      capable of entering into binding contracts, and (2) you have the right,
      authority and capacity to enter into this Agreement and to abide by the
      terms and conditions of this Agreement, and that you will so abide. Where
      you enter into this Agreement on behalf of a company or other
      organization, you represent and warrant that you have authority to act on
      behalf of that entity and to bind that entity to this Agreement. You
      represent and warrant that you have read, understand and are required to
      agree to and accept be bound by these Terms of Service and, the Privacy
      Policy in order to access and use the Platform. You represent and warrant
      that you will fulfill the commitments you make to other Users including
      paying/receiving payment through the Platform, communicating clearly and
      promptly through the chat thread and/or respond to requests promptly,
      being present and/or available at the time you agree upon with your Pilot
      or Client as the case may be and only utilizing the third party payment
      processing system specified or approved by us to make or receive payment
      for services provided through the Platform. You also represent and warrant
      that you will act professionally and responsibly in your interactions with
      other Users. Pilots additionally represent and warrant that you will
      provide timely, high quality services to your Clients, you will only offer
      and provide services for which you have the necessary licenses,
      certificates, ratings, endorsements, and recent experience and you will
      provide the services safely. Pilots also acknowledge they will personally
      perform the Flight for which they are hired. Contract between Clients and
      Pilots - You acknowledge and agree that a contract (the “Service
      Agreement”) is formed when you agree on the terms of a Flight with another
      User. The terms of the Service Agreement include the terms set forth in
      this Section 3, the engagement terms proposed and accepted on the
      Platform, and any other contractual terms accepted by both the Pilot and
      their Client to the extent such terms do not conflict with the terms in
      this Agreement including this Section 3 and do not expand Company&apos;ss
      obligations or restrict Company&apos;ss rights under this Agreement. You
      agree that Company is not a party to any Service Agreement and the
      formation of a Service Agreement will not, under any circumstance, create
      an employment or other service relationship between Company and the Pilot.
      The Client shall pay their Pilot directly for completed Flight services
      through the PSP (as defined below) as indicated on the Platform at the
      rates agreed to by the parties in the Service Agreement. Each party agrees
      to comply with this Agreement during the engagement, performance and
      completion of a Flight. Both parties agree to notify Company of any
      disputes prior to negotiation of or filing of any claims and to negotiate
      any dispute informally via Company representatives for at least ten (10)
      days before initiating any proceeding between the parties.
      <Paragraph>
        <strong>4. Billing and Payment</strong>
      </Paragraph>
      Users of the Platform contract for Flights directly with other Users.
      Company will not be a party to any contracts for Flights or services.
      Payment for Flight services through the Platform is made directly from the
      Client to the Pilot and not by Company. If a Client fails to pay a Pilot
      for services provided through the Platform, Company may, in its sole
      discretion, pay the Pilot for such services but Company is not obligated
      to do so. Notwithstanding the foregoing, Company is not obligated to pay
      Pilot for Client&apos;ss failure to pay for services. Users of the
      Platform will be required to provide their credit card and/or bank account
      details to Company and the Payment Service Provider retained by Company
      (the “PSP”). Pilots are responsible for invoicing their Clients within 24
      hours of the work being performed. Clients will be responsible for paying
      the invoice for each Flight (the “Invoice”), which will include (i) the
      pricing terms of the Flight agreed with and provided by a Pilot (“Flight
      Payment”), (ii) any out of pocket expenses agreed with and submitted by a
      Pilot in connection with the Flight, and (iii) cancellation fees, if
      applicable. Pilots will be responsible for paying (i) Company service
      charge, (ii) cancellation fees, if applicable and (iii) repayment of
      erroneous payments. Clients may also be charged credit card processing
      fees equal to three percent (2.9% + $0.30). Pilots may be required to
      register with the PSP, agree to Terms of Service of the PSP and go through
      a vetting process at the request of the PSP to set up their account with
      the PSP. Terms of Service between Pilots and the PSP retained by Company
      are available here (the “PSP Agreement”). By accepting these Terms of Use,
      each Pilot agrees that they have downloaded or printed, and reviewed and
      agreed to the PSP Agreement. Please note that Company is not a party to
      the PSP Agreement and that you, the PSP and any other parties listed in
      the PSP Agreement (currently, Stripe) are the parties to the PSP Agreement
      and that Company has no obligations or liability to any Pilot under the
      PSP Agreement. Company reserves the right, in its sole discretion (but not
      the obligation), upon request from Client or Pilot or upon notice of any
      potential fraud, unauthorized charges or other misuse of the Platform, to
      (i) place on hold any Flight Payment and out of pocket expenses, or (ii)
      refund, provide credits or arrange for the PSP to do so. Flight Payment
      and fees must be paid through the PSP as indicated on the Platform. Users
      of the Platform will be liable for any taxes (including VAT, if
      applicable) required to be paid on the Flight services provided under the
      Agreement (other than taxes on Company&apos;ss income).
      <Paragraph>
        <strong>5. (Reserved)</strong>
      </Paragraph>
      <Paragraph>
        <strong>6. Release</strong>
      </Paragraph>
      The Platform is only a venue for connecting Users. Because Company is not
      involved in the actual contact between Users or in the completion of the
      Flight, in the event that you have a dispute with one or more Users, you
      release Company and its affiliates (and their respective officers,
      directors, agents, investors, subsidiaries, and employees) from any and
      all claims, demands, or damages (actual or consequential) of every kind
      and nature, known and unknown, suspected and unsuspected, disclosed and
      undisclosed, arising out of or in any way connected with such disputes.
      COMPANY EXPRESSLY DISCLAIMS ANY LIABILITY THAT MAY ARISE BETWEEN USERS OF
      ITS PLATFORM. YOU HEREBY WAIVE THE PROTECTIONS OF CALIFORNIA CIVIL CODE §
      1542 (AND ANY ANALOGOUS LAW IN ANY OTHER APPLICABLE JURISDICTION) WHICH
      SAYS: “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES
      NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING
      THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED
      HIS OR HER SETTLEMENT WITH THE DEBTOR.”
      <Paragraph>
        <strong>7. Public Areas; Acceptable Use</strong>
      </Paragraph>
      The Platform may contain profiles, email systems, blogs, message boards,
      reviews, ratings, applications, job postings, chat areas, news groups,
      forums, communities and/or other message or communication facilities
      (“Public Areas”) that allow Users to communicate with other Users. You may
      only use such community areas to send and receive messages and material
      that are relevant and proper to the applicable forum. For the safety and
      integrity of the Platform, you may not share your personal contact
      information with other Users in Public Areas. Without limitation, while
      using the Platform, you may not: Defame, abuse, harass, stalk, threaten or
      otherwise violate the legal rights (such as, but not limited to, rights of
      privacy and publicity) of others, including Company staff, or use
      information learned from the Platform or during the performance of Flights
      to otherwise defame, abuse, harass, stalk, threaten, intimidate or
      mislead, or otherwise violate the legal rights of any other User or
      Company staff outside of the Platform. Publish, post, upload, distribute
      or disseminate any profane, defamatory, infringing, obscene or unlawful
      topic, name, material or information on the Platform. Use the Platform for
      any purpose, including, but not limited to requesting or completing a
      Flight, in violation of local, state, national, or international law.
      Upload files that contain software or other material that violates the
      intellectual property rights (or rights of privacy or publicity) of any
      third party. Upload files that contain viruses, Trojan horses, corrupted
      files, or any other similar software that may damage the operation of
      another&apos;s computer. Post or upload any content to which you have not
      obtained any necessary rights or permissions to use accordingly. Advertise
      or offer to sell any goods or services for any commercial purpose through
      the Platform which are not relevant to the Flight services offered through
      the Platform. Conduct or forward surveys, contests, pyramid schemes, or
      chain letters. Impersonate another person or a User or allow any other
      person or entity to use your identification. Restrict or inhibit any other
      User from using and enjoying the Public Areas. Imply or state that any
      statements you make are endorsed by Company, without the prior written
      consent of Company. Use a robot, spider, manual and/or automatic processes
      or devices to data-mine, data-crawl, scrape or index the Platform in any
      manner. Hack or interfere with the Platform, its servers or any connected
      networks. Adapt, alter, license, sublicense or translate the Platform for
      your own personal or commercial use. Remove or alter, visually or
      otherwise, any copyrights, trademarks or proprietary marks and rights
      owned by Company. Upload content to the Platform that is offensive and/or
      harmful, including, but not limited to, content that advocates, endorses,
      condones or promotes racism, bigotry, hatred or physical harm of any kind
      against any individual or group of individuals. Upload content that
      provides materials or access to materials that exploit people under the
      age of 18 in an abusive, violent or sexual manner. Use the Platform to
      solicit for any other business, website or service, or otherwise contact
      Users for employment, contracting or any purpose not related to use of the
      Platform as set forth herein. Use the Platform to collect usernames and
      or/email addresses of Users by electronic or other means. Use the Platform
      or the Flight services in violation of this Agreement. Use the Platform in
      a manner which is false or misleading (directly or by omission or failure
      to update information) or for the purpose of accessing or otherwise
      obtaining Company&apos;ss trade secret information for public disclosure
      or other purposes. Attempt to circumvent the payments system or service
      fees in anyway including, but not limited to, processing payments outside
      of the Platform, including inaccurate information on invoices, or
      otherwise invoicing in a fraudulent manner; Register under different
      usernames or identities, after your account has been suspended or
      terminated or register under multiple usernames or identities. Cause any
      third party to engage in the restricted activities above. You understand
      that all submissions made to Public Areas will be public and that you will
      be publicly identified by your name or login identification when
      communicating in Public Areas, and Company will not be responsible for the
      action of any Users with respect to any information or materials posted in
      Public Areas.
      <Paragraph>
        <strong>8. Termination and Suspension</strong>
      </Paragraph>
      Company may terminate, limit or suspend your right to use the Platform in
      the event that we believe that you have breached this Agreement (a “User
      Breach”) by providing you with written or email notice of such User Breach
      and such termination or suspension, and termination or suspension will be
      effective immediately upon delivery of such notice. If Company terminates,
      limits, or suspends your right to use the Platform as a Client for a User
      Breach, you will not be entitled to any refund of unused balance in your
      account, and you are prohibited from registering and creating a new
      account under your name, a fake or borrowed name, or the name of any third
      party, even if you may be acting on behalf of the third party. In addition
      to terminating, limiting, or suspending your account, Company reserves the
      right to take appropriate legal action, including without limitation
      pursuing arbitration, criminal, and injunctive redress in accordance with
      Section 20. Even after your right to use the Platform is terminated,
      limited, or suspended, this Agreement will remain enforceable against you.
      Company reserves the right to modify or discontinue, temporarily or
      permanently, all or any portion of the Platform at its sole discretion.
      Except for refundable fees you have advanced to Company (if any), Company
      is not liable to you for any modification or discontinuance of all or any
      portion of the Platform. Notwithstanding anything to the contrary in this
      Section 8, Company has the right to restrict anyone from completing
      registration as a Pilot if Company believes such person may threaten the
      safety and integrity of the Platform, or if, in Company&apos;ss
      discretion, such restriction is necessary to address any other reasonable
      business concern. You may terminate this Agreement at any time by ceasing
      all use of the Platform. All sections which by their nature should survive
      the expiration or termination of this Agreement shall continue in full
      force and effect subsequent to and notwithstanding the expiration or
      termination of this Agreement.
      <Paragraph>
        <strong>
          9. Account, Password, Security, and Mobile Phone Use, Texts and Calls
        </strong>
      </Paragraph>
      You must register with Company and create an account to use the Platform.
      You are the sole authorized user of your account. You are responsible for
      maintaining the confidentiality of any password and account number
      provided by you or Company for accessing the Platform. You are solely and
      fully responsible for all activities that occur under your password or
      account. Company has no control over the use of any User&apos;s account
      and expressly disclaims any liability derived therefrom. Should you
      suspect that any unauthorized party may be using your password or account
      or you suspect any other breach of security, you will contact Company
      immediately. By providing your mobile phone number and using the Platform,
      you hereby affirmatively consent to our use of your mobile phone number
      for calls and texts (including prerecorded and/or by automatic telephone
      dialing systems) in order to (a) perform and improve upon the Platform,
      (b) facilitate the carrying out of Flights through the Platform, (c)
      provide you with information and reminders regarding your registration,
      orientation, upcoming Flights, product alterations, changes and updates,
      service outages or alterations. These calls and texts may include, among
      other things, reminders about uncompleted or upcoming Flights. Company
      will not assess any charges for calls or texts, but standard message
      charges or other charges from your wireless carrier may apply. You may
      opt-out of receiving texts messages from us by modifying your account
      settings on the Site or texting “STOP” in response to any texts. You may
      opt-out of receiving calls from us by stating that you no longer wish to
      receive calls during any call with us. You understand that we may send you
      a text confirming any opt-out by you.
      <Paragraph>
        <strong>10. Your Information and Likeness</strong>
      </Paragraph>
      “Your Information” is defined as any information and materials you provide
      to Company or other Users in connection with your registration for and use
      of the Platform, including without limitation that posted or transmitted
      for use in Public Areas. You are solely responsible for Your Information,
      and we act merely as a passive conduit for your online distribution and
      publication of Your Information. The information and materials described
      in this Section, as provided by each User, is collectively referred to
      herein as “User Generated Content.” You hereby represent and warrant to
      Company that Your Information (a) will not be false, inaccurate,
      incomplete or misleading; (b) will not be fraudulent or involve the sale
      of counterfeit or stolen items; (c) will not infringe any third
      party&apos;s copyright, patent, trademark, trade secret or other
      proprietary right or rights of publicity or privacy; (d) will not violate
      any law, statute, ordinance, or regulation (including without limitation
      those governing export control, consumer protection, unfair competition,
      anti-discrimination or false advertising); (e) will not be defamatory,
      libelous, unlawfully threatening, or unlawfully harassing; (f) will not be
      obscene or contain child pornography or be harmful to minors; (g) will not
      contain any viruses, Trojan Horses, worms, time bombs, cancelbots or other
      computer programming routines that are intended to damage, detrimentally
      interfere with, surreptitiously intercept or expropriate any system, data
      or personal information; and (h) will not create liability for Company or
      cause Company to lose (in whole or in part) the services of its ISPs or
      other partners or suppliers. The Platform hosts User Generated Content
      relating to reviews and ratings of specific Users (“Feedback”). Such
      Feedback is such User&apos;ss opinion and not the opinion of Company, has
      not been verified or approved by Company and each User should undertake
      their own research to be satisfied with the qualities of any other User.
      You agree that Company is not liable for any Feedback or other User
      Generated Content. Company encourages each User to give objective,
      constructive and honest Feedback about the other Users with whom they have
      transacted. Company does not investigate any remarks posted by Users for
      accuracy or reliability but may do so if a User requests that Company do
      so. You hereby grant Company a non-exclusive, worldwide, perpetual,
      irrevocable, royalty-free, sublicensable (through multiple tiers) right to
      exercise all copyright, publicity rights, and any other rights you have in
      Your Information, in any media now known or not currently known in order
      to perform and improve upon the Platform. Each User who provides to
      Company any videotape, film, record, photograph, voice, or all related
      instrumental, musical, or other sound effects, in exchange for the right
      to use the Platform, hereby irrevocably grants to Company the
      non-exclusive, fully-paid, royalty-free, transferable, sublicensable,
      worldwide, unrestricted, and perpetual right to: Use any videotape, film,
      record or photograph that such User provides to Company or that Company
      takes of User, and use, reproduce, modify, or creative derivatives of such
      User&apos;ss picture, silhouette and other reproductions of their physical
      likeness (as the same may appear in any still camera photograph and/or
      motion picture film or video) (collectively the “Physical Likeness”), in
      and in connection with the exhibition, distribution, display, performance,
      transmission, broadcasting on any and all media, including, without
      limitation, the internet, of any videos or images of such User in
      connection with the Platform. Reproduce in all media any recordings of
      such User&apos;ss voice, and all related instrumental, musical, or other
      sound effects (collectively, the “Voice”), made in connection with the
      Platform. Use, and permit to be used, such User&apos;ss Physical Likeness
      and Voice in the advertising, marketing, and/or publicizing of the
      Platform in any media. Use, and permit to be used, such User&apos;ss name
      and identity in connection with the Platform. Each User hereby waives all
      rights and releases Company from, and shall neither sue nor bring any
      proceeding against any such parties for, any claim or cause of action,
      whether now known or unknown, for defamation, invasion of right to
      privacy, publicity or personality or any similar matter, or based upon or
      relating to the use and exploitation of such Pilot&apos;ss identity,
      likeness or voice in connection with the Platform. Each User acknowledges
      that Company shall not owe any financial or other remuneration for using
      the recordings provided hereunder by such User, either for initial or
      subsequent transmission or playback, and further acknowledges that Company
      is not responsible for any expense or liability incurred as a result of
      such User&apos;ss recordings or participation in any recordings, including
      any loss of such recording data.
      <Paragraph>
        <strong>11. Links to Other Websites</strong>
      </Paragraph>
      The Platform may contain links (such as hyperlinks) to third-party
      websites. Such links do not constitute the endorsement by Company of those
      sites or their content. They are provided as an information service, for
      reference and convenience only. Company does not control any such sites,
      and is not responsible for their (1) availability or accuracy, or (2)
      content, advertising, or products or services. The existence of links on
      the Platform to such websites (including without limitation external
      websites that are framed by the Platform as well as any advertisements
      displayed in connection therewith) does not mean that Company endorses any
      of the material on such websites, or has any association with their
      operators. It is your responsibility to evaluate the content and
      usefulness of the information obtained from other sites. The use of any
      website controlled, owned or operated by third parties is governed by the
      terms and conditions of use and privacy policies for those websites, and
      not by Company&apos;s Terms of Service or Privacy Policy. You access such
      third-party websites at your own risk. Company expressly disclaims any
      liability arising in connection with your use and/or viewing of any
      websites or other material associated with links that may appear on the
      Platform. You hereby agree to hold Company harmless from any liability
      that may result from the use of links that may appear on the Platform. As
      part of the functionality of the Platform, you may link your account with
      online accounts you may have with third party service providers (such as
      Facebook) (each such account, a “Third Party Account”) by either: (i)
      providing your Third Party Account login information through the Platform;
      or (ii) allowing Company to access your Third Party Account, as is
      permitted under the applicable terms and conditions that govern your use
      of each Third Party Account. You represent that you are entitled to
      disclose your Third Party Account login information to Company and/or
      grant Company access to your Third Party Account (including, but not
      limited to, for use for the purposes described herein), without breach by
      you of any of the terms and conditions that govern your use of the
      applicable Third Party Account and without obligating Company to pay any
      fees or making Company subject to any usage limitations imposed by such
      third party service providers. By granting Company access to any Third
      Party Accounts, you understand that (i) Company may access, make available
      and store (if applicable) any content that you have provided to and stored
      in your Third Party Account (the “SNS Content”) so that it is available on
      and through the Platform via your account, including without limitation
      any friend lists, and (ii) Company may submit and receive additional
      information to your Third Party Account to the extent you are notified
      when you link your account with the Third Party Account. Unless otherwise
      specified in these Terms of Service, all SNS Content, if any, shall be
      considered to be User Generated Content for all purposes of these Terms of
      Service. Depending on the Third Party Accounts you choose and subject to
      the privacy settings that you have set in such Third Party Accounts,
      personally identifiable information that you post to your Third Party
      Accounts may be available on and through your account on the Platform.
      Please note that if a Third Party Account or associated service becomes
      unavailable or Company&apos;ss access to such Third Party Account is
      terminated by the third party service provider, then SNS Content may no
      longer be available on and through the Platform. You will have the ability
      to disable the connection between your account on the Platform and your
      Third Party Accounts at any time. PLEASE NOTE THAT YOUR RELATIONSHIP WITH
      THE THIRD PARTY SERVICE PROVIDERS ASSOCIATED WITH YOUR THIRD PARTY
      ACCOUNTS IS GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH THIRD PARTY
      SERVICE PROVIDERS. Company makes no effort to review any SNS Content for
      any purpose, including but not limited to, for accuracy, legality or
      non-infringement, and Company is not responsible for any SNS Content. You
      acknowledge and agree that Company may access your e-mail address book
      associated with a Third Party Account and your contacts list stored on
      your mobile device or tablet computer solely for the purposes of
      identifying and informing you of those contacts who have also registered
      to use the Platform. At your request made via e-mail to
      info@flyingcompany.com, Company will deactivate the connection between the
      Platform and your Third Party Account and delete any information stored on
      Company&apos;ss servers that was obtained through such Third Party
      Account, except the username and profile picture that become associated
      with your account.
      <Paragraph>
        <strong>12. Worker Classification and Withholdings</strong>
      </Paragraph>
      AS SET FORTH IN SECTION 1, COMPANY DOES NOT PERFORM Flights AND DOES NOT
      EMPLOY INDIVIDUALS TO PERFORM Flights. Each User assumes all liability for
      proper classification of such User&apos;ss workers based on applicable
      legal guidelines. Users do not have authority to enter into written or
      oral — whether implied or express — contracts on behalf of Company. Each
      User acknowledges that Company does not, in any way, supervise, direct,
      control or monitor a Pilot&apos;ss work or Flights performed in any
      manner. Company does not set a Pilot&apos;ss work hours or location of
      work. Company will not provide any equipment, labor or materials needed
      for a particular Flight. Company does not provide any supervision to
      Users. The Platform is not an employment service and Company is not an
      employer of any User. As such, Company is not responsible for and will not
      be liable for any tax payments or withholding, including but not limited
      to unemployment insurance, social security, disability insurance or any
      other applicable federal or state withholdings in connection with your use
      of Users&apos;s Flight services.
      <Paragraph>
        <strong>13. (Reserved)</strong>
      </Paragraph>
      <Paragraph>
        <strong>14. Intellectual Property Rights</strong>
      </Paragraph>
      All text, graphics, editorial content, data, formatting, graphs, designs,
      HTML, look and feel, photographs, music, sounds, images, software, videos,
      designs, typefaces and other content (collectively “Proprietary Material”)
      that Users see or read through the Platform is owned by Company, excluding
      User Generated Content that Company has the right to use. Proprietary
      Material is protected in all forms, media and technologies now known or
      hereinafter developed. Company owns all Proprietary Material, as well as
      the coordination, selection, arrangement and enhancement of such
      Proprietary Materials as a Collective Work under the United States
      Copyright Act, as amended. The Proprietary Material is protected by the
      domestic and international laws of copyright, patents, and other
      proprietary rights and laws. Users may not copy, download, use, redesign,
      reconfigure, or retransmit anything from the Platform without
      Company&apos;s express prior written consent and, if applicable, the
      holder of the rights to the User Generated Content. Any use of such
      Proprietary Material, other than as permitted therein, is expressly
      prohibited without the prior permission of Company and, if applicable, the
      holder of the rights to the User Generated Content. The service marks and
      trademarks of Company, including without limitation Company and Company
      logos are service marks owned by Company. Any other trademarks, service
      marks, logos and/or trade names appearing via the Platform are the
      property of their respective owners. You may not copy or use any of these
      marks, logos or trade names without the express prior written consent of
      the owner.
      <Paragraph>
        <strong>15. Copyright Complaints and Copyright Agent</strong>
      </Paragraph>
      Company respects the intellectual property of others, and expects Users to
      do the same. If you believe, in good faith, that any materials provided on
      or in connection with the Platform infringe upon your copyright or other
      intellectual property right, please send the following information to
      Company&apos;s Copyright Agent at: Contract Flying Company, LLC, 310 NW O
      St, Bentonville, AR, 72712 or info@flyingcompany.com. A description of the
      copyrighted work that you claim has been infringed, including the URL
      (Internet address) or other specific location on the Platform where the
      material you claim is infringed is located. Include enough information to
      allow Company to locate the material, and explain why you think an
      infringement has taken place; A description of the location where the
      original or an authorized copy of the copyrighted work exists -- for
      example, the URL (Internet address) where it is posted or the name of the
      book in which it has been published; Your address, telephone number, and
      e-mail address; A statement by you that you have a good faith belief that
      the disputed use is not authorized by the copyright owner, its agent, or
      the law; A statement by you, made under penalty of perjury, that the
      information in your notice is accurate, and that you are the copyright
      owner or authorized to act on the copyright owner&apos;s behalf; and An
      electronic or physical signature of the owner of the copyright or the
      person authorized to act on behalf of the owner of the copyright interest.
      <Paragraph>
        <strong>16. Confidential Information</strong>
      </Paragraph>
      You acknowledge that Confidential Information (as defined below) is a
      valuable, special and unique asset of Company and agree that you will not
      disclose, transfer, use (or seek to induce others to disclose, transfer or
      use) any Confidential Information for any purpose other than disclosure to
      your authorized employees and agents who are bound to maintain the
      confidentiality of Confidential Information. You shall promptly notify
      Company in writing of any circumstances which may constitute unauthorized
      disclosure, transfer, or use of Confidential Information. You shall use
      best efforts to protect Confidential Information from unauthorized
      disclosure, transfer or use. You shall return all originals and any copies
      of any and all materials containing Confidential Information to Company
      upon termination of this Agreement for any reason whatsoever. The term
      “Confidential Information” shall mean any and all of Company&apos;ss trade
      secrets, confidential and proprietary information and all other
      information and data of Company that is not generally known to the public
      or other third parties who could derive value, economic or otherwise, from
      its use or disclosure. Confidential Information shall be deemed to include
      technical, financial, strategic and other proprietary and confidential
      information relating to Company&apos;ss business, operations and
      properties, including information about Company&apos;ss Users or partners,
      or other business information disclosed directly or indirectly in writing,
      orally or by drawings or observation.
      <Paragraph>
        <strong>17. Disclaimer of Warranties</strong>
      </Paragraph>
      USE OF THE SERVICE IS ENTIRELY AT YOUR OWN RISK. THE PLATFORM IS PROVIDED
      ON AN “AS IS” BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
      IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY,
      FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. COMPANY MAKES NO
      WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE
      CONTENT PROVIDED THROUGH THE PLATFORM OR THE CONTENT OF ANY SITES LINKED
      TO THE PLATFORM AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I)
      ERRORS, MISTAKES, OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR
      PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO
      AND USE OF THE PLATFORM, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
      SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
      INFORMATION STORED THEREIN. COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE
      OR ASSUME RESPONSIBILITY FOR ANY SERVICE ADVERTISED OR OFFERED BY A THIRD
      PARTY THROUGH THE PLATFORM OR ANY HYPERLINKED WEBSITE OR FEATURED IN ANY
      BANNER OR OTHER ADVERTISING AND COMPANY WILL NOT BE A PARTY TO OR IN ANY
      WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND
      THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES, OTHER THAN AS PROVIDED
      HEREIN. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR
      IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
      WHERE APPROPRIATE. WITHOUT LIMITING THE FOREGOING, NEITHER COMPANY NOR ITS
      AFFILIATES OR LICENSORS WARRANT THAT ACCESS TO THE PLATFORM WILL BE
      UNINTERRUPTED OR THAT THE PLATFORM WILL BE ERROR-FREE; NOR DO THEY MAKE
      ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE
      PLATFORM, OR AS TO THE TIMELINESS, ACCURACY, RELIABILITY, COMPLETENESS OR
      CONTENT OF ANY FLIGHT OR SERVICE, INFORMATION OR MATERIALS PROVIDED
      THROUGH OR IN CONNECTION WITH THE USE OF THE PLATFORM. NEITHER COMPANY NOR
      ITS AFFILIATES OR LICENSORS IS RESPONSIBLE FOR THE CONDUCT, WHETHER ONLINE
      OR OFFLINE, OF ANY USER. NEITHER COMPANY NOR ITS AFFILIATES OR LICENSORS
      WARRANT THAT THE PLATFORM IS FREE FROM VIRUSES, WORMS, TROJAN HORSES, OR
      OTHER HARMFUL COMPONENTS. COMPANY AND ITS AFFILIATES AND LICENSORS CANNOT
      AND DO NOT GUARANTEE THAT ANY PERSONAL INFORMATION SUPPLIED BY YOU WILL
      NOT BE MISAPPROPRIATED, INTERCEPTED, DELETED, DESTROYED OR USED BY OTHERS.
      IN ADDITION, NOTWITHSTANDING ANY FEATURE A CLIENT MAY USE TO EXPEDITE
      SELECTION, EACH CLIENT IS RESPONSIBLE FOR DETERMINING THE FLIGHT AND
      SELECTING THEIR PILOT AND COMPANY DOES NOT WARRANT ANY GOODS OR SERVICES
      PURCHASED BY A CLIENT AND DOES NOT RECOMMEND ANY PARTICULAR PILOT. COMPANY
      DOES NOT PROVIDE ANY WARRANTIES OR GUARANTEES REGARDING ANY PILOT&apos;sS
      PROFESSIONAL QUALIFICATIONS OR LICENSE.
      <Paragraph>
        <strong>18. No Liability</strong>
      </Paragraph>
      YOU ACKNOWLEDGE AND AGREE THAT COMPANY IS ONLY WILLING TO PROVIDE THE
      PLATFORM IF YOU AGREE TO CERTAIN LIMITATIONS OF OUR LIABILITY TO YOU AND
      THIRD PARTIES. THEREFORE, YOU AGREE NOT TO HOLD COMPANY, ITS AFFILIATES,
      ITS LICENSORS, ITS PARTNERS IN PROMOTIONS, SWEEPSTAKES OR CONTESTS, OR ANY
      OF SUCH PARTIES&apos;s AGENTS, EMPLOYEES, OFFICERS, DIRECTORS, CORPORATE
      PARTNERS, OR PARTICIPANTS LIABLE FOR ANY DAMAGE, SUITS, CLAIMS, AND/OR
      CONTROVERSIES (COLLECTIVELY, “LIABILITIES”) THAT HAVE ARISEN OR MAY ARISE,
      WHETHER KNOWN OR UNKNOWN, RELATING TO YOUR OR ANY OTHER PARTY&apos;sS USE
      OF OR INABILITY TO USE THE PLATFORM, INCLUDING WITHOUT LIMITATION ANY
      LIABILITIES ARISING IN CONNECTION WITH THE CONDUCT, ACT OR OMISSION OF ANY
      USER (INCLUDING WITHOUT LIMITATION STALKING, HARASSMENT THAT IS SEXUAL OR
      OTHERWISE, ACTS OF PHYSICAL VIOLENCE, AND DESTRUCTION OF PERSONAL
      PROPERTY), ANY DISPUTE WITH ANY USER, ANY INSTRUCTION, ADVICE, ACT, OR
      SERVICE PROVIDED BY COMPANY OR ITS AFFILIATES OR LICENSORS AND ANY
      DESTRUCTION OF YOUR INFORMATION, OTHER THAN PURSUANT TO THE HAPPINESS
      PLEDGE. UNDER NO CIRCUMSTANCES WILL COMPANY, ITS AFFILIATES, ITS
      LICENSORS, OR ANY OF SUCH PARTIES&apos;s AGENTS, EMPLOYEES, OFFICERS,
      DIRECTORS, CORPORATE PARTNERS, OR PARTICIPANTS BE LIABLE FOR ANY DIRECT,
      INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES ARISING
      IN CONNECTION WITH YOUR USE OF OR INABILITY TO USE THE PLATFORM OR THE
      FLIGHT SERVICES, EVEN IF ADVISED OF THE POSSIBILITY OF THE SAME. SOME
      STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR
      CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
      COMPANY DOES NOT ACCEPT ANY LIABILITY WITH RESPECT TO THE QUALITY OR
      FITNESS OF ANY WORK PERFORMED VIA THE PLATFORM. IF, NOTWITHSTANDING THE
      FOREGOING EXCLUSIONS, IT IS DETERMINED THAT COMPANY OR ITS PARTNERS IN
      PROMOTIONS, SWEEPSTAKES OR CONTESTS, AFFILIATES, ITS LICENSORS, OR ANY OF
      SUCH PARTIES&apos;s AGENTS, EMPLOYEES, OFFICERS, DIRECTORS, CORPORATE
      PARTNERS, OR PARTICIPANTS IS LIABLE FOR DAMAGES, IN NO EVENT WILL THE
      AGGREGATE LIABILITY, WHETHER ARISING IN CONTRACT, TORT, STRICT LIABILITY
      OR OTHERWISE, EXCEED THE TOTAL FEES PAID BY YOU TO COMPANY DURING THE SIX
      (6) MONTHS PRIOR TO THE TIME SUCH CLAIM AROSE.
      <Paragraph>
        <strong>19. Indemnification</strong>
      </Paragraph>
      You hereby agree to indemnify, defend, and hold harmless Company, its
      directors, officers, employees, agents, licensors, attorneys, independent
      contractors, providers, successors and assigns, subsidiaries, and
      affiliates from and against any and all claim, loss, expense or demand of
      liability, including attorneys&apos; fees and costs incurred, in
      connection with (i) your use or inability to use the Platform or Flight
      Services, or (ii) your breach or violation of this Agreement; (iii) your
      violation of any law the rights of any User or third party (iv) any
      content submitted by you or using your account to the Platform, including,
      but not limited to the extent such content may infringe on the
      intellectual rights of a third party or otherwise be illegal or unlawful.
      Company reserves the right, at its own expense, to assume the exclusive
      defense and control of any matter otherwise subject to your
      indemnification. You will not, in any event, settle any claim or matter
      without the written consent of Company.
      <Paragraph>
        <strong>20. Dispute Resolution – Arbitration</strong>
      </Paragraph>
      <Paragraph>
        <strong>(a) Informal Negotiations.</strong>
      </Paragraph>
      To expedite resolution and reduce the cost of any dispute, controversy or
      claim between you and Company (each a “Claim” and collectively “Claims”),
      you and Company agree to first attempt to negotiate any Claim (except
      those Claims expressly excluded below) informally for at least thirty (30)
      days before initiating any arbitration or court proceeding. Such informal
      negotiations will commence upon written notice. Your address for such
      notices is your billing address, with an email copy to the email address
      you have provided to Company. Company&apos;ss address for such notices is
      Flying Company, LLC, 310 NW O St, Bentonville, AR, 72712 Attention: Legal.
      If necessary to preserve a Claim under any applicable statute of
      limitations, you or Company may initiate arbitration while engaging in the
      informal negotiations.
      <Paragraph>
        <strong>(b) Binding Arbitration.</strong>
      </Paragraph>
      YOU AND COMPANY MUTUALLY AGREE TO WAIVE YOUR RESPECTIVE RIGHTS TO
      RESOLUTION OF ALL CLAIMS BETWEEN YOU (EXCEPT THOSE EXPRESSLY EXCLUDED
      BELOW) IN A COURT OF LAW BY A JUDGE OR JURY AND AGREE TO RESOLVE ANY
      DISPUTES BY BINDING ARBITRATION ON AN INDIVIDUAL BASIS AS SET FORTH
      HEREIN. This agreement to arbitrate, contained in Section 20,
      (“Arbitration Agreement”), is governed by the Federal Arbitration Act and
      survives the termination of this Agreement or your relationship with
      Company. Claims include, but are not limited to, any dispute, claim or
      controversy whether based on past, present or future events arising out of
      or relating to: this Agreement and prior versions (including the breach,
      termination, enforcement, interpretation or validity thereof), the
      Platform, services, Flights, your relationship with Company, the
      threatened or actual suspension, deactivation or termination of your User
      Account or this Agreement, payments made by you or any payments made or
      allegedly owed to you, any city, county, state or federal wage-hour law,
      compensation, breaks and rests periods, expense reimbursement, wrongful
      termination, discrimination, harassment, retaliation, fraud, defamation,
      trade secrets, unfair competition, emotional distress, any promotions,
      offers made by Company, breach of any express or implied contract or
      breach of any express or implied contract or covenant, claims arising
      under federal or state consumer protection laws; claims arising under
      antitrust laws, claims arising under the Telephone Consumer Protection Act
      and Fair Credit Reporting Act; and claims arising under the Fair Labor
      Standards Act, Civil Rights Act of 1964, Uniform Trade Secrets Act,
      Americans With Disabilities Act, Age Discrimination in Employment Act,
      Older Workers Benefit Protection Act, Family Medical Leave Act, Employee
      Retirement Income Security Act (except for individual claims for employee
      benefits under any benefit plan sponsored by Company and covered by the
      Employee Retirement Income Security Act of 1974 or funded by insurance),
      and state statutes, if any, addressing the same or similar subject
      matters, and all other federal and state statutory and common law claims.
      All disputes concerning the arbitrability of a Claim (including Claims
      about the scope, applicability, enforceability, revocability or validity
      of the Arbitration Agreement) shall be decided by the arbitrator, except
      as expressly provided below. YOU ACKNOWLEDGE AND UNDERSTAND THAT YOU AND
      COMPANY ARE WAIVING THE RIGHT TO SUE IN COURT OR HAVE A JURY TRIAL FOR ALL
      CLAIMS, EXCEPT AS EXPRESSLY OTHERWISE PROVIDED IN THIS ARBITRATION
      AGREEMENT. THIS ARBITRATION AGREEMENT IS INTENDED TO REQUIRE ARBITRATION
      OF EVERY CLAIM OR DISPUTE THAT CAN LAWFULLY BE ARBITRATED EXCEPT THOSE
      CLAIMS AND DISPUTES WHICH BY TERMS OF THIS ARBITRATION AGREEMENT ARE
      EXPRESSLY EXCLUDED FROM THE REQUIREMENT TO ARBITRATE.
      <Paragraph>
        <strong>
          (c) Agreement Prohibiting Class Actions and Non-Individualized Relief.
        </strong>
      </Paragraph>
      You and Company agree that any arbitration will be limited to the Claim
      between Company and you individually. YOU ACKNOWLEDGE AND AGREE THAT YOU
      AND COMPANY ARE EACH WAIVING THE RIGHT TO PARTICIPATE AS A PLAINTIFF OR
      CLASS MEMBER IN ANY PURPORTED CLASS ACTION OR REPRESENTATIVE PROCEEDING
      (“CLASS ACTION WAIVER”). Further, unless both you and Company otherwise
      agree, the arbitrator may not consolidate more than one person&apos;ss
      claims, and may not otherwise preside over any form of any class or
      representative proceeding. Notwithstanding the foregoing, this Class
      Action Waiver shall not apply to Private Attorney General Act Claims
      brought against Company, which are addressed separately in Section 20 (d)
      below. The arbitrator shall have no authority to consider or resolve any
      Claim or issue any relief on any basis other than an individual basis. The
      arbitrator shall have no authority to consider or resolve any Claim or
      issue any relief on a class, collective, or representative basis.
      Notwithstanding any other provision of this Agreement, the Arbitration
      Agreement or the AAA Rules (as defined below), disputes regarding the
      scope, applicability, enforceability, revocability or validity of the
      Class Action Waiver may be resolved only by a civil court of competent
      jurisdiction and not by an arbitrator. In any case in which: (1) the Claim
      is filed as a class, collective, or representative action and (2) there is
      a final judicial determination that the Class Action Waiver is
      unenforceable as to any Claims, the class, collective, and/or
      representative action on such Claims must be litigated in a civil court of
      competent jurisdiction, but the Class Action Waiver shall be enforced in
      arbitration on an individual basis as to all other Claims to the fullest
      extent possible.
      <Paragraph>
        <strong>(d) Representative PAGA Waiver</strong>
      </Paragraph>
      Notwithstanding any other provision of this Agreement or the Arbitration
      Agreement, to the fullest extent allowed by law: (1) you and Company agree
      not to bring a representative action on behalf of others under the
      California Private Attorneys General Act of 2004 (“PAGA”), California
      Labor Code § 2698 et seq., in any court or in arbitration, and (2) for any
      claim brought on a private attorney general basis, including under PAGA,
      both you and Company agree that any such Claim will be resolved in
      arbitration on an individual basis only (that is, to resolve whether you
      have personally been aggrieved or subject to any violations of law), and
      that such an action may not be used to resolve the claims or rights of
      other individuals in a single or collective proceeding (i.e., to resolve
      whether other individuals have been aggrieved or subject to any violations
      of law) (collectively, “representative PAGA Waiver”). Notwithstanding any
      other provision of this Agreement, the Arbitration Agreement or the AAA
      Rules, disputes regarding the scope, applicability, enforceability,
      revocability or validity of this representative PAGA Waiver may be
      resolved only by a civil court of competent jurisdiction and not by an
      arbitrator. If any provision of this representative PAGA Waiver is found
      to be unenforceable or unlawful for any reason: (i) the unenforceable
      provision shall be severed from this Agreement; (ii) severance of the
      unenforceable provision shall have no impact whatsoever on the Arbitration
      Agreement or the requirement that any remaining Claims be arbitrated on an
      individual basis pursuant to the Arbitration Agreement; and (iii) any such
      representative PAGA Waiver or other representative private attorneys
      general act claims must be litigated in a civil court of competent
      jurisdiction and not in arbitration. To the extent that there are any
      Claims to be litigated in a civil court of competent jurisdiction because
      a civil court of competent jurisdiction determines that the representative
      PAGA Waiver is unenforceable with respect to those Claims, the parties
      agree that litigation of those Claims shall be stayed pending the outcome
      of any individual Claims in arbitration.
      <Paragraph>
        <strong>(e) Rules and Logistics Governing Arbitration</strong>
      </Paragraph>
      The arbitration will be commenced and conducted under the Commercial
      Arbitration Rules (the “AAA Rules”) of the American Arbitration
      Association (“AAA”) in effect at the time the arbitration is initiated and
      modified by the terms set forth in this Agreement and, where appropriate,
      the AAA&apos;ss Supplementary Procedures for Consumer Related Disputes
      (“AAA Consumer Rules”), both of which are available at the AAA website
      www.adr.org or by calling the AAA at 1-800-778-7879. Notwithstanding the
      foregoing, if requested by you and if proper based on the facts and
      circumstances of the Claims presented, the arbitrator shall have the
      discretion to select a different set of AAA Rules, but in no event shall
      the arbitrator consolidate more than one person&apos;ss Claims, or
      otherwise preside over any form of representative, collective, or class
      proceeding. As part of the arbitration, both you and Company will have the
      opportunity for reasonable discovery of non-privileged information that is
      relevant to the Claim(s). The arbitrator may award any individualized
      remedies that would be available in court. The arbitrator may award
      declaratory or injunctive relief only in favor of the individual party
      seeking relief and only to the extent necessary to provide relief
      warranted by that party&apos;s individual claims. The arbitrator will
      provide a reasoned written statement of the arbitrator&apos;ss decision,
      which shall explain the award given and the findings and conclusions on
      which the decision is based. The arbitrator will decide the substance of
      all Claims in accordance with applicable law, and will honor all claims of
      privilege recognized by law. The arbitrator shall not be bound by rulings
      in prior arbitrations involving different Pilots or Clients, but is bound
      by rulings in prior arbitrations involving the same Pilot or Client to the
      extent required by applicable law. The arbitrator&apos;ss award shall be
      final and binding and judgment on the award rendered by the arbitrator may
      be entered in any court having jurisdiction thereof, provided that any
      award may be challenged in a court of competent jurisdiction in accordance
      with the Federal Arbitration Act. Your arbitration fees and your share of
      arbitrator compensation will be governed by the AAA Rules (and, where
      appropriate, limited by the AAA Consumer Rules) subject to the following
      modifications:
      <Paragraph>
        <strong>
          (i) If Company initiates arbitration under this Arbitration Agreement,
          Company will pay all AAA filing and arbitration fees. If a Client or
          Pilot, however, files a Claim in accordance with this Arbitration
          Agreement and the associated claim for damages does not exceed USD
          10,000, Company will pay all such fees unless the arbitrator finds
          that either the substance of your claim or the relief sought in your
          Demand for Arbitration was frivolous or was brought for an improper
          purpose (as measured by the standards set forth in Federal Rule of
          Civil Procedure 11(b)); (ii) For Claims that (A) are based on an
          alleged employment relationship between Company and the Pilot; (B)
          arise out of, or relate to, Company&apos;ss actual or threatened
          deactivation of a Pilot&apos;ss User account; (C) arise out of, or
          relate to Company&apos;ss actual or threatened termination of a
          Pilot&apos;ss Agreement with Company; or (D) arise out of, or relate
          to, monies earned or paid for Flight services (as defined above,
          including the service chargeor Trust and Support Fee), tips, bonuses
          or monies owed by Clients or Company to a Pilot, other than disputes
          relating to referral bonuses, other Company sponsored promotions, or
          consumer-type disputes (the subset of Claims in subsections (A)-(D)
          shall be collectively referred to as “Pilot Claims”), Company shall
          pay all costs unique to arbitration (as compared to the costs of
          adjudicating the same claims before a court), including the regular
          and customary arbitration fees and expenses (to the extent not paid by
          Company pursuant to the fee provisions above). However, if you are the
          party initiating the Pilot Claim, you shall be responsible for
          contributing up to an amount equal to the filing fee that would be
          paid to initiate the claim in the court of general jurisdiction in the
          state in which you provide Services to Clients, unless a lower fee
          amount would be owed by you pursuant to the AAA Rules, applicable law,
          or subsection 20(e)(1) above. Any dispute as to whether a cost is
          unique to arbitration shall be resolved by the arbitrator. (iii)
          Except as provided in Federal Rule of Civil Procedure 68 or any state
          equivalents, each party shall pay its own attorneys&apos;s fees and
          pay any costs that are not unique to the arbitration (i.e., costs that
          each party would incur if the Claim(s) were litigated in a court, such
          as costs to subpoena witnesses and/or documents, take depositions and
          purchase deposition transcripts, copy documents, etc.). (iv) At the
          conclusion of any arbitration, the arbitrator may award reasonable
          fees and costs or any portion thereof to you if you prevail, to the
          extent authorized by applicable law.
        </strong>
      </Paragraph>
      Unless you and Company agree otherwise, any arbitration hearings between
      Company and a Pilot will take place in the county of your billing address,
      and any arbitration hearings between Company and a Client will take place
      in the county in which you received Flight services. If AAA arbitration is
      unavailable in your county, the arbitration hearings will take place in
      the nearest available location for a AAA arbitration.
      <Paragraph>
        <strong>(f) Exceptions to Arbitration</strong>
      </Paragraph>
      The following types of Claims are not required to be arbitrated under the
      Arbitration Agreement: Claims for worker&apos;s compensation, disability
      insurance and unemployment insurance benefits; Small claims actions that
      are within the scope of small claims court jurisdiction and brought on an
      individual basis; A representative action brought on behalf of others
      under PAGA or other private attorney general acts, to the extent the
      representative PAGA Waiver in Section 20(d) is deemed unenforceable by a
      court of competent jurisdiction; and Claims that may not be subject to
      arbitration as a matter of law. Nothing in this Arbitration Agreement
      prevents you from making a report to or filing a claim or charge with the
      Equal Employment Opportunity Commission, U.S. Department of Labor,
      Securities Exchange Commission, National Labor Relations Board, or Office
      of Federal Contract Compliance Programs, or similar local, state or
      federal agency, and nothing in this Arbitration Agreement shall be deemed
      to preclude or excuse a party from bringing an administrative claim before
      any agency in order to fulfill the party&apos;s obligation to exhaust
      administrative remedies before making a claim in arbitration. However you
      knowingly and voluntarily waive the right to seek or recover money damages
      of any type pursuant to any administrative complaint and instead may seek
      such relief only through arbitration under this Agreement. This Agreement
      and Arbitration Agreement do not prevent you from participating in an
      investigation by a government agency of any report, claim or charge
      otherwise covered by this Arbitration Provision and do not prevent you
      from receiving an award for information provided to any government
      agencies.
      <Paragraph>
        <strong>(g) Severability.</strong>
      </Paragraph>
      In addition to the severability provisions in Section 20(d) above, in the
      event that any portion of this Arbitration Agreement is deemed illegal or
      unenforceable, such provision shall be severed and the remainder of the
      Arbitration Agreement shall be given full force and effect.
      <Paragraph>
        <strong>(h) Right to Opt Out of Arbitration Agreement</strong>
      </Paragraph>
      You may opt out of the Arbitration Agreement by notifying Company in
      writing within thirty (30) days of the date you first registered for the
      Platform or thirty (30) days from the date this Agreement was last
      updated. To opt out, you must send a written notification to Company at
      Flying Company, LLC, 310 NW O St, Bentonville, AR, 72712. Attention:
      Legal, that includes (a) your account username, (b) your name, (c) your
      address, (d) your telephone number, (e) your email address, and (f) a
      clear statement indicating that you do not wish to resolve claims through
      arbitration and demonstrating compliance with the 30-day time limit to opt
      out of the above arbitration and class action/jury trial waiver
      provisions.
      <Paragraph>
        <strong>21. Governing Law</strong>
      </Paragraph>
      Except as provided in Section 20 or expressly provided otherwise, this
      Agreement will be governed by, and will be construed under, the laws of
      the State of Arkansas, without regard to choice of law principles.
      <Paragraph>
        <strong>22. Special Promotions</strong>
      </Paragraph>
      Company may from time to time provide certain promotional opportunities,
      sweepstakes and contests to Users. All such promotions will be run at the
      sole discretion of Company, and can be activated, modified or removed at
      any time by Company without advance notification and the liability of any
      of Company&apos;ss partners pursuant to such promotional opportunities,
      sweepstakes and contests shall be limited pursuant to Section 18 of these
      Terms of Service, including but not limited to Section 18 of these Terms
      of Service.
      <Paragraph>
        <strong>23. No Agency</strong>
      </Paragraph>
      No agency, partnership, joint venture, employer-employee or
      franchiser-franchisee relationship is intended or created by this
      Agreement.
      <Paragraph>
        <strong>24. General Provisions</strong>
      </Paragraph>
      Failure by Company to enforce any provision(s) of this Agreement will not
      be construed as a waiver of any provision or right. This Agreement
      constitutes the entire agreement between you and Company with respect to
      its subject matter. If any provision of this Agreement is found to be
      invalid or unenforceable, the remaining provisions will be enforced to the
      fullest extent possible, and the remaining provisions will remain in full
      force and effect. This Agreement may not be assigned or transferred by you
      without our prior written approval. We may assign or transfer this
      Agreement without your consent, including but not limited to assignments:
      (i) to a parent or subsidiary, (ii) to an acquirer of assets, or (iii) to
      any other successor or acquirer. Any assignment in violation of this
      section shall be null and void. This Agreement will inure to the benefit
      of Company, its successors and assigns.
      <Paragraph>
        <strong>25. Licensed Professionals</strong>
      </Paragraph>
      Platform does not oversee, monitor or supervise the posting or performance
      of Flights. Accordingly, Clients must determine for themselves whether a
      Pilot is qualified to perform the specific Flight.
      <Paragraph>
        <strong>26. Changes to this Agreement and the FlyCo Platform</strong>
      </Paragraph>
      Company reserves the right, at its sole and absolute discretion, to
      change, modify, add to, supplement or delete any of the terms and
      conditions of this Agreement (including the Privacy Policy) and review,
      improve, modify or discontinue, temporarily or permanently, the Platform
      or any content or information through the Platform at any time, effective
      with or without prior notice and without any liability to Company. Company
      will endeavor to notify you of material changes by email, but will not be
      liable for any failure to do so. If any future changes to this Agreement
      are unacceptable to you or cause you to no longer be in compliance with
      this Agreement, you must terminate, and immediately stop using, the
      Platform. Your continued use of the Platform following any revision to
      this Agreement constitutes your complete and irrevocable acceptance of any
      and all such changes. Company may change, modify, suspend, or discontinue
      any aspect of the Platform at any time without notice or liability.
      Company may also impose limits on certain features or restrict your access
      to parts or all of the Platform without notice or liability.
      <Paragraph>
        I HEREBY ACKNOWLEDGE THAT I HAVE READ AND UNDERSTAND THE FOREGOING TERMS
        OF SERVICE AND PRIVACY POLICY AND AGREE THAT MY USE OF THE PLATFORM IS
        AN ACKNOWLEDGMENT OF MY AGREEMENT TO BE BOUND BY THE TERMS AND
        CONDITIONS OF THIS AGREEMENT.
      </Paragraph>
    </>
  );
}

export default TermsOfUsePage;
