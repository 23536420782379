import React from 'react';

function CheckBoxChecked() {
  return (
    <svg width="21px" height="21px" viewBox="0 0 21 21" version="1.1">
      <g
        id="all---4/30"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="3a:-Profile-+-Settings"
          transform="translate(-1176.000000, -386.000000)"
          fill="#D8D8D8"
        >
          <g id="Group-5" transform="translate(805.000000, 226.000000)">
            <g id="AMEX-Line" transform="translate(1.000000, 152.000000)">
              <path
                d="M380.5,29 C386.301508,29 391,24.3015082 391,18.5 C391,12.6984918 386.301508,8 380.5,8 C374.698492,8 370,12.6984918 370,18.5 C370.004035,24.297377 374.702623,28.9972459 380.5,29 Z M374.051967,12.0478361 C375.704702,10.3951016 377.98,9.37711803 380.502754,9.37711803 C383.022857,9.37711803 385.302115,10.3964443 386.954918,12.0478361 C388.607652,13.7005705 389.625636,15.9758689 389.625636,18.5 C389.625636,21.0201033 388.60631,23.2993607 386.954918,24.9521639 C385.302184,26.6048984 383.026885,27.622882 380.502754,27.622882 C377.982651,27.622882 375.703393,26.6035557 374.05059,24.9521639 C372.397856,23.2994295 371.379872,21.0241311 371.379872,18.5 C371.379872,15.9772115 372.399198,13.7006393 374.05059,12.0478361 L374.051967,12.0478361 Z"
                id="Fill-1"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default CheckBoxChecked;
