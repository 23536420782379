/** Object.keys(obj).length is O(N) complexity because it creates an array containing all the property names only to get the length of that array. Iterating over the object accomplishes the same goal but is O(1). */
export default function isEmptyObject<T extends object | undefined>(obj: T) {
  // eslint-disable-next-line no-restricted-syntax
  for (const prop in obj) {
    if (Object.hasOwn(obj, prop)) {
      return false;
    }
  }

  return true;
}
