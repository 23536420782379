import { AnyObject } from '../../types/util';
import isEmptyObject from '../../utilities/isEmptyObject';
import objectToString from '../../utilities/objectToString';
import { remapKeysToSnakeCase } from '../../utilities/remapKeys';
import fetchAPI from '../util';

// TODO change backend update route to standardize this type

async function uploadAvatar(image: File) {
  if (image.size > 5000000) {
    throw new Error(
      'Your photo needs to be less than 5MB and in JPG, PNG, or GIF format'
    );
  }
  try {
    const formData = new FormData();
    formData.append('image', image);

    await fetchAPI('/api/avatars/', {
      method: 'POST',
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      body: formData,
    });

    //  if (response && response.image)
    //  { type: AVATAR_UPLOAD_SUCCESS, avatar: response }
    //  handleChange('avatar', response.image));
    //  toast('Updated successfully')
  } catch (err) {
    console.log(err);
  }
}

export default async function updateUser(
  id: number,
  image?: File | null,
  data?: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
  }
): Promise<void> {
  try {
    // if File, then user has changed avatar image
    if (image instanceof File) {
      await uploadAvatar(image);
      // TODO set this uploaded path in parsedData
    }

    if (isEmptyObject(data)) return;

    const formattedData = remapKeysToSnakeCase(data as AnyObject) as Record<
      string,
      unknown
    >;
    // Filter out keys with empty values from formattedData
    const filteredData = Object.fromEntries(
      Object.entries(formattedData).filter(([key, value]) => value !== '')
    );

    if (
      Object.prototype.hasOwnProperty.call(filteredData, 'phone_number') &&
      typeof filteredData.phone_number === 'string' &&
      !filteredData.phone_number.startsWith('+1')
    ) {
      if (filteredData.phone_number.startsWith('1')) {
        filteredData.phone_number = `+${filteredData.phone_number}`;
      } else {
        filteredData.phone_number = `+1${filteredData.phone_number}`;
      }
    }

    const response = await fetchAPI(`/api/users/${id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filteredData),
    });

    if (response.statusCode !== 200) {
      const { statusCode, ...rest } = response;
      throw new Error(
        `An error occurred while updating the user: ${objectToString(rest)}`
      );
    }
    return;
  } catch (err) {
    console.error(err);

    throw err;
  }
}
