import Message from '../../types/Message';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';
import { endpoints } from '../endpoints';

interface NewMessageResponse {
  message: Message | Partial<Message>;
  statusCode: number;
  errors: string[];
}

const createNewMessage = async (
  message: Partial<Message>
): Promise<NewMessageResponse> => {
  try {
    const response = await fetch(endpoints.MESSAGES, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      body: JSON.stringify(message),
      credentials: 'include',
    });

    if (response.status !== 201) {
      throw new Error('Unable to create new message');
    }
    const createdMessage = await response.json();

    return {
      message: remapKeysToCamelCase(createdMessage) as Message,
      statusCode: response.status,
      errors: [],
    };
  } catch (e) {
    console.log(e);
    return {
      message,
      statusCode: 400,
      errors: [(e as Error).message],
    };
  }
};

export default createNewMessage;
