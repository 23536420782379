import Heading from '../../typography/Heading';

interface QuoteBannerProps {
  showQuoteCount: boolean;
  quoteCount: number;
}

function QuoteBanner({ showQuoteCount, quoteCount }: QuoteBannerProps) {
  return showQuoteCount && quoteCount ? (
    <Heading level={4} className="bg-secondary text-white p-2 text-center">
      {quoteCount} quote{quoteCount > 1 && 's'}
    </Heading>
  ) : null;
}

export default QuoteBanner;
