import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Trip from '../../../../types/Trip';
import Bid from '../../../../types/Bid';
import Alert from '../../../../components/atoms/Alert/Alert';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../components/atoms/Button/Button';
import PilotCard from '../../../../components/molecules/PilotCard/PilotCard';
import ControlBar from '../../../../components/molecules/ControlBar/ControlBar';
import Chat from '../../../../components/organisms/Chat/Chat';
import GrandTotalEstimateCard from '../../../../components/organisms/GrandTotalEstimateCard/GrandTotalEstimateCard';
import TripCard from '../../../../components/organisms/TripCard/TripCard';
import Heading from '../../../../components/typography/Heading';
import { createQuoteFromBid } from '../../../../types/Quote';
import { ROUTES } from '../../../routerConfig';
import approveExpenses from '../../../../API/Trips/approveExpenses';
import rejectExpenses from '../../../../API/Trips/rejectExpenses';
import makePayment from '../../../../API/Payments/makePayment';
import ReviewFinalExpenses from '../../subcomponents/ReviewFinalExpenses';
import Card from '../../../../components/molecules/Card/Card';
import RemainingTripCost from '../RemainingTripCost';
import PrimaryTripLayout from '../../Layouts/PrimaryTripLayout';

function ExpensesSubmittedView({
  trip,
  openDetailsModal,
}: {
  trip: Trip;
  openDetailsModal: (trip: Trip) => unknown;
}) {
  const navigate = useNavigate();
  const reviewExpensesRef = useRef<HTMLDivElement>(null);
  const navigateToTrips = () => navigate(ROUTES.TRIPS);
  const chatAreaRef = useRef<HTMLDivElement>(null);
  const tripAndQuoteAreaRef = useRef<HTMLDivElement>(null);
  const hiredBid = trip.conversations
    .map((c) => c.bid)
    .find((b) => b?.id === trip.hiredBid);
  if (!hiredBid) throw new Error(`No hired bid on ExpensesSubmittedView`);

  const hiredBidConversation = trip.conversations.find(
    (c) => c.bid?.id === trip.hiredBid
  );
  if (!hiredBidConversation)
    throw new Error(`No hired bid conversation on ExpensesSubmittedView`);

  const onConfirm = async () => {
    await approveExpenses(trip.id);
    await makePayment(trip.id);
    window.location.reload();
  };

  const onDecline = async () => {
    await rejectExpenses(trip.id);
    window.location.reload();
  };

  return (
    <>
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" />
          Trips
        </PrimaryButton>
        <SecondaryButton
          className="ml-auto"
          size="small"
          // onClick={openReviewExpensesModal}
          onClick={() => {
            // set active element to Review Expenses
            reviewExpensesRef.current?.scrollIntoView();
          }}
        >
          Review Expenses
        </SecondaryButton>
        <SecondaryButton size="small" onClick={() => openDetailsModal(trip)}>
          View Trip Details
        </SecondaryButton>
      </ControlBar>
      <div className="flex flex-col gap-4">
        <Heading level={2}>
          {hiredBidConversation.user.firstName}{' '}
          {hiredBidConversation.user.lastName} has submitted expenses.
        </Heading>
        <Alert
          message="Approve or decline the pilot's final expenses to proceed."
          status="warning"
        />
        {/* Three column grid layout that collapses to 2 col, then 1 col */}
        <PrimaryTripLayout>
          {/** Pilot profile area */}
          {/* Small display version */}
          <div className="h-full">
            <PilotCard
              pilot={hiredBidConversation.user}
              className="h-full"
              planeType={trip.planeType}
              selected
              expanded="always"
              airplane={trip.aircraft}
              hired
              tripId={trip.id}
            />
          </div>
          {/** Chat area */}
          {hiredBidConversation && (
            <div className="flex flex-col gap-4 h-full" ref={chatAreaRef}>
              {/** Quote card */}
              <GrandTotalEstimateCard
                cost={hiredBidConversation.cost}
                expenses={(hiredBid as Bid).expenses}
                className="flex-grow"
              />
              <Chat
                tripId={trip.id}
                title={
                  hiredBidConversation
                    ? `Chat with ${hiredBidConversation.user.firstName}`
                    : 'Loading'
                }
                conversation={hiredBidConversation}
              />
            </div>
          )}
          {/* Trip and Quote area  */}
          <div className="hidden lg:block h-full" ref={tripAndQuoteAreaRef}>
            {/** Trip card */}
            <TripCard
              trip={trip}
              showStatus={false}
              expanded="always"
              interactive={false}
              className="h-full"
            />
          </div>
        </PrimaryTripLayout>
        {/* Final Expenses */}
        <div className="w-full" ref={reviewExpensesRef}>
          <Card className="col-span-full" title="Final Expenses" responsive>
            <RemainingTripCost
              expenses={{
                alterations: {
                  id: trip.id,
                  startDate: trip.startDate,
                  endDate: trip.endDate,
                  totalTravelDays: createQuoteFromBid(
                    hiredBid,
                    hiredBidConversation.user,
                    trip,
                    false
                  ).travelDays,
                },
                itemizedExpenses: hiredBid.expenses,
              }}
              cost={hiredBidConversation.cost}
            />
            <ReviewFinalExpenses
              expenses={{
                alterations: {
                  id: trip.id,
                  startDate: trip.startDate,
                  endDate: trip.endDate,
                  totalTravelDays: createQuoteFromBid(
                    hiredBid,
                    hiredBidConversation.user,
                    trip,
                    false
                  ).travelDays,
                },
                itemizedExpenses: hiredBid.expenses,
              }}
              handleSubmit={onConfirm}
              handleCancel={onDecline}
              disabled
            />
          </Card>
        </div>
      </div>
    </>
  );
}

export default ExpensesSubmittedView;
