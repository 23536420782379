type BadgeProps = {
  value: string;
  displayValue?: string;
  titleValue?: string;
  className?: string;
  onRemove?: () => void;
  outline?: boolean;
  disabled?: boolean;
};

function Badge({
  value,
  displayValue,
  titleValue,
  className = '',
  onRemove,
  outline = false,
  disabled = false,
}: BadgeProps) {
  return onRemove ? (
    <button
      className={`btn btn-secondary btn-sm hover:bg-error ${className} ${
        outline ? 'btn-outline' : ''
      }`}
      title={titleValue || value}
      type="button"
      onClick={onRemove}
      key={value}
      disabled={disabled}
    >
      {displayValue || value} <i className="fa-solid fa-circle-xmark" />
    </button>
  ) : (
    <div
      className={`badge badge-secondary px-2 py-0.5 h-fit ${className} ${
        outline ? 'badge-outline' : ''
      }`}
      title={titleValue || value}
    >
      <span className="text-base">{displayValue || value}</span>
    </div>
  );
}

export default Badge;
