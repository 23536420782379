/* eslint-disable @typescript-eslint/no-unused-vars */
import { useUser } from '../../hooks/UserProvider/UserProvider';
import TripsAsConsumer from './TripsAsConsumer';
import TripsAsPilot from './TripsAsPilot';

function Trips() {
  const { role } = useUser();

  return role === 'consumer' ? <TripsAsConsumer /> : <TripsAsPilot />;
}

export default Trips;
