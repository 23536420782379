import React, { ReactNode } from 'react';

import classNames from 'classnames';

interface ParagraphProps {
  className?: string;
  children: ReactNode;
}

function Paragraph({ className, children }: ParagraphProps) {
  return (
    <p className={classNames('paragraph max-w-prose my-2', className)}>
      {children}
    </p>
  );
}

export default Paragraph;
