import { ChangeEvent } from 'react';

type SelectProps = {
  onChange?: (t: string) => void;
  options: readonly string[];
  prompt?: string;
  className?: string;
  getOptionDisplayName?: (t: string) => string;
  id?: string;
  disabled?: boolean;
  defaultvalue?: string;
  value?: string | undefined;
};

function Select({
  onChange,
  prompt,
  options,
  className,
  getOptionDisplayName,
  id = undefined,
  disabled = false,
  defaultvalue,
  value = undefined,
}: SelectProps) {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (onChange) onChange(e.target.value);
  };
  return (
    <select
      id={id}
      className={`select select-bordered w-full max-w-xs ${className}`}
      onChange={handleChange}
      disabled={disabled}
      value={value || defaultvalue || prompt}
    >
      {prompt && (
        <option disabled selected>
          {prompt}
        </option>
      )}
      {options.map((o) => (
        <option value={o as string}>
          {getOptionDisplayName ? getOptionDisplayName(o) : (o as string)}
        </option>
      ))}
    </select>
  );
}

export default Select;
