import React, {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from 'react';

import { useUser } from './UserProvider/UserProvider';

import getAircraft from '../API/Aircraft/getAircraft';
import { type Airplane } from '../types/Airplane';
// import getAircraft from '../API/Operator/getAircraft';

interface IAircraftContext {
  error: string;
  loading: boolean;
  airplanes: Airplane[];
  refreshAirplanes: () => Promise<void>;
}

const HangarContext = createContext<IAircraftContext>(undefined!);

export function AircraftProvider({ children }: { children: ReactNode }) {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [airplanes, setAirplanes] = useState<Airplane[]>([]);
  const { user } = useUser();

  const attemptGetPlanes = async () => {
    try {
      if (user === null) return;
      setLoading(true);
      const response = await getAircraft();

      if (response && response.length > 0) {
        setAirplanes(response);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e as string);
    }
  };

  const refreshAirplanes = async () => {
    await attemptGetPlanes();
  };

  useEffect(() => {
    if (!airplanes.length && user) attemptGetPlanes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const value = useMemo(
    () => ({ error, loading, airplanes, refreshAirplanes }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, loading, airplanes]
  );

  return (
    <HangarContext.Provider value={value}>{children}</HangarContext.Provider>
  );
}

/** Fetches an array of the user's aircraft */
export const useAircraft = () => useContext<IAircraftContext>(HangarContext);
