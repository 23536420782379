import CertificateLimitOption from '../../types/CertificateLimitOption';
import CertificateRatingOption from '../../types/CertificateRatingOption';

const endpoint = `/api/cert_ratings/`;

interface RatingsOptionsResponse {
  ratingOptions: CertificateRatingOption[];
  statusCode: number;
}

const getRatingOptions = async (): Promise<RatingsOptionsResponse | null> => {
  try {
    const ratingOptionsResponse = await fetch(endpoint, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
    });

    const ratingOptions = await ratingOptionsResponse.json();

    return {
      ratingOptions,
      statusCode: ratingOptionsResponse.status,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default getRatingOptions;
