interface AvatarProps {
  avatarUrl?: string;
  initials?: string;
  small?: boolean;
}

function Avatar({ avatarUrl, initials, small = false }: AvatarProps) {
  const w = small ? 'w-10 h-10' : 'w-24';

  return avatarUrl ? (
    <div className="avatar">
      <div className={`rounded-full ${w}`}>
        <img alt={`${'1'} avatar`} src={avatarUrl} />
      </div>
    </div>
  ) : (
    <div title="Add an avatar" className="avatar placeholder">
      <div className={`bg-neutral text-neutral-content rounded-full ${w}`}>
        <span className="text-3xl">{initials}</span>
      </div>
    </div>
  );
}

export default Avatar;
