import { CrewType } from '../../types/CrewType';
import { TripLeg } from '../../types/TripLeg';
import Trip from '../../types/Trip';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';
import objectToString from '../../utilities/objectToString';

const endpoint = `/api/trips/as_consumer/`;

interface NewTripProps {
  name: string;
  notes: string;
  pax: number;
  departureDate: Date;
  returnDate: Date;
  fromAirportId: string;
  toAirportId: string;
  planeTypeId: string;
  purpose: string;
  tailNumber: string;
  aircraft: number | null;
  operator: number | null;
  crewType: CrewType;
  legs: TripLeg[];
}

const createNewTrip = async (newTrip: NewTripProps) => {
  try {
    const {
      name,
      notes,
      pax,
      departureDate,
      returnDate,
      fromAirportId,
      toAirportId,
      planeTypeId,
      purpose = 'Not Specified',
      tailNumber,
      aircraft,
      operator,
      legs,
    } = newTrip;

    let { crewType } = newTrip;

    const errors = [];

    // Validation lives here for now. Maybe we can move it later.
    if (!name) errors.push(`No trip name provided.`);
    if (!departureDate) errors.push(`Please select a departure date.`);
    if (!returnDate) errors.push(`Please select a return date.`);
    if (!fromAirportId) errors.push(`Please select an origin airport.`);
    if (!toAirportId) errors.push(`Please select a destination airport.`);
    if (!planeTypeId) errors.push(`Please select a plane type.`);
    if (!tailNumber) errors.push(`Please provide a tail number.`);
    if (!aircraft) errors.push(`Please provide an aircraft.`);
    if (!operator) errors.push(`Please provide an operator.`);
    if (!crewType) errors.push(`Please select crew type.`);

    if (errors.length) {
      return {
        statusCode: 400,
        errors,
      };
    }

    // Transform legs
    const transformedLegs = legs.map((leg) => {
      // Step 1: Convert departureTime to the user's local time as a Date object
      const localDeparture = new Date(leg.departureTime);

      // Step 2: Create a string in "YYYY-MM-DDTHH:mm:ss" format without the timezone
      const departureStr = localDeparture
        .toLocaleString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        })
        .replace(', ', 'T');

      // Step 3: Append UTC-0 timezone identifier to treat it as UTC
      const departureTimeUTC = `${departureStr}Z`;

      // Step 4: Calculate arrival time (departure time + 2 hours)
      const arrivalTimeUTC = new Date(
        new Date(departureTimeUTC).getTime() + 2 * 60 * 60 * 1000
      ).toISOString();

      return {
        from_location: leg.fromLocation,
        departure_time: departureTimeUTC, // formatted as UTC
        to_location: leg.toLocation,
        arrival_time: arrivalTimeUTC, // 2 hours after departure
        pax: leg.pax,
      };
    });

    const payload: unknown[] = [];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!Array.isArray(crewType)) crewType = [crewType];

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    crewType.forEach((ct) => {
      // Convert departureDate to user's local time and then to UTC format
      const localDepartureDate = new Date(departureDate);
      const departureDateStr = localDepartureDate
        .toLocaleString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        })
        .replace(', ', 'T');
      const departureDateUTC = `${departureDateStr}Z`;

      // Convert returnDate similarly, but without adjusting the hours
      const localReturnDate = new Date(returnDate);
      const returnDateStr = localReturnDate
        .toLocaleString('en-CA', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false,
        })
        .replace(', ', 'T');
      const returnDateUTC = `${returnDateStr}Z`;

      // Add each transformed object to the payload array
      payload.push({
        name,
        notes,
        pax,
        start_date: departureDateUTC,
        end_date: returnDateUTC,
        departure_location_id: parseInt(fromAirportId, 10),
        destination_location_id: parseInt(toAirportId, 10),
        plane_type_id: parseInt(planeTypeId, 10),
        purpose,
        tail_number: tailNumber,
        aircraft_id: aircraft,
        operator_id: operator,
        pilot_job: ct,
        legs: transformedLegs,
      });
    });

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      body: JSON.stringify(payload),
      credentials: 'include',
    });

    if (response.status !== 200) {
      const createdTrip = await response.json();

      throw new Error(
        `An error occurred while creating the trip: ${objectToString(
          createdTrip[0]
        )}`
      );
    } else {
      const createdTrip = await response.json();
      return {
        trip: remapKeysToCamelCase(createdTrip[0]) as Trip,
        statusCode: response.status,
        errors: [],
      };
    }
  } catch (err) {
    console.error(err);

    throw err;
  }
};

export default createNewTrip;
