/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useUser } from '../../hooks/UserProvider/UserProvider';
import Trip from '../../types/Trip';
import ButtonGroup from '../../components/molecules/ButtonGroup';
import TripCard from '../../components/organisms/TripCard/TripCard';
import getTripsAsConsumer from '../../API/Trips/getTripsAsConsumer';
import { SecondaryButton } from '../../components/atoms/Button/Button';
import Loader from '../../components/molecules/Loader';
import Heading from '../../components/typography/Heading';
import { ROUTES } from '../routerConfig';
import { useModalContext } from '../../hooks/ModalProvider/ModalProvider';

enum TripTypes {
  UPCOMING = 'Upcoming',
  PAST = 'Past',
}

function TripsAsConsumer() {
  // Consumer trip categories
  const [pastTrips, setPastTrips] = useState<Trip[]>([]);
  const [upcomingTrips, setUpcomingTrips] = useState<Trip[]>([]);
  const [currentTrips, setCurrentTrips] = useState<Trip[]>(upcomingTrips);

  const [loading, setLoading] = useState(true);

  const [currentFilter, setCurrentFilter] = useState<TripTypes>(
    TripTypes.UPCOMING
  );

  const { user, loadingOperatorStatus, operatorOnboardingStatus } = useUser();
  const { closeModal } = useModalContext();
  const navigate = useNavigate();

  const updateCurrentFilter = (newFilter: string) => {
    if (newFilter === TripTypes.UPCOMING) {
      setCurrentFilter(TripTypes.UPCOMING);
      setCurrentTrips(upcomingTrips);
    } else if (newFilter === TripTypes.PAST) {
      setCurrentFilter(TripTypes.PAST);
      setCurrentTrips(pastTrips);
    } else setCurrentTrips([]);
  };

  // When trips are loaded, set the currentTrips value
  useEffect(() => {
    if (currentFilter === TripTypes.UPCOMING) setCurrentTrips(upcomingTrips);
    else if (currentFilter === TripTypes.PAST) setCurrentTrips(pastTrips);
  }, [upcomingTrips, pastTrips, currentFilter]);

  // Load trips
  useEffect(() => {
    const loadViewState = async () => {
      const loadedTrips = await getTripsAsConsumer();
      setPastTrips(loadedTrips.trips.past);
      setUpcomingTrips(loadedTrips.trips.upcoming);
      setLoading(false);
    };
    loadViewState();
  }, [closeModal, user]);

  const goToCreateNewTrip = () => navigate(`${ROUTES.TRIPS}/new`);

  return (
    <>
      <Heading level={1} className="mb-4 mt-2">
        Welcome, {user?.firstName}!
      </Heading>
      {operatorOnboardingStatus?.statusContent !== null &&
        !loadingOperatorStatus && (
          <Heading level={4} className="my-4">
            Please complete the required onboarding tasks before creating a
            trip.
          </Heading>
        )}
      <div className="flex flex-wrap gap-4">
        <ButtonGroup
          options={[
            {
              displayName: 'Upcoming',
              value: TripTypes.UPCOMING,
              selected: currentFilter === TripTypes.UPCOMING,
            },
            {
              displayName: 'Past',
              value: TripTypes.PAST,
              selected: currentFilter === TripTypes.PAST,
            },
          ]}
          onChange={(value) => updateCurrentFilter(value)}
        />
        <SecondaryButton
          onClick={goToCreateNewTrip}
          disabled={
            loading ||
            loadingOperatorStatus ||
            operatorOnboardingStatus?.statusContent !== null
          }
          isLoading={
            loading ||
            loadingOperatorStatus ||
            operatorOnboardingStatus?.statusContent !== null
          }
        >
          Create New Trip
        </SecondaryButton>
      </div>
      {loading && (
        <Loader className="mt-4" level={3} caption="Loading trips..." />
      )}
      {!loading && (
        <div className="flex flex-col mt-4 gap-4">
          {currentTrips.map((trip) => (
            <TripCard
              trip={trip}
              key={trip.id}
              className="max-w-2xl"
              showQuoteCount
            />
          ))}
        </div>
      )}
    </>
  );
}

export default TripsAsConsumer;
