import React from 'react';
import Direction from '../../../types/Direction';
import { useToast } from '../../../hooks/ToastProvider';
import { Toast } from './Toast';
import SuccessToast from './SuccessToast';
import { UIState } from '../../../types/UIState';
import InfoToast from './InfoToast';
import ErrorToast from './ErrorToast';
import WarningToast from './WarningToast';

/**
 * To add a toast, a component should consume the useToast hook,
 * providing ReactNode for the content prop to addToast. The useToast
 * hook is exported from the ToastProvider, which stores the stack of
 * active Toast state. These are mapped to a Toast component instance
 * until they timeout, when they are shifted out of the front of the Toast
 * stack.
 *
 * The ToastContainer consumes the useToasts hook which is also exported
 * from the ToastProvider. This provides read-only access to the stack
 * of Toast state that the Provider manages, which this component then
 * renders.
 */

interface ToastContainerProps {
  className?: string;
  directions?: Direction[];
}

// TODO add max toasts / toast cap

/**
 * Directions can be customized according to
 * the DaisyUI options for Toasts: https://daisyui.com/components/toast/
 */
function ToastContainer({ className, directions = [] }: ToastContainerProps) {
  const { toasts } = useToast();

  const getToastType = (state: UIState | undefined) => {
    if (state === 'success') return SuccessToast;
    if (state === 'info') return InfoToast;
    if (state === 'error') return ErrorToast;
    if (state === 'warning') return WarningToast;
    return Toast;
  };

  return (
    <div
      className={`toast ${directions
        .map((d) => `toast-${d}`)
        .join(' ')} ${className}`}
    >
      {toasts.map((t) => getToastType(t.state)({ children: t.content }))}
    </div>
  );
}

export default ToastContainer;
