import fetchAPI from '../util';

async function updateMedical(id?: number, data?: any) {
  try {
    console.log('UPDATE PILOT > data', data);
    const formData = new FormData();

    if (data?.dateMedical) {
      formData.append('date_medical', data.dateMedical);
    }
    if (data?.medicalClass) {
      formData.append('medical_class', data.medicalClass);
    }
    if (data?.medicalDocument instanceof File) {
      formData.append('medical_document', data.medicalDocument);
    }

    await fetchAPI(`/api/pilots/${id}/`, {
      method: 'PATCH',
      body: formData,
    });
  } catch (err) {
    console.log(err);
  }
}

export default updateMedical;
