import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import getLimitOptions from '../../API/Pilot/getLimitOptions';
import getRatingOptions from '../../API/Pilot/getRatingOptions';
import getCertificateTypes from '../../API/Pilot/getCertificateTypes';
import CertificateLimitOption from '../../types/CertificateLimitOption';
import CertificateRatingOption from '../../types/CertificateRatingOption';
import PilotCertificateType from '../../types/PilotCertificateType';
import PilotCertificateRating from '../../types/PilotCertificateRating';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';

interface CertificateMetadataProviderProps {
  children: React.ReactNode;
}

interface ICertificateMetadataContext {
  certificateOptionsLoading: boolean;
  limitsOptions: CertificateLimitOption[];
  ratingsOptions: CertificateRatingOption[];
  certificateTypes: PilotCertificateType[];
  commercialCertificateType: number | null;
  airTransportCertificateType: number | null;
  refreshCertificateOptions: () => Promise<unknown>;
  getRatingNameById: (id: PilotCertificateRating['id']) => string;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const CertificateMetadataContext = createContext<ICertificateMetadataContext>(
  undefined!
);

export function CertificateMetadataProvider({
  children,
}: CertificateMetadataProviderProps) {
  const [limitsOptions, setLimitsOptions] = useState<CertificateLimitOption[]>(
    []
  );
  const [ratingsOptions, setRatingsOptions] = useState<
    CertificateRatingOption[]
  >([]);
  const [certificateTypes, setCertificateTypes] = useState<
    PilotCertificateType[]
  >([]);

  const [commercialCertificateType, setCommercialCertificateType] = useState<
    number | null
  >(null);

  const [airTransportCertificateType, setAirTransportRatingType] = useState<
    number | null
  >(null);

  const [certificateOptionsLoading, setCertificateOptionsLoading] =
    useState(false);

  /** External methods */

  /** Loads the available options for limits, certificate types, and ratings */
  const refreshCertificateOptions = useCallback(async () => {
    setCertificateOptionsLoading(true);
    const limitOptionsResponse = await getLimitOptions();
    const ratingsOptionsResponse = await getRatingOptions();
    const certificateTypesResponse = await getCertificateTypes();

    // TODO look into what New Relic provides in terms of error reporting
    // Let the user know if any of these fail
    if (
      !limitOptionsResponse ||
      limitOptionsResponse.statusCode !== 200 ||
      !limitOptionsResponse.limitOptions
    ) {
      setCertificateOptionsLoading(false);
      throw new Error(`Unable to load limits options`);
    }
    if (!ratingsOptionsResponse || ratingsOptionsResponse.statusCode !== 200) {
      setCertificateOptionsLoading(false);
      throw new Error(`Unable to load ratings options`);
    }
    if (
      !certificateTypesResponse ||
      certificateTypesResponse.statusCode !== 200
    ) {
      setCertificateOptionsLoading(false);
      throw new Error(`Unable to load certificate types`);
    }

    limitOptionsResponse.limitOptions = remapKeysToCamelCase(
      limitOptionsResponse.limitOptions
    ) as CertificateLimitOption[];
    ratingsOptionsResponse.ratingOptions = remapKeysToCamelCase(
      ratingsOptionsResponse.ratingOptions
    ) as CertificateRatingOption[];
    certificateTypesResponse.certificateTypes = remapKeysToCamelCase(
      certificateTypesResponse.certificateTypes
    ) as PilotCertificateType[];

    const commericalCertificateType =
      certificateTypesResponse.certificateTypes.find(
        (ct) => ct.name === 'Commercial Pilot'
      );

    if (!commericalCertificateType) {
      setCertificateOptionsLoading(false);
      throw new Error(`No commercial certificate type configured.`);
    }

    setLimitsOptions(limitOptionsResponse.limitOptions);
    setCertificateTypes(certificateTypesResponse.certificateTypes);
    setRatingsOptions(ratingsOptionsResponse.ratingOptions);
    setCertificateOptionsLoading(false);
  }, []);

  const getRatingNameById = useCallback(
    (id: PilotCertificateRating['id']) => {
      const r = ratingsOptions.find((ro) => ro.id === id);
      return r ? r.name : '';
    },
    [ratingsOptions]
  );

  /** Initialize */
  useEffect(() => {
    const init = async () => {
      await refreshCertificateOptions();
    };
    init();
  }, [refreshCertificateOptions]);

  /** Find the commercial certificate type */
  useEffect(() => {
    const commercialCertType = certificateTypes.find(
      (ct) => ct.name === 'Commercial Pilot'
    );
    const atpCertType = certificateTypes.find(
      (ct) => ct.name === 'Air Transport Pilot'
    );
    setCommercialCertificateType(commercialCertType?.id || null);
    setAirTransportRatingType(atpCertType?.id || null);
  }, [certificateTypes]);

  const value = useMemo(() => {
    return {
      certificateOptionsLoading,
      limitsOptions,
      ratingsOptions,
      certificateTypes,
      refreshCertificateOptions,
      getRatingNameById,
      commercialCertificateType,
      airTransportCertificateType,
    };
  }, [
    certificateOptionsLoading,
    limitsOptions,
    ratingsOptions,
    certificateTypes,
    refreshCertificateOptions,
    getRatingNameById,
    commercialCertificateType,
    airTransportCertificateType,
  ]);

  /** Expose public interface */

  return (
    <CertificateMetadataContext.Provider value={value}>
      {children}
    </CertificateMetadataContext.Provider>
  );
}

export const useCertificateMetadata = () =>
  useContext<ICertificateMetadataContext>(CertificateMetadataContext);
