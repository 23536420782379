import getPilotHistoryForm from '../../../API/Pilot/getPilotHistoryForm';
import User from '../../../types/User';

interface PilotHistoryFormButtonProps {
  user: User;
}

function PilotHistoryFormButton({ user }: PilotHistoryFormButtonProps) {
  return (
    <button
      type="button"
      className="link inline-flex gap-2"
      onClick={() => user && getPilotHistoryForm(user)}
    >
      <span
        className="fa-solid fa-file-arrow-down"
        role="button"
        aria-label="Download Pilot Profile"
      />
      Download PHF
    </button>
  );
}

export default PilotHistoryFormButton;
