import React from 'react';
import classNames from 'classnames';

import './ButtonGroup.css';
import { PrimaryButton } from '../../atoms/Button/Button';

type ButtonGroupEventHandler = (value: string) => void;

interface ButtonGroupOption {
  displayName: string;
  value: string;
  selected?: boolean;
}

interface ButtonGroupProps {
  options: ButtonGroupOption[];
  className?: string;
  onChange?: ButtonGroupEventHandler;
}

function ButtonGroup({ options, className, onChange }: ButtonGroupProps) {
  const handleClick = (value: string) => onChange && onChange(value);

  return (
    <div className={classNames('join', className)}>
      {options.map((option) => (
        <PrimaryButton
          className={classNames('join-item')}
          outline={!option.selected}
          key={option.value}
          onClick={() => handleClick(option.value)}
        >
          {option.displayName}
        </PrimaryButton>
      ))}
    </div>
  );
}

export default ButtonGroup;
