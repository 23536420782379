import fetchAPI from '../util';

async function signAgreement(
  tripId: number,
  signature: File,
  userId: string
): Promise<any> {
  let signResponse;

  try {
    const formData = new FormData();

    if (localStorage.getItem(`role-${userId}`) === 'pilot') {
      if (signature instanceof File) {
        formData.append('pilot_signature', signature);
      }

      signResponse = await fetchAPI(
        `/api/trips/as_pilot/${tripId}/signature/`,
        {
          method: 'PUT',
          body: formData,
        }
      );
    } else {
      if (signature instanceof File) {
        formData.append('operator_signature', signature);
      }

      signResponse = await fetchAPI(
        `/api/trips/as_consumer/${tripId}/signature/`,
        {
          method: 'PUT',
          body: formData,
        }
      );
    }

    if (signResponse.statusCode === 200) {
      return { success: true };
    }
    return { success: false };
  } catch (err) {
    console.log(err);
    return { success: false };
  }
}

export default signAgreement;
