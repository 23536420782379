/* eslint-disable import/prefer-default-export */
export const endpoints = {
  AIRCRAFT_CONFIGURATION: '/api/aircraft_configurations/',
  AIRCRAFT_IDENTIFIER: '/api/aircraft_identifiers/',
  AIRCRAFT_INSURANCE: '/api/aircraft_insurance/',
  AIRCRAFT_LOCATION: '/api/aircraft_locations/',
  AIRCRAFT_PROCEDURE: '/api/aircraft_procedures/',
  OWNERS_OPERATORS: '/api/owners_operators/',
  AIRCRAFT: '/api/aircrafts/',
  FUEL_CARDS: '/api/fuel_cards/',
  MAINTAINER: '/api/maintainers/',
  MESSAGES: '/api/messages/',
  QUOTES: '/api/user/quotes',
  STRIPE: '/api/stripe/',
  PILOT_CERTIFICATE_LIMITS: '/api/pilot_cert_limits/',
  PILOT_CERTIFICATE_RATINGS: '/api/pilot_cert_ratings/',
};
