import React, { createContext, useRef, useState } from 'react';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import Card from '../../molecules/Card/Card';
import Debug from '../../atoms/Form/Debug';
import useDisabledObserver from '../../../hooks/useDisabledObserver';
import SettingsCard from '../../molecules/Card/SettingsCard';

// Create a context to hold the disabled state
export const SettingsFormDisabledContext = createContext<boolean | undefined>(
  undefined
);

/**
 * SettingsForm is a single Formik instance that renders a form with a title and
 * children form fields. The form can be toggled between edit and save mode, and
 * the form is submitted when the save button is clicked. The form state is
 * reset when the cancel button is clicked.
 * ```
 * <SettingsForm
 *  title="My Form"
 * initialValues={initialValues}
 * onSubmit={(values) => console.log('Full form submit', values)}
 * validationSchema={validSchema}
 * >
 * <MyFormikFields />
 * </SettingsForm>
 * ```
 */
function SettingsForm<T extends FormikValues>({
  title,
  initialValues,
  onSubmit,
  debug = false,
  children,
  validationSchema,
}: {
  title: string;
  initialValues: T;
  onSubmit: (values: T, helpers: FormikHelpers<T>) => void;
  debug?: boolean;
  children: React.ReactNode;
  validationSchema: Yup.ObjectSchema<any>;
}) {
  // local state to toggle disabled state
  const [disabled, setDisabled] = useState(true);
  const ref = useRef<HTMLFieldSetElement>(null);
  useDisabledObserver(ref, setDisabled);

  // https://formik.org/docs/guides/form-submission#submission
  const handleSubmit = (values: T, helpers: FormikHelpers<T>) => {
    setDisabled(true);

    // final submit
    console.log('submitting values...', values);
    onSubmit(values, helpers);
    helpers.setSubmitting(false);
    helpers.setTouched({});

    // update initialValues
    helpers.resetForm({ values });
  };

  return (
    <Card className="lg:rounded-l-none lg:border-l-0" responsive>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        initialTouched={initialValues[Object.keys(initialValues)[0]]}
      >
        {(formik) => (
          <SettingsCard
            className={classNames(!disabled && 'border-secondary')}
            disabled={disabled}
            onCancel={() => {
              formik.resetForm();
              setDisabled(true);
            }}
            onEdit={() => setDisabled(false)}
            onSave={formik.submitForm}
          >
            <Form className="flex flex-col">
              <fieldset className="group/field" ref={ref} disabled={disabled}>
                <SettingsFormDisabledContext.Provider value={disabled}>
                  {children}
                </SettingsFormDisabledContext.Provider>
              </fieldset>
              {debug && <Debug />}
            </Form>
          </SettingsCard>
        )}
      </Formik>
    </Card>
  );
}

export default SettingsForm;
