import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from '../../atoms/Button/Button';
import { ROUTES } from '../../../routes/routerConfig';
import { type Airplane } from '../../../types/Airplane';
import FullAirplaneDetails from '../FullAirplaneDetails/FullAirplaneDetails';
import Tabs, { TabData } from '../Tabs/Tabs';
import Card from '../../molecules/Card/Card';
import Loader from '../../molecules/Loader';
import PlaneType from '../../../types/PlaneType';
import { usePlaneTypes } from '../../../hooks/PlaneTypesProvider';
import Tooltip from '../../atoms/Tooltip/Tooltip';

type AirplaneListProps = {
  planeTypes: PlaneType[];
  loading: boolean;
  airplanes: Airplane[];
};

export default function AirplaneList({
  planeTypes,
  loading,
  airplanes,
}: AirplaneListProps) {
  const { getCraftNameById } = usePlaneTypes();
  const navigate = useNavigate();
  const airplaneTabs: TabData = airplanes.reduce((airplaneTabs, plane) => {
    const planeName = `${plane.identification?.tailNumber} - ${getCraftNameById(
      plane.model
    )}`;
    // eslint-disable-next-line no-param-reassign
    airplaneTabs[planeName] = {
      displayName: plane.isValid ? (
        <span className="rounded-btn px-4 mt-[-0.25rem] mb-[-0.25rem] ml-[calc(var(--tab-padding)*-1)] mr-[calc(var(--tab-padding)*-1)] group-[.tab-active]:text-success-content group-[.tab-active]:bg-success">
          {planeName}{' '}
          <i className="pl-1 fas fa-check-circle rounded-full group-[:not(.tab-active)]:text-success group-[:not(.tab-active)]:bg-success-content" />
        </span>
      ) : (
        <span className="rounded-btn px-4 mt-[-0.25rem] mb-[-0.25rem] ml-[calc(var(--tab-padding)*-1)] mr-[calc(var(--tab-padding)*-1)] group-[.tab-active]:text-error-content group-[.tab-active]:bg-error">
          {planeName}{' '}
          <i className="pl-1 fas fa-exclamation-circle group-[:not(.tab-active)]:text-error group-[:not(.tab-active)]:bg-error-content  rounded-full" />
        </span>
      ),
      content: <FullAirplaneDetails planeTypes={planeTypes} airplane={plane} />,
    };
    return airplaneTabs;
  }, {} as TabData);

  if (loading) {
    return (
      <Card responsive>
        <Loader level={3} caption="Loading aircraft..." />
      </Card>
    );
  }

  return (
    <div className="flex flex-col gap-4 mt-4">
      <SecondaryButton
        className="max-w-fit"
        onClick={() => navigate(ROUTES.ADD_AIRCRAFT)}
      >
        Add a plane
      </SecondaryButton>
      {airplanes.length === 0 ? (
        <Card responsive>
          <p>No aircraft found. Add a plane to get started.</p>
        </Card>
      ) : (
        <Tabs tabs={airplaneTabs} tabStyle="boxed" />
      )}
    </div>
  );
}
