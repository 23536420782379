import classNames from 'classnames';
import React, { ReactNode } from 'react';

/** Semantic element with built in styling/spacing */
export default function FormField({
  children,
  className,
  inline = false,
}: {
  children: ReactNode;
  className?: string;
  inline?: boolean;
}) {
  return (
    <div
      className={classNames(
        'w-full max-w-xs py-3',
        { 'flex items-center gap-4': inline },
        className
      )}
    >
      {children}
    </div>
  );
}
