import { useEffect, useState } from 'react';
import Certificate from './Certificate';
import Loader from '../../components/molecules/Loader';
import { useCertificateMetadata } from '../../hooks/PilotCertificateProvider/CertificateMetadataProvider';
import { PilotRole } from '../../types/PilotRole';
import PilotCertificate from '../../types/PilotCertificate';

interface CertificatesProps {
  loading: boolean;
  certificates: PilotRole['licenseCerts'];
}

/**
 * Certificates is the view displaying a pilot's certificates
 * including a method to add and delete them.
 */
function Certificates({ loading, certificates }: CertificatesProps) {
  const { certificateTypes } = useCertificateMetadata();

  /**
   * The tabs should correlate to the type ratings on a pilot's certificate.
   *
   * To do this, we reduce the pilot's plane type statistics down to the entries
   * that appear in the list from getTypeRatings utility function, using the
   * craftType property as the key.
   */

  const [currentTabId, setCurrentTabId] = useState(certificates[0].id);
  const [currentCert, setCurrentCert] = useState(certificates[0]);

  const getCertificateDisplayName = (cert: PilotCertificate) => {
    if (!cert) return '';
    const matchingType = certificateTypes.find((ct) => ct.id === cert.type);
    if (matchingType) return matchingType.name;
    return 'Unknown Type';
  };

  useEffect(() => {
    if (certificates.length) {
      setCurrentCert(
        certificates.find((c) => c.id === currentTabId) || certificates[0]
      );
    }
  }, [certificates, currentTabId]);

  return loading || !certificates.length || !currentCert ? (
    <Loader caption="Loading certificates..." />
  ) : (
    <>
      <div className="tabs tabs-boxed items-center max-w-fit" role="tablist">
        {certificates.map((cert) => (
          <button
            type="button"
            role="tab"
            key={cert.id}
            className={`tab ${currentTabId === cert.id && 'tab-active'} h-fit`}
            onClick={() => setCurrentTabId(cert.id)}
          >
            {certificateTypes.find((ct) => ct.id === cert.type)?.name}
          </button>
        ))}
      </div>
      <Certificate
        certificate={currentCert}
        certificateName={getCertificateDisplayName(currentCert)}
        key={currentCert.id}
      />
    </>
  );
}

export default Certificates;
