import PilotCertificate from '../../types/PilotCertificate';
import { PilotRole } from '../../types/PilotRole';
import isEmptyObject from '../../utilities/isEmptyObject';
import { remapKeysToSnakeCase } from '../../utilities/remapKeys';
import updateCertificateLicense from './updateCertificateLicense';
import createCertificateLicense from './createCertificateLicense';

const endpoint = {
  license: `/api/licenses/`,
  cert: `/api/pilot_certs/`,
  limits: `/api/pilot_cert_limits/`,
};

interface UpdatePilotCertificateResponse {
  statusText?: string;
  statusCode: number;
  certificate: PilotCertificate | null;
}

/** PATCH existing Pilot Certificate */
async function updatePilotCertificate(
  pilot: PilotRole,
  certificate: PilotCertificate
): Promise<UpdatePilotCertificateResponse | null> {
  try {
    if (isEmptyObject(certificate)) {
      throw new Error('No certificate provided');
    }

    if (!certificate.id) {
      throw new Error(
        `Certificate provided to updatePilotCertificate does not have an id`
      );
    }

    if (certificate.license) {
      /**
       * Check the front and back. If either is a File instead of
       * a string, the user has uploaded an image from the frontend
       * and we'll need to update the existing record with the new
       * images, or create a License if there's no matching record.
       *
       * If there is a valid License on the Certificate, the license
       * object will have an id. If not, we know we need to create one.
       */

      if (certificate.license.id) {
        /**
         * We know this license already exists, so let's see if we
         * need to update it.
         */

        /**
         * Start with a payload object that also holds the state
         * that can tell us if we need to update or not.
         */
        const updatePayload = {
          licenseId: certificate.license.id,
          front: undefined as File | undefined,
          back: undefined as File | undefined,
        };

        if (certificate.license.front instanceof File)
          updatePayload.front = certificate.license.front;
        if (certificate.license.back instanceof File)
          updatePayload.back = certificate.license.back;

        if (updatePayload.front || updatePayload.back) {
          console.log(
            `Updating ${updatePayload.front ? 'front' : ''} ${
              updatePayload.back ? 'back' : ''
            }`
          );
          await updateCertificateLicense(updatePayload);
        }
      } else {
        // We need to create a new license!
        console.log(`Creating license for certificate ${certificate.id}`);
        // eslint-disable-next-line no-param-reassign
        certificate.license = (
          await createCertificateLicense(certificate.id)
        ).license;
      }
    }

    // Make API call to update existing certificate
    const response = await fetch(`${endpoint.cert}${certificate.id}/`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      body: JSON.stringify({
        ...(remapKeysToSnakeCase(certificate) as Partial<PilotCertificate>),
      }),
    });

    const updatedCertificate = await response.json();
    return {
      statusText: response.statusText,
      statusCode: response.status,
      certificate: updatedCertificate as PilotCertificate,
    };
  } catch (error) {
    console.error(error);
    return null;
  }
}

export default updatePilotCertificate;
