import getUser from '../User/getUser';

const endpoint = `/auth/registration/`;

export interface RegistrationError {
  errors: {
    email?: string[];
    password1?: string[];
    password2?: string[];
    non_field_errors?: string[];
  };
  statusCode: number;
}

export interface RegistrationFields {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

/**
 * Use user provided information to register a new account and return the freshly created user object on success.
 *
 * @param firstName - string
 * @param lastName - string
 * @param email - string
 * @param password - string
 * @param confirmPassword - string
 * @returns A promise resolving to a RegistrationError or a GetUserResponse
 */
export const register = async ({
  firstName,
  lastName,
  email,
  password,
  confirmPassword,
}: RegistrationFields) => {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password1: password,
      password2: confirmPassword,
    }),
    credentials: 'include',
  });

  const responseBody = await response.json();

  if (response.status !== 201) {
    return {
      errors: responseBody,
      statusCode: response.status,
    } as RegistrationError;
  }

  const { key } = responseBody;

  localStorage.setItem('key', key);

  await fetch('/auth/user/', {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${key}`,
    },
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      username: email,
      key,
    }),
    credentials: 'include',
  });

  const getUserResponse = await getUser();

  return getUserResponse;
};
