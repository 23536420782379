import React from 'react';

import { FormikHelpers } from 'formik';

import Form from '../../../../components/organisms/Form/Form';
import QuoteFormFields from './QuoteFormFields';
import QuoteReview from './QuoteReview';
import { Quote } from '../../../../types/Quote';
import validQuote from './quoteSchema';

interface QuoteFormProps {
  initialForm: Quote;
  handleCancel: () => void;
  handleSubmit: (values: Quote, helpers: FormikHelpers<Quote>) => void;
}

export default function QuoteForm({
  initialForm,
  handleCancel,
  handleSubmit,
}: QuoteFormProps) {
  return (
    <Form
      isCancellable
      isValid={false}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      initialValues={initialForm}
      validationSchema={validQuote}
      submitLabel="Submit Quote"
      cancelLabel="Cancel Quote"
    >
      <div className="flex flex-col gap-8">
        <QuoteFormFields />
        <QuoteReview />
      </div>
    </Form>
  );
}
