import { useUser } from '../../hooks/UserProvider/UserProvider';

const endpoint = `/auth/logout/`;

const logout = async () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { logout } = useUser();

  await fetch(endpoint, {
    method: 'POST',
    credentials: 'include',
  });

  localStorage.removeItem('key');
  logout();
  window.location.reload();
};

export default logout;
