/* eslint-disable no-nested-ternary */
import { useAircraft } from '../../hooks/useAircraft';
import AirplaneList from '../../components/organisms/AirplaneList/AirplaneList';
import Heading from '../../components/typography/Heading';
import { usePlaneTypes } from '../../hooks/PlaneTypesProvider';
import Alert from '../../components/atoms/Alert/Alert';
import i18n from '../../strings/i18n';

function MyAircraftPage() {
  const { airplanes, loading } = useAircraft();
  const { planeTypes, loading: loadingPlaneTypes } = usePlaneTypes();

  return (
    <>
      <Heading level={1}>My Aircraft</Heading>
      <Heading level={2}>Planes</Heading>
      {/* TODO: handle error state */}
      <AirplaneList
        planeTypes={planeTypes}
        loading={loading || loadingPlaneTypes}
        airplanes={airplanes}
      />
      <Alert
        className="mt-4"
        message={`Need to remove an aircraft? Message us at ${i18n({
          contact: 'email',
        })} for assistance.`}
      />
    </>
  );
}

export default MyAircraftPage;
