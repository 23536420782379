import React from 'react';

import Heading, { Level } from '../../typography/Heading/Heading';

function Loader({
  caption,
  level = 4,
  className,
}: {
  caption?: string;
  level?: Level;
  className?: string;
}) {
  return caption ? (
    <div className={`flex items-center gap-4 ${className}`}>
      <Heading level={level}>{caption}</Heading>
      <span className="loading loading-spinner" />
    </div>
  ) : (
    <span className="loading loading-spinner" />
  );
}

export default Loader;
