// Import React
import { useEffect, useState } from 'react';
// Import shared app components and types
import SearchableDropdown from '../SearchableDropdown/SearchableDropdown';
import { AirportId } from '../../../types/AirportLocation';
// Import hooks and utilities
import { useAirports } from '../../../hooks/AirportsProvider';

interface AirportSelectProps {
  id: string;
  onSelect: (airportId: AirportId) => unknown;
  defaultValue?: AirportId;
  disabled?: boolean;
}

export default function AirportSelect({
  id,
  onSelect,
  defaultValue,
  disabled,
}: AirportSelectProps) {
  const { airports, getAirportDisplayName } = useAirports();

  const [airportOptions, setAirportOptions] = useState(
    airports.map((airport) => getAirportDisplayName(airport))
  );

  const defaultAirport = airports.find((ap) => ap.id === defaultValue);

  const handleSelect = (airportDisplayName: string) => {
    const selectedAirport = airports.find(
      (a) => getAirportDisplayName(a) === airportDisplayName
    );
    if (selectedAirport) {
      onSelect(selectedAirport.id);
    } else {
      // eslint-disable-next-line no-console
      console.error(
        `No airport found matching display name: ${airportDisplayName}`
      );
    }
  };

  useEffect(() => {
    setAirportOptions(
      airports.map((airport) => getAirportDisplayName(airport))
    );
  }, [airports, getAirportDisplayName]);

  return (
    <SearchableDropdown
      id={id}
      options={airportOptions}
      onSelect={handleSelect}
      className="w-full"
      disabled={disabled}
      defaultValue={
        defaultAirport ? getAirportDisplayName(defaultAirport) : undefined
      }
    />
  );
}
