import PilotCertificate from '../../types/PilotCertificate';

interface UpdateCertificateLicenseProps {
  licenseId: number;
  front?: File;
  back?: File;
}

const endpoint = `/api/licenses/`;

async function updateCertificateLicense({
  licenseId,
  front,
  back,
}: UpdateCertificateLicenseProps) {
  if (!front && !back) {
    throw new Error(
      `Can't updateCertificateLicense when front and back are both undefined`
    );
  }

  const licensePayload = new FormData();

  if (front) licensePayload.append('front', front);
  if (back) licensePayload.append('back', back);

  const response = await fetch(`${endpoint}${licenseId}/`, {
    method: 'PATCH',
    credentials: 'include',
    headers: {
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
    body: licensePayload,
  });

  const license = await response.json();

  return {
    statusCode: response.status,
    license: license as PilotCertificate['license'],
  };
}

export default updateCertificateLicense;
