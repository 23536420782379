import React from 'react';
import FormikInput from '../../../atoms/Form/FormikInput';
import FormikField from '../../../atoms/Form/FormikField';
import AircraftOwnersAndOperators from './AircraftOwnersAndOperators';
import i18n from '../../../../strings/i18n';
import Heading from '../../../typography/Heading';

function AircraftIdentifiers() {
  return (
    <>
      <AircraftOwnersAndOperators />
      <Heading level={3}>Aircraft Description</Heading>
      <FormikField
        name="identification.serialNumber"
        label="Serial Number"
        placeholder={i18n({ placeholder: 'serialNumber' })}
        component={FormikInput}
        type="number"
      />
      <FormikField
        name="identification.tailNumber"
        label="Tail Number"
        placeholder={i18n({ placeholder: 'tailNumber' })}
        component={FormikInput}
      />

      <FormikField
        name="identification.usCustomsDecalNumber"
        label="US Customs Decal Number"
        placeholder={i18n({ placeholder: 'usCustomsDecalNumber' })}
        component={FormikInput}
        type="number"
        optional
      />
      <FormikField
        name="identification.usCustomsDecalYear"
        label="US Customs Decal Year"
        placeholder={i18n({ placeholder: 'usCustomsDecalYear' })}
        component={FormikInput}
        type="number"
        optional
      />
      <FormikField
        name="identification.managementCompanyName"
        label="Management Company Name"
        placeholder={i18n({ placeholder: 'managementCompanyName' })}
        component={FormikInput}
        optional
      />
      <FormikField
        name="identification.managementCompanyAddress"
        label="Management Company Address"
        placeholder={i18n({ placeholder: 'managementCompanyAddress' })}
        component={FormikInput}
        optional
      />
    </>
  );
}

export default AircraftIdentifiers;
