import RegistrationForm from '../../components/organisms/RegistrationForm';
import Heading from '../../components/typography/Heading';

function RegisterPage() {
  return (
    <>
      <Heading level={1} className="text-center my-4">
        Register
      </Heading>
      <RegistrationForm />
    </>
  );
}

export default RegisterPage;
