import QuestionForm from './QuestionForm';
import Loader from '../../components/molecules/Loader';
import Card from '../../components/molecules/Card/Card';
import Heading from '../../components/typography/Heading';
import { useUser } from '../../hooks/UserProvider/UserProvider';

function QuestionsPage() {
  const { user } = useUser();

  return user ? (
    <div className="flex flex-col gap-4">
      <Heading level={1} className="mb-4">
        Insurance Questions
      </Heading>
      {user?.pilotRole ? (
        <QuestionForm />
      ) : (
        <Card responsive>
          <Loader level={3} caption="Loading Pilot Data..." />
        </Card>
      )}
    </div>
  ) : (
    <Loader caption="Loading user" />
  );
}

export default QuestionsPage;
