import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import logout from '../../API/Auth/logout';
import LoginForm from '../../components/organisms/LoginForm';
import Heading from '../../components/typography/Heading';
import { ROUTES } from '../routerConfig';

function LogoutPage() {
  const { user } = useUser();
  const navigate = useNavigate();

  logout();

  // Navigate home when user is cleared
  useEffect(() => {
    if (!user) {
      navigate(ROUTES.HOME);
    }
  }, [user, navigate]);

  return (
    <>
      <Heading level={1} className="text-center mb-4">
        Logging you out...
      </Heading>
      <LoginForm />
    </>
  );
}

export default LogoutPage;
