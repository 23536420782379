import { useAirports } from '../../../hooks/AirportsProvider';
import { TripLeg } from '../../../types/TripLeg';
import Loader from '../../molecules/Loader';
import Heading from '../../typography/Heading';

interface ItineraryTableProps {
  legs: TripLeg[];
}

function ItineraryTable({ legs }: ItineraryTableProps) {
  const { loading, getAirportFromId } = useAirports();
  return (
    <div className="p-6 col-span-2 border overflow-x-auto">
      <Heading level={4}>Itinerary</Heading>
      {loading ? (
        <Loader caption="Loading airports..." />
      ) : (
        <table className="table table-xs w-full">
          <thead>
            <tr>
              <th>Leg</th>
              <th>From</th>
              <th>To</th>
              <th>Departure Time</th>
              <th>Pax</th>
            </tr>
          </thead>
          <tbody>
            {legs.map(({ fromLocation, toLocation, departureTime, pax }, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{getAirportFromId(fromLocation || 0).icaoCode}</td>
                <td>{getAirportFromId(toLocation || 0).icaoCode}</td>
                <td>
                  {new Date(departureTime).toLocaleString('en-US', {
                    timeZone: 'UTC',
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  })}
                </td>
                <td>{pax}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default ItineraryTable;
