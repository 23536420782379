/* eslint-disable react/jsx-no-bind */
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../../hooks/UserProvider/UserProvider';

import Link from '../../atoms/Link';
import Logo from '../../atoms/Logo';
import SlidingPanel from '../SlidingPanel';
import ConfirmPilotConversionModal from './ConfirmPilotConversionModal';
import { Flight } from '../../atoms/Icons';

import useWindowWidth from '../../../hooks/useWindowWidth';
import query from '../../../const/query';
import { useModalContext } from '../../../hooks/ModalProvider/ModalProvider';

import Rule from '../../atoms/Rule';
import Heading from '../../typography/Heading';
import { ROUTES } from '../../../routes/routerConfig';
import {
  PrimaryButton,
  SecondaryButton,
  StateButton,
} from '../../atoms/Button/Button';
import createPilot from '../../../API/Pilot/createPilot';
import Paragraph from '../../typography/Paragraph';
import PilotHistoryFormButton from '../../molecules/PilotHistoryFormButton/PilotHistoryFormButton';

// TODO add header height to body top padding

function Header() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const {
    user,
    role,
    setUserRole,
    pilotOnboardingStatus,
    operatorOnboardingStatus,
    loadingOperatorStatus,
    loadingPilotStatus,
  } = useUser();
  const { openModal } = useModalContext();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();

  const isLoggedIn = user !== null;

  function toggleMenu() {
    setMenuIsOpen(!menuIsOpen);
  }

  const handleSwitchRole = () => {
    setUserRole(role === 'consumer' ? 'pilot' : 'consumer');
    setMenuIsOpen(false);
    navigate(ROUTES.TRIPS);
  };

  const handleBecomeAPilot = async () => {
    if (!user) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const conversionResponse = await createPilot({
      first_name: user.firstName,
      last_name: user.lastName,
      email: user.email,
    });
    navigate(ROUTES.TRIPS);
    localStorage.setItem(`role-${user?.id}`, 'pilot');
    window.location.reload();
  };

  const confirmUserPilotConversion = () => {
    openModal(<ConfirmPilotConversionModal handleYes={handleBecomeAPilot} />);
  };

  const renderLoggedOutLinks = () => (
    <>
      <Heading level={3}>Welcome!</Heading>
      <Link
        className="mt-4"
        to={ROUTES.LOGIN}
        onClick={() => setMenuIsOpen(false)}
      >
        <PrimaryButton>Log In</PrimaryButton>
      </Link>
      <Link
        className="mt-4"
        to={ROUTES.REGISTER}
        onClick={() => setMenuIsOpen(false)}
      >
        <SecondaryButton>Sign Up</SecondaryButton>
      </Link>
    </>
  );

  const renderLoggedInLinks = () => (
    <>
      <Link to={ROUTES.TRIPS} onClick={() => setMenuIsOpen(false)}>
        <Heading level={3}>Trips</Heading>
      </Link>
      <Rule className="mt-4" />
      {role === 'pilot' ? (
        <>
          <Heading className="mt-4" level={4}>
            Pilot Profile
          </Heading>
          <Link
            to={ROUTES.GENERAL_INFORMATION}
            onClick={() => setMenuIsOpen(false)}
          >
            General Information
          </Link>
          <Link
            to={ROUTES.CERTIFICATES_AND_RATINGS}
            onClick={() => setMenuIsOpen(false)}
          >
            Certificates and Ratings
          </Link>
          <Link to={ROUTES.HOURS_TRAINING} onClick={() => setMenuIsOpen(false)}>
            Hours and Training
          </Link>
          <Link to={ROUTES.QUESTIONS} onClick={() => setMenuIsOpen(false)}>
            Questions
          </Link>
          {!!user && <PilotHistoryFormButton user={user} />}
        </>
      ) : (
        <>
          <Heading className="mt-4" level={4}>
            Operator Profile
          </Heading>
          <Link
            to={ROUTES.GENERAL_INFORMATION}
            onClick={() => setMenuIsOpen(false)}
          >
            General Information
          </Link>
          <Link to={ROUTES.MY_AIRCRAFT} onClick={() => setMenuIsOpen(false)}>
            Aircraft
          </Link>
        </>
      )}
      <Rule className="mt-4" />
      <Heading level={4} className="mt-4">
        Account Settings
      </Heading>
      <Link
        to={ROUTES.BILLING_AND_PAYMENTS}
        onClick={() => setMenuIsOpen(false)}
      >
        Billing & Payments
      </Link>
      <Link to={ROUTES.ACCOUNT_SECURITY} onClick={() => setMenuIsOpen(false)}>
        Password & Security
      </Link>
      <Rule className="my-4" />
      <Link
        href={
          role === 'consumer'
            ? 'https://www.youtube.com/playlist?list=PLpmi6PsdPyeDUeerAccJ2IQ820RHiIaqt'
            : 'https://www.youtube.com/playlist?list=PLpmi6PsdPyeDaYSdZr4IgTDf-NMXPHj6T'
        }
        relative={false}
      >
        Tutorial for {role === 'consumer' ? 'Operators' : 'Pilots'}{' '}
        <i className="fa-solid fa-arrow-up-right-from-square" />
      </Link>
      <Rule className="my-4" />
      <div
        className={`flex flex-col gap-4 ${
          windowWidth >= query.tablet ? 'items-end' : 'items-center'
        }`}
      >
        {role === 'consumer' && (
          <Link to={ROUTES.CREATE_TRIP} onClick={() => setMenuIsOpen(false)}>
            <PrimaryButton>
              Create a Trip
              <Flight rotation={-15} />
            </PrimaryButton>
          </Link>
        )}
        {user?.pilotRole && (
          <PrimaryButton outline onClick={handleSwitchRole}>
            Switch Modes
          </PrimaryButton>
        )}
        {!user?.pilotRole && (
          <PrimaryButton outline onClick={confirmUserPilotConversion}>
            Create a Pilot Account
          </PrimaryButton>
        )}
        <Link to={ROUTES.LOGOUT} onClick={() => setMenuIsOpen(false)}>
          <StateButton status="error">Log Out</StateButton>
        </Link>
      </div>
    </>
  );

  const showOnboardingStatusProblems = () => {
    openModal(
      role === 'consumer'
        ? operatorOnboardingStatus?.statusContent
        : pilotOnboardingStatus?.statusContent
    );
  };

  return (
    <header
      className={classNames(
        'top-0 sticky py-2 px-3 flex flex-col gap-4 border-b-2 z-20',
        {
          'bg-white': role === 'consumer',
          'bg-accent': role === 'pilot',
        }
      )}
    >
      <div className={classNames('flex w-full items-center gap-6')}>
        <Logo />
        {isLoggedIn ? (
          <>
            <Paragraph className="text-lg">
              <span className="ml-4 block -skew-x-6">
                <span className="py-2 px-1 bg-primary mr-2" />
                <span className="py-2 px-2 bg-primary text-white font-semibold">
                  {role === 'pilot' ? 'Pilot' : 'Operator'}
                </span>
              </span>
            </Paragraph>
            <div className="ml-auto flex gap-4">
              {(role === 'consumer'
                ? !loadingOperatorStatus &&
                  operatorOnboardingStatus?.statusContent
                : !loadingPilotStatus &&
                  pilotOnboardingStatus?.statusContent) && (
                <StateButton
                  status="warning"
                  onClick={showOnboardingStatusProblems}
                >
                  <i className="fas fa-exclamation-triangle" />
                  <span className="hidden lg:block">
                    You have incomplete onboarding tasks
                  </span>
                </StateButton>
              )}
              <PrimaryButton onClick={toggleMenu}>Menu</PrimaryButton>
            </div>
            <SlidingPanel
              closeHandler={toggleMenu}
              isOpen={menuIsOpen}
              from={windowWidth >= query.tablet ? 'right' : 'top'}
              role={role}
            >
              <Logo className="mb-4" />
              {user ? renderLoggedInLinks() : renderLoggedOutLinks()}
            </SlidingPanel>
          </>
        ) : (
          <div className="ml-auto">
            <Link to={ROUTES.REGISTER}>
              <SecondaryButton>Sign Up</SecondaryButton>
            </Link>
            <Link className="ml-2" to={ROUTES.LOGIN}>
              <PrimaryButton>Log In</PrimaryButton>
            </Link>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
