import type { Expense } from '../../../../types/Expense';

/** @deprecated DO NOT USE */
export default function FinalExpensesStats({
  itemizedExpenses,
}: {
  itemizedExpenses: Expense[];
}) {
  // TODO: calculate unique expense titles
  const uniqueExpenseTitles = Array.from(
    new Set(
      itemizedExpenses
        .map((expense) => expense.title)
        .filter((title) => !!title)
    )
  );

  return (
    <div className="stats stats-vertical md:stats-horizontal shadow">
      {uniqueExpenseTitles.map((title) => (
        <div className="stat" key={title}>
          <div className="stat-figure text-secondary">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block h-8 w-8 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div className="stat-title">{title}</div>
          <div className="stat-value">
            $
            {itemizedExpenses.reduce(
              (acc, expense) =>
                expense.title === title ? acc + expense.cost : acc,
              0
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
