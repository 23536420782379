const confirmTripAsPilot = async (tripId: number) => {
  const response = await fetch(`/api/trips/as_pilot/${tripId}/confirm/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
    credentials: 'include',
  });

  if (response.status !== 204) {
    throw new Error('Unable to confirm trip');
  } else {
    return {
      success: true,
      statusCode: response.status,
    };
  }
};

export default confirmTripAsPilot;
