const en = {
  common: {
    allRequired: 'All fields are required unless otherwise specified.',
    back: 'Back',
    cancel: 'Cancel',
    delete: 'Delete',
    name: 'Name',
    next: 'Next',
    previous: 'Previous',
    save: 'Save',
    submit: 'Submit',
    type: 'Type',
  },
  contact: {
    email: 'info@flyingcompany.com',
  },
  newAircraft: {
    addFuelCard: 'Add Fuel Card',
    addOwnerOperator: 'Add Registered Owner / Operator',
    description:
      'Please provide the following information to add a new aircraft.',
    operator: 'Operator',
    registeredOwner: 'Registered Owner',
    success: 'Aircraft added successfully.',
    title: 'Add New Aircraft',
  },
  placeholder: {
    // give me a catering option with a star wars theme
    catering: 'Topo Chico, Clif Bars, and Bantha Milk',
    companyName: 'Ti-Fighter Insurance Inc.',
    contactEmail: 'lando.calrissan@gmail.com',
    contactName: 'Lando Calrissian',
    documentStorageLocation: 'Aft cabinet',
    dressAndAppearance: 'Business casual',
    fixedBaseOperator: 'Cloud City FBO',
    flightLogProcedures: 'Email times to Management Company',
    managementCompanyAddress: '1234 Rebel Base Rd',
    managementCompanyName: 'Rebel Alliance Management',
    operator: 'Han Solo',
    phoneNumber: '(123) 456-7899',
    preferredMaintenanceFacility: 'Millennium Falcon Repair - ENDOR',
    proceduresNotes:
      'Please start #2 engine 15 minutes before takeoff time while passengers arrive',
    registeredOwner: 'Millennium Falcon 1 LLC',
    serialNumber: '1300',
    spareKeyLocation: 'Held by Chief Pilot',
    squawks: 'GPU start required. Pilots map light INOP.',
    tailNumber: 'N1300YT',
    usCustomsDecalNumber: '12345678',
    usCustomsDecalYear: '2021',
  },
  validation: {
    basicEmptyWeight: 'Basic empty weight must be greater than 1,000.',
    email: 'Invalid email.',
    invalid: 'Invalid input.',
    invalidDynamic: 'The %field% you provided is not valid.',
    ownerOperator: 'Please name at least one owner and one operator.',
    password: 'Password must be at least 8 characters long.',
    passwordConfirm: 'Passwords must match.',
    phone: 'Invalid phone number.',
    required: 'This field is required.',
    requiredDynamic: '%field% is required.',
    selection: 'Please select an option.',
  },
};

export default en;
