import { useState } from 'react';
import Alert from '../../../components/atoms/Alert/Alert';
import {
  StateButton,
  PrimaryButton,
} from '../../../components/atoms/Button/Button';
import Dropdown from '../../../components/atoms/Dropdown/Dropdown';
import Label from '../../../components/atoms/Label';
import Select from '../../../components/atoms/Select/Select';
import Heading from '../../../components/typography/Heading';
import Input from '../../../components/atoms/Input';

const cancelOptions = [
  'Weather',
  'Schedule Change',
  'Crew Availability',
  'Maintenance Issues',
  'No suitable Pilot quotes',
  'Pilot found elsewhere',
  'Other (add explanation)',
];

function ConfirmCancelModal({
  onConfirm,
  onClose,
}: {
  onConfirm: (reason: string) => Promise<void>;
  onClose: () => void;
}) {
  const [cancelReason, setCancelReason] = useState(cancelOptions[0]);
  const [customReason, setCustomReason] = useState('');
  const [cancelling, setCancelling] = useState(false);

  const cancel = async () => {
    setCancelling(true);
    await onConfirm(
      cancelReason === 'Other (add explanation)' ? customReason : cancelReason
    );
    setCancelling(false);
  };

  return (
    <div className="flex flex-col gap-4">
      {!cancelling && (
        <Alert
          message="Are you sure you want to cancel this trip?"
          status="warning"
        />
      )}
      {cancelling && <Alert message="Cancelling trip..." status="warning" />}
      <div className="flex flex-col gap-4">
        <div>
          <Label htmlFor="cancelReasonSelect" required>
            Cancel Reason
          </Label>
          <Select
            id="cancelReasonSelect"
            options={cancelOptions}
            onChange={(r) => setCancelReason(r)}
            disabled={cancelling}
          />
        </div>
        {cancelReason === 'Other (add explanation)' && (
          <div>
            <Label htmlFor="cancelReasonSelect" required>
              Other Reason
            </Label>
            <Input
              onChange={(e) => setCustomReason(e.target.value)}
              disabled={cancelling}
            />
          </div>
        )}
      </div>
      <div className="w-full grid grid-cols-2 gap-4">
        <PrimaryButton onClick={onClose} disabled={cancelling}>
          Go Back
        </PrimaryButton>
        <StateButton status="error" onClick={cancel} disabled={cancelling}>
          Cancel Trip
        </StateButton>
      </div>
    </div>
  );
}

export default ConfirmCancelModal;
