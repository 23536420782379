import React from 'react';
import classNames from 'classnames';

import logo from './logo.png';
import Link from '../Link';
import { ROUTES } from '../../../routes/routerConfig';

function Logo({ className }: { className?: string }) {
  return (
    <Link to={ROUTES.HOME}>
      <img
        className={classNames('logo max-h-[3rem] md:max-h-[4rem]', className)}
        src={logo}
        alt="Flying company logo"
      />
    </Link>
  );
}

export default Logo;
