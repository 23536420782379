import * as Yup from 'yup';
import i18n from '../../../../strings/i18n';

const validAircraftLocationSchema = Yup.object({
  location: Yup.object().shape({
    airport: Yup.string().required(
      i18n({ validation: 'requiredDynamic' }, { field: 'Airport' })
    ),
    fixedBaseOperator: Yup.string().optional(),
    details: Yup.string().optional(),
  }),
});

export default validAircraftLocationSchema;
