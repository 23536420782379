export default function CancelIcon() {
  return (
    <svg
      width="inherit"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.23828 1.40198L10.7617 10.598M10.7617 1.40198L1.23828 10.598L10.7617 1.40198Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
