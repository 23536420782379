import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '../../hooks/ToastProvider';
import updatePilot from '../../API/Pilot/updatePilot';
import createPilot from '../../API/Pilot/createPilot';
import SettingsCard from '../../components/molecules/Card/SettingsCard';
import Question, {
  QuestionCategory,
  QuestionCategoryExplanation,
} from './Question';
import Checkbox from '../../components/atoms/Checkbox';
import FormField from '../../components/wrappers/FormField';
import Label from '../../components/atoms/Label';
import Alert from '../../components/atoms/Alert/Alert';
import User from '../../types/User';

function QuestionForm({ user }: { user: User }) {
  const { addToast } = useToast();

  const [disabled, setDisabled] = useState(true);

  const handleCancel = () => {
    window.location.reload();
  };

  const handleEdit = () => {
    setDisabled(false);
  };

  type QuestionCategoryAndSign = QuestionCategory | 'sign';

  const [questionStates, setQuestionStates] = useState<
    Record<QuestionCategoryAndSign, boolean | Date | null>
  >({
    aircraft_claim: user.pilotRole.aircraftClaim,
    certificate_revoked: user.pilotRole.certificateRevoked,
    convicted_drugs: user.pilotRole.convictedDrugs,
    dl_revoked: user.pilotRole.dlRevoked,
    dui: user.pilotRole.dui,
    felony: user.pilotRole.felony,
    insurance_declined: user.pilotRole.insuranceDeclined,
    regulation_violation: user.pilotRole.regulationViolation,
    sign: user.pilotRole.sign,
  });

  const [questionExplanationStates, setQuestionExplanationStates] = useState<
    Record<QuestionCategoryExplanation, string>
  >({
    exp_aircraft_claim: user?.pilotRole.expAircraftClaim || '',
    exp_certificate_revoked: user?.pilotRole.expCertificateRevoked || '',
    exp_convicted_drugs: user?.pilotRole.expConvictedDrugs || '',
    exp_dl_revoked: user?.pilotRole.expDlRevoked || '',
    exp_dui: user?.pilotRole.expDui || '',
    exp_felony: user?.pilotRole.expFelony || '',
    exp_insurance_declined: user?.pilotRole.expInsuranceDeclined || '',
    exp_regulation_violation: user?.pilotRole.expRegulationViolation || '',
  });

  const handleSave = async () => {
    const payload = { ...questionStates, ...questionExplanationStates };

    if (user?.pilotRole?.id) {
      setDisabled(true);

      if (!payload.sign) {
        addToast(
          <span>Please digitally sign the form before saving.</span>,
          'error'
        );
        setDisabled(false);
        return;
      }
      await updatePilot(user?.pilotRole?.id, payload);
      addToast(<span>Questions updated.</span>, 'success');
    } else {
      setDisabled(true);
      await createPilot(payload);
      setDisabled(false);
      addToast(<span>Questions updated.</span>, 'success');
    }
  };

  return (
    <SettingsCard
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      disabled={disabled}
      responsive
    >
      <Question
        question="aircraft_claim"
        questionExplanation={questionExplanationStates.exp_aircraft_claim}
        questionState={questionStates.aircraft_claim}
        setQuestionExplanation={(ex) =>
          setQuestionExplanationStates({
            ...questionExplanationStates,
            exp_aircraft_claim: ex,
          })
        }
        setQuestionState={(s) =>
          setQuestionStates({ ...questionStates, aircraft_claim: s })
        }
        disabled={disabled}
      />
      <Question
        question="certificate_revoked"
        questionExplanation={questionExplanationStates.exp_certificate_revoked}
        questionState={questionStates.certificate_revoked}
        setQuestionExplanation={(ex) =>
          setQuestionExplanationStates({
            ...questionExplanationStates,
            exp_certificate_revoked: ex,
          })
        }
        setQuestionState={(s) =>
          setQuestionStates({ ...questionStates, certificate_revoked: s })
        }
        disabled={disabled}
      />
      <Question
        question="convicted_drugs"
        questionExplanation={questionExplanationStates.exp_convicted_drugs}
        questionState={questionStates.convicted_drugs}
        setQuestionExplanation={(ex) =>
          setQuestionExplanationStates({
            ...questionExplanationStates,
            exp_convicted_drugs: ex,
          })
        }
        setQuestionState={(s) =>
          setQuestionStates({ ...questionStates, convicted_drugs: s })
        }
        disabled={disabled}
      />
      <Question
        question="dl_revoked"
        questionExplanation={questionExplanationStates.exp_dl_revoked}
        questionState={questionStates.dl_revoked}
        setQuestionExplanation={(ex) =>
          setQuestionExplanationStates({
            ...questionExplanationStates,
            exp_dl_revoked: ex,
          })
        }
        setQuestionState={(s) =>
          setQuestionStates({ ...questionStates, dl_revoked: s })
        }
        disabled={disabled}
      />
      <Question
        question="dui"
        questionExplanation={questionExplanationStates.exp_dui}
        questionState={questionStates.dui}
        setQuestionExplanation={(ex) =>
          setQuestionExplanationStates({
            ...questionExplanationStates,
            exp_dui: ex,
          })
        }
        setQuestionState={(s) =>
          setQuestionStates({ ...questionStates, dui: s })
        }
        disabled={disabled}
      />
      <Question
        question="felony"
        questionExplanation={questionExplanationStates.exp_felony}
        questionState={questionStates.felony}
        setQuestionExplanation={(ex) =>
          setQuestionExplanationStates({
            ...questionExplanationStates,
            exp_felony: ex,
          })
        }
        setQuestionState={(s) =>
          setQuestionStates({ ...questionStates, felony: s })
        }
        disabled={disabled}
      />
      <Question
        question="insurance_declined"
        questionExplanation={questionExplanationStates.exp_insurance_declined}
        questionState={questionStates.insurance_declined}
        setQuestionExplanation={(ex) =>
          setQuestionExplanationStates({
            ...questionExplanationStates,
            exp_insurance_declined: ex,
          })
        }
        setQuestionState={(s) =>
          setQuestionStates({ ...questionStates, insurance_declined: s })
        }
        disabled={disabled}
      />
      <Question
        question="regulation_violation"
        questionExplanation={questionExplanationStates.exp_regulation_violation}
        questionState={questionStates.regulation_violation}
        setQuestionExplanation={(ex) =>
          setQuestionExplanationStates({
            ...questionExplanationStates,
            exp_regulation_violation: ex,
          })
        }
        setQuestionState={(s) =>
          setQuestionStates({ ...questionStates, regulation_violation: s })
        }
        disabled={disabled}
      />

      {/* digitally sign */}
      <FormField className="flex flex-col gap-4">
        {/* <Label htmlFor="sign-id" required>
          I certify that the information provided is true, correct and up to
          date to the best of my knowledge.
        </Label> */}
        <Alert
          status="error"
          message={
            <div className="flex items-center gap-4">
              <Checkbox
                inverse
                id="sign"
                value={questionStates.sign as boolean}
                onChange={() =>
                  setQuestionStates({
                    ...questionStates,
                    sign: !questionStates.sign,
                  })
                }
              />
              <span>
                I certify that the information provided is true, correct and up
                to date to the best of my knowledge.
              </span>
            </div>
          }
        />
      </FormField>
    </SettingsCard>
  );
}

export default QuestionForm;
