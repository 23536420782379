import * as Yup from 'yup';
import i18n from '../../../../strings/i18n';

const validAircraftConfigurationSchema = Yup.object({
  configuration: Yup.object().shape({
    avionics: Yup.string().required(
      i18n({ validation: 'requiredDynamic' }, { field: 'Avionics' })
    ),
    basicEmptyWeight: Yup.number()
      .required(
        i18n({ validation: 'requiredDynamic' }, { field: 'Basic Empty Weight' })
      )
      .min(1000, i18n({ validation: 'basicEmptyWeight' })),
    lavatoryType: Yup.string().required(
      i18n({ validation: 'requiredDynamic' }, { field: 'Lavatory' })
    ),
    isRvsmCertified: Yup.boolean().required(i18n({ validation: 'required' })),
    seatingConfiguration: Yup.string().required(
      i18n(
        { validation: 'requiredDynamic' },
        { field: 'Seating Configuration' }
      )
    ),
    propellerType: Yup.string()
      .required(
        i18n({ validation: 'requiredDynamic' }, { field: 'Propeller Type' })
      )
      .nullable(),
  }),
});

export default validAircraftConfigurationSchema;
