import { useState } from 'react';
import useForm from '../../hooks/useForm';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import { useToast } from '../../hooks/ToastProvider';
import Input from '../../components/atoms/Input';
import updatePilot from '../../API/Pilot/updatePilot';
import createPilot from '../../API/Pilot/createPilot';
import Card from '../../components/molecules/Card/Card';
import SettingsCard from '../../components/molecules/Card/SettingsCard';

function HourInputCell({
  propName,
  inputs,
  disabled,
  handleChange,
}: {
  propName: string;
  inputs: Record<string, number>;
  disabled: boolean;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) {
  return (
    <td>
      <Input
        id={propName}
        value={inputs[propName]}
        disabled={disabled}
        onChange={handleChange}
        type="number"
      />
    </td>
  );
}

function HourInputRow({
  rowLabel,
  propNames,
  inputs,
  disabled,
  handleChange,
}: {
  rowLabel?: string;
  propNames: string[];
  inputs: Record<string, number>;
  disabled: boolean;
  handleChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
}) {
  return (
    <tr>
      <td>{rowLabel}</td>
      {propNames.map((prop) => (
        <HourInputCell
          propName={prop}
          inputs={inputs}
          disabled={disabled}
          handleChange={handleChange}
        />
      ))}
    </tr>
  );
}

export default function AllCraftHoursMatrix() {
  const { user, refreshUser } = useUser();
  const { addToast } = useToast();

  const initialForm = {
    allAircraftHours: user?.pilotRole?.allAircraftHours ?? 0,
    allAircraftHoursPic: user?.pilotRole?.allAircraftHoursPic ?? 0,
    allAircraftHoursLastNinetyDays:
      user?.pilotRole?.allAircraftHoursLastNinetyDays ?? 0,
    allAircraftHoursLastTwelveMonths:
      user?.pilotRole?.allAircraftHoursLastTwelveMonths ?? 0,
    multiEngineHours: user?.pilotRole?.multiEngineHours ?? 0,
    multiEngineHoursPic: user?.pilotRole?.multiEngineHoursPic ?? 0,
    multiEngineHoursLastNinetyDays:
      user?.pilotRole?.multiEngineHoursLastNinetyDays ?? 0,
    multiEngineHoursLastTwelveMonths:
      user?.pilotRole?.multiEngineHoursLastTwelveMonths ?? 0,
    turbineHours: user?.pilotRole?.turbineHours ?? 0,
    turbineHoursPic: user?.pilotRole?.turbineHoursPic ?? 0,
    turbineHoursLastNinetyDays:
      user?.pilotRole?.turbineHoursLastNinetyDays ?? 0,
    turbineHoursLastTwelveMonths:
      user?.pilotRole?.turbineHoursLastTwelveMonths ?? 0,
    turbojetHours: user?.pilotRole?.turbojetHours ?? 0,
    turbojetHoursPic: user?.pilotRole?.turbojetHoursPic ?? 0,
    turbojetHoursLastNinetyDays:
      user?.pilotRole?.turbojetHoursLastNinetyDays ?? 0,
    turbojetHoursLastTwelveMonths:
      user?.pilotRole?.turbojetHoursLastTwelveMonths ?? 0,
    rotaryWingHours: user?.pilotRole?.rotaryWingHours ?? 0,
    rotaryWingHoursPic: user?.pilotRole?.rotaryWingHoursPic ?? 0,
    rotaryWingHoursLastNinetyDays:
      user?.pilotRole?.rotaryWingHoursLastNinetyDays ?? 0,
    rotaryWingHoursLastTwelveMonths:
      user?.pilotRole?.rotaryWingHoursLastTwelveMonths ?? 0,
  };

  const { inputs, resetForm, handleChange } = useForm(initialForm);

  const [disabled, setDisabled] = useState(true);

  const handleHoursSave = async () => {
    if (!user || !user.pilotRole || !user.pilotRole.id)
      throw new Error(`Cannot save hours for invalid user`);

    try {
      const response = await updatePilot(user?.pilotRole?.id, inputs);
      if (response.statusCode === 200) {
        addToast(<span>Pilot Hours and Training saved!</span>, 'success');
      } else {
        const responseMessages = await Object.keys(response.response)
          .map((k) => response.response[k])
          .flat();
        addToast(
          <span>Unable to save pilot hours: {responseMessages}</span>,
          'error'
        );
      }
      await refreshUser();
      setDisabled(true);
    } catch (error) {
      addToast(<span>Unable to save pilot hours</span>, 'error');
      await refreshUser();
      setDisabled(true);
    }
  };

  const handleCancel = () => {
    resetForm();
    setDisabled(true);
  };

  return (
    <SettingsCard
      disabled={disabled}
      onEdit={() => setDisabled(false)}
      onCancel={handleCancel}
      onSave={handleHoursSave}
      responsive
      className="overflow-x-auto p-2"
    >
      <table className="table mt-12 min-w-[640px]">
        <thead>
          <tr>
            <th />
            <th>Total</th>
            <th>PIC</th>
            <th>Last 12 Months</th>
            <th>Last 90 Days</th>
          </tr>
        </thead>
        <tbody>
          <HourInputRow
            rowLabel="All Aircraft"
            propNames={[
              'allAircraftHours',
              'allAircraftHoursPic',
              'allAircraftHoursLastTwelveMonths',
              'allAircraftHoursLastNinetyDays',
            ]}
            inputs={inputs}
            disabled={disabled}
            handleChange={handleChange}
          />
          <HourInputRow
            rowLabel="Multi-engine"
            propNames={[
              'multiEngineHours',
              'multiEngineHoursPic',
              'multiEngineHoursLastTwelveMonths',
              'multiEngineHoursLastNinetyDays',
            ]}
            inputs={inputs}
            disabled={disabled}
            handleChange={handleChange}
          />
          <HourInputRow
            rowLabel="Turbine"
            propNames={[
              'turbineHours',
              'turbineHoursPic',
              'turbineHoursLastTwelveMonths',
              'turbineHoursLastNinetyDays',
            ]}
            inputs={inputs}
            disabled={disabled}
            handleChange={handleChange}
          />
          <HourInputRow
            rowLabel="Turbojet"
            propNames={[
              'turbojetHours',
              'turbojetHoursPic',
              'turbojetHoursLastTwelveMonths',
              'turbojetHoursLastNinetyDays',
            ]}
            inputs={inputs}
            disabled={disabled}
            handleChange={handleChange}
          />
          <HourInputRow
            rowLabel="Rotary Wing"
            propNames={[
              'rotaryWingHours',
              'rotaryWingHoursPic',
              'rotaryWingHoursLastTwelveMonths',
              'rotaryWingHoursLastNinetyDays',
            ]}
            inputs={inputs}
            disabled={disabled}
            handleChange={handleChange}
          />
        </tbody>
      </table>
    </SettingsCard>
  );
}
