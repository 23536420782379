import React, { useState } from 'react';
import Input from '../../components/atoms/Input';
import Label from '../../components/atoms/Label';
import Heading from '../../components/typography/Heading';
import FormField from '../../components/wrappers/FormField';
import useForm from '../../hooks/useForm';
import resetPassword from '../../API/Auth/resetPassword';
import Loader from '../../components/molecules/Loader';
import { useToast } from '../../hooks/ToastProvider';
import SettingsCard from '../../components/molecules/Card/SettingsCard';

function PasswordAndSecurityPage() {
  const initialForm = {
    oldPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  };

  const { inputs, handleChange, resetForm } = useForm(initialForm);
  const { addToast } = useToast();

  const [disabled, setDisabled] = useState(true);
  const [waiting, setWaiting] = useState(false);

  const cancelChanges = () => {
    resetForm();
    setDisabled(true);
  };

  const handleSave = () => {
    const handleReset = async () => {
      const resetResponse = await resetPassword(
        inputs.oldPassword,
        inputs.newPassword,
        inputs.newPasswordConfirmation
      );
      if (resetResponse.statusCode === 200) {
        addToast(<span>Successfully updated password!</span>, 'success');
        setWaiting(false);
      } else {
        addToast(<span>Failed to reset password.</span>, 'error');
        cancelChanges();
        setWaiting(false);
      }
    };
    setWaiting(true);
    handleReset();

    // Clear inputs
    resetForm();
  };

  const passwordConfirmationStyle = () => {
    if (!disabled && inputs.newPassword) {
      if (inputs.newPassword !== inputs.newPasswordConfirmation)
        return 'text-error';
      return 'text-success';
    }
    return '';
  };

  return (
    <>
      <Heading level={2}>Password and Security</Heading>
      <div className="flex flex-col gap-4">
        {waiting ? (
          <Loader level={3} caption="Resetting Password" />
        ) : (
          <SettingsCard
            disabled={disabled}
            onEdit={() => setDisabled(false)}
            onCancel={() => setDisabled(true)}
            onSave={handleSave}
            responsive
          >
            <div className="flex flex-col gap-4">
              <Heading level={3}>Reset Password</Heading>
            </div>
            <FormField className="mt-8">
              <Label htmlFor="oldPassword" required>
                Old Password
              </Label>
              <Input
                id="oldPassword"
                disabled={disabled}
                value={inputs.oldPassword}
                onChange={handleChange}
                type="password"
              />
            </FormField>
            <FormField className={passwordConfirmationStyle()}>
              <Label htmlFor="newPassword" required>
                New Password
              </Label>
              <Input
                id="newPassword"
                disabled={disabled}
                value={inputs.newPassword}
                onChange={handleChange}
                type="password"
              />
            </FormField>
            <FormField className={passwordConfirmationStyle()}>
              <Label htmlFor="newPasswordConfirmation" required>
                Confirm New Password
              </Label>
              <Input
                id="newPasswordConfirmation"
                disabled={disabled}
                value={inputs.newPasswordConfirmation}
                onChange={handleChange}
                type="password"
              />
            </FormField>
          </SettingsCard>
        )}
      </div>
    </>
  );
}

export default PasswordAndSecurityPage;
