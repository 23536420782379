import React from 'react';

import * as Yup from 'yup';
import { FormikHelpers } from 'formik';

import Form from '../../../components/organisms/Form/Form';

import FinalExpensesTable from './FinalExpensesCard/FinalExpensesTable';

import { FinalExpenses } from '../../../types/FinalExpenses';
import { Expense } from '../../../types/Expense';
import { TripAlteration } from '../../../types/TripAlteration';
import FinalExpensesAlterations from './FinalExpensesCard/FinalExpensesAlterations';

const validFinalExpenses: Yup.ObjectSchema<FinalExpenses> = Yup.object().shape({
  alterations: Yup.object<TripAlteration>().shape({
    id: Yup.number().required(),
    startDate: Yup.string().required('Required'),
    endDate: Yup.string().required('Required'),
    totalTravelDays: Yup.number().required('Required'),
  }),
  itemizedExpenses: Yup.array()
    .required()
    .of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        // title needs to match the type of Expense title
        title: Yup.mixed<Expense['title']>()
          .oneOf(
            ['Hotel', 'Air Travel', 'Ground Travel', 'Other'],
            'Invalid title'
          )
          .required('Title is required'),
        cost: Yup.number().required('Required'),
        description: Yup.string().optional(),
        isQuoted: Yup.boolean().required(),
        isReimbursable: Yup.boolean().required(),
        receipt: Yup.mixed<File | string>()
          .required('Receipt is required')
          .test('fileType', 'The file must be a valid file type', (value) => {
            if (!value) return false;
            // renders as a string if already uploaded to AWS
            if (typeof value === 'string') return true;
            if (value instanceof File) {
              // Extract the file extension from the filename
              const fileExtension = value.name
                .slice(value.name.lastIndexOf('.') - 1 + 1)
                .toLowerCase();

              // Check if the file extension is in the array of valid extensions
              return !!fileExtension.match(/\.(jpg|jpeg|png|gif|pdf)$/i);
            }
            return false;
          })
          .test('fileSize', 'File too large', (value) => {
            if (value instanceof File) {
              return value.size <= 5000000;
            }
            return true;
          }),
      })
    ),
  // removedExpenses: Yup.array().of(Yup.number().required()).required(),
});

interface ExpensesFormProps {
  expenses: FinalExpenses;
  handleCancel: () => void;
  handleSubmit: (
    values: FinalExpenses,
    helpers: FormikHelpers<FinalExpenses>
  ) => void;
  disabled?: boolean;
}

function FinalExpensesForm({
  expenses,
  handleSubmit,
  handleCancel,
  disabled,
}: ExpensesFormProps) {
  return (
    <Form
      isCancellable
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      initialValues={expenses}
      validationSchema={validFinalExpenses}
      disabled={disabled}
      readOnly={disabled}
    >
      <div className="grid sm:grid-cols-2 sm:gap-4">
        <FinalExpensesAlterations />
      </div>
      <FinalExpensesTable />
    </Form>
  );
}

export default FinalExpensesForm;
