import PilotCertificateRating from '../../types/PilotCertificateRating';
import { endpoints } from '../endpoints';
import fetchAPI from '../util';

async function addNewRatingOnPilotCertificate(
  ratingOptionIdToAdd: number,
  pilotCertificateId: number
) {
  try {
    const response = await fetchAPI(endpoints.PILOT_CERTIFICATE_RATINGS, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rating: ratingOptionIdToAdd,
        pilot_cert: pilotCertificateId,
      }),
    });
    return response as PilotCertificateRating;
  } catch (error) {
    throw new Error(error as string);
  }
}

export default addNewRatingOnPilotCertificate;
