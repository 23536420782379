import { Flight } from '../../atoms/Icons';
import Paragraph from '../../typography/Paragraph';

function IconAndCraft({ craftName }: { craftName: string }) {
  return (
    <div className="hidden @md:flex flex-col my-auto justify-center">
      <div className="hidden @xs:block w-fit h-fit aspect-square p-2 pt-0 rounded-full bg-primary mx-auto text-white">
        <Flight
          size="3em"
          className="off-white"
          rotation={-15}
          style={{ marginTop: '6px' }}
        />
      </div>
      <Paragraph className="mt-2 font-bold text-center">{craftName}</Paragraph>
    </div>
  );
}

export default IconAndCraft;
