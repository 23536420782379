import React from 'react';

function Back() {
  return (
    <svg width="35px" height="35px" viewBox="8 8 20 20" version="1.1">
      <defs>
        <filter
          x="-16.7%"
          y="-14.1%"
          width="133.3%"
          height="132.5%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="6"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        id="all-"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="0:-Landing-Page_Become-a-Pilot"
          transform="translate(-7.000000, -560.000000)"
          stroke="#30454A"
          strokeWidth="2"
        >
          <g
            id="fly-for-us->-copy"
            filter="url(#filter-1)"
            transform="translate(0.000000, 411.000000)"
          >
            <g id="Group" transform="translate(16.000000, 154.000000)">
              <g id="ic_back" transform="translate(0.000000, 2.000000)">
                <g
                  transform="translate(8.500000, 8.500000) scale(-1, 1) translate(-8.500000, -8.500000) "
                  id="forward"
                >
                  <g transform="translate(2.833333, 2.125000)">
                    <path d="M0,6.375 L11.3333333,6.375" id="Line" />
                    <path
                      d="M11.3333333,6.375 L5.62256742,0.664234082"
                      id="Line"
                    />
                    <path
                      d="M11.3333333,6.375 L5.62256742,12.0857659"
                      id="Line"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Back;
