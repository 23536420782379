import { AirplaneMake } from '../../types/AirplaneMake';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';

const endpoint = `/api/aircraft_makes/`;

interface PlaneTypesResponse {
  data: AirplaneMake[];
  statusCode: number;
}

const getPlaneTypes = async (): Promise<PlaneTypesResponse | null> => {
  try {
    const planeTypesResponse = await fetch(endpoint, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
    });

    const planeTypes = await planeTypesResponse.json();

    return {
      data: remapKeysToCamelCase(planeTypes) as AirplaneMake[],
      statusCode: planeTypesResponse.status,
    };
  } catch (error) {
    return null;
  }
};

export default getPlaneTypes;
