import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentMethod } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import Card from '../../components/molecules/Card/Card';
import Heading from '../../components/typography/Heading';
import { useToast } from '../../hooks/ToastProvider';
import PaymentMethodForm from '../../components/organisms/PaymentMethodForm';
import getStripeKey from '../../utilities/getStripeKey';
import createSetupIntent from '../../API/Payments/createSetupIntent';
import PaymentMethodsList from '../../components/organisms/PaymentMethodsList';
import deletePaymentMethod from '../../API/Payments/deleteCard';
import changeDefaultCard from '../../API/Payments/changeDefaultCard';
import getPaymentMethods from '../../API/Payments/getPaymentMethods';
import Paragraph from '../../components/typography/Paragraph';

const stripePromise = loadStripe(getStripeKey());

function OperatorView() {
  const [clientSecret, setClientSecret] = useState<undefined | string>(
    undefined
  );
  const [loadingMethods, setLoadingMethods] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  const [defaultPaymentMethodId, setDefaultPaymentMethodId] =
    useState<string>('');

  useEffect(() => {
    createSetupIntent().then((result) => {
      if (result) {
        setClientSecret(result.clientSecret);
        getPaymentMethods()
          .then((result) => {
            setLoadingMethods(false);
            setPaymentMethods(result.payment_methods);
            setDefaultPaymentMethodId(result.default_payment_method_id); // Update state with default ID
          })
          .catch((error) =>
            console.error('Error fetching payment methods:', error)
          );
      }
    });
  }, []);

  const handleDelete = async (id: string) => {
    try {
      await deletePaymentMethod(id);
      setPaymentMethods((prevMethods) =>
        prevMethods.filter((method) => method.id !== id)
      );
      // Handle default logic if necessary
    } catch (e) {
      console.error('Error deleting payment method:', e);
    }
  };

  const handleSetDefault = async (id: string) => {
    try {
      await changeDefaultCard(id); // Call the function to set the default card
      setDefaultPaymentMethodId(id); // Update the local state to reflect the new default
    } catch (e) {
      console.error('Error setting default payment method:', e);
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <Heading level={2}>Billing and Payments</Heading>
      <Card
        responsive
        className="bg-gradient-to-br from-[#535f7f] to-[#535f7fee] text-white"
      >
        <i className="text-5xl fa-brands fa-cc-stripe" />
        <Heading level={3}>
          Flying Company partners with Stripe for payment processing.
        </Heading>
        <Paragraph>
          Please ensure that you&apos;ve set up a payment method using the
          options below. This will ensure you are able to create trips and pay
          pilots.
        </Paragraph>
      </Card>
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <PaymentMethodsList
          loadingMethods={loadingMethods}
          paymentMethods={paymentMethods}
          handleDelete={handleDelete}
          handleSetDefault={handleSetDefault} // Pass set default handler
          defaultPaymentMethodId={defaultPaymentMethodId} // Pass default ID
        />
      </Elements>
      {/* Add new payment method if user hasn't created one */}
      {!loadingMethods && (
        <Card responsive title="Add a Payment Method">
          {clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <PaymentMethodForm />
            </Elements>
          )}
        </Card>
      )}
    </div>
  );
}

export default OperatorView;
