import fetchAPI from '../util';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';
import { endpoints } from '../endpoints';
import { PaymentMethods } from '../../types/PaymentMethods';

/**
 *
 * @returns
 */
export default async function getPaymentMethods(): Promise<PaymentMethods> {
  const response = await fetchAPI(endpoints.STRIPE, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  });
  return response as PaymentMethods;
}
