import { useMemo } from 'react';
import Heading from '../../components/typography/Heading';
import Loader from '../../components/molecules/Loader';
import { usePlaneTypes } from '../../hooks/PlaneTypesProvider';
import TypeRatingTab from './TypeRatingTab';
import AllCraftHoursMatrix from './AllCraftHoursMatrix';
import { SecondaryButton } from '../../components/atoms/Button/Button';
import AddTypeRatingModal from './AddTypeRatingModal';
import { useModalContext } from '../../hooks/ModalProvider/ModalProvider';
import { useTrainingFacilities } from '../../hooks/useTrainingFacilities';
import Tabs, { TabData } from '../../components/organisms/Tabs/Tabs';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import PlaneType from '../../types/PlaneType';
import addPlaneTypeStatistic from '../../API/Pilot/addTypeRating';

function HoursAndTrainingPage() {
  const { user, refreshUser } = useUser();
  const { openModal } = useModalContext();

  const { facilities } = useTrainingFacilities();

  const {
    allPlaneTypes,
    loading: planeTypesLoading,
    getTypeRatings,
  } = usePlaneTypes();

  const typeRatings = useMemo(() => getTypeRatings(), [getTypeRatings]);

  // Get a list of all plane type ids that are already on the user object...
  const currentPlaneTypeStatIds =
    user?.pilotRole?.statistics.planeTypeStatistics.map((pt) => pt.planeType);

  // Filter out the type ratings options that are included in that list
  const typeRatingOptions = typeRatings.filter(
    (pt) => !currentPlaneTypeStatIds?.includes(pt.id)
  );

  const handleNewPlaneTypeStatistic = async (typeRating: PlaneType) => {
    if (!user) return;
    await addPlaneTypeStatistic(user.pilotRole, typeRating);

    await refreshUser();
  };

  const onAddTypeRating = () => {
    openModal(
      <AddTypeRatingModal
        typeRatingOptions={typeRatingOptions}
        handleNewTypeRating={handleNewPlaneTypeStatistic}
      />
    );
  };

  /**
   * The tabs should correlate to the type ratings on a pilot's certificate.
   *
   * To do this, we reduce the pilot's plane type statistics down to the entries
   * that appear in the list from getTypeRatings utility function, using the
   * craftType property as the key.
   */
  const tabs: TabData =
    allPlaneTypes.length > 0
      ? (user?.pilotRole?.statistics?.planeTypeStatistics.reduce(
          (tabData: TabData, stat) => {
            // Pilots add type ratings at the top level, then
            // models within the nested view

            // See if we have a match within the global type ratings list
            const typeRating = typeRatings.find(
              (tr) => tr.id === stat.planeType
            );

            // If this isn't a type rating, don't use it in the tabs
            if (!typeRating) return tabData;

            // If there's not a tab for this rating already, create one
            if (!tabData[typeRating.craftType])
              // eslint-disable-next-line no-param-reassign
              tabData[typeRating.craftType] = {
                displayName: typeRating.craftType,
                content: (
                  <TypeRatingTab
                    typeRating={typeRating}
                    handleAddModel={handleNewPlaneTypeStatistic}
                    statistics={user.pilotRole.statistics.planeTypeStatistics}
                  />
                ),
              };
            return tabData;
          },
          {}
        ) as TabData)
      : {};

  return (
    <>
      <Heading level={1}>Aircraft Hours and Training</Heading>

      {(!allPlaneTypes.length && planeTypesLoading) ||
      !user?.pilotRole ||
      !facilities ? (
        <Loader caption="Loading plane data..." />
      ) : (
        <div className="flex flex-col gap-4">
          <div>
            <Heading level={2}>Total Hours</Heading>
            <AllCraftHoursMatrix />
          </div>
          <div className="flex flex-col gap-4 mt-4">
            <Heading level={2} className="mb-0">
              Aircraft Statistics
            </Heading>
            {(planeTypesLoading || !allPlaneTypes.length) && (
              <Loader caption="Loading plane type data" />
            )}
            {!planeTypesLoading && allPlaneTypes.length && (
              <>
                <div className="mb-8">
                  <SecondaryButton
                    className="max-w-content"
                    onClick={onAddTypeRating}
                    disabled={typeRatingOptions.length < 1}
                  >
                    Add Type Rating / Aircraft Qualification
                  </SecondaryButton>
                </div>
                <Tabs tabs={tabs} tabStyle="boxed" />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default HoursAndTrainingPage;
