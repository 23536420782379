/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useField } from 'formik';
import React from 'react';

interface FormikRatingProps {
  name: string;
  label: string;
}

function FormikRating({ name, label }: FormikRatingProps) {
  const [field, meta, helpers] = useField(name);

  return (
    <fieldset className="rating">
      <legend>{label}</legend>

      <input
        type="radio"
        name={name}
        className="rating-hidden"
        defaultChecked
      />
      <input
        type="radio"
        id={`${label} for 1 star rating`}
        name={name}
        className="mask mask-star-2 bg-orange-400"
        onClick={() => helpers.setValue(1)}
      />
      <label className="sr-only" htmlFor={`${label} for 1 star rating`} />

      <input
        type="radio"
        id={`${label} for 2 star rating`}
        name={name}
        className="mask mask-star-2 bg-orange-400"
        onClick={() => helpers.setValue(2)}
      />
      <label className="sr-only" htmlFor={`${label} for 2 star rating`} />

      <input
        type="radio"
        id={`${label} for 3 star rating`}
        name={name}
        className="mask mask-star-2 bg-orange-400"
        onClick={() => helpers.setValue(3)}
      />
      <label className="sr-only" htmlFor={`${label} for 3 star rating`} />

      <input
        type="radio"
        id={`${label} for 4 star rating`}
        name={name}
        className="mask mask-star-2 bg-orange-400"
        onClick={() => helpers.setValue(4)}
      />
      <label className="sr-only" htmlFor={`${label} for 4 star rating`} />

      <input
        type="radio"
        id={`${label} for 5 star rating`}
        name={name}
        className="mask mask-star-2 bg-orange-400"
        onClick={() => helpers.setValue(5)}
      />
      <label className="sr-only" htmlFor={`${label} for 5 star rating`} />
    </fieldset>
  );
}

export default FormikRating;
