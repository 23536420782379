import { useState } from 'react';
import {
  StateButton,
  PrimaryButton,
} from '../../components/atoms/Button/Button';
import Heading from '../../components/typography/Heading';
import { useModalContext } from '../../hooks/ModalProvider/ModalProvider';
import Select from '../../components/atoms/Select/Select';
import PilotCertificateType from '../../types/PilotCertificateType';

interface AddCertificateProps {
  certificateTypes: PilotCertificateType[];
  handleNewCertificate: (pilotCertificate: PilotCertificateType) => void;
}

export default function AddCertificateModal({
  certificateTypes,
  handleNewCertificate,
}: AddCertificateProps) {
  const { closeModal } = useModalContext();
  const [selectedCertificate, setSelectedCertificate] =
    useState<PilotCertificateType>(certificateTypes[0]);
  const certificateTypesAsStrings = certificateTypes.map((cert) => cert.name);

  const onSelect = (certificateTypeName: string) => {
    setSelectedCertificate(
      certificateTypes.find(
        (cert) => cert.name === certificateTypeName
      ) as PilotCertificateType
    );
  };

  const handleConfirm = () => {
    if (selectedCertificate) {
      handleNewCertificate(selectedCertificate);
    }
    closeModal();
  };

  return (
    <div className="flex flex-col gap-4 mb-24">
      <Heading level={3}>Add a Certificate</Heading>
      <Select
        options={certificateTypesAsStrings}
        onChange={onSelect}
        defaultvalue={selectedCertificate?.name || certificateTypesAsStrings[0]}
      />
      <div className="flex gap-4">
        <StateButton
          outline
          status="error"
          className="btn-sm"
          onClick={closeModal}
        >
          Cancel
        </StateButton>
        <PrimaryButton className="btn-sm" onClick={handleConfirm}>
          Confirm
        </PrimaryButton>
      </div>
    </div>
  );
}
