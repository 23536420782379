import { SecondaryButton } from '../../../components/atoms/Button/Button';
import TripCard from '../../../components/organisms/TripCard/TripCard';
import Heading from '../../../components/typography/Heading';
import { useModalContext } from '../../../hooks/ModalProvider/ModalProvider';
import Trip from '../../../types/Trip';

interface TripDetailsModalProps {
  trip: Trip;
}

function TripDetailsModal({ trip }: TripDetailsModalProps) {
  const { closeModal } = useModalContext();

  return (
    <div className="flex flex-col gap-4 mb-24">
      <Heading level={3}>Trip Details</Heading>
      <TripCard trip={trip} expanded="true" />
      <SecondaryButton onClick={closeModal}>Return</SecondaryButton>
    </div>
  );
}

export default TripDetailsModal;
