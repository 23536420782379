import React from 'react';

import * as Yup from 'yup';
import { FormikHelpers } from 'formik';

import Form from '../../../components/organisms/Form/Form';
import { FinalExpenses } from '../../../types/FinalExpenses';
import { Expense } from '../../../types/Expense';
import { TripAlteration } from '../../../types/TripAlteration';
import FinalExpensesAlterations from './FinalExpensesCard/FinalExpensesAlterations';
import ReviewFinalExpensesTable from './ReviewFinalExpensesTable';

const validFinalExpenses: Yup.ObjectSchema<FinalExpenses> = Yup.object().shape({
  alterations: Yup.object<TripAlteration>().shape({
    id: Yup.number().required(),
    startDate: Yup.string().required('Required'),
    endDate: Yup.string().required('Required'),
    totalTravelDays: Yup.number().required('Required'),
  }),
  itemizedExpenses: Yup.array()
    .required()
    .of(
      Yup.object().shape({
        id: Yup.number().nullable(),
        // title needs to match the type of Expense title
        title: Yup.mixed<Expense['title']>()
          .oneOf(
            ['Hotel', 'Air Travel', 'Ground Travel', 'Other'],
            'Invalid title'
          )
          .required('Title is required'),
        cost: Yup.number().required('Required'),
        description: Yup.string().optional(),
        isQuoted: Yup.boolean().required(),
        receipt: Yup.mixed<File | string>().required('Receipt is required'),
      })
    ),
  // removedExpenses: Yup.array().of(Yup.number().required()).required(),
});

interface ExpensesFormProps {
  expenses: FinalExpenses;
  handleCancel: () => void;
  handleSubmit: (
    values: FinalExpenses,
    helpers: FormikHelpers<FinalExpenses>
  ) => void;
  disabled?: boolean;
  readOnly?: boolean;
}

function ReviewFinalExpenses({
  expenses,
  handleSubmit,
  handleCancel,
  disabled,
  readOnly = false,
}: ExpensesFormProps) {
  return (
    <Form
      isCancellable
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      initialValues={expenses}
      validationSchema={validFinalExpenses}
      disabled={disabled}
      readOnly={readOnly}
      cancelLabel="Reject Expenses"
      submitLabel="Approve Expenses"
    >
      <div className="grid md:grid-cols-2 sm:gap-4">
        <FinalExpensesAlterations />
        <div className="col-span-full">
          <ReviewFinalExpensesTable />
        </div>
      </div>
    </Form>
  );
}

export default ReviewFinalExpenses;
