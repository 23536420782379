import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Trip from '../../../../types/Trip';
import Alert from '../../../../components/atoms/Alert/Alert';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../../components/atoms/Button/Button';
import PilotCard from '../../../../components/molecules/PilotCard/PilotCard';
import ControlBar from '../../../../components/molecules/ControlBar/ControlBar';
import Chat from '../../../../components/organisms/Chat/Chat';
import TripCard from '../../../../components/organisms/TripCard/TripCard';
import Heading from '../../../../components/typography/Heading';
import { createQuoteFromBid } from '../../../../types/Quote';
import { ROUTES } from '../../../routerConfig';
import ReviewFinalExpenses from '../../subcomponents/ReviewFinalExpenses';
import Card from '../../../../components/molecules/Card/Card';
import PrimaryTripLayout from '../../Layouts/PrimaryTripLayout';
import QuoteModal from '../../PilotView/QuoteModal';
import { useModalContext } from '../../../../hooks/ModalProvider/ModalProvider';
import GrandTotalEstimateCard from '../../../../components/organisms/GrandTotalEstimateCard/GrandTotalEstimateCard';

/** Once expenses are approved, trigger the final payment and prompt for review */
function ExpensesApprovedView({
  trip,
  openDetailsModal,
  openReviewModal,
}: {
  trip: Trip;
  openDetailsModal: (trip: Trip) => unknown;
  openReviewModal?: () => unknown;
}) {
  const navigate = useNavigate();
  const { openModal } = useModalContext();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);
  const chatAreaRef = useRef<HTMLDivElement>(null);
  const tripAndQuoteAreaRef = useRef<HTMLDivElement>(null);
  const hiredBid = trip.conversations
    .map((c) => c.bid)
    .find((b) => b?.id === trip.hiredBid);
  if (!hiredBid) throw new Error(`No hired bid on ExpensesAcceptedView`);

  const hiredBidConversation = trip.conversations.find(
    (c) => c.bid?.id === trip.hiredBid
  );
  if (!hiredBidConversation)
    throw new Error(`No hired bid conversation on ExpensesAcceptedView`);

  useEffect(() => {
    if (!trip?.review?.consumerPilotRating && openReviewModal!) {
      openReviewModal();
    }
  });

  const openQuoteModal = () => {
    openModal(
      <QuoteModal
        conversation={hiredBidConversation}
        isQuoted={false}
        isPilot={false}
      />
    );
  };

  return (
    <>
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" />
          Trips
        </PrimaryButton>
        <SecondaryButton size="small" onClick={() => openDetailsModal(trip)}>
          View Trip Details
        </SecondaryButton>
        <SecondaryButton size="small" onClick={openQuoteModal}>
          View Invoice
        </SecondaryButton>
      </ControlBar>
      <div className="flex flex-col gap-4">
        <Heading level={2}>
          You accepted {hiredBidConversation.user.firstName}{' '}
          {hiredBidConversation.user.lastName}&apos;s expenses.
        </Heading>
        <Alert status="success" message="This trip is pending completion." />
        {/* Three column grid layout that collapses to 2 col, then 1 col */}
        <PrimaryTripLayout>
          {/** Pilot profile area */}
          {/* Small display version */}
          <div className="h-full">
            <PilotCard
              pilot={hiredBidConversation.user}
              className="h-full"
              planeType={trip.planeType}
              selected
              expanded="always"
              airplane={trip.aircraft}
              hired
              tripId={trip.id}
            />
          </div>
          {/** Chat area */}
          {hiredBidConversation && (
            <div className="flex flex-col gap-4 h-full" ref={chatAreaRef}>
              <GrandTotalEstimateCard
                isPilot={false}
                isQuoted={false}
                cost={hiredBidConversation.cost}
                expenses={hiredBid.expenses}
                className="flex-grow"
              />
              <Chat
                tripId={trip.id}
                title={
                  hiredBidConversation
                    ? `Chat with ${hiredBidConversation.user.firstName}`
                    : 'Loading'
                }
                conversation={hiredBidConversation}
              />
            </div>
          )}
          {/* Trip and Quote area  */}
          <div className="hidden lg:block h-full" ref={tripAndQuoteAreaRef}>
            {/** Trip card */}
            <TripCard
              trip={trip}
              showStatus={false}
              expanded="always"
              interactive={false}
              className="h-full"
            />
          </div>
          <Card className="col-span-full" title="Final Expenses" responsive>
            <ReviewFinalExpenses
              expenses={{
                alterations: {
                  id: trip.id,
                  startDate: trip.startDate,
                  endDate: trip.endDate,
                  totalTravelDays: createQuoteFromBid(
                    hiredBid,
                    hiredBidConversation.user,
                    trip,
                    false
                  ).travelDays,
                },
                itemizedExpenses: hiredBid.expenses,
              }}
              handleSubmit={() => {}}
              handleCancel={() => {}}
              disabled
              readOnly
            />
          </Card>
        </PrimaryTripLayout>
      </div>
    </>
  );
}

export default ExpensesApprovedView;
