import Trip from '../../types/Trip';

const rejectExpenses = async (tripId: Trip['id']) => {
  try {
    const response = await fetch(`/api/trips/as_consumer/${tripId}/expense/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      body: JSON.stringify({ is_rejected: true }),
      credentials: 'include',
    });
  } catch (err) {
    throw new Error(err as string);
  }
};

export default rejectExpenses;
