/* eslint-disable import/prefer-default-export */
// TODO: make dynamic root per environment with env variable?
const ROOT = '';

const OPERATOR_ONLY = {
  ADD_AIRCRAFT: `${ROOT}/my-aircraft/add`,
  MY_AIRCRAFT: `${ROOT}/my-aircraft`,
};
const PILOT_ONLY = {
  CERTIFICATES_AND_RATINGS: `${ROOT}/certificates-ratings`,
  CREATE_TRIP_NEW: `${ROOT}/trips/new_demo`,
  HOURS_TRAINING: `${ROOT}/hours-and-training`,
  QUESTIONS: `${ROOT}/questions`,
};

const SHARED_ROUTES = {
  BILLING_AND_PAYMENTS: `${ROOT}/billing-and-payments`,
  CREATE_TRIP: `${ROOT}/trips/new`,
  GENERAL_INFORMATION: `${ROOT}/general-information`,
  LOGOUT: `${ROOT}/logout`,
  NOTIFICATION_SETTINGS: `${ROOT}/notification-settings`,
  PAYMENT_METHODS: `${ROOT}/payment-methods`,
  PILOT_ONBOARDING: `${ROOT}/pilot-onboarding`,
  PILOTS: `${ROOT}/pilots`,
  PRIVACY_POLICY: `${ROOT}/privacy`,
  ACCOUNT_SECURITY: `${ROOT}/password-and-security`,
  TERMS_OF_SERVICE: `${ROOT}/terms`,
  TRIPS: `${ROOT}/trips`,
} as const;

export const UNPROTECTED_ROUTES = {
  HOME: `${ROOT}/`,
  LOGIN: `${ROOT}/login`,
  REGISTER: `${ROOT}/register`,
  ERROR: `${ROOT}/error`,
  VERIFY_EMAIL: `${ROOT}/verify-email/`,
};

export const ROUTES = {
  ...OPERATOR_ONLY,
  ...PILOT_ONLY,
  ...SHARED_ROUTES,
  ...UNPROTECTED_ROUTES,
} as const;
