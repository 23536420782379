import { differenceInDays } from 'date-fns';
import { PostQuoteResponse } from './PostQuoteResponse';
import User from './User';
import Trip from './Trip';

export interface Quote {
  flyingDayRate: number;
  flyingDays: number;
  travelDayRate: number;
  travelDays: number;
  perDiemRate: number;
  hotelCosts: number;
  hotelNotes: string;
  airTravelCosts: number;
  airTravelNotes: string;
  groundTravelCosts: number;
  groundTravelNotes: string;
  otherCosts: number;
  otherCostsNotes: string;
}

export const PER_DIEM_DEFAULT = 100;

export function createQuote(initialValues: Partial<Quote>): Quote {
  return {
    flyingDayRate: 0,
    flyingDays: 0,
    travelDayRate: 0,
    travelDays: 0,
    perDiemRate: PER_DIEM_DEFAULT,
    hotelCosts: 0,
    hotelNotes: '',
    airTravelCosts: 0,
    airTravelNotes: '',
    groundTravelCosts: 0,
    groundTravelNotes: '',
    otherCosts: 0,
    otherCostsNotes: '',
    ...initialValues,
  };
}

export function createQuoteFromBid(
  bid: PostQuoteResponse,
  pilotUser: User,
  trip: Trip,
  useQuoted = true
): Quote {
  const exp = useQuoted ? bid.quotedExpenses : bid.expenses;

  return {
    flyingDayRate:
      bid.aircraftPricePerDayOverride ||
      pilotUser?.pilotRole.defaultAircraftPricePerDay ||
      0,
    flyingDays:
      differenceInDays(new Date(trip.endDate), new Date(trip.startDate)) + 1,
    travelDayRate:
      bid.extraTravel[0]?.pricePerDay ||
      pilotUser?.pilotRole?.defaultTravelPricePerDay ||
      0,
    travelDays: bid.extraTravel[0]?.days || 0,
    perDiemRate: PER_DIEM_DEFAULT,
    hotelCosts: exp.find((e) => e.title === 'Hotel')?.cost || 0,
    hotelNotes: exp.find((e) => e.title === 'Hotel')?.description || '',
    airTravelCosts: exp.find((e) => e.title === 'Air Travel')?.cost || 0,
    airTravelNotes:
      exp.find((e) => e.title === 'Air Travel')?.description || '',
    groundTravelCosts: exp.find((e) => e.title === 'Ground Travel')?.cost || 0,
    groundTravelNotes:
      exp.find((e) => e.title === 'Ground Travel')?.description || '',
    otherCosts: exp.find((e) => e.title === 'Other')?.cost || 0,
    otherCostsNotes: exp.find((e) => e.title === 'Other')?.description || '',
  };
}
