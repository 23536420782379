import React, { createContext, useState } from 'react';

import {
  Form as FormikForm,
  Formik,
  FormikHelpers,
  FormikValues,
} from 'formik';
import * as Yup from 'yup';

import Debug from '../../atoms/Form/Debug';
import { SecondaryButton, StateButton } from '../../atoms/Button/Button';
import Heading from '../../typography/Heading';

// Create a context to hold the disabled state
export const DisabledContext = createContext<boolean | undefined>(undefined);

/**
 * Form is a single Formik instance that renders a form with a title and
 * children form fields. The form can be toggled between edit and save mode, and
 * the form is submitted when the save button is clicked. The form state is
 * reset when the cancel button is clicked.
 * ```
 * <Form
 *  title="My Form"
 * initialValues={initialValues}
 * onSubmit={(values) => console.log('Full form submit', values)}
 * validationSchema={validSchema}
 * >
 * <MyFormikFields />
 * </Form>
 * ```
 */
function Form<T extends FormikValues>({
  title,
  initialValues,
  isCancellable = false,
  isValid = true,
  onCancel,
  onSubmit,
  disabled: disabledProp = false,
  readOnly = false,
  debug = false,
  children,
  validationSchema,
  cancelLabel = 'Cancel',
  submitLabel = 'Submit',
}: {
  title?: string;
  initialValues: T;
  isCancellable: boolean;
  isValid?: boolean;
  onSubmit: (values: T, helpers: FormikHelpers<T>) => Promise<void> | void;
  onCancel?: () => void;
  disabled?: boolean;
  readOnly?: boolean;
  debug?: boolean;
  children: React.ReactNode;
  validationSchema: Yup.ObjectSchema<any>;
  cancelLabel?: string;
  submitLabel?: string;
}) {
  // local state to toggle disabled state
  const [disabled, setDisabled] = useState(disabledProp);

  // Formik version of this isn't working so we'll just do it the ole reliable way
  const [submitting, setSubmitting] = useState(false);

  // https://formik.org/docs/guides/form-submission#submission
  const handleSubmit = async (values: T, helpers: FormikHelpers<T>) => {
    setDisabled(true);
    setSubmitting(true);
    // final submit
    await onSubmit(values, helpers);
    setSubmitting(false);

    helpers.setTouched({});
    // update initialValues
    helpers.resetForm({ values });
  };

  return (
    <div className="lg:rounded-l-none lg:border-l-0">
      {title && <Heading level={2}>{title}</Heading>}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        enableReinitialize
        // initialTouched={initialValues[Object.keys(initialValues)[0]]}
        isInitialValid={isValid}
      >
        {(formik) => (
          <div>
            <FormikForm className="flex flex-col">
              <fieldset className="group/field" disabled={disabled}>
                <DisabledContext.Provider value={disabled}>
                  {children}
                </DisabledContext.Provider>
              </fieldset>
              {!readOnly &&
                (isCancellable ? (
                  <div className="w-full mt-4 grid grid-cols-2 gap-4">
                    <StateButton
                      status="error"
                      onClick={onCancel}
                      disabled={submitting}
                    >
                      {cancelLabel}
                    </StateButton>
                    <SecondaryButton
                      type="submit"
                      isLoading={formik.isSubmitting}
                      disabled={!formik.isValid || submitting}
                      aria-disabled={!isValid || submitting}
                    >
                      {submitLabel}
                    </SecondaryButton>
                  </div>
                ) : (
                  <div className="w-full grid grid-cols-2 gap-4">
                    <SecondaryButton
                      className="w-full"
                      type="submit"
                      isLoading={formik.isSubmitting || submitting}
                      disabled={submitting}
                    >
                      {submitLabel}
                    </SecondaryButton>
                  </div>
                ))}
              {debug && <Debug />}
            </FormikForm>
          </div>
        )}
      </Formik>
    </div>
  );
}

export default Form;
