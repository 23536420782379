import { format } from 'date-fns';

import Message from '../../../types/Message';
import User from '../../../types/User';
import Avatar from '../../molecules/Avatar/Avatar';

function OtherUserMessage({ message, user }: { message: Message; user: User }) {
  return (
    <div className="chat chat-start" key={message.id}>
      <div className="chat-image avatar">
        <div className="w-10 rounded-full">
          <Avatar small avatarUrl={user.avatar?.image} />
        </div>
      </div>
      <div className="chat-header">
        {`${user.firstName} ${user.lastName} `}
        <time className="text-xs opacity-50">
          {format(new Date(message.sentAt), 'M/d/yyyy h:mm aa')}
        </time>
      </div>
      <div className="chat-bubble bg-secondary text-white">
        {message.content}
      </div>
      <div className="chat-footer opacity-50">
        {message.readAt
          ? `Seen at ${format(new Date(message.readAt), 'M/d/yyyy h:mm aa')}`
          : 'Delivered'}
      </div>
    </div>
  );
}

export default OtherUserMessage;
