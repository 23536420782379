import { Flight } from '../../components/atoms/Icons';
import { HowItWorks } from '../../components/organisms/Graphics';
import HeroImage2 from '../../assets/images/HeroImage2.jpg';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../components/atoms/Button/Button';
import Heading from '../../components/typography/Heading';
import Hero from '../../components/wrappers/Hero';
import Section from '../../components/wrappers/Section';

function Pilots() {
  return (
    <>
      <Hero backgroundImage={HeroImage2} minHeight="640px" className="center">
        <Heading level={1} className="hero text-center text-white">
          Become a Pilot for Hire
        </Heading>
        <PrimaryButton size="large" className="flex mt-m">
          <Flight rotation={-15} />
          Let&apos;s Fly
        </PrimaryButton>
      </Hero>
      <Section className="center p-12">
        <Heading level={2} className="xl mb-4">
          How it Works
        </Heading>
        <HowItWorks kind="pilot" />
      </Section>
      <Section className="flex justify-center items-center gap-4 p-12">
        <Heading level={3} className="text-center">
          Where will life take you today?
        </Heading>
        <SecondaryButton size="large">Create New Trip</SecondaryButton>
      </Section>
    </>
  );
}

export default Pilots;
