import Heading from '../../components/typography/Heading';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import Alert from '../../components/atoms/Alert/Alert';
import Certificates from './Certificates';
import { SecondaryButton } from '../../components/atoms/Button/Button';
import { useModalContext } from '../../hooks/ModalProvider/ModalProvider';
import AddCertificateModal from './AddCertificateModal';
import PilotCertificateType from '../../types/PilotCertificateType';
import { useCertificateMetadata } from '../../hooks/PilotCertificateProvider/CertificateMetadataProvider';
import { usePilotCertificates } from '../../hooks/PilotCertificateProvider/PilotCertificateProvider';
import MedicalCertificateView from './MedicalCertificateView';
import Loader from '../../components/molecules/Loader';

function CertificatesAndRatingsPage() {
  const { openModal } = useModalContext();
  const { user, refreshUser } = useUser();
  const {
    limitsOptions,
    ratingsOptions,
    certificateTypes,
    certificateOptionsLoading,
  } = useCertificateMetadata();
  const {
    pilotLimits,
    pilotRatings,
    loadingPilotCertificates,
    addCertificate,
    refreshPilot,
  } = usePilotCertificates();

  const DEBUG_CERTS_AND_RATINGS = false;

  if (DEBUG_CERTS_AND_RATINGS)
    console.log({
      pilotLimits,
      pilotRatings,
    });

  if (DEBUG_CERTS_AND_RATINGS)
    console.log({
      limitsOptions,
      ratingsOptions,
      certificateTypes,
    });

  const createNewCertificate = async (
    certificateType: PilotCertificateType
  ) => {
    try {
      await addCertificate(certificateType);
      await refreshPilot(true);
    } catch (error) {
      console.error('Error creating new certificate', error);
    }
  };

  const handleAddCertificate = () => {
    openModal(
      <AddCertificateModal
        // only show the certificateTypes that are not already on the pilot's licenseCerts
        certificateTypes={certificateTypes?.filter(
          (ct) =>
            !user?.pilotRole.licenseCerts.find((cert) => cert.type === ct.id)
        )}
        handleNewCertificate={createNewCertificate}
      />
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <Heading level={1}>Certificates and Ratings</Heading>
      {user ? (
        <>
          <MedicalCertificateView />
          {/** Other Certificates */}
          <section className="flex flex-col gap-4 mb-8">
            <Heading level={2}>Pilot &amp; Instructor Certificates</Heading>
            {user?.pilotRole.licenseCerts.length === 0 && (
              <Alert message="Add a certificate to get started." />
            )}
            <SecondaryButton
              className="self-start"
              onClick={handleAddCertificate}
            >
              Add a Certificate
            </SecondaryButton>
            {user?.pilotRole.licenseCerts.length ? (
              <Certificates
                loading={loadingPilotCertificates || certificateOptionsLoading}
                certificates={user.pilotRole.licenseCerts}
              />
            ) : null}
          </section>
        </>
      ) : (
        <Loader caption="Loading your account..." />
      )}
    </div>
  );
}

export default CertificatesAndRatingsPage;
