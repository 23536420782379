export const TRIP_STATUSES = {
  Created: {
    showInUI: true,
  },
  'Quotes Received': {
    showInUI: true,
  },
  'Pilot Hired': {
    showInUI: true,
  },
  'Pilot Confirmed': {
    showInUI: false,
  },
  'Pilot Initial Payment': {
    showInUI: false,
  },
  Underway: {
    showInUI: true,
  },
  'Expenses Submitted': {
    showInUI: true,
  },
  'Expenses Approved': {
    showInUI: true,
  },
  'Pilot Final Payment': {
    showInUI: false,
  },
  'Pilot Review': {
    showInUI: false,
  },
  'Operator Review': {
    showInUI: false,
  },
  Complete: {
    showInUI: true,
  },
  Cancelled: {
    showInUI: false,
  },
};

export type TripStatus = keyof typeof TRIP_STATUSES;
