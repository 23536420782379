import DatePicker from '../../atoms/DatePicker/DatePicker';

interface DayPickerProps {
  value: Date | null;
  onChange: (d: Date) => void;
  className?: string;
  id?: string;
  disabled?: boolean;
}

function DayPicker({
  value,
  onChange,
  className,
  id,
  disabled,
}: DayPickerProps) {
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      dateFormat="MMMM d, yyyy"
      showYearDropdown
      scrollableYearDropdown
      yearDropdownItemNumber={80}
      className={`input !input-bordered w-full max-w-xs ${className}`}
      id={id}
      disabled={disabled}
    />
  );
}

export default DayPicker;
