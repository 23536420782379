import CertificateLimitOption from '../../types/CertificateLimitOption';

const endpoint = `/api/cert_limits/`;

const createLimitOption = async (
  name: string,
  createdBy: number,
  forAllPilots = true
): Promise<CertificateLimitOption | null> => {
  try {
    const optionResponse = await fetch(endpoint, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        created_by: createdBy,
        for_all_pilots: forAllPilots,
      }),
    });

    const option = await optionResponse.json();
    return option as CertificateLimitOption;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default createLimitOption;
