import fetchAPI from '../util';
import { endpoints } from '../endpoints';

// TODO is stripeToken a string?
export default function deletePaymentMethod(id: string) {
  return fetchAPI(endpoints.STRIPE, {
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      stripe_token: id,
    }),
  });
}
