import { useNavigate } from 'react-router-dom';
import Trip from '../../../../types/Trip';
import TripCard from '../../../../components/organisms/TripCard/TripCard';
import Heading from '../../../../components/typography/Heading';
import ControlBar from '../../../../components/molecules/ControlBar/ControlBar';
import {
  PrimaryButton,
  StateButton,
} from '../../../../components/atoms/Button/Button';
import { ROUTES } from '../../../routerConfig';
import Alert from '../../../../components/atoms/Alert/Alert';

function CreatedView({
  trip,
  handleCancel,
}: {
  trip: Trip;
  handleCancel: () => unknown;
}) {
  const navigate = useNavigate();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);

  return (
    <>
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" /> Trips
        </PrimaryButton>
        <StateButton size="small" onClick={handleCancel} status="error">
          Cancel Trip
        </StateButton>
      </ControlBar>
      <div className="flex flex-col gap-4">
        <Heading level={2} className="max-w-prose">
          Viewing {trip.name}
        </Heading>
        <Alert message="Your trip is awaiting quotes." />
        <div className="max-w-2xl">
          <TripCard trip={trip} expanded="true" />
        </div>
      </div>
    </>
  );
}

export default CreatedView;
