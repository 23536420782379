// Import shared app components and types
import Heading from '../../../components/typography/Heading';
import Card from '../../../components/molecules/Card/Card';
import Conversation from '../../../types/Conversation';
import { FinalExpenses } from '../../../types/FinalExpenses';

function RemainingTripCost({
  cost,
  expenses,
}: {
  cost: Conversation['cost'];
  expenses: FinalExpenses;
}) {
  const { estimation } = cost;
  const { travelDays } = estimation;
  const remainingFlightDays = estimation.flightDays - 1; // subtract 1 for the first day already paid
  const pilotFlyingRate = estimation.pricePerDay.toFixed(2);
  const flyingCost = (estimation.flyingCost - estimation.pricePerDay).toFixed(
    2
  );
  const pilotTravelDayRate = estimation.travelDays
    ? estimation.travelCost / estimation.travelDays
    : 0;
  const travelCost = estimation.travelCost.toFixed(2);
  const dayPerDiem = estimation.dayDiem.toFixed(2);
  const tripDuration = remainingFlightDays + travelDays;
  const perDiemRemainingTotal = (estimation.diem - estimation.dayDiem).toFixed(
    2
  );
  const remainingServiceFee = estimation.finalPaymentTravelFee.toFixed(2);
  const remainingCost = estimation.dayTwoCharge.toFixed(2);

  console.log(expenses);

  return (
    <Card responsive noPadding>
      <div className="py-2 background bg-primary rounded-t-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          Remaining Trip Cost
        </Heading>
      </div>
      <div className="flex flex-col p-4 gap-2">
        <div className="flex">
          <span>
            Flying Day-Rate ({remainingFlightDays} days remaining, $
            {pilotFlyingRate}
            /day):
          </span>
          <span className="ml-auto font-semibold">${flyingCost}</span>
        </div>
        <div className="flex">
          <span>
            Travel Day-Rate ({travelDays} days
            {pilotTravelDayRate
              ? `, $${pilotTravelDayRate.toFixed(2)}/day)`
              : ')'}
            :
          </span>
          <span className="ml-auto font-semibold">${travelCost}</span>
        </div>
        <div className="flex">
          <span>
            Per-Diem ({tripDuration} days remaining, ${dayPerDiem}/day):
          </span>
          <span className="ml-auto font-semibold">
            ${perDiemRemainingTotal}
          </span>
        </div>
        <div className="flex">
          <span>Remaining Service Fee:</span>
          <span className="ml-auto font-semibold">${remainingServiceFee}</span>
        </div>
        <Heading level={4} className="underline font-normal pt-2">
          Final Expenses:
        </Heading>
        {expenses.itemizedExpenses.map((expense) => (
          <div className="flex">
            <span>{expense.title}:</span>
            <span key={expense.id} className="ml-auto font-semibold">
              {expense.cost.toFixed(2)}
            </span>
          </div>
        ))}
      </div>
      <div className="py-2 background bg-primary rounded-b-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          Remaining Total: ${remainingCost}
        </Heading>
      </div>
    </Card>
  );
}

export default RemainingTripCost;
