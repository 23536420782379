/* eslint-disable camelcase */
import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Link from '../../atoms/Link';
import { RegistrationFields, register } from '../../../API/Auth/register';
import useWindowWidth from '../../../hooks/useWindowWidth';
import query from '../../../const/query';
import { ROUTES } from '../../../routes/routerConfig';
import { PrimaryButton, SecondaryButton } from '../../atoms/Button/Button';
import { useUser } from '../../../hooks/UserProvider/UserProvider';
import i18n from '../../../strings/i18n';
import FormikInput from '../../atoms/Form/FormikInput';
import FormikField from '../../atoms/Form/FormikField';

const signupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(i18n({ validation: 'requiredDynamic' }, { field: 'First Name' })),

  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required(i18n({ validation: 'requiredDynamic' }, { field: 'Last Name' })),

  email: Yup.string()
    .email('Enter a valid email address')
    .required(i18n({ validation: 'requiredDynamic' }, { field: 'Email' })),

  password: Yup.string()
    .min(8, 'Password must be at least 8 characters.')
    .required(i18n({ validation: 'requiredDynamic' }, { field: 'Password' })),

  confirmPassword: Yup.string().required(
    i18n({ validation: 'requiredDynamic' }, { field: 'Password' })
  ),
});

function RegistrationForm() {
  const { refreshUser } = useUser();
  const navigate = useNavigate();
  const windowWidth = useWindowWidth();
  const initialValues: RegistrationFields = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const onSubmit = async (
    values: RegistrationFields,
    helpers: FormikHelpers<RegistrationFields>
  ) => {
    helpers.setSubmitting(true);

    try {
      const registerResponse = await register(values);

      if (registerResponse.statusCode !== 200 && 'errors' in registerResponse) {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { email, password1, password2, non_field_errors } =
          registerResponse.errors;

        helpers.setSubmitting(false);
        helpers.setErrors({
          email: email?.join(' '),
          password: password1?.join(' '),
          confirmPassword: [
            password2?.join(' '),
            non_field_errors?.join(' '),
          ].toString(),
        });
      } else {
        refreshUser();
        helpers.setSubmitting(false);
        navigate(ROUTES.TRIPS);
      }
    } catch (err) {
      helpers.setErrors({
        confirmPassword: 'An error occurred. Please try again.',
      });
      helpers.setSubmitting(false);
    }
  };

  return (
    <div className="mt-8">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={signupSchema}
      >
        {(formik) => (
          <Form>
            <fieldset
              className="flex flex-col max-w-lg mx-auto p-4 border rounded"
              disabled={formik.isSubmitting}
            >
              <div
                className={`flex gap-4 ${
                  windowWidth <= query.mobile ? 'flex-col' : ''
                }`}
              >
                <FormikField
                  name="firstName"
                  label="First Name"
                  placeholder="Enter your name..."
                  component={FormikInput}
                  type="text"
                />
                <FormikField
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter your name..."
                  component={FormikInput}
                  type="text"
                />
              </div>
              <div>
                <FormikField
                  name="email"
                  label="Email"
                  placeholder="Enter your email..."
                  component={FormikInput}
                  noMaxWidth
                  type="email"
                />
                <FormikField
                  name="password"
                  label="Password"
                  placeholder=""
                  component={FormikInput}
                  noMaxWidth
                  type="password"
                />
                <FormikField
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder=""
                  component={FormikInput}
                  noMaxWidth
                  type="password"
                />
              </div>

              <div className="flex flex-col gap-8">
                <SecondaryButton type="submit" isLoading={formik.isSubmitting}>
                  Register
                </SecondaryButton>
                <Link to={ROUTES.LOGIN}>
                  <PrimaryButton className="w-full">
                    Have an account? Log in
                  </PrimaryButton>
                </Link>
              </div>
            </fieldset>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default RegistrationForm;
