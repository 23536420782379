import { useState } from 'react';
import Checkbox from '../../components/atoms/Checkbox';
import Label from '../../components/atoms/Label';
import Heading from '../../components/typography/Heading';
import { usePlaneTypes } from '../../hooks/PlaneTypesProvider';
import { PlaneTypeStatistic } from '../../types/PlaneTypeStatistic';
import Input from '../../components/atoms/Input';
import FileSelect from '../../components/atoms/FileSelect/FileSelect';
import SearchableDropdown from '../../components/molecules/SearchableDropdown/SearchableDropdown';
import { useTrainingFacilities } from '../../hooks/useTrainingFacilities';
import DayPicker from '../../components/molecules/DayPicker/DayPicker';
import Card from '../../components/molecules/Card/Card';
import updateStats from '../../API/Pilot/updateStats';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import { useToast } from '../../hooks/ToastProvider';
import { createPlaneTypeCert } from '../../types/PlaneTypeCertificate';
import SettingsCard from '../../components/molecules/Card/SettingsCard';

interface PlaneModelStatisticsProps {
  statistics: PlaneTypeStatistic;
  onChange: (statistics: PlaneTypeStatistic) => unknown;
}

function PlaneModelStatistics({
  statistics,
  onChange,
}: PlaneModelStatisticsProps) {
  const { user, refreshUser } = useUser();
  const { getPlaneTypeById, getCraftNameById } = usePlaneTypes();
  const {
    loading: trainingFacilitiesLoading,
    facilities,
    getTrainingFacilityByName,
    getTrainingFacilityById,
  } = useTrainingFacilities();
  const { addToast } = useToast();

  const trainingFacilitiesAsStrings = facilities.map((f) => f.facility_name);

  const [disabled, setDisabled] = useState(true);
  const [isCurrent, setIsCurrent] = useState(false);

  const [state, setState] = useState(statistics);

  function updateProperty<T>(prop: keyof PlaneTypeStatistic, value: T) {
    setState({ ...state, [prop]: value });
  }

  const model = getPlaneTypeById(statistics.planeType);
  if (!model)
    throw new Error(
      `No plane type associated with planeType property on plane type statistics. Plane type value (id): ${statistics.planeType}, statistics object id: ${statistics.id}`
    );

  const handleTrainingFacilitySelect = async (trainingFacilityName: string) => {
    if (!user?.pilotRole) return;
    const facility = getTrainingFacilityByName(trainingFacilityName);
    if (facility) {
      updateProperty('planeTypeCert', {
        ...state.planeTypeCert,
        trainingFacility: facility.id,
      });
      console.log(state);
    }
  };

  const handleSave = async () => {
    if (!user?.pilotRole) return;
    if (!state.planeTypeCert) state.planeTypeCert = createPlaneTypeCert();
    const updateResponse = await updateStats(user.pilotRole, state);

    if (updateResponse.statusCode === 200) {
      addToast(
        <span>
          {getCraftNameById(state.planeType) || 'Aircraft'} hours and training
          data saved
        </span>,
        'success'
      );
    } else {
      addToast(
        <span>
          {getCraftNameById(state.planeType)}
          hours and training data failed to save
        </span>,
        'error'
      );
    }
    await refreshUser();
    setDisabled(true);
  };

  const handleEdit = () => setDisabled(false);
  const handleCancel = () => {
    setState({ ...statistics });
    setDisabled(true);
  };

  const handleTrainingDiplomaSelect = (file: File | null) => {
    if (!file) {
      addToast(<span>Invalid file type or contents.</span>, 'error');
    } else {
      setState((prevState) => ({
        ...prevState,
        planeTypeCert: {
          ...prevState.planeTypeCert,
          certDocument: file || null,
        },
      }));
    }
  };

  const handleDifferencesTrainingSelect = (file: File | null) => {
    if (!file) {
      addToast(<span>Invalid file type or contents.</span>, 'error');
    } else {
      setState((prevState) => ({
        ...prevState,
        planeTypeCert: {
          ...prevState.planeTypeCert,
          diffDocument: file || null,
        },
      }));
    }
  };

  return (
    <SettingsCard
      onSave={handleSave}
      onEdit={handleEdit}
      onCancel={handleCancel}
      disabled={disabled}
      responsive
      className="flex flex-col gap-4"
    >
      <Heading level={3}>Aircraft model: {model.craftName}</Heading>
      <div className="flex flex-col gap-4">
        {/* <!-- 12 month current? --> */}
        <div className="flex items-center gap-2">
          <Checkbox
            value={state.twelveCurrent}
            onChange={() =>
              updateProperty('twelveCurrent', !state.twelveCurrent)
            }
            disabled={disabled}
          />
          <Label htmlFor="twelveMoCurrent">12 month current</Label>
        </div>
        {/* <!-- Hours --> */}
        <div>
          <Heading level={4}>Hours (MM):</Heading>
          <div className="grid grid-cols-1 md:grid-cols-4 gap-2">
            <div>
              <Label htmlFor="timeInCraft">Total</Label>
              <Input
                id="timeInCraft"
                type="number"
                value={state.timeInCraft}
                className="!max-w-none"
                disabled={disabled}
                onChange={(e) =>
                  updateProperty(
                    'timeInCraft',
                    e.target.value ? Math.round(Number(e.target.value)) : null
                  )
                }
              />
            </div>
            <div>
              <Label htmlFor="inCommandTime">PIC</Label>
              <Input
                id="inCommandTime"
                type="number"
                value={state.inCommandTime}
                className="!max-w-none"
                disabled={disabled}
                onChange={(e) =>
                  updateProperty(
                    'inCommandTime',
                    e.target.value ? Math.round(Number(e.target.value)) : null
                  )
                }
              />
            </div>
            <div>
              <Label htmlFor="lastTwelveMonths">12 Months</Label>
              <Input
                id="lastTwelveMonths"
                type="number"
                value={state.lastTwelveMonths}
                className="!max-w-none"
                disabled={disabled}
                onChange={(e) =>
                  updateProperty(
                    'lastTwelveMonths',
                    e.target.value ? Math.round(Number(e.target.value)) : null
                  )
                }
              />
            </div>
            <div>
              <Label htmlFor="lastNinetyDays">90 Days</Label>
              <Input
                id="lastNinetyDays"
                type="number"
                value={state.lastNinetyDays}
                className="!max-w-none"
                disabled={disabled}
                onChange={(e) =>
                  updateProperty(
                    'lastNinetyDays',
                    e.target.value ? Math.round(Number(e.target.value)) : null
                  )
                }
              />
            </div>
          </div>
        </div>
        {/* <!-- Training and Docs section --> */}
        {state.twelveCurrent && (
          <div className="grid gap-4 grid-cols-1 md:grid-cols-2">
            {/* <!-- Annual Training --> */}
            <Card responsive className="flex flex-col gap-4">
              <Heading level={4}>Annual Training</Heading>
              <div>
                <Label htmlFor="trainingFacility">Training Facility</Label>
                <SearchableDropdown
                  id="trainingFacility"
                  options={trainingFacilitiesAsStrings}
                  onSelect={handleTrainingFacilitySelect}
                  disabled={disabled}
                  defaultValue={
                    state.planeTypeCert?.trainingFacility
                      ? getTrainingFacilityById(
                          state.planeTypeCert?.trainingFacility
                        )?.facility_name
                      : ''
                  }
                  maxResults={1000}
                />
              </div>
              <div>
                <Label htmlFor="trainingDate">Training Date</Label>
                <DayPicker
                  value={
                    state.planeTypeCert?.certificationDate
                      ? new Date(state.planeTypeCert?.certificationDate)
                      : null
                  }
                  id="trainingDate"
                  onChange={(newDate) =>
                    updateProperty('planeTypeCert', {
                      ...state.planeTypeCert,
                      certificationDate: newDate.toISOString(),
                    })
                  }
                  disabled={disabled}
                  className="z-10"
                />
              </div>
            </Card>
            {/* <!-- Documentation --> */}
            <Card responsive className="flex flex-col gap-4">
              <Heading level={4}>Documentation</Heading>
              <div>
                <Label htmlFor="certDocument">Training Diploma</Label>
                <FileSelect
                  disabled={disabled}
                  onChange={handleTrainingDiplomaSelect}
                  value={state.planeTypeCert?.certDocument || null}
                  id="certDocument"
                />
              </div>
              <div>
                <Label htmlFor="diffDocument">Differences Training</Label>
                <FileSelect
                  disabled={disabled}
                  onChange={handleDifferencesTrainingSelect}
                  value={state.planeTypeCert?.diffDocument || null}
                  id="diffDocument"
                />
              </div>
            </Card>
          </div>
        )}
      </div>
    </SettingsCard>
  );
}

export default PlaneModelStatistics;
