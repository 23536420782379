import { BankAccount } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';
import Card from '../../components/molecules/Card/Card';
import Heading from '../../components/typography/Heading';
import { useToast } from '../../hooks/ToastProvider';
import createSetupIntent, {
  type SetupIntentDetails,
} from '../../API/Payments/createSetupIntent';
import getPaymentMethods from '../../API/Payments/getPaymentMethods';
import Paragraph from '../../components/typography/Paragraph';
import PlaneRateTable from './PlaneRateTable';
import updatePilot from '../../API/Pilot/updatePilot';
import TravelRateCard from './TravelRateCard';
import Link from '../../components/atoms/Link';
import Button, { SecondaryButton } from '../../components/atoms/Button/Button';
import Loader from '../../components/molecules/Loader';
import { useUser } from '../../hooks/UserProvider/UserProvider';

function PilotView() {
  const [setupIntent, setSetupIntent] = useState<
    undefined | SetupIntentDetails
  >(undefined);
  const [loadingBankAccounts, setLoadingBankAccounts] = useState(false);
  const [bankAccounts, setBankAccounts] = useState<null | BankAccount[]>(null);
  const { addToast } = useToast();
  const { user, refreshUser, loadingPilotStatus, pilotOnboardingStatus } =
    useUser();

  if (!user) throw new Error(`No user on Billing and Payments Pilot View`);

  useEffect(() => {
    setLoadingBankAccounts(true);
    createSetupIntent().then((result) => {
      if (result) {
        setSetupIntent(result);
      }
    });
    getPaymentMethods()
      .then((result) => {
        setLoadingBankAccounts(false);
        if (result) {
          setBankAccounts(result.bank_accounts);
        }
      })
      .catch((error) =>
        console.error('Error fetching payment methods:', error)
      );
  }, []);

  const handleReplace = (id: string) => {
    // TODO: implement edit payment method
    console.log(`Edit payment method with id ${id}`);
  };

  const updateTravelRate = async (newRate: number) => {
    const payload = {
      defaultTravelPricePerDay: newRate,
    };
    await updatePilot(user?.pilotRole.id, payload);
    addToast(<span>Saved rates.</span>, 'success');

    await refreshUser();
  };

  return (
    <div className="flex flex-col gap-4">
      <Heading level={2}>Billing and Payments</Heading>
      <Card
        responsive
        className="bg-gradient-to-br from-[#535f7f] to-[#535f7fee] text-white"
      >
        <i className="text-5xl fa-brands fa-cc-stripe" />
        <Heading level={3}>
          Flying Company partners with Stripe for payment processing.
        </Heading>
        <Paragraph>
          Please ensure that you&apos;ve set up a Stripe Connect account using
          the options below. This will ensure you are able to quote trips and
          receive payments.
        </Paragraph>
        {!loadingPilotStatus && !pilotOnboardingStatus?.billingAndPayments && (
          <>
            <Paragraph>
              Already added an account but still getting the &ldquo;onboarding
              incomplete&rdquo; notification? Try refreshing your Stripe Connect
              status.
            </Paragraph>
            <Button onClick={refreshUser}>Refresh</Button>
          </>
        )}
      </Card>
      {loadingBankAccounts && (
        <Loader level={3} caption="Loading bank accounts..." />
      )}
      {bankAccounts &&
        bankAccounts.length > 0 &&
        bankAccounts?.map((bankAccount) => (
          <Card key={bankAccount.id}>
            <Heading level={3}>Deposit Account</Heading>
            <Heading level={4}>{bankAccount.bank_name}</Heading>
            <Paragraph>***{bankAccount.last4}</Paragraph>
            <div className="flex gap-4">
              {setupIntent && (
                <Link target="_self" href={setupIntent.accountLinkUrl}>
                  <SecondaryButton>Replace</SecondaryButton>
                </Link>
              )}
            </div>
          </Card>
        ))}
      {setupIntent?.requiresStripeOnboarding && (
        <>
          {bankAccounts && bankAccounts.length === 0 && (
            <Paragraph>
              You have no bank accounts saved. Add a payment method to get paid.
            </Paragraph>
          )}
          <div className="flex flex-col gap-4">
            {/* add new payment methods */}
            <Card responsive title="Setup your Stripe Connect account">
              {/* {setupIntent && (
                  <Elements stripe={stripePromise} options={{ setupIntent }}>
                    <PaymentMethodForm />
                  </Elements>
                )} */}
              <div>
                Further action is required in your stripe account before you can
                recieve payments.
              </div>
              {setupIntent && (
                <Link target="_self" href={setupIntent.accountLinkUrl}>
                  <i className="fa-solid fa-arrow-up-right-from-square mr-2" />{' '}
                  Setup your Connect account
                </Link>
              )}
            </Card>
          </div>
        </>
      )}
      <PlaneRateTable />
      <TravelRateCard
        rate={user?.pilotRole.defaultTravelPricePerDay || 0}
        updateRate={updateTravelRate}
      />
    </div>
  );
}

export default PilotView;
