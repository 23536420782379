import { Airplane } from '../types/Airplane';
import User from '../types/User';

type OpenPilotClauseProperties = {
  planeTypeFlightHours: number | null;
  multiEngineHours: number | null;
  totalFlightHours: number | null;
  turbineHours: number | null;
  turboJetHours: number | null;
};

function qualifiesForOPP(pilot: User, airplane: Airplane) {
  // First, find the relevant plane type statistic
  const relevantStatistic = pilot.pilotRole.statistics.planeTypeStatistics.find(
    (p) => p.planeType === airplane.model
  );

  // If there isn't one, no OPP
  if (!relevantStatistic) {
    console.log(
      `Pilot does not have relevant statistic for plane type id: ${airplane.model}`
    );
    return false;
  }

  const airplaneOPP = airplane.insurance
    .openPilotClause as OpenPilotClauseProperties;

  if (
    airplaneOPP.planeTypeFlightHours &&
    relevantStatistic.timeInCraft < airplaneOPP.planeTypeFlightHours
  ) {
    console.log(
      `Pilot has ${relevantStatistic.timeInCraft} timeInCraft, OPP requires ${airplaneOPP.planeTypeFlightHours}`
    );
    return false;
  }

  if (
    airplaneOPP.multiEngineHours &&
    pilot.pilotRole.multiEngineHours < airplaneOPP.multiEngineHours
  ) {
    console.log(
      `Pilot has ${pilot.pilotRole.multiEngineHours} multi engine hours, OPP requires ${airplaneOPP.multiEngineHours}`
    );
    return false;
  }

  if (
    airplaneOPP.totalFlightHours &&
    pilot.pilotRole.allAircraftHours < airplaneOPP.totalFlightHours
  ) {
    console.log(
      `Pilot has ${pilot.pilotRole.allAircraftHours} total flight hours, OPP requires ${airplaneOPP.totalFlightHours}`
    );
    return false;
  }

  if (
    airplaneOPP.turbineHours &&
    pilot.pilotRole.turbineHours < airplaneOPP.turbineHours
  ) {
    console.log(
      `Pilot has ${pilot.pilotRole.turbineHours} turbine hours, OPP requires ${airplaneOPP.turbineHours}`
    );
    return false;
  }

  if (
    airplaneOPP.turboJetHours &&
    pilot.pilotRole.turbojetHours < airplaneOPP.turboJetHours
  ) {
    console.log(
      `Pilot has ${pilot.pilotRole.turbojetHours} turbo jet hours, OPP requires ${airplaneOPP.turboJetHours}`
    );
    return false;
  }

  return true;
}

export default qualifiesForOPP;
