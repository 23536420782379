/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
// TODO: fix type nonsense with Receipts and File
import React from 'react';

import {
  ErrorMessage as FormikError,
  FieldArray,
  FormikProps,
  useFormikContext,
} from 'formik';

import Heading from '../../../../components/typography/Heading';
import {
  PrimaryButton,
  SecondaryButton,
  StateButton,
} from '../../../../components/atoms/Button/Button';

import FormikField from '../../../../components/atoms/Form/FormikField';
import FormikInput from '../../../../components/atoms/Form/FormikInput';
import FormikDropdown from '../../../../components/atoms/Form/FormikDropdown';

import type { Expense } from '../../../../types/Expense';
import ErrorMessage from '../../../../components/molecules/ErrorMessage';
import FileSelect from '../../../../components/atoms/FileSelect/FileSelect';
import { FinalExpenses } from '../../../../types/FinalExpenses';
import FinalExpensesStats from './FinalExpensesStats';
import Tooltip from '../../../../components/atoms/Tooltip/Tooltip';
import { Receipt } from '../../../../types/Receipt';

export default function FinalExpensesTable({
  readOnly = false,
}: {
  readOnly?: boolean;
}) {
  const {
    values: { itemizedExpenses },
    setFieldValue,
  } = useFormikContext<FinalExpenses>();

  return (
    <div className="py-8">
      <Heading level={3}>Final Trip Expenses</Heading>

      <FieldArray name="itemizedExpenses">
        {({ form, insert, remove, push }) => (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th className="pl-0 sr-only">Amount</th>
                  <th className="pl-0 sr-only">Description</th>
                  <th className="pl-0 sr-only">Expense Type</th>
                  <th className="pl-0 sr-only">Upload Receipt</th>
                  <th className="pl-0 sr-only">Remove Expense</th>
                </tr>
              </thead>
              <tbody>
                {form.values.itemizedExpenses?.map(
                  ({ id, receipt }: Expense, index: number) => (
                    <tr key={id || index}>
                      <td>
                        <FormikField
                          label="Cost"
                          name={`itemizedExpenses.${index}.cost`}
                          component={FormikInput}
                          type="number"
                        />
                      </td>
                      <td>
                        <FormikField
                          label="Description"
                          name={`itemizedExpenses.${index}.description`}
                          component={FormikInput}
                          optional
                        />
                      </td>
                      <td className="pl-0">
                        <FormikDropdown
                          options={[
                            { label: 'Hotel', value: 'Hotel' },
                            { label: 'Ground Travel', value: 'Ground Travel' },
                            { label: 'Air Travel', value: 'Air Travel' },
                            { label: 'Other', value: 'Other' },
                          ]}
                          label="Expense Category"
                          placeholder="Select a category"
                          name={`itemizedExpenses.${index}.title`}
                        />
                      </td>
                      <td className="relative">
                        <FileSelect
                          id={`receipt${index}`}
                          value={receipt}
                          onChange={(file) =>
                            setFieldValue(
                              `itemizedExpenses.${index}.receipt`,
                              file
                            )
                          }
                          label="Upload Receipt"
                        />
                        <div className="absolute w-[65ch]">
                          <FormikError
                            component={ErrorMessage}
                            name={`itemizedExpenses.${index}.receipt`}
                          />
                        </div>
                      </td>
                      <td className="group-disabled/field:hidden">
                        <StateButton
                          status="error"
                          onClick={() => {
                            // if (id) {
                            //   form.setFieldValue('removedExpenses', [
                            //     ...form.values.removedExpenses,
                            //     id,
                            //   ]);
                            // }
                            remove(index);
                          }}
                        >
                          Remove Expense
                        </StateButton>
                      </td>
                    </tr>
                  )
                )}
                <tr className="group-disabled/field:hidden">
                  <td colSpan={3} className="pl-0">
                    <SecondaryButton
                      onClick={() =>
                        push({
                          type: '',
                          cost: '',
                          description: '',
                          receipt: null,
                          isQuoted: false,
                          isReimbursable: true,
                        })
                      }
                    >
                      Add Expense
                    </SecondaryButton>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* @see https://formik.org/docs/api/fieldarray#fieldarray-validation-gotchas */}
            {typeof (form as FormikProps<{ itemizedExpenses: Expense[] }>)
              ?.errors?.itemizedExpenses === 'string' ? (
              <FormikError component={ErrorMessage} name="itemizedExpenses" />
            ) : null}
          </>
        )}
      </FieldArray>

      <FinalExpensesStats itemizedExpenses={itemizedExpenses} />
    </div>
  );
}
