import { AirportLocation } from '../../types/AirportLocation';
import { AnyObject } from '../../types/util';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';
import { type PaginatedResults } from '../pagination';

const endpoint = `/api/airports/`;

/**
 * Maps snake case fields to camel case and turns date strings into real Dates.
 * @param rawAirport the snake cased airport data object from the backend
 * @returns an AirportLocation
 */
const mapAirportFromBackend = (rawAirport: AnyObject) =>
  remapKeysToCamelCase({
    ...rawAirport,
    createdDate: new Date(rawAirport.created_date as string),
    modifiedDate: new Date(rawAirport.modified_date as string),
  }) as AirportLocation;

const getAirports = async (name?: string, offset?: number) => {
  const params = new URLSearchParams();
  if (name) params.append('search', name);
  if (offset) params.append('offset', offset.toString());
  const url = Array.from(params).length ? `${endpoint}?${params}` : endpoint;

  const airportsFetchResponse = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
  });

  const rawAirports: PaginatedResults<any> = await airportsFetchResponse.json();
  const airports = rawAirports.results.map(
    mapAirportFromBackend
  ) as AirportLocation[];
  return {
    airports,
    statusCode: airportsFetchResponse.status,
    next: rawAirports.next,
  };
};

export default getAirports;
