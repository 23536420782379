import { Form, Formik, FormikHelpers } from 'formik';
import {
  SecondaryButton,
  StateButton,
} from '../../../components/atoms/Button/Button';
import Heading from '../../../components/typography/Heading';
import FormikTextArea from '../../../components/atoms/Form/FormikTextArea';
import FormikField from '../../../components/atoms/Form/FormikField';
import FormikRating from '../../../components/atoms/Form/FormikRating';
import FormField from '../../../components/wrappers/FormField';
import { type ReviewPilotFields } from '../../../API/Trips/reviewTripAsConsumer';

function ReviewPilotModal({
  onSubmit,
  onClose,
  debug = false,
}: {
  onSubmit: (
    values: ReviewPilotFields,
    helpers: FormikHelpers<ReviewPilotFields>
  ) => void;
  debug?: boolean;
  onClose: () => unknown;
}) {
  return (
    <div className="w-full flex flex-col gap-4 mb-24">
      <Heading level={3}>Review your trip</Heading>
      <Heading level={4}>
        How did you fly? If you had any major concerns, please email us at
        info@flyingcompany.com
      </Heading>
      <Formik
        initialValues={{
          consumerPilotRating: 0,
          consumerFlycoRating: 0,
          consumerNotes: '',
        }}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form className="flex flex-col">
            <FormField>
              <FormikRating name="consumerPilotRating" label="Review Pilot" />
            </FormField>
            <FormField>
              <FormikRating
                name="consumerFlycoRating"
                label="Review Flying Company"
              />
            </FormField>
            <FormikField
              name="consumerNotes"
              label="Additional Notes"
              placeholder="Please share some feedback on your experiences with the Operator and Flying Company..."
              component={FormikTextArea}
              type="number"
            />
            <div className="w-full grid grid-cols-2 gap-4">
              <StateButton status="error" onClick={onClose}>
                Cancel
              </StateButton>
              <SecondaryButton type="submit" isLoading={formik.isSubmitting}>
                Submit feedback
              </SecondaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ReviewPilotModal;
