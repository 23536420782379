// export const CrewTypeOptions = [
//   'PIC (Solo Pilot)',
//   'SIC',
//   'Instructor',
//   'PIC (Part of Crew)',
//   'Entire Crew',
// ] as const;

export const CREW_TYPE_OPTIONS = {
  0: 'PIC (Single Pilot)',
  1: 'PIC (Part of Crew)',
  2: 'SIC',
  3: 'Entire Crew (PIC & SIC)',
};

export type CrewType =
  | keyof typeof CREW_TYPE_OPTIONS
  | [keyof typeof CREW_TYPE_OPTIONS, keyof typeof CREW_TYPE_OPTIONS];
