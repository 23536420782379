import { AirportId } from './AirportLocation';

export type TripLeg = {
  id?: number;
  index: number;
  trip?: number;
  fromLocation?: AirportId;
  toLocation?: AirportId;
  departureTime: string;
  arrivalTime: string;
  pax: number;
};

export function createTripLeg({
  id,
  index,
  trip,
  fromLocation,
  toLocation,
  departureTime = '',
  arrivalTime = '',
  pax = 0,
}: {
  id?: number;
  index: number;
  trip?: number;
  fromLocation?: AirportId;
  toLocation?: AirportId;
  departureTime: string;
  arrivalTime: string;
  pax: number;
}): TripLeg {
  return {
    id,
    index,
    trip,
    fromLocation,
    toLocation,
    departureTime,
    arrivalTime,
    pax,
  };
}
