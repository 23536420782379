import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface MainProps {
  className?: string;
  children: ReactNode;
}

/** A page-level container with build it margin to compensate for the header */
function Main({ className, children }: MainProps) {
  return (
    <main
      className={classNames(
        'px-6 md:px-16 pb-8 pt-8 overflow-y-scroll',
        className
      )}
    >
      {children}
    </main>
  );
}

export default Main;
