import { useEffect, useState } from 'react';
import Badge from '../../components/atoms/Badge/Badge';
import { PrimaryButton } from '../../components/atoms/Button/Button';
import Label from '../../components/atoms/Label';
import Rule from '../../components/atoms/Rule';
import Select from '../../components/atoms/Select/Select';
import { useCertificateMetadata } from '../../hooks/PilotCertificateProvider/CertificateMetadataProvider';
import CertificateRatingOption from '../../types/CertificateRatingOption';
import PilotCertificate from '../../types/PilotCertificate';

interface RatingSelectProps {
  disabled?: boolean;
  certificateType: PilotCertificate['type'];
  ratings: CertificateRatingOption['id'][];
  className?: string;
  label?: string;
  addRating: (ratingOptionId: CertificateRatingOption['id']) => unknown;
  removeRating: (ratingOptionId: CertificateRatingOption['id']) => unknown;
}

/** This component renders the standard controls to add and remove Ratings */
function RatingSelect({
  disabled,
  certificateType,
  ratings,
  className,
  addRating,
  removeRating,
  label = 'Add Rating',
}: RatingSelectProps) {
  const { ratingsOptions, getRatingNameById } = useCertificateMetadata();

  const [selectedRating, setSelectedRating] = useState<
    CertificateRatingOption['id'] | undefined
  >(undefined);

  const certificateOptionsAsIds = ratingsOptions
    .filter((ro) => ro.type === certificateType)
    .map((ro) => ro.id);

  const validRatingsOptions = ratingsOptions.filter((ro) => {
    if (ro.type !== certificateType) return false;
    if (ratings.includes(ro.id)) return false;

    const thisRatingOptionName = ratingsOptions.find(
      (ratingOption) => ratingOption.id === ro.id
    )?.name;

    /**
     * Remove rating options that have the same human-readable
     * name and are already selected
     */
    const sameNameDifferentRatingInUse = ratings.filter((ratingId) => {
      const matchingRatingOption = ratingsOptions.find(
        (ro) => ro.id === ratingId
      );
      if (matchingRatingOption) {
        if (
          matchingRatingOption.name === thisRatingOptionName &&
          matchingRatingOption.id !== ro.id
        )
          return true;
      }
      return false;
    });
    if (sameNameDifferentRatingInUse.length) return false;
    return true;
  });

  useEffect(() => {
    setSelectedRating(undefined);
  }, [ratings]);

  return (
    <div className={`p-2 border-2 rounded-lg ${className}`}>
      <Label htmlFor="rating-select">{label}</Label>
      <div className="flex gap-2">
        <Select
          id="rating-select"
          prompt="Select a rating"
          options={validRatingsOptions.map((ro) => ro.id.toString())}
          getOptionDisplayName={(t) =>
            ratingsOptions.find((ro) => ro.id.toString() === t)!.name
          }
          disabled={disabled}
          onChange={(croId) => {
            // alert(croId);
            setSelectedRating(parseInt(croId, 10));
          }}
          value={selectedRating?.toString() || undefined}
        />
        <PrimaryButton
          disabled={disabled}
          onClick={() => selectedRating && addRating(selectedRating)}
        >
          Add
        </PrimaryButton>
      </div>
      {!!ratings.length && (
        <>
          <Rule className="my-4" />
          <div className="flex gap-2 flex-wrap">
            {ratings
              .filter((r) => certificateOptionsAsIds.includes(r))
              .map((ratingOptionId) => (
                <Badge
                  key={ratingOptionId}
                  value={getRatingNameById(ratingOptionId)}
                  disabled={disabled}
                  onRemove={() => removeRating(ratingOptionId)}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
}

export default RatingSelect;
