import { PilotRole } from '../../types/PilotRole';
import { ProjectedPilotRating } from '../../types/ProjectedPilotRating';

const endpoint = (pilotId: number) => `/api/pilots/${pilotId}/ratings/`;

async function getAllPilotRatings(pilot: PilotRole) {
  const response = await fetch(endpoint(pilot.id), {
    headers: {
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
    method: 'GET',
    credentials: 'include',
  });

  const pilotRatings: ProjectedPilotRating[] = await response.json();

  return {
    statusCode: (await response).status,
    pilotRatings,
  };
}

export default getAllPilotRatings;
