import * as Yup from 'yup';
import { OwnersAndOperators } from '../../../../types/AirplaneAttributes/AirplaneAttributesOnHired';
import i18n from '../../../../strings/i18n';

const validAircraftIdentificationSchema = Yup.object({
  identification: Yup.object().shape({
    ownerOperator: Yup.array()
      .required()
      .of(
        Yup.object().shape({
          name: Yup.string().required(
            i18n({ validation: 'requiredDynamic' }, { field: 'Name' })
          ),
          type: Yup.string().required(i18n({ validation: 'selection' })),
        })
      )
      .required(i18n({ validation: 'ownerOperator' }))
      .test(
        'owner-operator-validation',
        i18n({ validation: 'ownerOperator' }),
        (value) => {
          // each owner must have at least one operator, or at least one entity must be both
          if (!value) return false;
          const owners = value.filter((v) => v.type === 'owner').length;
          const operators = value.filter((v) => v.type === 'operator').length;
          const both = value.filter((v) => v.type === 'both').length;

          return (
            // (owners === operators && owners > 0 && operators > 0) || both > 0
            (owners > 0 && operators > 0) || both > 0
          );
        }
      ),
    deletedOwnerOperators: Yup.array().of(Yup.number()).optional(),
    serialNumber: Yup.number().required(
      i18n({ validation: 'requiredDynamic' }, { field: 'Serial Number' })
    ),
    tailNumber: Yup.string().required(
      i18n({ validation: 'requiredDynamic' }, { field: 'Tail Number' })
    ),
    managementCompanyName: Yup.string().optional(),
    managementCompanyAddress: Yup.string().optional(),
    usCustomsDecalNumber: Yup.number().optional(),
    usCustomsDecalYear: Yup.number().optional(),
  }),
});

export default validAircraftIdentificationSchema;
