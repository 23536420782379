const makePayment = async (tripId: number, asPilot?: boolean) => {
  const response = await fetch(
    `/api/trips/${asPilot ? 'as_pilot' : 'as_consumer'}/${tripId}/pay/`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      credentials: 'include',
    }
  );

  if (response.status !== 204) {
    throw new Error('Unable to confirm trip');
  } else {
    return {
      statusCode: response.status,
    };
  }
};

export default makePayment;
