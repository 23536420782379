import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { UIState } from '../types/UIState';

type ToastState = {
  content: React.ReactNode;
  state?: UIState;
  timestamp: number;
  customDuration?: number;
};

interface ToastProviderContextInterface {
  addToast: (
    content: React.ReactNode,
    state?: UIState,
    customDuration?: number
  ) => void;
  toasts: readonly ToastState[];
}

const ToastProviderContext =
  createContext<ToastProviderContextInterface | null>(null);

function ToastProvider({ children }: { children: ReactNode }) {
  const [toasts, setToasts] = useState<ToastState[]>([]);

  const removeToast = useCallback((timestamp: number) => {
    setToasts((toasts) => toasts.filter((t) => t.timestamp !== timestamp));
  }, []);

  /** How long a Toast lasts, in milliseconds. */
  const TOAST_TIMEOUT = 3000;

  const addToast = useCallback(
    (content: React.ReactNode, state?: UIState, customDuration?: number) => {
      const timestamp = new Date().getTime();

      setToasts([
        ...toasts,
        {
          content,
          state,
          timestamp,
          customDuration,
        },
      ]);

      setTimeout(() => {
        removeToast(timestamp);
      }, customDuration || TOAST_TIMEOUT);
    },
    [toasts, removeToast]
  );

  const value = useMemo(() => {
    return {
      addToast,
      toasts,
    };
  }, [addToast, toasts]);

  return (
    <ToastProviderContext.Provider value={value}>
      {children}
    </ToastProviderContext.Provider>
  );
}

const useToast = () => {
  const context = useContext(ToastProviderContext);

  if (!context)
    throw new Error(`useToast cannot be consumed without a ToastProvider`);

  return context;
};

export { ToastProvider, useToast };
