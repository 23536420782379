import convert from 'heic-convert/browser';

async function HEICToJPEG(heic: File): Promise<File> {
  try {
    const tempBuffer = new Uint8Array(await heic.arrayBuffer());
    // Convert the HEIC file to JPEG format using heic-convert
    const jpegBuffer = await convert({
      buffer: await tempBuffer,
      format: 'JPEG',
      quality: 1,
    });

    // Generate a new filename with a .jpg extension
    const originalName = heic.name;
    const newName = `${originalName.replace(/\.[^/.]+$/, '')}.jpg`;

    // Create a new File object from the buffer with the JPEG data
    const jpegFile = new File([jpegBuffer], newName, { type: 'image/jpeg' });

    return jpegFile;
  } catch (error) {
    console.error('Error converting HEIC to JPEG:', error);
    throw error;
  }
}

export default HEICToJPEG;
