import React, { useState } from 'react';

import { useFormikContext } from 'formik';
import FormikInput from '../../../../components/atoms/Form/FormikInput';
import FormikField from '../../../../components/atoms/Form/FormikField';

import Heading from '../../../../components/typography/Heading';
import Info from '../../../../components/atoms/Info/Info';
import Paragraph from '../../../../components/typography/Paragraph';
import { Quote } from '../../../../types/Quote';
import Input from '../../../../components/atoms/Input';
import { PrimaryButton } from '../../../../components/atoms/Button/Button';
import FormikTextArea from '../../../../components/atoms/Form/FormikTextArea';

function TotalDays() {
  const {
    values: { flyingDays, travelDays },
  } = useFormikContext<Quote>();

  return (
    <div className="w-full py-5">
      <label
        className="text-base text-left font-semibold block mb-1 max-w-fit"
        htmlFor="totalDays"
      >
        {/* // eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        Total Days
      </label>
      <div className="relative">
        <Input
          id="totalDays"
          value={flyingDays + travelDays}
          type="number"
          disabled
        />
      </div>
    </div>
  );
}

function QuoteFormFields() {
  const [hasAirTravelNotes, setHasAirTravelNotes] = useState(false);
  const [hasGroundTravelNotes, setHasGroundTravelNotes] = useState(false);
  const [hasHotelNotes, setHasHotelNotes] = useState(false);
  const [hasOtherNotes, setHasOtherNotes] = useState(false);

  return (
    <div className="border shadow-md rounded-lg row-start-1 lg:col-start-2 h-fit max-w-full">
      <div className="py-2 background bg-primary rounded-t-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          Create a Quote
        </Heading>
      </div>
      <div className="p-8 grid gap-8">
        <div>
          <Heading level={3}>Pilot Services</Heading>
          <div className="grid lg:grid-cols-2 lg:gap-4">
            <FormikField
              name="flyingDayRate"
              label="Day Rate"
              component={FormikInput}
              type="number"
            />
            <FormikField
              name="flyingDays"
              label="Flying Days"
              component={FormikInput}
              type="number"
              disabled
            />
          </div>
        </div>
        <div>
          <Heading level={3}>Travel</Heading>
          <div className="grid lg:grid-cols-2 lg:gap-4">
            <FormikField
              name="travelDayRate"
              label={
                <>
                  Day Rate
                  <Info>
                    This can be changed on the &quot;Billing and Payments&quot;
                    page.
                  </Info>
                </>
              }
              component={FormikInput}
              type="number"
              disabled
            />
            <FormikField
              name="travelDays"
              label="Travel Days (Estimated)"
              component={FormikInput}
              type="number"
            />
          </div>
        </div>
        <div>
          <Heading level={3}>Expenses (Estimated)</Heading>
          <Paragraph>
            You won&apos;t be held to these...don&apos;t worry. However,
            it&apos;s really helpful for Operators if you can provide them some
            estimated expenses for your trip.
          </Paragraph>

          <div className="grid lg:grid-cols-2 lg:gap-4">
            <FormikField
              name="perDiemRate"
              label={
                <>
                  Per Diem{' '}
                  <Info>
                    No need to take photos of a $5 coffee receipt. Per diem of
                    $100/day is pre-calculated for your meals and incidentals.
                  </Info>
                </>
              }
              component={FormikInput}
              type="number"
              disabled
            />
            <TotalDays />
          </div>
          <div className="grid lg:grid-cols-2 lg:gap-4">
            <div>
              <FormikField
                name="airTravelCosts"
                label="Air Travel Costs (Total)"
                component={FormikInput}
                type="number"
              />
              {!hasAirTravelNotes && (
                <PrimaryButton
                  className="mt-2"
                  size="small"
                  outline
                  onClick={() => setHasAirTravelNotes(true)}
                >
                  Add Notes
                </PrimaryButton>
              )}
              {hasAirTravelNotes && (
                <FormikField
                  name="airTravelNotes"
                  label="Air Travel Notes"
                  placeholder="Will you need to book flights? Please list your anticipated itinerary."
                  component={FormikTextArea}
                  rows={2}
                  optional
                />
              )}
            </div>
            {/* replicate the above div with the remaining costs */}
            <div>
              <FormikField
                name="groundTravelCosts"
                label="Ground Travel Costs (Total)"
                component={FormikInput}
                type="number"
              />
              {!hasGroundTravelNotes && (
                <PrimaryButton
                  className="mt-2"
                  size="small"
                  outline
                  onClick={() => setHasGroundTravelNotes(true)}
                >
                  Add Notes
                </PrimaryButton>
              )}
              {hasGroundTravelNotes && (
                <FormikField
                  name="groundTravelNotes"
                  label="Ground Travel Notes"
                  placeholder="Will you need ground transportation? Indicate if you plan to utilize ride-sharing or renting a car."
                  component={FormikTextArea}
                  rows={2}
                  optional
                />
              )}
            </div>
          </div>
          <div className="grid lg:grid-cols-2 lg:gap-4">
            <div>
              <FormikField
                name="hotelCosts"
                label="Hotel Costs (Total)"
                component={FormikInput}
                type="number"
              />
              {!hasHotelNotes && (
                <PrimaryButton
                  className="mt-2"
                  size="small"
                  outline
                  onClick={() => setHasHotelNotes(true)}
                >
                  Add Notes
                </PrimaryButton>
              )}
              {hasHotelNotes && (
                <FormikField
                  name="hotelNotes"
                  label="Hotel Notes"
                  placeholder="Will you need hotel accommodations? Specify the preferred location."
                  component={FormikTextArea}
                  rows={2}
                  optional
                />
              )}
            </div>
            <div>
              <FormikField
                name="otherCosts"
                label="Other Expenses (Total)"
                component={FormikInput}
                type="number"
              />
              {!hasOtherNotes && (
                <PrimaryButton
                  className="mt-2"
                  size="small"
                  outline
                  onClick={() => setHasOtherNotes(true)}
                >
                  Add Notes
                </PrimaryButton>
              )}
              {hasOtherNotes && (
                <FormikField
                  name="otherCostsNotes"
                  label="Other Costs Notes"
                  placeholder="Do you anticipate any additional expenses? Please list them."
                  component={FormikTextArea}
                  rows={2}
                  optional
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuoteFormFields;
