import classNames from 'classnames';
import React, { ReactNode } from 'react';
import SemanticSize from '../../../types/SemanticSize';

interface LabelProps {
  className?: string;
  htmlFor: string;
  children: ReactNode;
  required?: boolean;
  size?: SemanticSize;
}

function Label({
  className,
  htmlFor,
  required,
  children,
  size = 'small',
}: LabelProps) {
  const sizes = {
    small: 'text-sm',
    regular: 'text-md',
    large: 'text-lg',
  };

  return (
    <label
      className={classNames(
        'text-left font-semibold block mb-1 max-w-fit',
        sizes[size],
        className
      )}
      htmlFor={htmlFor}
    >
      {children}
      {required ? <span className="text-error">&nbsp;*</span> : ''}
    </label>
  );
}

export default Label;
