// import and export all validation schemas in this file
import validAircraftConfigurationSchema from './aircraftConfiguration';
import validAircraftLocationSchema from './aircraftLocation';
import validAircraftMaintenanceSchema from './aircraftMaintenance';
import validAircraftIdentificationSchema from './aircraftIdentification';
import validAircraftInsuranceSchema from './aircraftInsurance';
import validAircraftOperatingProceduresSchema from './aircraftOperatingProcedures';
import validAircraftTypeSchema from './aircraftType';

export {
  validAircraftConfigurationSchema,
  validAircraftLocationSchema,
  validAircraftMaintenanceSchema,
  validAircraftIdentificationSchema,
  validAircraftInsuranceSchema,
  validAircraftOperatingProceduresSchema,
  validAircraftTypeSchema,
};
