import Label from '../../components/atoms/Label';
import Select from '../../components/atoms/Select/Select';
import TextArea from '../../components/atoms/TextArea';
import FormField from '../../components/wrappers/FormField';

export type QuestionCategory =
  | 'aircraft_claim'
  | 'regulation_violation'
  | 'certificate_revoked'
  | 'felony'
  | 'dui'
  | 'dl_revoked'
  | 'convicted_drugs'
  | 'insurance_declined';
export type QuestionCategoryExplanation =
  | 'exp_aircraft_claim'
  | 'exp_regulation_violation'
  | 'exp_certificate_revoked'
  | 'exp_felony'
  | 'exp_dui'
  | 'exp_dl_revoked'
  | 'exp_convicted_drugs'
  | 'exp_insurance_declined';

export const questionMapping: Record<
  QuestionCategory,
  QuestionCategoryExplanation
> = {
  aircraft_claim: 'exp_aircraft_claim',
  regulation_violation: 'exp_regulation_violation',
  certificate_revoked: 'exp_certificate_revoked',
  felony: 'exp_felony',
  dui: 'exp_dui',
  dl_revoked: 'exp_dl_revoked',
  convicted_drugs: 'exp_convicted_drugs',
  insurance_declined: 'exp_insurance_declined',
};

const labelMapping: Record<QuestionCategory, string> = {
  aircraft_claim: 'Have you ever had an aircraft claim, incident, or accident?',
  certificate_revoked:
    'Has your pilot certificate ever been suspended or revoked?',
  convicted_drugs:
    'Have you ever been convicted of or are you under indictment in a legal action involving drugs or narcotics?',
  dl_revoked: `Has your driver's license ever been suspended or revoked?`,
  dui: 'Have you ever been convicted of driving a motor vehicle under the influence of alcohol or narcotics, or of reckless driving?',
  felony:
    'Have you ever been convicted of a felony or are you under indictment for a felony?',
  insurance_declined:
    'Has any insurance company ever cancelled, declined to issue or declined to renew any insurance policy held by you?',
  regulation_violation:
    'Have you ever been cited or fined for violation of an aviation regulation?',
};

interface QuestionProps {
  question: QuestionCategory;
  questionState: boolean | null | Date;
  questionExplanation: string;
  setQuestionState: (state: boolean) => unknown;
  setQuestionExplanation: (val: string) => unknown;
  disabled: boolean;
}

function Question({
  question,
  questionState,
  questionExplanation,
  setQuestionState,
  setQuestionExplanation,
  disabled,
}: QuestionProps) {
  return (
    <FormField className="flex flex-col gap-4">
      <Label htmlFor={`${question}-id`} required>
        {labelMapping[question]}
      </Label>
      <Select
        id={`${question}-id}`}
        options={['No', 'Yes']}
        defaultvalue={
          // eslint-disable-next-line no-nested-ternary
          questionState === null ? undefined : questionState ? 'Yes' : 'No'
        }
        prompt="Please provide an answer"
        onChange={(t) => setQuestionState(t === 'Yes')}
        disabled={disabled}
      />
      {questionState && (
        <TextArea
          value={questionExplanation}
          onChange={(e) => setQuestionExplanation(e.target.value)}
          disabled={disabled}
        />
      )}
    </FormField>
  );
}

export default Question;
