/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames';
import { FieldProps } from 'formik';
import React, { InputHTMLAttributes, forwardRef, ForwardedRef } from 'react';

export type FormikInputProps = InputHTMLAttributes<HTMLInputElement> &
  FieldProps;

const FormikInput = forwardRef(
  (
    {
      className,
      field, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      ...standardInputProps
    }: FormikInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <input
        className={classNames(
          'input input-bordered w-full group-disabled/field:bg-base-200 group-disabled/field:text-neutral',
          className
        )}
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...standardInputProps}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        onWheel={() => (document.activeElement as HTMLElement).blur()}
      />
    );
  }
);

export default FormikInput;
