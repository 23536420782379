import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Modal } from '../../components/organisms/Modal/Modal';

interface IModalContext {
  /**
   * * Opens the passed content inside a `<Modal />`.
   * * Defaults as dismissible via close icon, pressing ESC, or clicking anywhere in the backdrop.
   */
  openModal: (content: ReactNode, dismissible?: boolean) => void;
  closeModal: () => void;
}

const ModalContext = createContext<IModalContext | undefined>(undefined);

export function ModalProvider({ children }: { children: ReactNode }) {
  const [modalContent, setModalContent] = useState<ReactNode>('');
  const [isDismissible, setIsDismissible] = useState(true);

  const openModal: IModalContext['openModal'] = useCallback(
    (content: ReactNode, dismissible = true) => {
      setModalContent(content);
      setIsDismissible(dismissible);
      (document.getElementById('modal') as HTMLDialogElement)?.showModal();
    },
    []
  );
  const closeModal = useCallback(() => {
    (document.getElementById('modal') as HTMLDialogElement)?.close();
    setModalContent(null);
  }, []);

  const value = useMemo(() => {
    return {
      openModal,
      closeModal,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal, closeModal]);

  return (
    <ModalContext.Provider value={value}>
      {children}
      <Modal closeModal={closeModal} dismissible={isDismissible}>
        {modalContent}
      </Modal>
    </ModalContext.Provider>
  );
}

export const useModalContext = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
