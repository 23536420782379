// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DebounceFunction = (...args: any[]) => void;

function debounce<F extends DebounceFunction>(
  func: F,
  delay = 300
): (...args: Parameters<F>) => void {
  let timer: NodeJS.Timeout;

  return (...args: Parameters<F>) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
}

export default debounce;
