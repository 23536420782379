import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  AccentButton,
  PrimaryButton,
} from '../../components/atoms/Button/Button';
import Heading from '../../components/typography/Heading';
import Section from '../../components/wrappers/Section';
import circleImage from '../../assets/images/HeroImage1.png';
import popaLogo from '../../assets/partner_logos/popa_logo.png';
import Paragraph from '../../components/typography/Paragraph';
import AirplaneCard from '../../components/organisms/AirplaneCard';
import TestimonialCard from '../../components/organisms/TestimonialCard/TestimonialCard';
import ChevronCard from '../../components/organisms/ChevronCard/ChevronCard';
import { ROUTES } from '../routerConfig';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import getPlaneTypeStatsGrouped from '../../API/Plane/getPlaneTypeStatsGrouped';
import PlaneTypeStatsGrouped from '../../types/PlaneTypeStatsGrouped';
import LogoAnimated from '../../components/atoms/Logo/LogoAnimated';

function HomePage() {
  const navigate = useNavigate();
  const { user } = useUser();
  const [supportedAircraft, setSupportedAircraft] = useState<
    PlaneTypeStatsGrouped[]
  >([]);

  const isLoggedIn = user !== null;

  /** Flagging this for now since we are not using testimonials */
  const showTestimonials = false;

  useEffect(() => {
    const getSupportedAircraft = async () => {
      try {
        const response = await getPlaneTypeStatsGrouped();
        if (response) setSupportedAircraft(response?.data);
      } catch (e) {
        /* empty */
      }
    };

    getSupportedAircraft();
  }, []);

  return (
    <>
      <Section className="flex-col-reverse lg:flex-row gap-8 text-center lg:text-left py-16 px-0">
        <div className="w-full">
          <div className="flex flex-col items-center justify-center">
            <div className="mb-6">
              <LogoAnimated />
            </div>
            <Heading
              level={1}
              className="lg:text-7xl md:text-6xl !leading-none text-center"
            >
              FIND THE <span className="text-accent">RIGHT</span> PILOT
            </Heading>
            <Heading
              level={1}
              className="lg:text-7xl md:text-6xl !leading-none text-center"
            >
              NOT JUST <span className="text-accent">ANY</span> PILOT
            </Heading>
            <span className="md:text-3xl block text-center max-w-prose">
              Seamlessly connecting aircraft owners
            </span>
            <span className="md:text-3xl block text-center max-w-prose">
              and operators with contract pilots.
            </span>
          </div>
          {!isLoggedIn && (
            <div className="mt-8 flex justify-center">
              <PrimaryButton
                size="large"
                onClick={() => navigate(ROUTES.LOGIN)}
              >
                Get started for free!
              </PrimaryButton>
            </div>
          )}
        </div>
      </Section>
      <Section className="flex-col lg:flex-col py-8">
        <Heading level={2} className="xl border-b-2 w-full mb-8">
          Supported Aircrafts
        </Heading>
        <div className="grid grid-cols-2 gap-1">
          {supportedAircraft?.map((aircraft) => (
            <AirplaneCard
              imgSrc={aircraft.imageUrl}
              imgAlt={aircraft.craftName}
              type={aircraft.craftName}
              pilotCount={aircraft.totalPilots}
              ttAvg={aircraft.totalTime}
              mmAvg={aircraft.totalTimeInCraft}
            />
          ))}
        </div>
      </Section>
      {showTestimonials ?? (
        <Section className="flex-col lg:flex-col py-8">
          <Heading level={2} className="xl border-b-2 w-full mb-8">
            Testimonials
          </Heading>
          <div className="flex flex-col lg:flex-row gap-8">
            <TestimonialCard
              imgSrc={circleImage}
              imgAlt="Test Plane"
              message="Something something about how awesome the website is"
              author="Guy who owns a plane"
            />
            <TestimonialCard
              imgSrc={circleImage}
              imgAlt="Test Plane"
              message="Something something about how awesome the website is"
              author="Guy who owns a plane"
            />
          </div>
        </Section>
      )}
      <Section className="flex-col md:flex-col py-8">
        <Heading level={2} className="xl border-b-2 w-full mb-8">
          How It Works
        </Heading>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 md:gap-8 flex-col md:flex-row">
          <ChevronCard
            title="Create a Trip"
            bgColor="bg-[#85eae9]"
            icon={<i className="text-3xl fa-solid fa-paper-plane" />}
          >
            Easily build your aircraft profile and create a trip whenever you
            need a contract pilot. The pilots on FlyingCompany.com, who are
            qualified to fly your aircraft type, will be instantly notified.
          </ChevronCard>
          <ChevronCard
            title="Receive Quotes"
            bgColor="bg-[#3edad8]"
            icon={<i className="text-3xl fa-solid fa-user-plus" />}
          >
            Pilots who are available and interested will submit detailed quotes
            for their services. Communicate directly with them through our
            platform, without needing to share personal contact information.
          </ChevronCard>
          <ChevronCard
            title="Assess"
            bgColor="bg-[#37c9ef]"
            icon={<i className="text-3xl fa-solid fa-file-circle-check" />}
          >
            Download key documents like the Pilot History Form (PHF), Pilot
            Certificate, Medical Certificate, and Annual Training Diploma to
            ensure your chosen pilots meet all necessary qualifications.
          </ChevronCard>
          <ChevronCard
            title="Hire"
            bgColor="bg-[#2c92d4]"
            icon={<i className="text-3xl fa-solid fa-file-signature" />}
          >
            Select the right pilot for your trip and both parties will digitally
            sign an industry-standard Pilot Services Agreement (PSA), ensuring
            clear expectations and mutual agreement.
          </ChevronCard>
          <ChevronCard
            title="Pay"
            bgColor="bg-primary"
            icon={<i className="text-3xl fa-solid fa-credit-card" />}
          >
            Before the trip begins, make a one-day down payment for pilot
            services through our secure Stripe integration. After the trip,
            pilots can submit expenses for reimbursement and the final payment
            for their services.
          </ChevronCard>
        </div>
      </Section>
      <Section className="flex-col lg:flex-col py-8">
        <Heading level={2} className="xl border-b-2 w-full mb-8">
          Partners
        </Heading>
        <div className="flex justify-start gap-8">
          <img className="rounded-md" src={popaLogo} alt="POPA Logo" />
        </div>
      </Section>
    </>
  );
}

export default HomePage;
