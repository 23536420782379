import { useState } from 'react';
import { FormikHelpers } from 'formik';
import Heading from '../../../components/typography/Heading';
import { SecondaryButton } from '../../../components/atoms/Button/Button';
import Trip from '../../../types/Trip';
import Paragraph from '../../../components/typography/Paragraph';
import signAgreement from '../../../API/Trips/signAgreement';
import ConfirmHireForm, {
  ConfirmHireFields,
} from '../subcomponents/ConfirmHireForm/ConfirmHireForm';
import { useUser } from '../../../hooks/UserProvider/UserProvider';

export default function ConfirmHireModal({
  trip,
  bidId,
  isBid,
  onConfirmHire,
  onDeclineHire,
  onReviewTripDetails,
}: {
  trip: Trip | null;
  bidId: number;
  isBid: boolean;
  onConfirmHire: () => unknown;
  onDeclineHire: () => unknown;
  onReviewTripDetails: () => unknown;
}) {
  const [isConfirming, setIsConfirming] = useState(false);
  const { user } = useUser();

  if (!user) return null;

  const handleConfirmHire = async (
    values: ConfirmHireFields,
    helpers: FormikHelpers<ConfirmHireFields>
  ) => {
    try {
      setIsConfirming(true);

      helpers.setSubmitting(true);

      if (trip?.id && values.signature instanceof File) {
        await signAgreement(trip?.id, values.signature, user.id.toString());
        await onConfirmHire();
      } else {
        helpers.setFieldError('signature', 'Please sign the agreement');
      }
      helpers.setSubmitting(false);
      helpers.setSubmitting(false);
      setIsConfirming(false);
    } catch (error) {
      setIsConfirming(false);
      helpers.setSubmitting(false);
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col gap-4 mb-24">
      <Heading level={3}>
        Your offer to fly for {trip?.consumer?.fullName} was accepted!
      </Heading>
      <div className="flex flex-col gap-4">
        <Paragraph>
          You have 24 hours to double check your schedule and ensure you&apos;re
          still available to fly on the trip dates. When you are ready, please
          press &quot;Confirm Hire&quot; and wait for the confirmation screen
        </Paragraph>
        <Paragraph>
          Once you confirm the hiring, a down-payment of 1 day of Pilot
          Services, and 1 day of Per Diem will be initiated. You should receive
          that payment into your bank account in approximately 3 business days.
        </Paragraph>
        <ConfirmHireForm
          handleSubmit={handleConfirmHire}
          handleCancel={onDeclineHire}
          bidId={(bidId || trip?.id) as number}
          isBid={isBid}
        />
        <SecondaryButton onClick={onReviewTripDetails}>
          Review Trip Details
        </SecondaryButton>
      </div>
    </div>
  );
}
