// import and export all steps
import AircraftConfiguration from './AircraftConfiguration';
import AircraftIdentifiers from './AircraftIdentifiers';
import AircraftInsurance from './AircraftInsurance';
import AircraftLocation from './AircraftLocation';
import AircraftMaintenance from './AircraftMaintenance';
import AircraftOperatingProcedures from './AircraftOperatingProcedures';
import AircraftType from './AircraftType';

export {
  AircraftConfiguration,
  AircraftIdentifiers,
  AircraftInsurance,
  AircraftLocation,
  AircraftMaintenance,
  AircraftOperatingProcedures,
  AircraftType,
};
