import React, { AnchorHTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { ActionIcon } from '../Icons';

import './Link.css';

interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  className?: string;
  children?: ReactNode;
  action?: string;
}
interface ExternalLink extends LinkProps {
  href: string;
  to?: never;
  relative?: never;
}
interface InternalLink extends LinkProps {
  href?: never;
  to: string;
  relative?: never;
}

interface InternalRelativeLink extends LinkProps {
  href: string;
  to?: never;
  relative: boolean;
}

function Link({
  href,
  target,
  to,
  className,
  relative,
  onClick,
  children,
  action,
}: ExternalLink | InternalLink | InternalRelativeLink) {
  if (to) {
    return (
      <RouterLink
        className={classNames('link', className)}
        to={to}
        onClick={onClick}
      >
        {children}
        {action ? <ActionIcon action={action} /> : null}
      </RouterLink>
    );
  }
  if (relative) {
    return (
      <a
        className={classNames('link', className)}
        href={href}
        onClick={onClick}
        rel="noreferrer"
      >
        {children}
        {action ? <ActionIcon action={action} /> : null}
      </a>
    );
  }
  return (
    <a
      className={classNames('link', className)}
      href={href}
      target={target || '_blank'}
      onClick={onClick}
      rel="noreferrer"
    >
      {children}
      {action ? <ActionIcon action={action} /> : null}
    </a>
  );
}

export default Link;
