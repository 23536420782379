import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../components/atoms/Button/Button';
import ControlBar from '../../../components/molecules/ControlBar/ControlBar';
import Heading from '../../../components/typography/Heading';
import Paragraph from '../../../components/typography/Paragraph';
import { ROUTES } from '../../routerConfig';
import Rule from '../../../components/atoms/Rule';
import Link from '../../../components/atoms/Link';

function UnavailableTrip() {
  const navigate = useNavigate();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);
  return (
    <>
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" />
          Trips
        </PrimaryButton>
      </ControlBar>
      <div className="flex flex-col gap-4">
        <Heading level={2} className="mt-4">
          Trip Unavailable
        </Heading>
        <Rule />
        <Paragraph>
          Thanks so much for your interest! Unfortunately, it seems like this
          trip is no longer available. This could be for a few different
          reasons.
        </Paragraph>
        <Paragraph>
          {/* Yeah, there's no ul here. The bullets disappear if there is */}
          <li>The Operator has cancelled the trip</li>
          <li>The Operator has accepted a quote from a pilot already</li>
          <li>The trip start time has passed</li>
        </Paragraph>
        <Paragraph>
          Be sure to mark emails from{' '}
          <Link href="mailto:info@flyingcompany.com">
            info@flyingcompany.com
          </Link>{' '}
          as &ldquo;important&rdquo; so that you can be the first to find out
          and provide a quote.
        </Paragraph>
      </div>
    </>
  );
}

export default UnavailableTrip;
