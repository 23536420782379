import Paragraph from '../../typography/Paragraph';

export interface TestimonialCardProps {
  className?: string;
  imgSrc?: string;
  imgAlt?: string;
  message?: string;
  author?: string;
}

export default function TestimonialCard({
  className,
  imgSrc,
  imgAlt,
  message,
  author,
}: TestimonialCardProps) {
  return (
    <article
      className={`flex flex-col lg:flex-row items-center gap-4 ${
        className ?? ''
      }`}
    >
      {imgSrc && (
        <img className="rounded-full w-36 h-36" src={imgSrc} alt={imgAlt} />
      )}
      <div className="text-center lg:text-left max-w-1/2">
        {message && <Paragraph>{message}</Paragraph>}
        {author && <Paragraph className="font-bold">- {author}</Paragraph>}
      </div>
    </article>
  );
}
