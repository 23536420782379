import { PilotRole } from '../../types/PilotRole';
import { AnyObject } from '../../types/util';
import isEmptyObject from '../../utilities/isEmptyObject';
import { remapKeysToSnakeCase } from '../../utilities/remapKeys';
import fetchAPI from '../util';

type CreateExpensePayload = Array<any>;

async function createExpenses(pilot: PilotRole, id: string, quote: any) {
  try {
    if (isEmptyObject(quote)) return;

    console.log(quote);
    const payload: CreateExpensePayload = [
      {
        cost: quote.hotelCosts,
        title: 'Hotel',
        description: quote.hotelNotes,
        is_quoted: true,
      },
      {
        cost: quote.groundTravelCosts,
        title: 'Ground Travel',
        description: quote.groundTravelNotes,
        is_quoted: true,
      },
      {
        cost: quote.airTravelCosts,
        title: 'Air Travel',
        description: quote.airTravelNotes,
        is_quoted: true,
      },
      {
        cost: quote.otherCosts,
        title: 'Other',
        description: quote.otherCostsNotes,
        is_quoted: true,
      },
    ];
    await fetchAPI(`/api/trips/as_pilot/${id}/quoted_expense/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  } catch (err) {
    console.log(err);
  }
}

export default createExpenses;
