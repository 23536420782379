import { useFormikContext } from 'formik';
import { FinalExpenses } from '../../../../types/FinalExpenses';
import DatePicker from '../../../../components/atoms/DatePicker/DatePicker';
import FormikField from '../../../../components/atoms/Form/FormikField';
import FormikInput from '../../../../components/atoms/Form/FormikInput';
import Label from '../../../../components/atoms/Label';

export default function FinalExpensesAlterations() {
  const {
    values: { alterations },
    setFieldValue,
  } = useFormikContext<FinalExpenses>();

  return (
    <div>
      <div className="py-5">
        <Label htmlFor="startDate">Start Date</Label>
        <DatePicker
          id="startDate"
          selected={new Date(alterations.startDate)}
          onChange={(newDate) =>
            setFieldValue('alterations.startDate', newDate)
          }
          dateFormat="MMMM d, yyyy"
          className="input input-bordered w-full max-w-xs"
        />
      </div>
      <div className="py-5">
        <Label htmlFor="endDate">End Date</Label>
        <DatePicker
          id="endDate"
          selected={new Date(alterations.endDate)}
          onChange={(newDate) => setFieldValue('alterations.endDate', newDate)}
          dateFormat="MMMM d, yyyy"
          className="input input-bordered w-full max-w-xs"
        />
      </div>
      <FormikField
        name="alterations.totalTravelDays"
        label="Total Travel Days"
        type="number"
        component={FormikInput}
      />
    </div>
  );
}
