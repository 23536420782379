import React from 'react';

function Calendar() {
  return (
    <svg width="20px" height="19px" viewBox="0 0 20 19" version="1.1">
      <g
        id="kayak-pivot"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="1-kayak-form" transform="translate(-103.000000, -269.000000)">
          <g id="ic_calender" transform="translate(101.000000, 266.000000)">
            <g id="Group" transform="translate(3.000000, 4.000000)">
              <rect
                id="Rectangle-3"
                stroke="#C3C8CA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                x="0"
                y="3"
                width="18"
                height="14"
              />
              <path
                d="M5,5 L5,0"
                id="Line"
                stroke="#C3C8CA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M13,5 L13,0"
                id="Line-Copy"
                stroke="#C3C8CA"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <circle id="Oval-2" fill="#C3C8CA" cx="5" cy="9" r="1" />
              <circle id="Oval-2-Copy-3" fill="#C3C8CA" cx="5" cy="13" r="1" />
              <circle id="Oval-2-Copy" fill="#C3C8CA" cx="9" cy="9" r="1" />
              <circle id="Oval-2-Copy-4" fill="#C3C8CA" cx="9" cy="13" r="1" />
              <circle id="Oval-2-Copy-2" fill="#C3C8CA" cx="13" cy="9" r="1" />
              <circle id="Oval-2-Copy-5" fill="#C3C8CA" cx="13" cy="13" r="1" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
export default Calendar;
