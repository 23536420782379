export default function PlusIcon() {
  return (
    <svg viewBox="0 0 34 34" version="1.1">
      <g
        id="UI-Wireframes"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="3a:-Profile-+-Settings"
          transform="translate(-455.000000, -257.000000)"
          fill="currentColor"
        >
          <g id="Profile-Info" transform="translate(270.000000, 167.000000)">
            <path
              d="M213.339299,95.6607 C206.859699,89.1811 196.339299,89.1811 189.859699,95.6607 C183.3801,102.1403 183.3801,112.6607 189.859699,119.1403 C196.339299,125.6199 206.859699,125.6199 213.339299,119.1403 C219.818899,112.6607 219.818899,102.1011 213.339299,95.6607 Z M209.139299,108.3403 C208.898675,108.580924 208.539299,108.7403 208.179939,108.7403 L202.979939,108.7403 L202.979939,113.9403 C202.979939,114.299676 202.820563,114.66062 202.579939,114.89966 C202.339315,115.140284 201.979939,115.29966 201.620579,115.29966 C200.861219,115.29966 200.261219,114.69966 200.261219,113.9403 L200.261219,108.7403 L195.061219,108.7403 C194.301859,108.7403 193.701859,108.1403 193.701859,107.38094 C193.701859,106.62158 194.301859,106.02158 195.061219,106.02158 L200.261219,106.02158 L200.261219,100.82158 C200.261219,100.06222 200.861219,99.46222 201.620579,99.46222 C202.379939,99.46222 202.979939,100.06222 202.979939,100.82158 L202.979939,106.02158 L208.179939,106.02158 C208.939299,106.02158 209.539299,106.62158 209.539299,107.38094 C209.498675,107.740316 209.378363,108.10126 209.139299,108.3403 Z"
              id="Page-1"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
