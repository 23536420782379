import React from 'react';
import classNames from 'classnames';

import './Rule.css';

function Rule({ className }: { className?: string }) {
  return <hr className={classNames('rule', className)} />;
}

export default Rule;
