import React from 'react';

import LoginForm from '../../components/organisms/LoginForm';
import Heading from '../../components/typography/Heading';

function LoginPage() {
  return (
    <>
      <Heading level={1} className="text-center my-4">
        Log In
      </Heading>
      <LoginForm />
    </>
  );
}

export default LoginPage;
