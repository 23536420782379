import { useState } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import Card from '../../molecules/Card/Card';
import CreditCard from './CreditCard';
import Heading from '../../typography/Heading';
import { StateButton } from '../../atoms/Button/Button';
import Loader from '../../molecules/Loader';
import Badge from '../../atoms/Badge/Badge';

function PaymentMethodsList({
  loadingMethods,
  paymentMethods,
  handleDelete,
  handleSetDefault, // New prop for setting a default payment method
  defaultPaymentMethodId, // Prop for default payment method ID
}: {
  loadingMethods: boolean;
  paymentMethods: PaymentMethod[];
  handleDelete: (id: PaymentMethod['id']) => unknown;
  handleSetDefault: (id: PaymentMethod['id']) => unknown; // Type for setting default method
  defaultPaymentMethodId: string; // Type for default payment method ID
}) {
  return (
    <Card responsive>
      <Heading level={3}>Payment Methods</Heading>
      {loadingMethods && <Loader caption="Loading payment methods..." />}
      {!loadingMethods && paymentMethods.length > 0 && (
        <div className="flex flex-wrap gap-4">
          {paymentMethods.map((method) => (
            <CreditCard card={method.card!} key={method.id}>
              {method.id === defaultPaymentMethodId && (
                <Badge value="Default" className="bg-success font-semibold" />
              )}
              <StateButton
                status="success"
                onClick={() => handleSetDefault(method.id)} // Call set default handler
                disabled={method.id === defaultPaymentMethodId} // Disable if it's already the default
              >
                Set as Default
              </StateButton>
              <StateButton
                status="error"
                onClick={() => handleDelete(method.id)}
              >
                DELETE
              </StateButton>
            </CreditCard>
          ))}
        </div>
      )}
    </Card>
  );
}

export default PaymentMethodsList;
