import React, { type RefObject } from 'react';

/**
 * Hook that runs a callback when it detects a click outside of the passed ref
 */
export default function useClickOutside(callback: (e?: Event) => void) {
  const ref: RefObject<HTMLElement> = React.useRef(null);
  const refCb = React.useRef(callback);

  React.useLayoutEffect(() => {
    refCb.current = callback;
  });

  React.useEffect(() => {
    const handler = (e: Event) => {
      const element = ref.current;
      if (element && !element.contains(e.target as Node)) {
        refCb.current(e);
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  return ref;
}
