import { FormEvent, useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import ErrorMessage from '../../molecules/ErrorMessage';
import { PrimaryButton } from '../../atoms/Button/Button';
import { useUser } from '../../../hooks/UserProvider/UserProvider';

function PaymentMethodForm() {
  const { refreshUser } = useUser();
  const currentUrlWithoutQueryParams = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<undefined | string>(undefined);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        // for redirecting back to our app when authing some payment methods
        return_url: currentUrlWithoutQueryParams,
        // return_url: ROUTES.BILLING_AND_PAYMENTS,
      },
    });
    setLoading(false);

    if (result.error) {
      setError(result.error.message);
    } else {
      console.log('Payment method added successfully!');
    }
    await refreshUser();
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col">
        <PaymentElement />
        <PrimaryButton
          type="submit"
          disabled={!stripe}
          className="self-end"
          isLoading={loading}
        >
          Save Payment Method
        </PrimaryButton>
      </form>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
}

export default PaymentMethodForm;
