import { ToastProps, Toast } from './Toast';

function SuccessToast({ children, className }: ToastProps) {
  return (
    <Toast className={`alert-success text-white ${className}`}>
      {children}
    </Toast>
  );
}

export default SuccessToast;
