import { Link } from 'react-router-dom';
import getAircraft from '../../../API/Aircraft/getAircraft';
import getPaymentMethods from '../../../API/Payments/getPaymentMethods';
import { ROUTES } from '../../../routes/routerConfig';
import User from '../../../types/User';
import Heading from '../../typography/Heading';

export type PilotOnboardingStatus = {
  generalInformation: boolean;
  certificatesAndRatings: boolean;
  hoursAndTraining: boolean;
  questions: boolean;
  billingAndPayments: boolean;
  statusContent: null | React.ReactNode;
};

export type OperatorOnboardingStatus = {
  generalInformation: boolean;
  aircraft: boolean;
  billingAndPayments: boolean;
  statusContent: null | React.ReactNode;
};

/**
 * This function examines the pilot role object to determine
 * how far along the user has progressed into the onboarding
 * flow.
 * @returns PilotOnboardingStatus
 */
export const getPilotOnboardingStatus = async (
  user: User,
  closeModal: () => unknown
) => {
  const onboardingStatus: PilotOnboardingStatus = {
    generalInformation: true,
    certificatesAndRatings: true,
    hoursAndTraining: true,
    questions: true,
    billingAndPayments: true,
    statusContent: null,
  };

  if (!user) return onboardingStatus;

  let numIncomplete = 0;

  // Check if phone number or home location are missing
  if (!user?.pilotRole.phoneNumber || !user.pilotRole.homeLocation) {
    onboardingStatus.generalInformation = false;
    numIncomplete++;
  }
  if (
    !user?.pilotRole.medicalClass ||
    !user.pilotRole.dateMedical ||
    user.pilotRole.licenseCerts.length < 1
  ) {
    onboardingStatus.certificatesAndRatings = false;
    numIncomplete++;
  }
  if (
    !user?.pilotRole.allAircraftHours ||
    user?.pilotRole.statistics.planeTypeStatistics.length < 1
  ) {
    onboardingStatus.hoursAndTraining = false;
    numIncomplete++;
  }
  if (user?.pilotRole.aircraftClaim === null) {
    onboardingStatus.questions = false;
    numIncomplete++;
  }
  const getPaymentMethodsResults = await getPaymentMethods();
  if (
    getPaymentMethodsResults.bank_accounts.length < 1 ||
    getPaymentMethodsResults.requires_stripe_onboarding
  ) {
    onboardingStatus.billingAndPayments = false;
    numIncomplete++;
  }

  if (numIncomplete)
    onboardingStatus.statusContent = (
      <div>
        <Heading level={3} className="mb-4">
          Before you can create quotes, please complete the following profile
          sections:
        </Heading>
        <ul className="flex flex-col gap-4">
          {!onboardingStatus.generalInformation && (
            <li>
              <Link
                to={ROUTES.GENERAL_INFORMATION}
                onClick={closeModal}
                className="underline"
              >
                General Information
              </Link>
            </li>
          )}

          {!onboardingStatus.certificatesAndRatings && (
            <li>
              <Link
                className="underline"
                to={ROUTES.CERTIFICATES_AND_RATINGS}
                onClick={closeModal}
              >
                Certificates and Ratings
              </Link>
            </li>
          )}

          {!onboardingStatus.hoursAndTraining && (
            <li>
              <Link
                to={ROUTES.HOURS_TRAINING}
                onClick={closeModal}
                className="underline"
              >
                Hours and Training
              </Link>{' '}
              - You must add your total hours and provide a type rating or
              aircraft qualification.
            </li>
          )}

          {!onboardingStatus.questions && (
            <li>
              <Link
                className="underline"
                to={ROUTES.QUESTIONS}
                onClick={closeModal}
              >
                Questions
              </Link>
            </li>
          )}
          {!onboardingStatus.billingAndPayments && (
            <li>
              <Link
                className="underline"
                to={ROUTES.BILLING_AND_PAYMENTS}
                onClick={closeModal}
              >
                Billing and Payments
              </Link>
            </li>
          )}
        </ul>
      </div>
    );

  return onboardingStatus;
};

/**
 * This function examines the operator role object to determine
 * how far along the user has progressed into the onboarding
 * flow.
 * @returns OperatorOnboardingStatus
 */
export const getOperatorOnboardingStatus = async (
  user: User,
  closeModal: () => unknown
) => {
  const onboardingStatus: OperatorOnboardingStatus = {
    generalInformation: true,
    aircraft: true,
    billingAndPayments: true,
    statusContent: null,
  };

  if (!user) return onboardingStatus;

  const aircraft = await getAircraft();

  let numIncomplete = 0;

  // Check if phone number or position are missing
  if (!user?.consumerRole.phoneNumber || !user.consumerRole.position) {
    onboardingStatus.generalInformation = false;
    numIncomplete++;
  }

  // Make sure there is at least one valid aircraft
  if (!aircraft.some((ac) => ac.isValid)) {
    onboardingStatus.aircraft = false;
    numIncomplete++;
  }

  const paymentMethods = await getPaymentMethods();

  if (!paymentMethods.payment_methods.length) {
    onboardingStatus.billingAndPayments = false;
    numIncomplete++;
  }

  if (numIncomplete)
    onboardingStatus.statusContent = (
      <div>
        <Heading level={3} className="mb-4">
          Before you can create trips, please complete the following profile
          sections:
        </Heading>
        <ul className="flex flex-col gap-4">
          {!onboardingStatus.generalInformation && (
            <li>
              <Link
                to={ROUTES.GENERAL_INFORMATION}
                onClick={closeModal}
                className="underline"
              >
                General Information
              </Link>{' '}
              - You must complete required fields.
            </li>
          )}

          {!onboardingStatus.aircraft && (
            <li>
              <Link
                className="underline"
                to={ROUTES.MY_AIRCRAFT}
                onClick={closeModal}
              >
                Aircraft
              </Link>{' '}
              - You must add a valid aircraft.
            </li>
          )}

          {!onboardingStatus.billingAndPayments && (
            <li>
              <Link
                to={ROUTES.BILLING_AND_PAYMENTS}
                onClick={closeModal}
                className="underline"
              >
                Billing and Payments
              </Link>{' '}
              - You must add a payment method.
            </li>
          )}
        </ul>
      </div>
    );

  return onboardingStatus;
};
