import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { FormikHelpers } from 'formik';
import { differenceInDays } from 'date-fns';
import {
  PrimaryButton,
  SecondaryButton,
  StateButton,
} from '../../../components/atoms/Button/Button';
import Rule from '../../../components/atoms/Rule';
import ConsumerCard from '../../../components/molecules/ConsumerCard/ConsumerCard';
import ControlBar from '../../../components/molecules/ControlBar/ControlBar';
import Chat from '../../../components/organisms/Chat/Chat';
import QuoteCard from '../../../components/organisms/QuoteCard/QuoteCard';
import TripCard from '../../../components/organisms/TripCard/TripCard';
import Heading from '../../../components/typography/Heading';
import { ROUTES } from '../../routerConfig';
import Bid from '../../../types/Bid';
import Trip from '../../../types/Trip';
import { Quote, createQuoteFromBid } from '../../../types/Quote';
import GrandTotalEstimateCard from '../../../components/organisms/GrandTotalEstimateCard/GrandTotalEstimateCard';
import Loader from '../../../components/molecules/Loader';
import Alert from '../../../components/atoms/Alert/Alert';
import submitFinalExpenses from '../../../API/Trips/submitFinalExpenses';
import { FinalExpenses } from '../../../types/FinalExpenses';
import FinalExpensesForm from '../subcomponents/FinalExpensesForm';
import Card from '../../../components/molecules/Card/Card';
import submitTripAlteration from '../../../API/Trips/submitTripAlteration';
import PrimaryTripLayout from '../Layouts/PrimaryTripLayout';

interface PilotUnderwayViewProps {
  trip: Trip;
  bid: Bid;
  openDetailsModal: () => unknown;
  openQuoteModal: () => unknown;
  id: string;
}

function PilotUnderwayView({
  trip,
  bid,
  openDetailsModal,
  openQuoteModal,
  id,
}: PilotUnderwayViewProps) {
  const navigate = useNavigate();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);
  const [quote, setQuote] = useState<Quote | null>(
    trip ? createQuoteFromBid(bid, trip.conversations[0].user, trip) : null
  );
  const submitExpensesRef = useRef<HTMLDivElement>(null);

  const hiredBid = trip.conversations
    .map((c) => c.bid)
    .find((b) => b?.id === trip.hiredBid);
  if (!hiredBid) throw new Error(`No hired bid on UnderwayView`);

  const hiredBidConversation = trip.conversations.find(
    (c) => c.bid?.id === trip.hiredBid
  );
  if (!hiredBidConversation)
    throw new Error(`No hired bid conversation on UnderwayView`);

  const { cost } = hiredBidConversation;

  const handleSubmit = async (
    values: FinalExpenses,
    helpers: FormikHelpers<FinalExpenses>
  ) => {
    try {
      await submitFinalExpenses(id, values.itemizedExpenses);

      // check for TripAlteration
      if (
        values.alterations.totalTravelDays !== quote?.travelDays ||
        differenceInDays(
          new Date(values.alterations.endDate),
          new Date(values.alterations.startDate)
        ) +
          1 !==
          quote?.flyingDays
      ) {
        await submitTripAlteration(values.alterations);
      }

      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (trip)
      setQuote(createQuoteFromBid(bid, trip.conversations[0].user, trip));
  }, [bid, trip]);

  return (
    <>
      {/* Mobile secondary top menu */}
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" /> Trips
        </PrimaryButton>
        <SecondaryButton
          className="lg:hidden"
          size="small"
          onClick={openDetailsModal}
        >
          <span>View Details</span>
        </SecondaryButton>
        <SecondaryButton
          className="lg:hidden "
          size="small"
          onClick={openQuoteModal}
        >
          Review Quote
        </SecondaryButton>
        <StateButton
          status="success"
          className="ml-auto"
          size="small"
          onClick={() => {
            submitExpensesRef.current?.scrollIntoView();
          }}
        >
          Submit Expenses
        </StateButton>
      </ControlBar>
      {trip && quote ? (
        <div className="flex flex-col gap-4">
          <Heading level={2}>{trip.name} is underway!</Heading>
          <Alert status="success" message="Have a great flight!" />
          <PrimaryTripLayout>
            {/** Operator profile area */}
            <div className="relative h-full">
              <ConsumerCard
                user={trip.conversations[0].user}
                className="h-full"
                userTitle="Client"
              />
            </div>
            {/** Chat area */}
            <div className="h-full flex flex-col gap-4">
              {/** Quote card... Bad trip duration  */}
              {bid && cost && (
                <GrandTotalEstimateCard
                  isPilot
                  cost={cost}
                  expenses={(bid as Bid).quotedExpenses}
                  className="flex-grow"
                />
              )}
              <Chat tripId={trip.id} conversation={trip.conversations[0]} />
            </div>
            <div className="hidden lg:block h-full">
              {/** Trip card */}
              <TripCard
                trip={trip}
                showStatus={false}
                expanded="always"
                interactive={false}
                className="h-full"
              />
            </div>
          </PrimaryTripLayout>
          <div className="w-full mt-4" ref={submitExpensesRef}>
            <Card
              className="col-span-full"
              title="Submit Final Expenses"
              responsive
            >
              <FinalExpensesForm
                expenses={{
                  alterations: {
                    id: bid.id,
                    startDate: trip.startDate,
                    endDate: trip.endDate,
                    totalTravelDays: quote.travelDays,
                  },
                  itemizedExpenses: [
                    {
                      cost: 0,
                      description: '',
                      title: 'Air Travel',
                      receipt: '',
                      isQuoted: false,
                      isReimbursable: true,
                    },
                  ],
                  // removedExpenses: [],
                }}
                handleSubmit={handleSubmit}
                handleCancel={() => {}}
              />
            </Card>
          </div>
        </div>
      ) : (
        <Card responsive>
          <Loader level={3} caption="Loading Trip..." />
        </Card>
      )}
    </>
  );
}

export default PilotUnderwayView;
