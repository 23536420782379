// Import hooks and utilities
import { useUser } from '../../hooks/UserProvider/UserProvider';
// Import sub-components and views
import OperatorView from './OperatorView';
import PilotView from './PilotView';

function BillingAndPaymentsPage() {
  const { user, refreshUser, role } = useUser();

  return role === 'consumer' ? (
    <OperatorView />
  ) : (
    <PilotView user={user} refreshUser={refreshUser} />
  );
}

export default BillingAndPaymentsPage;
