import React, { useState, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';
import debounce from '../../../utilities/debounce';

import Input from '../../atoms/Input';
import CancelIcon from '../../../assets/icons/cancel';
import { StateButton } from '../../atoms/Button/Button';
import Loader from '../Loader';

import useForm from '../../../hooks/useForm';

import './SearchableDropdown.css';

function SearchableDropdown<T extends string>({
  options,
  placeholder,
  onSelect,
  onChange,
  onClear,
  className,
  id,
  defaultValue,
  disabled = false,
  maxResults = 15,
  isLoading,
}: {
  options: readonly T[];
  placeholder?: string;
  onSelect: (t: T) => unknown;
  onChange?: (t: T) => unknown;
  onClear?: () => unknown;
  className?: string;
  id: string;
  defaultValue?: T;
  disabled?: boolean;
  maxResults?: number;
  isLoading?: boolean;
}) {
  const MAX_RESULTS = maxResults;
  const searchTerm = `${id}Search`;
  const { inputs, handleChange, updateFields, resetForm } = useForm({
    [searchTerm]: defaultValue || null,
  });
  const [filteredOptions, setFilteredOptions] = useState<readonly T[]>([]);
  const [selectedOption, setSelectedOption] = useState<T | null>(
    defaultValue || null
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useRef<HTMLUListElement>(null);
  const hasResults = filteredOptions.length > 0;

  const handleSearch = (value: string) => {
    if (options.length) {
      setFilteredOptions(
        options
          .filter((option) =>
            option?.toLowerCase().includes(value?.toLowerCase())
          )
          .slice(0, MAX_RESULTS)
      );
    }

    if (document.activeElement === inputRef.current) {
      setDropdownOpen(true);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchTerm = useCallback(debounce(handleSearch), [
    inputs[searchTerm],
  ]);

  useEffect(() => {
    if (inputs[searchTerm]?.trim() === '') {
      setFilteredOptions([]);
      setDropdownOpen(false);
      return;
    }

    debounceSearchTerm(inputs[searchTerm] || '');
  }, [inputs, searchTerm, debounceSearchTerm, options]);

  // TODO: pass focus back to input on clickoption
  const handleOptionClick = (option: string) => {
    setSelectedOption(option as T);
    onSelect?.(option as T);
    updateFields({ [searchTerm]: option });
    setDropdownOpen(false);
  };

  const handleClearSearchTerm = () => {
    if (!onClear) return;
    if (selectedOption) {
      resetForm();
      setSelectedOption(defaultValue || null);
    }
    if (inputRef.current) inputRef.current.focus();
    onClear();
  };

  const handleFocus = () => {
    // if we access inputs[searchTerm] directly, the call to debounceSearchTerm
    // throws a type error because TS fails to recognize that the '!== null' bit
    // necessarily means that inputs[searchTerm] can NOT be null
    const searchState = inputs[searchTerm];
    debounceSearchTerm(searchState || '');
    setDropdownOpen(true);
  };

  const handleCancelFocus = () => {
    setDropdownOpen(true);
  };

  const handleBlur = (event: React.FocusEvent<HTMLElement>) => {
    const element = event.relatedTarget as Element;

    if (!wrapperRef?.current?.contains(element)) {
      setDropdownOpen(false);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className={classNames('searchable-dropdown', className)}>
      <div className="input-wrapper w-full">
        {isLoading ? (
          <Loader />
        ) : (
          <Input
            id={`${id}Search`}
            type="text"
            className="relative"
            value={inputs[searchTerm] || ''}
            placeholder={placeholder}
            onChange={(e) => {
              handleChange(e);
              onChange?.(e.target.value as T);
            }}
            onBlur={handleBlur}
            onFocus={handleFocus}
            ref={inputRef}
            disabled={disabled}
            autoComplete="off"
            role="search"
          />
        )}
        {inputs[searchTerm] !== '' &&
          inputs[searchTerm] !== null &&
          !disabled &&
          onClear && (
            <div className="absolute right-2 top-1/2 -translate-y-1/2">
              <StateButton
                tabIndex={-1}
                className={classNames('btn btn-circle p-2')}
                size="small"
                status="error"
                onClick={handleClearSearchTerm}
                onBlur={handleBlur}
                onFocus={handleCancelFocus}
                disabled={disabled}
              >
                <CancelIcon />
              </StateButton>
            </div>
          )}
      </div>
      {dropdownOpen && hasResults && (
        <ul
          id={`${id}Searches`}
          ref={wrapperRef}
          // className="dropdown-list z-[10000]"
          // TODO: fix stacking context from overlapping top level cards
          className="dropdown-list !pb-8"
          role="listbox"
        >
          {filteredOptions.map((option) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <li
              key={option}
              aria-selected={selectedOption === option}
              role="option"
              tabIndex={0}
              className={`!text-sm ${
                selectedOption === option ? 'selected' : ''
              }`}
              onClick={() => handleOptionClick(option)}
              onBlur={handleBlur}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SearchableDropdown;
