import * as Yup from 'yup';
import i18n from '../../../../strings/i18n';

const validAircraftMaintenanceSchema = Yup.object({
  maintenance: Yup.object().shape({
    contactName: Yup.string().optional(),
    contactPhoneNumber: Yup.string().optional(),
    preferredMaintenanceFacility: Yup.string().required(
      i18n(
        { validation: 'requiredDynamic' },
        { field: 'Preferred Maintenance Facility' }
      )
    ),
    // maintenanceFacilityLocation: Yup.string().required(
    //   i18n(
    //     { validation: 'requiredDynamic' },
    //     { field: 'Preferred Facility Location' }
    //   )
    // ),
    squawks: Yup.string().optional(),
  }),
});

export default validAircraftMaintenanceSchema;
