import { SecondaryButton } from '../../../components/atoms/Button/Button';
import GrandTotalEstimateCard from '../../../components/organisms/GrandTotalEstimateCard/GrandTotalEstimateCard';
import Heading from '../../../components/typography/Heading';
import { useModalContext } from '../../../hooks/ModalProvider/ModalProvider';
import Bid from '../../../types/Bid';
import Conversation from '../../../types/Conversation';

interface QuoteModalProps {
  conversation: Conversation;
  isQuoted: boolean;
  isPilot: boolean;
}

function QuoteModal({ conversation, isQuoted, isPilot }: QuoteModalProps) {
  const { closeModal } = useModalContext();
  const { cost, bid } = conversation;

  if (!cost || !bid) return null;

  return (
    <div className="flex flex-col gap-4 mb-24">
      <Heading level={3}>Quote Details</Heading>
      <GrandTotalEstimateCard
        expenses={isQuoted ? bid.quotedExpenses : (bid as Bid).expenses}
        cost={cost}
        isQuoted={isQuoted}
        isPilot={isPilot}
      />
      <SecondaryButton onClick={closeModal}>Return</SecondaryButton>
    </div>
  );
}

export default QuoteModal;
