// Import React
import { useState, useEffect } from 'react';
// Import shared app components and types
import Section from '../../components/wrappers/Section';
import Loader from '../../components/molecules/Loader';
import Trip from '../../types/Trip';
// Import utilities and hooks
import getTripAsPilot from '../../API/Trips/getTripAsPilot';

// Import sub-components
import AvailableTrip from './PilotView/AvailableTrip';
import UnavailableTrip from './PilotView/UnavailableTrip';
import QuotedTrip from './PilotView/QuotedTrip';
import { useModalContext } from '../../hooks/ModalProvider/ModalProvider';
import QuoteAccepted from './PilotView/QuoteAccepted';
import PilotInitialPaymentView from './PilotView/PilotInitialPaymentView';
import TripDetailsModal from './PilotView/TripDetailsModal';
import QuoteModal from './PilotView/QuoteModal';
import PilotUnderwayView from './PilotView/PilotUnderwayView';
import ExpensesView from './PilotView/ExpensesView';
import CompleteView from './PilotView/CompleteView';
import ReviewOperatorModal from './PilotView/ReviewOperatorModal';
import reviewTripAsPilot, {
  type ReviewOperatorFields,
} from '../../API/Trips/reviewTripAsPilot';
import CancelledView from './PilotView/CancelledView';
import Card from '../../components/molecules/Card/Card';

function PilotView({ id }: { id: string }) {
  const { openModal, closeModal } = useModalContext();
  const [loading, setLoading] = useState(true);
  const [trip, setTrip] = useState<Trip | null>(null);
  const [failedToLoadTrip, setFailedToLoadTrip] = useState(false);

  // Load trip
  useEffect(() => {
    const getAndSetTrip = async () => {
      try {
        const loadedTrip = await getTripAsPilot(id as string);
        setTrip(loadedTrip);
        setLoading(false);
      } catch (error) {
        setTrip(null);
        setFailedToLoadTrip(true);
      }
    };
    getAndSetTrip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const submitReview = async (values: ReviewOperatorFields) => {
    try {
      if (!trip) return;

      await reviewTripAsPilot(values, trip.id);

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const submitEmptyReview = () => {
    if (!trip) return;

    // TODO: this is a workaround due to the fact that the review is currently required on the backend
    // reviewTripAsPilot(
    //   { pilotConsumerRating: 0, pilotFlycoRating: 0, pilotNotes: '' },
    //   trip.id
    // );
    closeModal();
  };

  const openDetailsModal = () => {
    if (trip) openModal(<TripDetailsModal trip={trip} />);
  };

  const openReviewModal = () => {
    openModal(
      <ReviewOperatorModal
        onClose={submitEmptyReview}
        onSubmit={submitReview}
      />
    );
  };

  if (failedToLoadTrip) return <UnavailableTrip />;
  if (loading)
    return (
      <Card responsive>
        <Loader level={3} caption="Loading Trip..." />
      </Card>
    );
  if (!trip) return <Section>No trip found</Section>;
  if (!trip.conversations.length || !trip.conversations[0].bid)
    return <AvailableTrip trip={trip} id={id} />;

  return (
    <>
      {/* 
        We can show "QuotedTrip" because the if statement above 
        will cause an early return if there is no quote from this
        pilot user
      */}
      {trip.status === 'Quotes Received' && (
        <QuotedTrip trip={trip} openDetailsModal={openDetailsModal} />
      )}
      {(trip.status === 'Pilot Hired' || trip.status === 'Pilot Confirmed') && (
        <QuoteAccepted trip={trip} openDetailsModal={openDetailsModal} />
      )}
      {trip.status === 'Pilot Initial Payment' && (
        <PilotInitialPaymentView
          trip={trip}
          openDetailsModal={openDetailsModal}
        />
      )}
      {trip.status === 'Underway' && (
        <PilotUnderwayView trip={trip} openDetailsModal={openDetailsModal} />
      )}
      {(trip.status === 'Expenses Submitted' ||
        trip.status === 'Expenses Approved' ||
        trip.status === 'Pilot Final Payment') && (
        <ExpensesView
          trip={trip}
          openReviewModal={openReviewModal}
          openDetailsModal={openDetailsModal}
        />
      )}
      {trip.status === 'Complete' && (
        <CompleteView
          trip={trip}
          openReviewModal={openReviewModal}
          openDetailsModal={openDetailsModal}
        />
      )}
      {trip.status === 'Cancelled' && <CancelledView trip={trip} />}
    </>
  );
}

export default PilotView;
