import CancelIcon from '../../../assets/icons/cancel';
import Button from '../../atoms/Button';

export interface ModalProps {
  closeModal: () => void;
  children: React.ReactNode;
  dismissible: boolean;
}

export function Modal({ closeModal, children, dismissible }: ModalProps) {
  return dismissible ? (
    <dialog id="modal" className="modal">
      <div className="modal-box">
        <form method="dialog">
          {/* if there is a button in form, it will close the modal */}
          <Button
            className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
            onClick={closeModal}
          >
            <CancelIcon />
          </Button>
        </form>
        {children}
      </div>
      {/* This form is invisible */}
      <form method="dialog" className="modal-backdrop">
        {/* eslint-disable-next-line react/button-has-type */}
        <button>close</button>
      </form>
    </dialog>
  ) : (
    <dialog id="modal" className="modal">
      <div className="modal-box">{children}</div>
    </dialog>
  );
}
