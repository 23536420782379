import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';

import Link from '../../atoms/Link';
import { useUser } from '../../../hooks/UserProvider/UserProvider';
import { ROUTES } from '../../../routes/routerConfig';
import { PrimaryButton, SecondaryButton } from '../../atoms/Button/Button';
import FormikField from '../../atoms/Form/FormikField';
import FormikInput from '../../atoms/Form/FormikInput';
import i18n from '../../../strings/i18n';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Enter a valid email address')
    .required(i18n({ validation: 'requiredDynamic' }, { field: 'Email' })),

  password: Yup.string()
    .min(8, 'Password must be at least 8 characters.')
    .required(i18n({ validation: 'requiredDynamic' }, { field: 'Password' })),
});

function LoginForm() {
  const { login } = useUser();
  const navigate = useNavigate();
  const initialValues = {
    email: '',
    password: '',
  };

  const onSubmit = async (
    { email, password }: { email: string; password: string },
    helpers: FormikHelpers<{ email: string; password: string }>
  ) => {
    helpers.setSubmitting(true);

    try {
      await login(email, password);
    } catch (err) {
      helpers.setSubmitting(false);
      helpers.setErrors({ password: String(err) });
      return;
    }

    helpers.setSubmitting(false);

    // TODO refactor to make sure user state has been updated by login call before navigating instead of being lazy with setTimeout
    setTimeout(() => {
      navigate(ROUTES.TRIPS);
    }, 1000);
  };

  return (
    <div className="mt-8">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={loginSchema}
      >
        {(formik) => (
          <Form>
            <fieldset
              className="flex flex-col max-w-lg mx-auto p-4 border rounded"
              disabled={formik.isSubmitting}
            >
              <div>
                <FormikField
                  name="email"
                  label="Email"
                  placeholder="Enter your email..."
                  component={FormikInput}
                  noMaxWidth
                  type="email"
                />
                <FormikField
                  name="password"
                  label="Password"
                  placeholder=""
                  component={FormikInput}
                  noMaxWidth
                  type="password"
                />
              </div>

              <div className="flex flex-col gap-8">
                <SecondaryButton type="submit" isLoading={formik.isSubmitting}>
                  Login
                </SecondaryButton>
                <Link to={ROUTES.REGISTER}>
                  <PrimaryButton className="w-full">
                    No account? Register here
                  </PrimaryButton>
                </Link>
              </div>
            </fieldset>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default LoginForm;
