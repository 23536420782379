import { Airplane } from '../../types/Airplane';
import { endpoints } from '../endpoints';
import mappedAircraft from './util/mappedAircraft';

const getAircraft = async (id?: number): Promise<Airplane[]> => {
  const url = id ? `${endpoints.AIRCRAFT}/${id}` : `${endpoints.AIRCRAFT}`;

  try {
    const response = await fetch(url, {
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      method: 'GET',
      credentials: 'include',
    });

    if (response.status !== 200) {
      throw new Error('Unable to get aircraft data');
    } else {
      const aircraft = await response.json();

      return aircraft.map((a: Airplane) => {
        return mappedAircraft(a);
      });
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export default getAircraft;
