import { createContext, ReactNode, useContext, useMemo, useState } from 'react';

interface IScrollContext {
  lockBodyScroll: () => void;
  unlockBodyScroll: () => void;
  isBodyScrollLocked: () => boolean;
  scrollToTop: () => unknown;
}

const ScrollContext = createContext<IScrollContext>(undefined!);

export function ScrollProvider({ children }: { children: ReactNode }) {
  const [locked, setLocked] = useState(false);

  const value = useMemo(() => {
    const lockBodyScroll = () => {
      setLocked(true);
      document.body.classList.add('scroll-lock');
    };
    const unlockBodyScroll = () => {
      setLocked(false);
      document.body.classList.remove('scroll-lock');
    };
    const isBodyScrollLocked = () => locked;
    const scrollToTop = () => {
      document.body.scrollTop = 0;
    };
    return {
      lockBodyScroll,
      unlockBodyScroll,
      isBodyScrollLocked,
      scrollToTop,
    };
  }, [locked]);

  return (
    <ScrollContext.Provider value={value}>{children}</ScrollContext.Provider>
  );
}

export const useScroll = () => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error('useScroll must be used within a ScrollProvider');
  }
  return context;
};
