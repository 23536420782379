import { useState } from 'react';
import Badge from '../../components/atoms/Badge/Badge';
import { PrimaryButton } from '../../components/atoms/Button/Button';
import Label from '../../components/atoms/Label';
import Rule from '../../components/atoms/Rule';
import Select from '../../components/atoms/Select/Select';
import PilotCertificate from '../../types/PilotCertificate';
import { usePlaneTypes } from '../../hooks/PlaneTypesProvider';

interface TypeRatingSelectProps {
  disabled?: boolean;
  pilotTypeRatings: PilotCertificate['typeRatings'];
  updateTypeRatings: (newState: PilotCertificate['typeRatings']) => unknown;
  className?: string;
}

/** This component renders the standard controls to add and remove Type Ratings */
function TypeRatingSelect({
  disabled,
  pilotTypeRatings,
  updateTypeRatings,
  className,
}: TypeRatingSelectProps) {
  // etc

  const { getTypeRatings } = usePlaneTypes();

  const typeRatings = getTypeRatings();

  const unusedTypeRatings = typeRatings.filter(
    (tr) => !pilotTypeRatings.includes(tr.id)
  );

  const [selectedTypeRating, setSelectedTypeRating] = useState<
    number | undefined
  >(undefined);

  const addSelectedTypeRating = () => {
    if (selectedTypeRating !== undefined) {
      const matchingPlaneTypeId = typeRatings.find(
        (tr) => tr.id === selectedTypeRating
      )?.id;
      if (matchingPlaneTypeId) {
        updateTypeRatings([...pilotTypeRatings, matchingPlaneTypeId]);
        setSelectedTypeRating(undefined);
      }
    }
  };

  const removeTypeRating = (typeRating: number) => {
    updateTypeRatings([...pilotTypeRatings.filter((r) => r !== typeRating)]);
  };

  return (
    <div className={`p-2 border-2 rounded-lg ${className}`}>
      <Label className="" htmlFor="type-rating-select">
        Add Type Rating / Aircraft Qualification
      </Label>
      <div className="flex gap-2">
        <Select
          id="type-rating-select"
          prompt="Select a rating"
          options={unusedTypeRatings.map((tr) => tr.id.toString())}
          getOptionDisplayName={(trId) =>
            unusedTypeRatings.find((tr) => tr.id.toString() === trId)
              ?.craftType || 'Error'
          }
          disabled={disabled || unusedTypeRatings.length < 1}
          onChange={(trId) => {
            setSelectedTypeRating(parseInt(trId, 10));
          }}
          value={selectedTypeRating?.toString() || undefined}
        />
        <PrimaryButton
          disabled={disabled || unusedTypeRatings.length < 1}
          onClick={addSelectedTypeRating}
        >
          Add
        </PrimaryButton>
      </div>
      {!!pilotTypeRatings.length && (
        <>
          <Rule className="my-4" />
          <div className="flex gap-2 flex-wrap">
            {pilotTypeRatings.map((trId) => (
              <Badge
                key={trId}
                value={
                  typeRatings.find((tr) => tr.id === trId)?.craftName || 'Error'
                }
                disabled={disabled}
                onRemove={() => removeTypeRating(trId)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default TypeRatingSelect;
