import React from 'react';

function Location() {
  return (
    <svg width="17px" height="19px" viewBox="0 0 17 19" version="1.1">
      <defs />
      <g
        id="kayak-pivot"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g
          id="1-kayak-form"
          transform="translate(-104.000000, -159.000000)"
          stroke="#C3C8CA"
          strokeWidth="2"
        >
          <g id="location" transform="translate(105.000000, 160.000000)">
            <path
              d="M14.5723565,7.28599171 C14.5723565,13.3538488 8.50054131,16.994563 7.28617826,16.994563 C6.07181522,16.994563 0,13.3538488 0,7.28599171 C0,3.26542962 3.2629935,0.00456323338 7.28617826,0.00456323338 C11.309363,0.00456323338 14.5723565,3.26542962 14.5723565,7.28599171 Z M7.28617826,9.71630106 C8.62683506,9.71630106 9.71490435,8.62894107 9.71490435,7.28915823 C9.71490435,5.94937539 8.62683506,4.86201541 7.28617826,4.86201541 C5.94552146,4.86201541 4.85745218,5.94937539 4.85745218,7.28915823 C4.85745218,8.62894107 5.94552146,9.71630106 7.28617826,9.71630106 Z"
              id="Stroke-179"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
export default Location;
