import isEmptyObject from '../../utilities/isEmptyObject';
import fetchAPI from '../util';

export interface ReviewPilotFields {
  consumerPilotRating: number;
  consumerFlycoRating: number;
  consumerNotes: string;
}

interface NullableReviewPilotFields {
  consumerPilotRating: number | null;
  consumerFlycoRating: number | null;
  consumerNotes: string | null;
}

async function reviewTripAsConsumer(
  fields: NullableReviewPilotFields,
  id: number
) {
  try {
    if (isEmptyObject(fields)) return;

    await fetchAPI(`/api/trips/as_consumer/${id}/review/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        stars: fields.consumerPilotRating,
        flyco_stars: fields.consumerFlycoRating,
        notes: fields.consumerNotes || 'No additional notes provided.',
      }),
    });
  } catch (err) {
    console.log(err);
  }
}

export default reviewTripAsConsumer;
