/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

// Import CSS
import { useAirports } from '../../../../hooks/AirportsProvider';
import { AirportId } from '../../../../types/AirportLocation';
import { createTripLeg, TripLeg } from '../../../../types/TripLeg';
import { SecondaryButton, StateButton } from '../../../atoms/Button/Button';
import DatePicker from '../../../atoms/DatePicker/DatePicker';
import Input from '../../../atoms/Input';
import Label from '../../../atoms/Label';
import Rule from '../../../atoms/Rule';
import AirportSelect from '../../../molecules/AirportSelect/AirportSelect';
import Loader from '../../../molecules/Loader';
import Heading from '../../../typography/Heading';
import './Itinerary.css';

function Itinerary({
  className,
  itinerary,
  setItinerary,
}: {
  className?: string;
  itinerary: TripLeg[];
  setItinerary: (newItineraryState: TripLeg[]) => void;
}) {
  const { airports } = useAirports();

  const updateLegOrigin = (
    legIndex: number,
    newFromLocation: AirportId | null
  ) => {
    const updatedItinerary = Array.from(itinerary);
    updatedItinerary[legIndex].fromLocation = newFromLocation || 0;
    if (legIndex > 0) {
      updatedItinerary[legIndex - 1].toLocation = newFromLocation || 0;
    }
    setItinerary(updatedItinerary);
  };

  const updateLegDestination = (
    legIndex: number,
    newtoLocation: AirportId | null
  ) => {
    const updatedItinerary = Array.from(itinerary);
    if (updatedItinerary.length === 1) {
      // There's only one leg, so just set the toLocation
      updatedItinerary[0].toLocation = newtoLocation || 0;
    } else {
      // There are multiple legs,
      // so set the toLocation and if there's a leg after, set
      // its fromLocation to match
      updatedItinerary[legIndex].toLocation = newtoLocation || 0;
      if (updatedItinerary.length > legIndex + 1) {
        updatedItinerary[legIndex + 1].fromLocation = newtoLocation || 0;
      }
    }
    setItinerary(updatedItinerary);
  };

  const updateLegNumberOfPassengers = (
    legIndex: number,
    numberOfPassengers: number
  ) => {
    const updatedItinerary = Array.from(itinerary);
    updatedItinerary[legIndex].pax = numberOfPassengers;
    setItinerary(updatedItinerary);
  };

  const addLeg = (legIndex: number) => {
    const updatedItinerary = Array.from(itinerary);
    updatedItinerary.splice(
      legIndex + 1,
      0,
      createTripLeg({
        index: legIndex + 1,
        fromLocation: updatedItinerary[legIndex].toLocation,
        departureTime: updatedItinerary[legIndex].departureTime,
        arrivalTime: updatedItinerary[legIndex].departureTime,
        pax: updatedItinerary[legIndex].pax,
      })
    );
    setItinerary(updatedItinerary);
  };

  const removeLeg = (legIndex: number) => {
    const updatedItinerary = Array.from(itinerary);
    if (legIndex > 0 && legIndex < updatedItinerary.length - 1) {
      updatedItinerary[legIndex + 1].fromLocation =
        updatedItinerary[legIndex - 1].toLocation;
    }
    updatedItinerary.splice(legIndex, 1);
    for (let i = 0; i < updatedItinerary.length; i++) {
      updatedItinerary[i].index = i;
    }
    setItinerary(updatedItinerary);
  };

  const updateLegdepartureTime = (legIndex: number, newDate: Date) => {
    const updatedItinerary = Array.from(itinerary);
    updatedItinerary.find((leg) => leg.index === legIndex)!.departureTime =
      newDate.toISOString();
    setItinerary(updatedItinerary);
  };

  return (
    <div className={`itinerary w-full ${className}`}>
      <Heading level={3}>Itinerary</Heading>
      {airports && airports.length ? (
        <table className="table">
          <thead className="hidden md:block">
            <tr className="itinerary-row p-4 gap-4 items-center">
              <th className="p-0" />
              <th className="p-0">From</th>
              <th className="p-0">To</th>
              <th className="p-0">Departure Time (L)</th>
              <th className="p-0">PAX</th>
              <th className="p-0">Add</th>
              <th className="p-0">Delete</th>
            </tr>
          </thead>
          <tbody>
            {itinerary.map((leg) => (
              <tr
                className="itinerary-row gap-4 mb-4 items-center p-4 rounded-md bg-slate-100"
                key={`${leg.fromLocation}-${leg.toLocation}-${leg.arrivalTime}`}
              >
                <th className="p-0">Leg {leg.index + 1}</th>
                <Rule className="!border-slate-300 md:hidden" />
                <td className="p-0">
                  <Label
                    className="md:hidden"
                    htmlFor={`leg-${leg.index}-origin`}
                  >
                    From
                  </Label>
                  <AirportSelect
                    id={`leg-${leg.index}-origin`}
                    onSelect={(airport) => updateLegOrigin(leg.index, airport)}
                    defaultValue={leg.fromLocation}
                  />
                </td>
                <td className="p-0">
                  <Label
                    className="md:hidden"
                    htmlFor={`leg-${leg.index}-origin`}
                  >
                    To
                  </Label>
                  <AirportSelect
                    id={`leg-${leg.index}-destination`}
                    onSelect={(airport) =>
                      updateLegDestination(leg.index, airport)
                    }
                    defaultValue={leg.toLocation}
                  />
                </td>
                <td className="p-0">
                  <Label
                    className="md:hidden"
                    htmlFor={`leg-${leg.index}-origin`}
                  >
                    Departure Time (L)
                  </Label>
                  <DatePicker
                    selected={new Date(leg.departureTime)}
                    onChange={(newDate) =>
                      updateLegdepartureTime(leg.index, newDate || new Date())
                    }
                    showTimeSelect
                    dateFormat="MMMM d, yyyy h:mm aa"
                    className="input input-bordered w-full max-w-xs"
                  />
                </td>
                <td className="p-0 w-full">
                  <Label
                    className="md:hidden"
                    htmlFor={`leg-${leg.index}-origin`}
                  >
                    PAX
                  </Label>
                  <Input
                    onChange={(e) =>
                      updateLegNumberOfPassengers(
                        leg.index,
                        parseInt(e.target.value, 10)
                      )
                    }
                    className="w-full max-w-2xl"
                  />
                </td>
                <div className="flex gap-4 md:contents">
                  <td className="p-0">
                    <SecondaryButton
                      size="small"
                      onClick={() => addLeg(leg.index)}
                      className="btn-circle"
                    >
                      +
                    </SecondaryButton>
                  </td>
                  <td className="p-0">
                    <StateButton
                      status="error"
                      size="small"
                      onClick={() => removeLeg(leg.index)}
                      disabled={itinerary.length <= 1}
                      className="btn-circle"
                    >
                      x
                    </StateButton>
                  </td>
                </div>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Loader caption="Loading airports" />
      )}
    </div>
  );
}

export default Itinerary;
