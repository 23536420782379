import React from 'react';

function Phone() {
  return (
    <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1">
      <g
        id="kayak-pivot"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Profile-+-Settings-Client"
          transform="translate(-292.000000, -714.000000)"
          fill="#C3C8CA"
        >
          <g id="profile" transform="translate(231.000000, 226.000000)">
            <g id="Group-2" transform="translate(45.000000, 108.000000)">
              <g id="Form" transform="translate(0.000000, 156.000000)">
                <g id="ic_email" transform="translate(15.000000, 89.000000)">
                  <path
                    d="M5.91854787,147.614709 C8.89304787,151.214209 16.1430479,154.664459 17.8930479,153.890209 C19.6430479,153.114809 20.0180479,151.440009 19.9428479,150.665709 C19.8676529,149.890309 15.8930979,147.515209 14.8930979,147.066209 C13.8930979,146.616009 12.7426979,148.066209 12.1928479,148.691209 C11.6176479,149.290809 9.41734787,147.466609 8.46834787,146.540809 C8.46834787,146.540809 5.96834787,143.741059 6.54354787,143.140309 C7.11874787,142.540709 8.54354787,141.340509 8.04354787,140.364809 C7.54354787,139.390209 5.01804787,135.489809 4.21829787,135.440059 C3.44289787,135.390254 1.79349787,135.840459 1.09329787,137.614859 C0.393097872,139.390259 3.74379787,146.164609 7.46829787,148.989859"
                    id="Page-1"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Phone;
