import fetchAPI from '../util';

// const endpoint = '/api/trips/as_consumer/tripId/hire/';

type RequestHirePayload = {
  bidId: number;
  tripId: number;
};

async function requestHire({
  bidId,
  tripId,
}: RequestHirePayload): Promise<any> {
  try {
    const payload = { bid_id: bidId };

    const requestHireResponse = await fetchAPI(
      `/api/trips/as_consumer/${tripId}/hire/`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      }
    );

    if (requestHireResponse.statusCode === 204) {
      return { success: true };
    }
    return requestHireResponse;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export default requestHire;
