import { PilotRole } from '../../types/PilotRole';
import PlaneType from '../../types/PlaneType';
import updatePilot from './updatePilot';

type UpdatePilotPayload = {
  hoursPerPlaneType: {
    pricePerDay: number;
    planeTypeId: number;
  }[];
};

export default async function addTypeRating(
  pilot: PilotRole,
  typeRating: PlaneType
) {
  const payload: UpdatePilotPayload = {
    hoursPerPlaneType: pilot.statistics.planeTypeStatistics.map((pts) => ({
      pricePerDay: pts.pricePerDay,
      planeTypeId: pts.planeType,
    })),
  };

  payload.hoursPerPlaneType.push({
    pricePerDay: 1000,
    planeTypeId: typeRating.id,
  });

  console.log('ADD TYPE RATING');
  console.log(payload);
  await updatePilot(pilot.id, payload);
}
