import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import {
  PrimaryButton,
  SecondaryButton,
} from '../../../components/atoms/Button/Button';
import Rule from '../../../components/atoms/Rule';
import ConsumerCard from '../../../components/molecules/ConsumerCard/ConsumerCard';
import ControlBar from '../../../components/molecules/ControlBar/ControlBar';
import Chat from '../../../components/organisms/Chat/Chat';
import TripCard from '../../../components/organisms/TripCard/TripCard';
import { useUser } from '../../../hooks/UserProvider/UserProvider';
import { createQuoteFromBid } from '../../../types/Quote';
import Trip from '../../../types/Trip';
import { ROUTES } from '../../routerConfig';
import Alert from '../../../components/atoms/Alert/Alert';
import { PostQuoteResponse } from '../../../types/PostQuoteResponse';
import FinalExpensesForm from '../subcomponents/FinalExpensesForm';
import Card from '../../../components/molecules/Card/Card';
import Heading from '../../../components/typography/Heading';
import PilotFinalCostCard from '../../../components/organisms/PilotFinalCostCard/PilotFinalCostCard';
import PrimaryTripLayout from '../Layouts/PrimaryTripLayout';

interface ExpensesViewProps {
  trip: Trip;
  bid: PostQuoteResponse;
  openReviewModal?: () => unknown;
  openDetailsModal: () => unknown;
  openQuoteModal: () => unknown;
}

function ExpensesView({
  trip,
  bid,
  openReviewModal,
  openDetailsModal,
  openQuoteModal,
}: ExpensesViewProps) {
  const { user } = useUser();
  const navigate = useNavigate();
  const navigateToTrips = () => navigate(ROUTES.TRIPS);

  const quote = createQuoteFromBid(bid, user!, trip);

  useEffect(() => {
    if (!trip?.review?.pilotConsumerRating && openReviewModal!) {
      openReviewModal();
    }
  });

  const hiredBid = trip.conversations
    .map((c) => c.bid)
    .find((b) => b?.id === trip.hiredBid);
  if (!hiredBid) throw new Error(`No hired bid on ExpensesView`);

  const hiredBidConversation = trip.conversations.find(
    (c) => c.bid?.id === trip.hiredBid
  );
  if (!hiredBidConversation)
    throw new Error(`No hired bid conversation on ExpensesView`);

  return (
    <>
      <ControlBar>
        <PrimaryButton
          className="bg-white"
          outline
          size="small"
          onClick={navigateToTrips}
        >
          <i className="fa-solid fa-arrow-left" /> Trips
        </PrimaryButton>
        <SecondaryButton
          className="lg:hidden ml-auto"
          size="small"
          onClick={openDetailsModal}
        >
          <span>View Trip Details</span>
        </SecondaryButton>
        <SecondaryButton
          className="lg:hidden "
          size="small"
          onClick={openQuoteModal}
        >
          Review Quote
        </SecondaryButton>
      </ControlBar>
      <div className="flex flex-col gap-4">
        <Heading level={2}>Trip Processing</Heading>
        {trip.status === 'Expenses Approved' && (
          <Alert
            status="success"
            message={`Your expenses for ${trip.name} were approved.`}
          />
        )}
        {trip.status === 'Expenses Submitted' && (
          <Alert
            status="warning"
            message="Your expenses are awaiting approval."
          />
        )}
        {trip.status === 'Pilot Final Payment' && (
          <Alert
            status="info"
            message="Processing final payment for this trip."
          />
        )}
        <PrimaryTripLayout>
          {/** Operator profile area */}
          <div className="relative h-full">
            <ConsumerCard
              user={trip.conversations[0].user}
              className="h-full"
              userTitle="Client"
            />
          </div>
          {/** Chat area */}
          <div className="h-full flex flex-col gap-4">
            <PilotFinalCostCard
              expenses={{
                alterations: {
                  id: trip.id,
                  startDate: trip.startDate,
                  endDate: trip.endDate,
                  totalTravelDays: createQuoteFromBid(
                    hiredBid,
                    hiredBidConversation.user,
                    trip,
                    false
                  ).travelDays,
                },
                itemizedExpenses: hiredBid.expenses,
              }}
              cost={hiredBidConversation.cost}
              className="flex-grow"
            />
            <Chat tripId={trip.id} conversation={trip.conversations[0]} />
          </div>
          <div className="hidden lg:block h-full">
            {/** Trip card */}
            <TripCard
              trip={trip}
              showStatus={false}
              expanded="always"
              interactive={false}
              className="h-full"
            />
          </div>
          {/* TODO: render readonly expenses table */}
          <Card className="col-span-full" title="Final Expenses" responsive>
            <FinalExpensesForm
              expenses={{
                alterations: {
                  id: trip.id,
                  startDate: trip.startDate,
                  endDate: trip.endDate,
                  totalTravelDays: quote.travelDays,
                },
                itemizedExpenses: bid.expenses,
              }}
              handleSubmit={() => {}}
              handleCancel={() => {}}
              disabled
            />
          </Card>
        </PrimaryTripLayout>
      </div>
    </>
  );
}

export default ExpensesView;
