import React from 'react';

function Padlock() {
  return (
    <svg width="16px" height="21px" viewBox="0 0 16 21" version="1.1">
      <g
        id="kayak-pivot"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="Profile-+-Settings-Client"
          transform="translate(-293.000000, -645.000000)"
          fill="#C3C8CA"
        >
          <g id="profile" transform="translate(231.000000, 226.000000)">
            <g id="Group-2" transform="translate(45.000000, 108.000000)">
              <g id="Form" transform="translate(0.000000, 156.000000)">
                <g id="ic_lock" transform="translate(14.000000, 155.000000)">
                  <path
                    d="M11,15.5833333 C12.0083333,15.5833333 12.8333333,14.7583333 12.8333333,13.75 C12.8333333,12.7416667 12.0083333,11.9166667 11,11.9166667 C9.99166667,11.9166667 9.16666667,12.7416667 9.16666667,13.75 C9.16666667,14.7583333 9.99166667,15.5833333 11,15.5833333 L11,15.5833333 Z M16.5,7.33333333 L15.5833333,7.33333333 L15.5833333,5.5 C15.5833333,2.97 13.53,0.916666667 11,0.916666667 C8.47,0.916666667 6.41666667,2.97 6.41666667,5.5 L6.41666667,7.33333333 L5.5,7.33333333 C4.49166667,7.33333333 3.66666667,8.15833333 3.66666667,9.16666667 L3.66666667,18.3333333 C3.66666667,19.3416667 4.49166667,20.1666667 5.5,20.1666667 L16.5,20.1666667 C17.5083333,20.1666667 18.3333333,19.3416667 18.3333333,18.3333333 L18.3333333,9.16666667 C18.3333333,8.15833333 17.5083333,7.33333333 16.5,7.33333333 L16.5,7.33333333 Z M8.15833333,5.5 C8.15833333,3.9325 9.4325,2.65833333 11,2.65833333 C12.5675,2.65833333 13.8416667,3.9325 13.8416667,5.5 L13.8416667,7.33333333 L8.15833333,7.33333333 L8.15833333,5.5 L8.15833333,5.5 Z M16.5,18.3333333 L5.5,18.3333333 L5.5,9.16666667 L16.5,9.16666667 L16.5,18.3333333 L16.5,18.3333333 Z"
                    id="Shape"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Padlock;
