import { useState } from 'react';
import {
  StateButton,
  PrimaryButton,
} from '../../components/atoms/Button/Button';
import Heading from '../../components/typography/Heading';
import { useModalContext } from '../../hooks/ModalProvider/ModalProvider';
import PlaneType from '../../types/PlaneType';
import Select from '../../components/atoms/Select/Select';

interface AddModelModalProps {
  modelOptions: PlaneType[];
  handleNewModel: (modelPlaneType: PlaneType) => void;
}

export default function AddModelModal({
  modelOptions,
  handleNewModel,
}: AddModelModalProps) {
  const { closeModal } = useModalContext();

  const [selectedModel, setSelectedModel] = useState<PlaneType>(
    modelOptions[0]
  );

  const modelOptionsAsStrings = modelOptions.map((pt) => pt.craftName);

  const onSelect = (craftName: string) => {
    const matchingModel = modelOptions.find((pt) => pt.craftName === craftName);
    if (matchingModel) {
      setSelectedModel(matchingModel);
    }
  };

  const handleConfirm = () => {
    if (selectedModel) {
      handleNewModel(selectedModel);
    }
    closeModal();
  };

  return (
    <div className="flex flex-col gap-4 mb-24">
      <Heading level={3}>Add a Model</Heading>
      <Select
        options={modelOptionsAsStrings}
        onChange={onSelect}
        defaultvalue={selectedModel?.craftName || modelOptionsAsStrings[0]}
      />
      <div className="flex gap-4">
        <StateButton
          outline
          status="error"
          className="btn-sm"
          onClick={closeModal}
        >
          Cancel
        </StateButton>
        <PrimaryButton className="btn-sm" onClick={handleConfirm}>
          Confirm
        </PrimaryButton>
      </div>
    </div>
  );
}
