import { useState } from 'react';
import { usePilotCertificates } from '../../hooks/PilotCertificateProvider/PilotCertificateProvider';
import FileSelect from '../../components/atoms/FileSelect/FileSelect';
import Label from '../../components/atoms/Label';
import Select from '../../components/atoms/Select/Select';
import SettingsCard from '../../components/molecules/Card/SettingsCard';
import DayPicker from '../../components/molecules/DayPicker/DayPicker';
import Heading from '../../components/typography/Heading';
import { MedicalCertificateClassOptions } from '../../types/MedicalCertificateClass';
import { useUser } from '../../hooks/UserProvider/UserProvider';
import Loader from '../../components/molecules/Loader';
import { useToast } from '../../hooks/ToastProvider';

function MedicalCertificateView() {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const { user } = useUser();
  const { updateMedical } = usePilotCertificates();
  const { addToast } = useToast();

  if (!user || !user.pilotRole)
    throw new Error(`User or Pilot Role unavailable for MedicalCertificate`);

  const [state, setState] = useState({
    medicalClass: user.pilotRole.medicalClass,
    dateMedical: user.pilotRole.dateMedical,
    medicalDocument: user.pilotRole.medicalDocument,
  });

  function updateProperty<T>(prop: keyof typeof state, value: T) {
    setState({ ...state, [prop]: value });
  }

  const handleSave = async () => {
    setDisabled(true);
    setLoading(true);
    try {
      await updateMedical(state);
      addToast(<span>Saved medical certificate.</span>, 'success');
    } catch (err) {
      addToast(<span>Unable to save medical certificate.</span>, 'error');
    }
    setLoading(false);
  };

  const handleEdit = () => {
    setDisabled(false);
  };

  const handleCancel = () => {
    // TODO reset
    setDisabled(true);
  };

  const handleUploadMedicalCertificate = (file: File | null) => {
    if (!file) {
      addToast(<span>Invalid file type or contents.</span>, 'error');
    } else {
      updateProperty('medicalDocument', file);
    }
  };

  const imageStyle = {
    maxWidth: '200px',
    maxHeight: '200px',
    borderRadius: '10px',
    display: 'block',
    marginTop: '10px',
  };

  return (
    <SettingsCard
      onSave={handleSave}
      onEdit={handleEdit}
      onCancel={handleCancel}
      disabled={disabled}
    >
      <Heading level={2}>Medical</Heading>
      {loading ? (
        <Loader caption="Loading medical certificate..." />
      ) : (
        <div className="flex gap-4 flex-wrap">
          <div>
            <Label htmlFor="dateMedical">Date of Examination</Label>
            <DayPicker
              className="input"
              value={state.dateMedical ? new Date(state.dateMedical) : null}
              onChange={(d) =>
                updateProperty(
                  'dateMedical',
                  new Date(d).toISOString().split('T')[0]
                )
              }
              disabled={disabled}
              id="dateMedical"
            />
          </div>
          <div>
            <Label htmlFor="medicalCertificateClass">
              Medical Certificate Class
            </Label>
            <Select
              options={MedicalCertificateClassOptions}
              prompt="Class"
              className="max-w-fit"
              defaultvalue={state?.medicalClass}
              onChange={(e) => updateProperty('medicalClass', e)}
              disabled={disabled}
            />
          </div>
          <div>
            <div>
              <Label htmlFor="front">Upload Medical Certificate (8500-9)</Label>
              <FileSelect
                disabled={disabled}
                onChange={handleUploadMedicalCertificate}
                value={state.medicalDocument || null}
                id="medicalDocument"
              />
            </div>
          </div>
        </div>
      )}
    </SettingsCard>
  );
}

export default MedicalCertificateView;
