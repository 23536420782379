const endpoint = `/api/trips/as_consumer`;

const cancelTrip = async (tripId: string, reason: string) => {
  const response = await fetch(`${endpoint}/${tripId}/cancel/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Token ${localStorage.getItem('key')}`,
    },
    credentials: 'include',
    body: JSON.stringify({
      cancel_reason_type: reason,
      cancel_reason: reason,
    }),
  });

  if (response.status !== 204) {
    throw new Error('Unable to cancel trip');
  } else {
    return {
      statusCode: response.status,
    };
  }
};

export default cancelTrip;
