import * as Yup from 'yup';

const validAircraftInsuranceSchema = Yup.object({
  insurance: Yup.object().shape({
    companyName: Yup.string().required('Required'),
    insuranceContactName: Yup.string().optional(),
    insuranceContactEmail: Yup.string().optional(),
    openPilotClause: Yup.object()
      .required()
      .shape({
        totalFlightHours: Yup.number().required('Required'),
        planeTypeFlightHours: Yup.number().required('Required'),
        multiEngineHours: Yup.number().required('Required').nullable(),
        turboJetHours: Yup.number().required('Required').nullable(),
        turbineHours: Yup.number().required('Required').nullable(),
      }),
  }),
});

export default validAircraftInsuranceSchema;
