import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AnyObject } from 'yup';
import loginApi from '../../API/Auth/login';
import {
  register as registerApi,
  RegistrationError,
  RegistrationFields,
} from '../../API/Auth/register';
import getUser from '../../API/User/getUser';
import User from '../../types/User';
import GetUserResponse from '../../API/User/GetUserResponse';
import { ROUTES, UNPROTECTED_ROUTES } from '../../routes/routerConfig';
import getAircraft from '../../API/Aircraft/getAircraft';
import getPaymentMethods from '../../API/Payments/getPaymentMethods';
import Heading from '../../components/typography/Heading';
import fetchAPI from '../../API/util';
import isEmptyObject from '../../utilities/isEmptyObject';
import objectToString from '../../utilities/objectToString';
import {
  remapKeysToCamelCase,
  remapKeysToSnakeCase,
} from '../../utilities/remapKeys';

type LogInFunction = (email: string, password: string) => void;

interface UserProviderProps {
  user: User | null;
  children: ReactNode;
}

type UserRole = 'pilot' | 'consumer';

type SetUserRoleFunction = (newRole: UserRole) => void;

interface UpdateUserProps {
  id: number;
  image?: File | null;
  data?: {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
  };
}

interface IUserContext {
  user: User | null;
  refreshUser: () => Promise<unknown>;
  login: LogInFunction;
  role: UserRole;
  setUserRole: SetUserRoleFunction;
  hasKey: () => boolean;
  logout: () => void;
  operatorOnboardingStatus: OperatorOnboardingStatus | null;
  pilotOnboardingStatus: PilotOnboardingStatus | null;
  loadingOperatorStatus: boolean;
  loadingPilotStatus: boolean;
  updateUser: (props: UpdateUserProps) => Promise<void>;
}

type PilotOnboardingStatus = {
  generalInformation: boolean;
  certificatesAndRatings: boolean;
  hoursAndTraining: boolean;
  questions: boolean;
  billingAndPayments: boolean;
  statusContent: null | React.ReactNode;
};

type OperatorOnboardingStatus = {
  generalInformation: boolean;
  aircraft: boolean;
  billingAndPayments: boolean;
  statusContent: null | React.ReactNode;
};

/**
 * This function examines the pilot role object to determine
 * how far along the user has progressed into the onboarding
 * flow.
 * @returns PilotOnboardingStatus
 */
const getPilotOnboardingStatus = async (user: User) => {
  const onboardingStatus: PilotOnboardingStatus = {
    generalInformation: true,
    certificatesAndRatings: true,
    hoursAndTraining: true,
    questions: true,
    billingAndPayments: true,
    statusContent: null,
  };

  if (!user) return onboardingStatus;

  let numIncomplete = 0;

  // Check if phone number or home location are missing
  if (!user?.pilotRole.phoneNumber || !user.pilotRole.homeLocation) {
    onboardingStatus.generalInformation = false;
    numIncomplete++;
  }
  if (
    !user?.pilotRole.medicalClass ||
    !user.pilotRole.dateMedical ||
    user.pilotRole.licenseCerts.length < 1
  ) {
    onboardingStatus.certificatesAndRatings = false;
    numIncomplete++;
  }
  if (
    !user?.pilotRole.allAircraftHours ||
    user?.pilotRole.statistics.planeTypeStatistics.length < 1
  ) {
    onboardingStatus.hoursAndTraining = false;
    numIncomplete++;
  }
  if (user?.pilotRole.aircraftClaim === null) {
    onboardingStatus.questions = false;
    numIncomplete++;
  }
  const getPaymentMethodsResults = await getPaymentMethods();
  if (
    getPaymentMethodsResults.bank_accounts.length < 1 ||
    getPaymentMethodsResults.requires_stripe_onboarding
  ) {
    onboardingStatus.billingAndPayments = false;
    numIncomplete++;
  }

  if (numIncomplete)
    onboardingStatus.statusContent = (
      <div>
        <Heading level={3} className="mb-4">
          Before you can create quotes, please complete the following profile
          sections:
        </Heading>
        <ul className="flex flex-col gap-4">
          {!onboardingStatus.generalInformation && (
            <li>
              <Link to={ROUTES.GENERAL_INFORMATION} className="underline">
                General Information
              </Link>
            </li>
          )}

          {!onboardingStatus.certificatesAndRatings && (
            <li>
              <Link className="underline" to={ROUTES.CERTIFICATES_AND_RATINGS}>
                Certificates and Ratings
              </Link>
            </li>
          )}

          {!onboardingStatus.hoursAndTraining && (
            <li>
              <Link to={ROUTES.HOURS_TRAINING} className="underline">
                Hours and Training
              </Link>{' '}
              - You must add your total hours and provide a type rating or
              aircraft qualification.
            </li>
          )}

          {!onboardingStatus.questions && (
            <li>
              <Link className="underline" to={ROUTES.QUESTIONS}>
                Questions
              </Link>
            </li>
          )}
          {!onboardingStatus.billingAndPayments && (
            <li>
              <Link className="underline" to={ROUTES.BILLING_AND_PAYMENTS}>
                Billing and Payments
              </Link>
            </li>
          )}
        </ul>
      </div>
    );

  return onboardingStatus;
};

/**
 * This function examines the operator role object to determine
 * how far along the user has progressed into the onboarding
 * flow.
 * @returns OperatorOnboardingStatus
 */
const getOperatorOnboardingStatus = async (user: User) => {
  const onboardingStatus: OperatorOnboardingStatus = {
    generalInformation: true,
    aircraft: true,
    billingAndPayments: true,
    statusContent: null,
  };

  if (!user) return onboardingStatus;

  const aircraft = await getAircraft();

  let numIncomplete = 0;

  // Check if phone number or position are missing
  if (!user?.consumerRole.phoneNumber || !user.consumerRole.position) {
    onboardingStatus.generalInformation = false;
    numIncomplete++;
  }

  // Make sure there is at least one valid aircraft
  if (!aircraft.some((ac) => ac.isValid)) {
    onboardingStatus.aircraft = false;
    numIncomplete++;
  }

  const paymentMethods = await getPaymentMethods();

  if (!paymentMethods.payment_methods.length) {
    onboardingStatus.billingAndPayments = false;
    numIncomplete++;
  }

  if (numIncomplete)
    onboardingStatus.statusContent = (
      <div>
        <Heading level={3} className="mb-4">
          Before you can create trips, please complete the following profile
          sections:
        </Heading>
        <ul className="flex flex-col gap-4">
          {!onboardingStatus.generalInformation && (
            <li>
              <Link to={ROUTES.GENERAL_INFORMATION} className="underline">
                General Information
              </Link>{' '}
              - You must complete required fields.
            </li>
          )}

          {!onboardingStatus.aircraft && (
            <li>
              <Link className="underline" to={ROUTES.MY_AIRCRAFT}>
                Aircraft
              </Link>{' '}
              - You must add a valid aircraft.
            </li>
          )}

          {!onboardingStatus.billingAndPayments && (
            <li>
              <Link to={ROUTES.BILLING_AND_PAYMENTS} className="underline">
                Billing and Payments
              </Link>{' '}
              - You must add a payment method.
            </li>
          )}
        </ul>
      </div>
    );

  return onboardingStatus;
};

async function uploadAvatar(image: File) {
  if (image.size > 5000000) {
    throw new Error(
      'Your photo needs to be less than 5MB and in JPG, PNG, or GIF format'
    );
  }
  try {
    const formData = new FormData();
    formData.append('image', image);

    await fetchAPI('/api/avatars/', {
      method: 'POST',
      headers: {
        Authorization: `Token ${localStorage.getItem('key')}`,
      },
      body: formData,
    });

    //  if (response && response.image)
    //  { type: AVATAR_UPLOAD_SUCCESS, avatar: response }
    //  handleChange('avatar', response.image));
    //  toast('Updated successfully')
  } catch (err) {
    console.log(err);
  }
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const UserContext = createContext<IUserContext>(undefined!);

export function UserProvider({ user: propsUser, children }: UserProviderProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<User | null>(propsUser);
  const [role, setRole] = useState<UserRole>(
    (localStorage.getItem(`role-${user?.id}`) as UserRole) || 'consumer'
  );
  const [operatorOnboardingStatus, setOperatorOnboardingStatus] =
    useState<OperatorOnboardingStatus | null>(null);
  const [pilotOnboardingStatus, setPilotOnboardingStatus] =
    useState<PilotOnboardingStatus | null>(null);

  const [loadingOperatorStatus, setLoadingOperatorStatus] = useState(true);
  const [loadingPilotStatus, setLoadingPilotStatus] = useState(true);

  const logout = () => {
    setUser(null);
    setLoadingOperatorStatus(true);
    setLoadingPilotStatus(true);
  };

  const clearOperatorStatus = () => {
    setOperatorOnboardingStatus(null);
    setLoadingOperatorStatus(false);
  };

  const clearPilotStatus = () => {
    setPilotOnboardingStatus(null);
    setLoadingPilotStatus(false);
  };

  const updatePilotStatus = useCallback(async (user: User) => {
    clearOperatorStatus();
    setLoadingPilotStatus(true);
    const pilotStatus = await getPilotOnboardingStatus(user);
    setPilotOnboardingStatus(pilotStatus);
    setLoadingPilotStatus(false);
  }, []);

  const updateOperatorStatus = useCallback(async (user: User) => {
    clearPilotStatus();
    setLoadingOperatorStatus(true);
    const operatorStatus = await getOperatorOnboardingStatus(user);
    setOperatorOnboardingStatus(operatorStatus);
    setLoadingOperatorStatus(false);
  }, []);

  const refreshOnboardingStatus = useCallback(async () => {
    if (!user) return;
    if (role === 'pilot') {
      await updatePilotStatus(user);
    } else {
      await updateOperatorStatus(user);
    }
  }, [role, updateOperatorStatus, updatePilotStatus, user]);

  const setUserRole = useCallback(
    async (newRole: UserRole) => {
      if (!user) return;
      setRole(newRole);
      localStorage.setItem(`role-${user?.id}`, newRole);
      if (newRole === 'pilot') {
        await updatePilotStatus(user);
      } else {
        await updateOperatorStatus(user);
      }
    },
    [updateOperatorStatus, updatePilotStatus, user]
  );

  const initializeRole = useCallback(
    async (user: User) => {
      const storedRole = localStorage.getItem(`role-${user.id}`);

      if (storedRole) {
        // If there was already a role saved for this user, set it in state
        setRole(storedRole as UserRole);
        if (storedRole === 'pilot') {
          await updatePilotStatus(user);
        } else {
          await updateOperatorStatus(user);
        }
      } else {
        // Make an assumption about what role to intialize with
        // If the user has a pilot role, assume that's what they want to log into
        const assumedRole = user.pilotRole ? 'pilot' : 'consumer';
        localStorage.setItem(`role-${user.id}`, assumedRole);
        setRole(assumedRole as UserRole);
        if (assumedRole === 'pilot') {
          await updatePilotStatus(user);
        } else {
          await updateOperatorStatus(user);
        }
      }
    },
    [updateOperatorStatus, updatePilotStatus]
  );

  const getAndSetUser = useCallback(async () => {
    const getUserResponse = await getUser();
    setUser(getUserResponse);
    await initializeRole(getUserResponse);
  }, [initializeRole]);

  useEffect(() => {
    const f = async () => {
      // User is not logged in, but we have a session key
      // Use it to get the user
      if (!user && localStorage.getItem('key')) {
        await getAndSetUser();
        // navigate to login if not accessing an unprotected route
      } else if (
        !Object.values(UNPROTECTED_ROUTES).includes(location.pathname)
      ) {
        navigate(ROUTES.LOGIN);
      }
    };
    f();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshUser = useCallback(async () => {
    await getAndSetUser();
  }, [getAndSetUser]);

  const updateUser = useCallback(
    async ({ id, image, data }: UpdateUserProps) => {
      try {
        // if File, then user has changed avatar image
        if (image instanceof File) {
          await uploadAvatar(image);
          // TODO set this uploaded path in parsedData
        }

        if (isEmptyObject(data)) return;

        const formattedData = remapKeysToSnakeCase(data as AnyObject) as Record<
          string,
          unknown
        >;
        // Filter out keys with empty values from formattedData
        const filteredData = Object.fromEntries(
          Object.entries(formattedData).filter(([key, value]) => value !== '')
        );

        if (
          Object.prototype.hasOwnProperty.call(filteredData, 'phone_number') &&
          typeof filteredData.phone_number === 'string' &&
          !filteredData.phone_number.startsWith('+1')
        ) {
          if (filteredData.phone_number.startsWith('1')) {
            filteredData.phone_number = `+${filteredData.phone_number}`;
          } else {
            filteredData.phone_number = `+1${filteredData.phone_number}`;
          }
        }

        const response = await fetchAPI(`/api/users/${id}/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(filteredData),
        });

        if (response.statusCode !== 200) {
          const { statusCode, ...rest } = response;
          throw new Error(
            `An error occurred while updating the user: ${objectToString(rest)}`
          );
        }
        return;
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    []
  );

  const register = async ({
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
  }: RegistrationFields) => {
    const registerResult = await registerApi({
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
    });

    if (registerResult.statusCode === 201) {
      setUser(registerResult as GetUserResponse);
      return { success: true, errors: [] };
    }
    const errors = Object.values(registerResult as RegistrationError);
    return { success: false, errors };
  };

  const login = useCallback(
    async (email: string, password: string) => {
      const loginResult = await loginApi(email, password);
      setUser(loginResult);
      await initializeRole(loginResult);
    },
    [initializeRole]
  );

  const hasKey = () => !!localStorage.getItem('key');

  const value = useMemo(() => {
    return {
      user,
      refreshUser,
      setUser,
      login,
      register,
      setUserRole,
      role,
      hasKey,
      logout,
      operatorOnboardingStatus,
      pilotOnboardingStatus,
      loadingOperatorStatus,
      loadingPilotStatus,
      updateUser,
    };
  }, [
    user,
    refreshUser,
    login,
    setUserRole,
    role,
    operatorOnboardingStatus,
    pilotOnboardingStatus,
    loadingOperatorStatus,
    loadingPilotStatus,
    updateUser,
  ]);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export const useUser = () => useContext<IUserContext>(UserContext);
