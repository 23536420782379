import User from '../../types/User';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';
import GetUserResponse from './GetUserResponse';

const endpoint = `/api/users/`;

const GetUser = () => {
  return new Promise<GetUserResponse>((resolve, reject) => {
    try {
      fetch(endpoint, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Authorization: `Token ${localStorage.getItem('key')}`,
        },
      })
        .then(async (response) => {
          const json: User = await response.json();

          resolve({
            ...(remapKeysToCamelCase(json) as typeof json),
            statusCode: response.status,
          });
        })
        .catch((error) => {
          reject(error);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export default GetUser;
