// Import packages
import classNames from 'classnames';
// Import shared app components and types
import Heading from '../../typography/Heading';
import Card from '../../molecules/Card/Card';
import { PER_DIEM_DEFAULT, Quote } from '../../../types/Quote';

interface QuoteCardProps {
  className?: string;
  quote: Quote;
  title?: string;
}

function QuoteCard({ className, quote, title }: QuoteCardProps) {
  const FLYING_TOTAL = quote.flyingDays * quote.flyingDayRate;
  const TRAVEL_TOTAL = quote.travelDays * quote.travelDayRate;
  const PER_DIEM_DAYS = quote.flyingDays + quote.travelDays;
  const PER_DIEM_TOTAL = quote.perDiemRate * PER_DIEM_DAYS;
  const EXPENSES_TOTAL =
    quote.airTravelCosts +
    quote.groundTravelCosts +
    quote.hotelCosts +
    quote.otherCosts;
  const GRAND_TOTAL =
    FLYING_TOTAL + TRAVEL_TOTAL + PER_DIEM_TOTAL + EXPENSES_TOTAL;

  return (
    <Card responsive noPadding className={classNames('@container', className)}>
      <div className="py-2 background bg-primary rounded-t-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          {title || 'Quote Totals'}
        </Heading>
      </div>
      <div className="grid p-6 gap-4 @sm:grid-cols-2">
        <div>
          <Heading level={4}>Pilot Services</Heading>
          <div className="flex flex-col gap-1">
            <span>
              {quote.flyingDays} days @ ${quote.flyingDayRate}/day
            </span>
            <span className="underline">Total: ${FLYING_TOTAL}</span>
          </div>
        </div>
        <div>
          <Heading level={4}>Travel Days</Heading>
          <div className="flex flex-col gap-1">
            <span>
              {quote.travelDays} days @ ${quote.travelDayRate}/day
            </span>
            <span className="underline">Total: ${TRAVEL_TOTAL}</span>
          </div>
        </div>
        <div>
          <Heading level={4}>Per Diem</Heading>
          <span>
            {PER_DIEM_DAYS} days @ ${PER_DIEM_DEFAULT}/day
          </span>
          <br />
          <span className="underline">Total: ${PER_DIEM_TOTAL}</span>
        </div>
        <div>
          <Heading level={4}>Estimated Expenses</Heading>
          Air Travel: <span>${quote.airTravelCosts}</span>
          <br />
          Ground Travel: <span>${quote.groundTravelCosts}</span>
          <br />
          Hotel: <span>${quote.hotelCosts}</span>
          <br />
          Other: <span>${quote.otherCosts}</span>
          <br />
          <span className="underline">Total: ${EXPENSES_TOTAL}</span>
        </div>
      </div>
      <div className="mt-auto py-2 background bg-primary rounded-b-lg">
        <Heading
          level={4}
          className="uppercase text-center text-white font-normal py-2"
        >
          Grand Total: ${GRAND_TOTAL}
        </Heading>
      </div>
    </Card>
  );
}

export default QuoteCard;
