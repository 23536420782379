import classNames from 'classnames';
import React from 'react';

export interface ToastProps {
  children?: React.ReactNode;
  className?: string;
}

export function Toast({ children, className }: ToastProps) {
  return (
    <div
      key={children?.toString().substring(0, 5)}
      className={classNames(
        `alert min-w-[75vw] max-w-[95vw] !whitespace-normal`,
        className
      )}
    >
      {children}
    </div>
  );
}
