import React from 'react';
import { useFormikContext } from 'formik';
import FormikInput from '../../../atoms/Form/FormikInput';
import FormikField from '../../../atoms/Form/FormikField';
import FormikDropdown from '../../../atoms/Form/FormikDropdown';
import FormikCheckbox from '../../../atoms/Form/FormikCheckbox';
import { Airplane } from '../../../../types/Airplane';
import PlaneType from '../../../../types/PlaneType';
import Tooltip from '../../../atoms/Tooltip/Tooltip';

interface AircraftConfigurationProps {
  currentPlaneType: PlaneType;
}

function AircraftConfiguration({
  currentPlaneType,
}: AircraftConfigurationProps) {
  const {
    values: { model },
    touched,
    setFieldValue,
  } = useFormikContext<Omit<Airplane, 'id'>>();

  return (
    <>
      <FormikDropdown
        name="configuration.avionics"
        label="Avionics"
        placeholder="Choose your avionics"
        options={
          currentPlaneType?.avionics?.map((avionics) => ({
            label: avionics.avionicsType,
            value: avionics.id,
          })) || []
        }
        optional={false}
      />
      <FormikField
        name="configuration.basicEmptyWeight"
        label="Basic Empty Weight"
        placeholder="25,000"
        component={FormikInput}
      />

      <FormikDropdown
        name="configuration.lavatoryType"
        label="Lavatory Type"
        placeholder="Select your lavatory type"
        options={
          currentPlaneType?.lavatory?.map((lv) => ({
            label: lv.lavatoryType,
            value: lv.id,
          })) || []
        }
        optional={false}
      />
      <FormikField
        name="configuration.isRvsmCertified"
        label={
          <>
            Is your aircraft
            <Tooltip trigger="RVSM">
              Reduced Vertical Separation Minimum
            </Tooltip>
            certified?
          </>
        }
        inline
        component={FormikCheckbox}
        optional
      />
      <FormikDropdown
        name="configuration.seatingConfiguration"
        label="Seating Configuration"
        placeholder="Select your seating configuration"
        options={
          currentPlaneType?.seating?.map((seat) => ({
            label: seat.seatingType,
            value: seat.id,
          })) || []
        }
        optional={false}
      />

      {currentPlaneType.hasPropeller ? (
        <FormikDropdown
          name="configuration.propellerType"
          label="Propeller Type"
          placeholder="Enter your propeller type..."
          options={
            currentPlaneType?.propeller?.map((p) => ({
              label: p.propellerType,
              value: p.id,
            })) || []
          }
          optional={false}
        />
      ) : (
        <FormikDropdown
          name="configuration.propellerType"
          label="Propeller Type"
          disabled
          options={[
            {
              label: 'NO PROPELLER',
              value: null,
            },
          ]}
        />
      )}
    </>
  );
}

export default AircraftConfiguration;
