import Heading from '../../typography/Heading';
import Paragraph from '../../typography/Paragraph';

interface LocationProps {
  displayName: string;
  icaoCode: string;
  date: Date;
  className?: string;
}

function Location({ displayName, icaoCode, date, className }: LocationProps) {
  return (
    <div className={className}>
      <Paragraph className="underline">{displayName}</Paragraph>
      <Heading level={3} className="font-normal text-2xl my-2">
        {icaoCode}
      </Heading>
      <Paragraph>
        {date.toLocaleDateString('en-US', {
          timeZone: 'UTC',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })}
      </Paragraph>
    </div>
  );
}

export default Location;
