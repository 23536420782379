import { useState } from 'react';
import Input from '../../components/atoms/Input';
import Heading from '../../components/typography/Heading';
import Label from '../../components/atoms/Label';
import Card from '../../components/molecules/Card/Card';
import SettingsCard from '../../components/molecules/Card/SettingsCard';

interface TravelRateCardProps {
  rate: number;
  updateRate: (newRate: number) => unknown;
}

function TravelRateCard({ rate, updateRate }: TravelRateCardProps) {
  const [disabled, setDisabled] = useState(true);

  const [newRate, setNewRate] = useState(rate);

  const handleCancel = () => {
    setNewRate(rate);
    setDisabled(true);
  };

  const handleSave = () => {
    updateRate(newRate);
    setDisabled(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewRate(parseFloat(e.target.value));
  };

  return (
    <SettingsCard
      disabled={disabled}
      onEdit={() => setDisabled(false)}
      onCancel={handleCancel}
      onSave={handleSave}
      responsive
    >
      <Heading level={3}>Default Travel Day Rate</Heading>
      <Label htmlFor="rate">Rate</Label>
      <Input
        id="rate"
        value={newRate}
        disabled={disabled}
        onChange={handleChange}
        type="number"
      />
    </SettingsCard>
  );
}

export default TravelRateCard;
