import { PilotRole } from '../../types/PilotRole';
import isEmptyObject from '../../utilities/isEmptyObject';
import { remapKeysToCamelCase } from '../../utilities/remapKeys';
import fetchAPI from '../util';
import createExpenses from './createExpenses';
import { PostQuoteResponse } from '../../types/PostQuoteResponse';
import { Quote } from '../../types/Quote';

type CreateQuotePayload = {
  aircraft_price_per_day_override: number;
  extra_travel_days: string;
  is_certified: boolean;
  notes: string;
  expenses: Array<any>;
  trip: string;
};

async function postQuote(
  pilot: PilotRole,
  id: string,
  quote: Quote
): Promise<PostQuoteResponse | null> {
  try {
    if (isEmptyObject(quote)) return null;

    const payload: CreateQuotePayload = {
      aircraft_price_per_day_override: quote.flyingDayRate,
      extra_travel_days: String(quote.travelDays),
      is_certified: true,
      notes: '',
      expenses: [
        {
          cost: quote.hotelCosts,
          title: 'Hotel',
        },
        {
          cost: quote.groundTravelCosts,
          title: 'Ground Travel',
        },
        {
          cost: quote.airTravelCosts,
          title: 'Air Travel',
        },
        {
          cost: quote.otherCosts,
          title: 'Other',
        },
      ],
      trip: id,
    };
    const postQuoteResponse = await fetchAPI(`/api/trips/as_pilot/${id}/bid/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (postQuoteResponse.statusCode === 200) {
      await createExpenses(pilot, id, quote);
      return remapKeysToCamelCase(postQuoteResponse.bid) as PostQuoteResponse;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export default postQuote;
