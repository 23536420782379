import PlaneType from '../../../types/PlaneType';

export type AirplaneCardProps = {
  className?: string;
  imgSrc?: PlaneType['imageUrl'];
  imgAlt?: string;
  type?: PlaneType['craftType'];
  pilotCount?: number;
  ttAvg?: number;
  mmAvg?: number;
};

export default function AirplaneCard({
  className,
  imgSrc,
  imgAlt,
  type,
  pilotCount,
  ttAvg,
  mmAvg,
}: AirplaneCardProps) {
  return (
    <article
      className={`flex flex-col lg:flex-row items-center gap-4 ${
        className ?? ''
      }`}
    >
      {imgSrc && (
        <div className="w-full lg:w-1/2">
          <img className="rounded-md" src={imgSrc} alt={imgAlt} />
        </div>
      )}
      <div className="text-center lg:text-left">
        <ul className="font-bold ml-0 text-l">
          {type !== undefined && <li>Type: {type}</li>}
          {pilotCount !== undefined && <li>Pilots: {pilotCount.toString()}</li>}
          {ttAvg !== undefined && <li>TT(Avg): {ttAvg} Hrs.</li>}
          {mmAvg !== undefined && <li>MM(Avg): {mmAvg} Hrs.</li>}
        </ul>
      </div>
    </article>
  );
}
