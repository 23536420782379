// Import sub-components and views nested within this view
import ConsumerView from './ConsumerView';
import PilotView from './PilotView';
// Import hooks and utilities
import { useUser } from '../../hooks/UserProvider/UserProvider';

/**
 * Renders the /general-information route with a UI matching the user's active role.
 * @returns JSX.Element
 */
function GeneralInformationPage() {
  const { role } = useUser();

  return role === 'consumer' ? <ConsumerView /> : <PilotView />;
}

export default GeneralInformationPage;
